import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SalesService {


  // public url   = 'http://localhost:20001';
  public url   = 'https://g2.clubgournet.cl:20001';


  token               = localStorage.getItem('token');
  datos:HttpHeaders   = new HttpHeaders({
    'Authorization': 'Bearer ' + this.token,
    'Content-Type': 'application/json' // Si es necesario, también configura otros encabezados aquí
  });

  constructor(private http: HttpClient) { }



  traeDatos( ruta:string ) {
    const options = { headers: this.datos }; // Configura los encabezados en un objeto de opciones
    return this.http.get( this.url + ruta, { headers:this.datos} );
  }

  guardarDato(ruta, body) {
    const options = { headers: this.datos }; 
    return this.http.post( this.url + ruta , body, options );
  }



}
