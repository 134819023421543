export class turnoModel {
    id: number;
    storeId: number;
    name:string;
    start: string;
    finish: string;
    position: number
    status:number;
    constructor() {
        this.id             = 0;
        this.storeId        = 0;
        this.name           = '';
        this.start          = '';
        this.finish         = '';
        this.position       = 0;
        this.status         = 1;
    }
}


