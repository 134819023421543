export class tableModel {
    id: string;
    storeId: number;
    code: string;
    sectorId: number;
    openedAt: string;
    date: string;
    realDate: string;
    userId: number;
    isOpen: number;
    openFrom: string;
    hold: number;
    comment: string;
    npeople: number;
    status: number;    
    
    constructor() {
        this.id             = '';
        this.storeId        = 0;
        this.code           = ''; //MAX 10
        this.sectorId       = 0;
        this.openedAt       = ''; // Hora apertura.
        this.date           = ''; // Fecha del turno
        this.realDate       = ''; // fecha real.
        this.userId         = 0;
        this.isOpen         = 0;  // Si estan digitando algo en la mesa para que no entren dos personas la mismo tiempo.
        this.openFrom       = ''; // Desde que dispositivo se abrió. ej: celular garzon X
        this.hold          = 0;  // creo que es para dejarla en espera por que hay un producto pedido pero no comandado.
        this.comment        = '';
        this.npeople        = 1;  // Cuantos comensales hay en la mesa.
        this.status         = 1;  //    0 borrado 1 libre, 2 ocupada, 3 comandada, 5 precuenta
    }


 
}




