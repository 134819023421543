
<div class="row mt-4">
    <div class="col-12 col-md-8  offset-md-2">
        <h3>Data</h3>
        <p>{{data}}</p>
        <hr>
        <h3>Sales</h3>
        <p>{{sales}}</p>
        <hr>
        <h3>Trans</h3>
        <p>{{trans}}</p>
        <hr>
        
    </div>
</div>


<div class="row">
    <div class="col-4">
        <button class="btn btn-success" (click)="getDocumentos()">Documentos</button>
    </div>
</div>