export class CancellationModel {
    id: string;
    headId: string;
    detSaleId: string;
    storeId: number;
    sellerId: number;
    productName: string;
    total: number;
    itemCost: number;
    reason: string;
    authorizedBy: number;
    created_at: string;

    constructor() {
        this.id                 = '';
        this.headId             = '';
        this.detSaleId          = '';
        this.storeId            = 0;
        this.sellerId           = 0;
        this.productName        = '';
        this.total              = 0;
        this.itemCost           = 0;
        this.reason             = '';
        this.authorizedBy       = 0;
        this.created_at         = '';
    }
}
