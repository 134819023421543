import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { TransactionsService } from './transactions.service';
import { Router } from '@angular/router';
import { MensajesService } from './mensajes.service';

@Injectable({
  providedIn: 'root'
})
export class ShiftService {

  params = JSON.parse(localStorage.getItem('paramsGournet'));


  constructor(
            private conexData: DataService,
            private conexTrans: TransactionsService,
            private router: Router,
            private _m:MensajesService) { 
            console.log('ACA PARAMS', this.params);
            }




// BUSCO TURNOS ABIERTOS
openShift(storeId, cajaId){
  console.log('opneShift', storeId, cajaId);
  this.conexTrans.getData(`/trans/openShift/${storeId}/${cajaId}`).subscribe({
    next: (data: any) => {
      console.log('data', data);
      
      if (data == null){
        console.log('No hay turnos Abierto');
        this.router.navigateByUrl('general/shift/menu');
        return;        
      } 

      console.log('hay info', data);
      console.log('params turno', this.params);
      if(this.params.turno != data){
        this.params.turno  = data;
        localStorage.setItem('paramsGournet', JSON.stringify(this.params));
      }
    },
    error: (error) => {
      console.error(error);
      this._m.error('Error Turno', 'No se pudo conectar a la BD');
      this.router.navigateByUrl('/home');
    }
  });
}



  modificarTurnos(turnos){
    for (let t of turnos){
      t.start2 = this.modifHora(t.start);
      t.finish2 = this.modifHora(t.finish);
    }
    return turnos;
  }


  modifHora(hora){
    const date = new Date(hora);
    const hh = String(date.getUTCHours()).padStart(2, '0');
    const mm = String(date.getUTCMinutes()).padStart(2, '0');
    const timeValue = `${hh}:${mm}`;
    return timeValue
  }

  // openShift(){
  //   this.con
  // }



}
