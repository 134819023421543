<app-loading *ngIf="loading"></app-loading>

<div class="row header azul r0" (click)="info()">
    <div class="col-12">
       <h2 class="animate__animated animate__fadeIn">            
        <i class="fa-solid fa-sack-dollar icono"></i>
        Pagos</h2>
    </div>
</div>

<div class="row headMenu animate__animated animate__fadeIn r0 pad20 shadow r0  ">
    <div class="col-12 hvr-forward">
        <p class=" botonMenu animate__animated animate__pulse" data-bs-toggle="offcanvas" data-disableScrolling="false" href="#menuStats" role="button" aria-controls="menuStats"> < Menu estadisticas </p>
    </div>

    <div class="col-12 col-md" > 
        <label>Escoge en local</label>
        <select [(ngModel)]="selectedStore" (change)="onStoreChange($event.target.value)" class="form-select">
            <option *ngFor="let store of stores" [value]="store.id">
                {{ store.name }}
            </option>
        </select>
    </div>

    <div class="col-12 col-md">
        <div class="form-group">
            <label>Fecha Inicial</label>
            <input type="date" class="form-control" placeholder="Inicio Periodo" [(ngModel)]="firstDay" name="fechaIni" required>
        </div>
    </div>
    <div class="col-12 col-md">
        <div class="form-group">
            <label>Fecha Final</label>
            <input type="date" class="form-control" placeholder="Fin Periodo" [(ngModel)]="lastDay" name="fechaFin" required>
        </div>
    </div>
 

    <div class="col-12 col-md-2 mt-4">
      <button class="btn btn-primary" (click)="filtrar()">Buscar</button>
    </div>
</div> 


<div class="row contenedor  r0 listaPagos">
    <div class="col-12 col-md-10 offset-md-1 bg-light shadow">




<div class="row generales r0">
    <div class="col-12 col-md-6">

        <div class="row cardData  bg-dark text-light shadow mt-2">
            <div class="col-6">
                <h1>Pagos<br> Totales</h1>
            </div>
            <div class="col-6">
                <h2 class="text-end mt-4">${{ totalVentas | separator:0}}</h2>
            </div>
        </div>

        <div class="row azul cardData agrupados" style="margin-top:20px;">
            <div class="col-6">
               <p *ngFor="let p of agrupados"><span>{{p.name}}</span></p>
            </div>
            <div class="col-6 text-end">
               <p *ngFor="let p of agrupados">${{p.total | separator:0}} | {{p.pagos.length | separator:0}}</p>
            </div>
        </div>
    </div>
    <div class="col-12 col-md-6 text-center">

        <canvas *ngIf="agrupados.length > 0"
                baseChart
                class="chart"
                [data]="pieChartData"
                [type]="pieChartType"
                >
        </canvas>

    </div>
</div>


<div class="row">
    <div class="col-12">

        <table class="table table-striped table-hover">
            <thead>
            <tr>
                <th scope="col">#</th>
                <th scope="col">Medio de Pago</th>
                <th scope="col">Monto</th>
                <th scope="col">Fecha</th>
                <th scope="col">Hora</th>
                <th scope="col">status</th>
            </tr>
            </thead>
            <tbody *ngIf="pagos.length > 0" >
            <tr *ngFor="let p of pagos | filter : { 'payName' : searchString, 'date' : searchString, 'amountA' : searchString} ; index as i " 
            class="pointer" 
            [ngClass]="p.status == 2 ? '' : 'ojo' "
            (click)="selectPay(p)"
            data-bs-toggle="offcanvas" data-bs-target="#offDetalle" aria-controls="offDetalle"
            >
                <th scope="row">{{i + 1}}</th>
                <td [ngClass]="p.payType == 'NC' ? 'text-warning' : '' ">{{p.payName}}</td>
                <td > $ {{p.amountA | separator : 0  }}</td>
                <td >{{p.date | date: 'dd/MM/YYY'  }}</td>
                <td >{{p.date | date: 'HH:mm'  }}</td>
                <td *ngIf="p.status == 1 || p.status == 3"><i class="fa-solid fa-hourglass-half text-warning"></i> </td>
                <td *ngIf="p.status == 2"><i class="fa-solid fa-check text-success"></i> </td>
                <td *ngIf="p.status == 4"><i class="fas fa-times text-danger"></i> </td>
            </tr>
            </tbody>
            <p *ngIf="pagos.length < 1">Sin pagos en este filtro</p>
        </table> 
    </div>
</div>


<!-- divis contenedor -->
 </div>
</div>

<div class="offcanvas offcanvas-start row fondoOff " tabindex="-1" id="offDetalle" aria-labelledby="offDetalleLabel">
    <div class="col-12">
 
         <div [ngClass]="venta.status == 2 ? 'verde' : 'rojo' " class="row p-3 shadow text-light ">
             <div class="col-3">
                 <h5 class="offcanvas-title" id="offDetalleLabel">Detalle Venta</h5>
             </div>
             <div class="col-6 text-center">
                 <button *ngIf="venta.status != 2" class="btn btn-outline-light" (click)="abrirEnCaja(venta)">Abrir en Caja</button>
             </div>
             <div class="col-3 text-end hvr-icon-rotate">
                 <i class="fas fa-times fa-2x pointer hvr-icon" data-bs-dismiss="offcanvas" aria-label="Close" ></i>
             </div>
         </div>
 
         
         <!-- <div  class="scroll">
 
             <div  class="row encVenta ">
                 <div class="col-12 col-md-8">
                     <p><span>Tienda: </span>{{venta.storeName}}</p>
                     <p><span>Caja: </span>{{venta.cajaName}}</p>
                     <p><span>Fecha Turno: </span>{{venta.shiftDate | date: 'dd-MM-yyyy'}}</p>
                     <p><span>Fecha Real: </span>{{venta.realDate | date: 'dd-MM-yyyy'}}</p>
                     <p><span>Hora: </span>{{venta.openTime}}</p>
                     <p><span>Canal: </span>{{venta.canal}}</p>
                     <p><span>Documento: </span>{{venta.docName | titlecase}}</p>
                     <p><span>Cantidad de personas: </span>{{venta.npeople}}</p>
             
                     <p><span >Origen: </span>{{venta.saleOrigin}}</p>
                 
                     <p *ngIf="venta.saleChannel == 1"><span >Mesa</span>{{venta.mesaCode}}</p>
                 </div>
                 <div class="col-12 col-md-4 text-end">
                     <h1>$ {{venta.total | number}}</h1>
                     <p><span class="text-danger text-end">Saldo: </span>{{venta.saldo}}</p>
                     <p><span class="text-success text-end">Abono: </span>{{venta.abono}}</p>
                     <button *ngIf="venta.status != 2" class="btn btn-danger" (click)="abrirEnCaja(venta)">Abrir en Caja</button>
                 </div>
             </div>
         
 
             <div class="row detVenta ">
                 <div class="col-10 offset-1 table-responsive comanda papel shadow">
                     <h5 style="font-weight: 700;padding-left:10px"><i class="fa-solid fa-receipt"></i> Comanda</h5>
                     <hr>
                     <table class="table table-hover" style="border-bottom: rgba(0,0,0,0);">
                         <tbody *ngIf="!loadingDetalle">
                         <tr *ngFor="let d of detalleVenta ; index as i " class="pointer">
                             <th scope="row">{{i + 1}}</th>
                             <td >{{d.productName }}</td>
                             <td >{{d.quantity | number }} x $ {{d.price | number}}</td>
                             <td >$ {{d.total | number}}</td>
                         </tr>
                         
                         </tbody>
                     </table>
 
                     <p *ngIf="loadingDetalle"> Cargando Detalle de la venta <i class="fas fa-spinner fa-spin"></i></p>
 
                 </div>
             </div>
 
 
             <div class="row">
                 <div class="col-5 offset-1 ">
                     <p class ="text-success pointer hvr-grow" data-bs-toggle="collapse" data-bs-target="#pagosExample" aria-expanded="false" aria-controls="pagosExample"> <i class="fa-solid fa-hand-holding-dollar"></i> Ver pagos</p>
                 </div>
                 <div class="col-5 text-end">
                     <p class ="text-danger pointer hvr-grow" > <i class="fas fa-times"></i> Anular Venta</p>
                 </div>
 
                 <div class="col-10 offset-1 collapse" id="pagosExample">
                     <div class="card card-body table-responsive">
 
                         <table class="table table-hover" style="border-bottom: rgba(0,0,0,0);">
 
                             <thead>
                                 <tr>
                                     <th scope="col">Medio de pago</th>
                                     <th scope="col">Pagado</th>
                                     <th scope="col">Abonado</th>
                                     <th scope="col">Propina</th>
                                     <th scope="col">Vuelto</th>
                                     <th scope="col">Atendió</th>
                                 </tr>
                               </thead>
 
 
                             <tbody>
                                 <tr *ngFor="let p of detPayments ; index as i " class="pointer">
                                     <td >{{p.tipoPago.name }}</td>
                                     <td >$ {{p.amountP  | number}}</td>
                                     <td >$ {{p.amountA  | number}}</td>
                                     <td >$ {{p.tip  | number}}</td>
                                     <td >$ {{p.change  | number}}</td>
                                     <td >{{p.seller.firstName  | titlecase}} {{p.seller.lastName  | titlecase}}</td>
                                     <td></td>
                                 </tr>
                             </tbody>
                         </table>
 
                     </div>
                   </div>
 
             </div>
 
 
 
 
         </div> -->
     </div>
 </div>


<app-menu-stats></app-menu-stats>