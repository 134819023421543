export class detSaleModel {
    id: string;
    headId: string;
    storeId: number;
    // docType: string;
    cashierId: number;
    sellerId: number;
    numel: number; // orden en que se comandaron los productos
    productId: number;
    productName: string;
    price: number;
    quantity: number;
    discount: number;
    discountPercent: number;
    neto: number;
    porIva: number;
    IVA: number;
    bruto: number;
    total: number;
    itemCost: number; // costo del producto "vppploc"
    netoBruto: string;
    idDocAnt: string; //De la guia que proviene, o la comanda etc
    eater: number;
    status: number; //0borrado, 1Pendiente de pago 2:pagado, 3:anulada
    created_at: string;

    constructor(){
        this.id                      = '';
        this.headId                  = '';
        this.storeId                 = 0;
        // this.docType                 = '';
        this.sellerId                = 0;
        this.numel                   = 0; // Orden en que se comandaron los productos
        this.productId               = 0;
        this.productName             = '';
        this.price                   = 0;
        this.quantity                = 0;
        this.discount                = 0;
        this.discountPercent         = 0;
        this.neto                    = 0;
        this.porIva                  = 0;
        this.IVA                     = 19;
        this.bruto                   = 0;
        this.total                   = 0;
        this.itemCost                = 0;  // costo del producto "vppploc"
        this.netoBruto               = 'B';
        this.idDocAnt                = ''; // De la guia que proviene, o la comanda etc
        this.eater                   = 1; // para separar cuentas;
        this.status                  = 1; // 0:borrado | 1:Pendiente de pago | 2:pagado | 3:anulada
        this.created_at              = '';
    }
 }

