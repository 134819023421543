import { Component,  Output, EventEmitter } from '@angular/core';
import { messageModel } from 'src/app/models/data/message.model';
import { DataService } from 'src/app/services/data.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-message-modal',
  templateUrl: './message-modal.component.html',
  styleUrl: './message-modal.component.css'
})
export class MessageModalComponent {

  @Output()
  enviarPadre: EventEmitter<object> = new EventEmitter<object>();



  loading                 = true;
  user:any                = {};
  mensajes:any            = [];
  msg:messageModel        = new messageModel();


  constructor(
    private conexData: DataService,
    private _user:UserService,
    ) { 
      this.user = this._user.userInfo();
      this.getData();
    }

    ngOnInit(): void {
    }

    getData(){
      this.conexData.getData(`/data/messages/${this.user.companyId}`).subscribe({
        next:(resp:any) => { 
          console.log('messages',resp); 
          if (!resp){
            this.mensajes = [];
          } else {
            this.mensajes = resp;
          }
          this.loading = false
        },
        error:(error:any) => { console.log('err',error)}
      })
    }

    selectMessage(m:messageModel){
      console.log('este', m);
      this.enviarPadre.emit(m);
    }
    
    guardar(){
      console.log('guardar');
      this.enviarPadre.emit(this.msg);
    }


    cerrar(){
      this.msg = new messageModel();
      console.log('cerrar');
      this.enviarPadre.emit(this.msg);
    }

}
