import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { tableModel } from 'src/app/models/data/table.model';
import { DataService } from 'src/app/services/data.service';
import { MensajesService } from 'src/app/services/mensajes.service';
import { TransactionsService } from 'src/app/services/transactions.service';
import { UserService } from 'src/app/services/user.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-mesas',
  templateUrl: './mesas.component.html',
  styleUrls: ['./mesas.component.css']
})
export class MesasComponent {
  loading                 = true;
  loading2                = true;
  user:any                = {};

  stores:any             = [];
  selectedStore:any      = {};

  sectores:any           = [];
  sector:any             = { id: 0,  name: '', storeId: '', status: 1}

  mesasAll:any[]         = [];
  mesas:any[]            = [];
  mesa:tableModel        = new tableModel();
  memoria                = '';


  constructor(  
    private router: Router,
    private conexData: DataService,
    private conexTrans: TransactionsService,
    private _user:UserService,
    public _m: MensajesService) { 
    
      this.user    = this._user.userInfo();
      console.log('user', this.user);
}

ngOnInit(): void {
  this.getStores();
}


getStores(){
  this.conexData.getData('/data/stores').subscribe( resp =>{
    console.log('resp stores', resp);
    this.stores            = resp;
    if (this.stores.length < 1){
      this._m.error('error', 'Comunicate con Gour-net por favor');
      this.loading = false;
      return;
    } 
      this.selectedStore = this.stores.find( s => s.id == this.user.storeId);
      this.getData();
      console.log('store', this.selectedStore);
  })
}



getData(){
  this.loading2 = true;
  forkJoin([
    this.conexData.getData(`/data/salon/sectores/${this.selectedStore.id}`),
    this.conexTrans.getData(`/mesas/mesas/${this.selectedStore.id}`)
  ]).subscribe({ 
    next: (data:any) => {
      console.log('data', data);
      
      if(!data[0]){
        this._m.warning('No hay sectores creados','Seras redireccionado a la pantalla de sectores.');
        this.router.navigateByUrl('data/config/sectores');
        return;
      }

      this.sectores = data[0];
      this.sector   = this.sectores[0];
      
      if(!data[1]){
        console.log('no hay mesas aun');
        this.loading2 = false
      } else {
        this.mesasAll =  data[1];
        this.filtrarSector();
      }
      
      this.loading = false;
      

    },
    error: (error) => {
      console.error(error);
    }
  });
}


onStoreChange() {
  this.limpiarData();
  console.log('store', this.selectedStore);
  this.getData();
}

filtrarSector(){
  console.log('sector', this.sector);
  console.log('mesas ALL', this.mesasAll);
  this.mesas    = this.mesasAll.filter( m => m.sectorId == this.sector.id);
  this.loading2 = false;
}



info(){
  console.log('stores', this.stores);
  console.log('selectedStore', this.selectedStore);
  console.log('sectores', this.sectores);
  console.log('sector', this.sector);
  console.log('Mesas', this.mesas);
  console.log('Mesa', this.mesa);
}

limpiarData(){
  this.sectores       = [];
  this.sector         = { id: 0,  name: '', storeId: '', status: 1}
  this.mesasAll       = [];
  this.mesas          = [];
  this.mesa           = new tableModel();
}


newMesa(){
  this.mesa = new tableModel();
}

selectMesa(m){
  this.mesa = m;
}



guardarMesa(){
  console.log('mesa', this.mesa);

  const validCode = this.validarCodigo();
  if (!validCode){
    this._m.warning('Código Repetido','Prueba con otro por favor');
    return
  }

  this.loading = true;

  this.mesa.storeId      = this.selectedStore.id;
  this.mesa.sectorId     = this.sector.id;
  if (this.mesa.id != ''){
    this.updateMesa();
  } else {
    this.conexTrans.guardarDato(`/mesas/newTable`, this.mesa).subscribe({
      next: (data:any) => {
        console.log('ok data', data.recordset);
        this.mesa.id = data.recordset[0].id;
        this.mesasAll.push(this.mesa);
        this.filtrarSector();
        this.loading = false;
      },
      error: (err:any) => {
        this._m.error('Ups', 'hubo un error guardando el dato.');

        console.log('error', err);
        this.loading = false;
      },
    })
  }
}



updateMesa(){
  this.conexTrans.guardarDato(`/mesas/updateTable`, this.mesa).subscribe({
    next: (data:any) => {
      this._m.ok('Ok', 'Mesa Actualizada con éxito');
      this.newMesa();
      this.loading = false;
    },
    error: (err:any) => {
      this._m.error('Ups', 'hubo un error guardando el dato.')
      console.log('error', err);
      this.loading = false;
    },
  })
}




validarCodigo(){
  const existen = this.mesasAll.filter( m => m.code == this.mesa.code);
  console.log('existe', existen);


  if (this.mesa.id != '' && existen.length > 1){
    return false;
  } 

  if (this.mesa.id == '' && existen.length > 0){
    return false;
  } 

  return true;
}

}
