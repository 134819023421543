<nav class="navbar fixed-top navbar-expand-lg barra">
    <div class="container-fluid">
      <a class="navbar-brand" href="#">        
        <img src="assets/images/logo_w.svg" width="80px">
      </a>
      <i class="fa-solid fa-bars text-light" data-bs-toggle="offcanvas" data-disableScrolling="false" data-bs-target="#idMenu" aria-controls="idMenu"> </i>

    </div>
  </nav>
<!-- 
  <div class="row fixed-top barra">
    <div class="col-6">
        <img src="assets/images/logo_w.svg" width="80px">
    </div>
    <div class="col-6 text-end iconoMenu">
        <i class="fa-solid fa-bars" data-bs-toggle="offcanvas" data-disableScrolling="false" data-bs-target="#idMenu" aria-controls="idMenu"> </i>
    </div>
  </div>
 -->


  <div class="offcanvas offcanvas-start" tabindex="-1" id="idMenu" aria-labelledby="offMenu">
    
    <div class="offcanvas-header">
      <h5 class="offcanvas-title" id="offMenu">Menu</h5>
      <i class="fa-solid fa-xmark fa-2x" data-bs-dismiss="offcanvas" aria-label="Close" ></i>
    </div>

    <div class="offcanvas-body">
        

      <!-- <div class="link row">
        <div class="content" routerLink="/general/home" [routerLinkActive]="'active'"  >
          <div class="icon">
            <i class="fa-solid fa-house icono" ></i>
          </div>
          <div class="title">Home</div>
        </div>
      </div> -->
     
      
      <div class="link rowBtn ">
          <div class="col-12 btnLink hvr-icon-rotate" routerLink="/general/home" [routerLinkActive]="'active'" data-bs-dismiss="offcanvas" aria-label="Close">
            <p><i class="fa-solid fa-house icono hvr-icon" ></i> Home</p>
          </div>
      </div>
      
      
      <div class="link rowBtn">
        <div class="col-12 btnLink" [routerLink]="['/salon/mesas']" [routerLinkActive]="'active'" data-bs-dismiss="offcanvas" aria-label="Close">
          <p><img src="assets/images/table.svg" width="25px" class="hvr-rotate"> Mesas</p>
        </div>
      </div>
      
      
      <div class="link rowBtn ">
          <div class="col-12 btnLink hvr-icon-rotate" routerLink="/cajas/caja/directa/0" [routerLinkActive]="'active'" data-bs-dismiss="offcanvas" aria-label="Close">
            <p><i class="fa-solid fa-cash-register icono hvr-icon" ></i> Venta Directa</p>
          </div>
      </div>
    
      
      <div class="link rowBtn ">
          <div class="col-12 btnLink hvr-icon-rotate" routerLink="/general/shift/menu" [routerLinkActive]="'active'" data-bs-dismiss="offcanvas" aria-label="Close">
            <p><i class="fa-regular fa-clock icono hvr-icon" ></i> Turno</p>
          </div>
      </div>
    
      <div class="link rowBtn">
        <div class="col-12 btnLink" [routerLink]="['/data/productos']" [routerLinkActive]="'active'" data-bs-dismiss="offcanvas" aria-label="Close">
          <p><img src="assets/images/data.svg" width="25px" class="hvr-rotate"> Data</p>
        </div>
      </div>
    
      <div class="link rowBtn ">
        <div class="col-12 btnLink hvr-icon-rotate" routerLink="/stats/live" [routerLinkActive]="'active'" data-bs-dismiss="offcanvas" aria-label="Close">
          <p><i class="fa-solid fa-chart-simple  icono hvr-icon" ></i> Informes</p>
        </div>
    </div>
     
    <div class="link rowBtn ">
        <div class="col-12 btnLink hvr-icon-rotate" routerLink="/config/config" [routerLinkActive]="'active'" data-bs-dismiss="offcanvas" aria-label="Close">
          <p><i class="fa-solid fa-gear  icono hvr-icon" ></i> Configuración</p>
        </div>
    </div>
  
    <div class="link rowBtn ">
        <div class="col-12 btnLink hvr-icon-rotate" (click)="fullscreen()">
          <p><i class="fa-solid fa-expand icono hvr-icon" ></i> Pantalla Completa</p>
        </div>
    </div>

    <div class="link rowBtn ">
      <a href="http://www.gour-net.cl/clientes">
        <div class="col-12 btnLink hvr-icon-rotate" >
          <p><i class="fa-solid fa-headset icono hvr-icon" ></i> Soporte</p>
        </div>
        </a>
    </div>


    <div class="link rowBtn ">
      <div class="col-12 btnLink hvr-icon-rotate" (click)="_user.cerrarSesion()">
        <p><i class="fa-solid fa-door-open icono hvr-icon" ></i> Salir</p>
      </div>
  </div>



      

        <!-- <div class="row link ">
            <div class="col-2 text-end">
                <i class="fa-solid fa-house icono" ></i>
            </div>
            <div class="col-10">
                <p>Home</p>
            </div>
         </div>

         <hr>

           
        <div class="row link ">
            <div class="col-2 text-end">
                <img src="assets/images/table.svg" width="25px" class="colorsvg">
            </div>
            <div class="col-10">
                <p>Atención a mesas</p>
            </div>
         </div>
  
         <hr>

           
        <div class="row link ">
            <div class="col-2 text-end">
                <i class="fa-solid fa-cash-register icono"></i>
            </div>
            <div class="col-10">
                <div class="title">Venta Directa</div>
            </div>
         </div>

         <hr>

           
        <div class="row link " [routerLink]="['/data/productos']">
            <div class="col-2 text-end">
            <img src="assets/images/data.svg" width="30px">
            </div>
            <div class="col-10">
                <div class="title">Data</div>
            </div>
         </div>
   -->
    </div>

    </div>
