<app-loading *ngIf="loading"></app-loading>

<div class="row header azul r0">
    <div class="col-11">
       <h2 (click)="info()" class="animate__animated animate__fadeIn"><i class="fa-regular fa-clock"></i> Mesas</h2>
    </div>
    <div class="col-1 text-right hvr-icon-bounce">
        <h2><i class="fa-solid fa-circle-question hvr-icon pointer" (click)="_m.info('Mesas', 'Cada mesa pertenece a un Sector, Debes crearle un codigo de 3 letras que ayude a los garzones a reconocerlas. Recomendamos que sea una Letra y numeros por Ejemplo M01')"></i></h2>
    </div>
</div>


<div *ngIf="!loading" class="row headMenu  shadow r0">
    <div class="col-12 pad20 ">
    
        <div class="row hvr-forward">
            <p class=" botonMenu animate__animated animate__pulse" data-bs-toggle="offcanvas" data-disableScrolling="false" href="#menuData" role="button" aria-controls="menuData"> < Menu Data </p>
        </div>

        <div  class="row r0">
            <div *ngIf="stores.length > 1" class="col-12 col-md-5">

                <div class="row">
                    <div class="col-12">
                        <label>Selecciona el local</label>
                        <select [(ngModel)]="selectedStore" (change)="onStoreChange()"  class="form-select">
                            <option *ngFor="let store of stores" [ngValue]="store">
                              {{ store.name }}
                            </option>
                          </select>
                    </div>
                </div> 

                <div class="row mt-2">
                    <div class="col-12 col-md-6">
                        <label>Selecciona el Sectore</label>
                        <select [(ngModel)]="sector" (change)="filtrarSector()"  class="form-select">
                            <option *ngFor="let s of sectores" [ngValue]="s">
                              {{ s.name }}
                            </option>
                          </select>
                    </div>
                    <div class="col-12 col-md-6">
                        <button class="btn btn-primary mt-4" (click)="newMesa()">Nueva Mesa</button>
                    </div>
                </div>
    
            </div>

            <hr class="d-block d-md-none mt-4">
         
            <div class="col-12 col-md-7 der text-end">
                <h1 *ngIf="mesa.id == '' " >Nueva Mesa</h1>
                <h1 *ngIf="mesa.id != '' " >Editar Mesa</h1>
                <div class="row">
                    <div class="col-6 col-md-5 text-end mt-2">
                        <p>Código</p>
                    </div>
                    <div class="col-6 col-md-3">
                        <input type="text" max="3" [(ngModel)]="mesa.code" class="form-control" placeholder="ej: M01">
                    </div>
                    <div class="col-12 col-md-4 text-end">
                
                        <button class="btn btn-success w-100" (click)="guardarMesa()">Guardar</button>
                    </div>
                </div>
            </div>
        </div>


    </div>
</div> 


<div *ngIf="!loading2" class="row">
    <div class="col-12">


        <div *ngIf="mesas.length < 1"class="row">
            <div class="col-12 text-center mt-4 animate__animated animate__fadeIn">
                <p>Sector sin mesas aún</p>
                <button class="btn btn-primary" (click)="newMesa()">Nueva Mesa</button>
            </div>
        </div>
    
        <div *ngIf="mesas.length > 0" class="row mesas animate__animated animate__fadeIn">

            <div class="col-12">
                <p>Mesas</p>
                <div  *ngFor="let m of mesas" class="mesa hvr-grow">
                    <p (click)="selectMesa(m)" class=""> {{m.code}}</p>
                </div>
            </div>
                
            <div class="col-12 col-md-6 offset-md-3">
                <button class="btn btn-primary w-100" (click)="newMesa()">Nueva Mesa</button>
            </div>

        </div>

    </div>
</div>


<div  *ngIf="loading2" class="row text-center">
    <div class="col-12 mt-4">
        <p>Cargando Mesas <i class="fa fa-spinner fa-spin"></i></p>
    </div>
</div>






<app-menu-data></app-menu-data>