
// import { Pipe, PipeTransform } from '@angular/core';

// @Pipe({
//   name: 'separator'
// })
// export class SeparatorPipe implements PipeTransform {
//   transform(value: number | string): string {
//     if (typeof value === 'string') {
//       value = parseFloat(value);
//     }

//     if (!isNaN(value)) {
//       return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
//     }

//     return value.toString();
//   }
// }

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'separator'
})
export class SeparatorPipe implements PipeTransform {
  transform(value: number | string, decimalPlaces: number = 2): string {
    if (typeof value === 'string') {
      value = parseFloat(value);
    }

    if (!isNaN(value)) {
      const roundedValue = (Math.round(value * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces)).toFixed(decimalPlaces);
      return roundedValue.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    }

    return value.toString();
  }
}
