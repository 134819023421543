<div class="row fondoModal r0">
    <div class="col-12  col-md-8 offset-md-4 animate__animated animate__slideInRight bg-dark">


            <div class="headerD row">
              <div class="col-10">
                  <h5 class="text-light" ><i class="fa-solid fa-cash-register"></i> Declaración de Caja</h5>
              </div>
              <div class="col-2 text-end hvr-icon-rotate">
                  <i class="fas fa-times text-light fa-2x pointer hvr-icon" (click)="cerrar('cerrar')"></i>
              </div>
            </div>

            <hr>


             <div *ngIf="!loading" class="cuerpoCierre">
              <div class="row info text-light">
                  <div class="col-12 col-md-8">
                      <h2>{{selectedShift.name}}</h2>
                      <p><span>Abrió el </span> {{selectedShift.started | date: 'dd-MM-yyyy'}} <span>a las </span> {{selectedShift.started | date: 'HH:mm'}}</p>
                      <p><span>Caja Inicial: </span> $ {{selectedShift.initial_amount | separator : 0 }}</p>
                      <p><span>Caja: </span> {{selectedShift.cajaName}}</p>
                  </div>
                  <div class="col-12 col-md-4 text-end">
                      <h3><span>Total Declarado: </span>  <br>  ${{totalDeclarado | separator : 0 }}</h3>
                  </div>
              </div>
          
              <hr>
          
          
              <div class="row">
                  <div class="col-12 col-md-6">
                
                      <div class="input-group flex-nowrap mt-4">
                          <span class="input-group-text" id="addon-wrapping">Efectivo</span>
                          <input
                            type="text"class="form-control" aria-describedby="addon-wrapping"
                            [ngModel]="_user.formatAmount(payList.EFE.amount)"
                            (ngModelChange)="payList.EFE.amount = _user.parseFormattedAmount($event)"
                            (change)="calcTotal()"
                          />
                        </div>
          
                  
                        <div class="input-group flex-nowrap mt-4">
                          <span class="input-group-text" id="addon-wrapping">Débito</span>
                          <input
                            type="text"class="form-control" aria-describedby="addon-wrapping"
                            [ngModel]="_user.formatAmount(payList.DEB.amount)"
                            (ngModelChange)="payList.DEB.amount = _user.parseFormattedAmount($event)"
                            (change)="calcTotal()"
                          />
                        </div>
          
          
          
          
                        <div class="input-group flex-nowrap mt-4">
                          <span class="input-group-text" id="addon-wrapping">Crédito</span>
                          <input
                            type="text"class="form-control" aria-describedby="addon-wrapping"
                            [ngModel]="_user.formatAmount(payList.OO.amount)"
                            (ngModelChange)="payList.OO.amount = _user.parseFormattedAmount($event)"
                            (change)="calcTotal()"
                          />
                        </div>
          
          
                        <div class="input-group flex-nowrap mt-4">
                          <span class="input-group-text" id="addon-wrapping">Transferencia</span>
                          <input
                            type="text"class="form-control" aria-describedby="addon-wrapping"
                            [ngModel]="_user.formatAmount(payList.TRA.amount)"
                            (ngModelChange)="payList.TRA.amount = _user.parseFormattedAmount($event)"
                            (change)="calcTotal()"
                          />
                        </div>
          
          
                      <button class="btn btn-outline-light w-100 mt-4" data-bs-toggle="collapse" data-bs-target="#ubers" aria-expanded="false" aria-controls="ubers"> Plataforma de delivery </button>
          
                      <button class="btn btn-outline-light w-100 mt-4" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample"> Convenios </button>
          
                      <button class="btn btn-outline-primary w-100 mt-4" (click)="validarDeclaracion()" > Guardar </button>


                      
          
          
                   </div>
             
                   <div class="col-12 col-md-6 otrosMedios">
               
                      
                      <div class="collapse mt-2" id="ubers">
                          <div class="card card-body">
                              <label>Uber Eats</label>
                              <input
                              type="text"class="form-control" aria-describedby="addon-wrapping"
                              [ngModel]="_user.formatAmount(payList.GUE.amount)"
                              (ngModelChange)="payList.GUE.amount = _user.parseFormattedAmount($event)"
                              (change)="calcTotal()"
                            />
          
          
                              <label>Pedidos Ya</label>
                              <input
                              type="text"class="form-control" aria-describedby="addon-wrapping"
                              [ngModel]="_user.formatAmount(payList.GPY.amount)"
                              (ngModelChange)="payList.GPY.amount = _user.parseFormattedAmount($event)"
                              (change)="calcTotal()"
                            />
          
                              <label>Rappi</label>
                              <input
                              type="text"class="form-control" aria-describedby="addon-wrapping"
                              [ngModel]="_user.formatAmount(payList.GRA.amount)"
                              (ngModelChange)="payList.GRA.amount = _user.parseFormattedAmount($event)"
                              (change)="calcTotal()"
                            />
                             
                              <label>Justo</label>
                              <input
                              type="text"class="form-control" aria-describedby="addon-wrapping"
                              [ngModel]="_user.formatAmount(payList.GJU.amount)"
                              (ngModelChange)="payList.GJU.amount = _user.parseFormattedAmount($event)"
                              (change)="calcTotal()"
                            />
                             
                              <label>Didi</label>
                              <input
                              type="text"class="form-control" aria-describedby="addon-wrapping"
                              [ngModel]="_user.formatAmount(payList.GDI.amount)"
                              (ngModelChange)="payList.GDI.amount = _user.parseFormattedAmount($event)"
                              (change)="calcTotal()"
                            />
          
                          </div>
                        </div>
          
                        <div class="collapse mt-2" id="collapseExample">
                          <div class="card card-body">
                              <label>Sodexo</label>
                             <input
                              type="text"class="form-control" aria-describedby="addon-wrapping"
                              [ngModel]="_user.formatAmount(payList.SXO.amount)"
                              (ngModelChange)="payList.SXO.amount = _user.parseFormattedAmount($event)"
                              (change)="calcTotal()"
                            />
          
                              <label>EdenRed</label>
                             <input
                              type="text"class="form-control" aria-describedby="addon-wrapping"
                              [ngModel]="_user.formatAmount(payList.EDE.amount)"
                              (ngModelChange)="payList.EDE.amount = _user.parseFormattedAmount($event)"
                              (change)="calcTotal()"
                            />
          
                              <label>Junaeb</label>
                              <input
                              type="text"class="form-control" aria-describedby="addon-wrapping"
                              [ngModel]="_user.formatAmount(payList.JUN.amount)"
                              (ngModelChange)="payList.JUN.amount = _user.parseFormattedAmount($event)"
                              (change)="calcTotal()"
                            />
                             
                              <label>Amipass</label>
                              <input
                              type="text"class="form-control" aria-describedby="addon-wrapping"
                              [ngModel]="_user.formatAmount(payList.APS.amount)"
                              (ngModelChange)="payList.APS.amount = _user.parseFormattedAmount($event)"
                              (change)="calcTotal()"
                            />
                             
                              <label>Gift Card</label>
                              <input
                              type="text"class="form-control" aria-describedby="addon-wrapping"
                              [ngModel]="_user.formatAmount(payList.GIF.amount)"
                              (ngModelChange)="payList.GIF.amount = _user.parseFormattedAmount($event)"
                              (change)="calcTotal()"
                            />
          
                          </div>
                        </div>
          
                   </div>
              </div>
            </div>
          
            <div *ngIf="loading" class="row">
              <div class="col-12 text-center text-light">
                <p>Cargando declaración usuario <i class="fas fa-spinner fa-spin"></i></p>
              </div>
            </div>

    </div>
</div>

