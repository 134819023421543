import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { shiftModel } from 'src/app/models/venta/shiftHistory.model';
import { MensajesService } from 'src/app/services/mensajes.service';
import { SalesService } from 'src/app/services/sales.service';
import { TransactionsService } from 'src/app/services/transactions.service';
import { UserService } from 'src/app/services/user.service';
import { forkJoin } from 'rxjs';
import { ProductStore } from 'src/app/storage/products.store';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-cierre',
  templateUrl: './cierre.component.html',
  styleUrls: ['./cierre.component.css']
})
export class CierreComponent {
  id: any;
  loading                = true;
  loadingRanking         = true;
  user:any               = {};
  shift:any              = new shiftModel();
  searchString           = '';
  modalCierre = false;
  saleChannel             = [{id:1 , name:'SALON'},{id:2 , name:'VENTA_DIRECTA'},{id:3 , name:'DELIVERY'},{id:4 , name:'LOCAL'}]

// General
  users            = [];
  docTypes         = [];
  payTypes         = [];
  listGrandeGrupos = [];
  listGrupos       = [];

// arqueo
  payments           = [];
  userDeclaration    = [];

 // stats 
  ventas      = [];
  ranking     = [];
  sellers     = [];
  docs        = [];
  granGrupos  = [];
  grupos      = [];
 
 propinas     = { efectivo: 0,
                  otros:0,
                  total: 0
                }

  ventaTotal = {neto: 0,
                bruto: 0,
                abono:0,
                saldo: 0,
                descuentos:0,
                iva: 0,
                npeople: 0,
                arqueoGournet: 0,
                arqueoUsuario: 0
              }

  constructor(public _user:UserService, 
    private router: Router,
    private conexSales:SalesService,
    private conexTrans: TransactionsService,
    private _m: MensajesService,
    private route: ActivatedRoute,
    private productStore: ProductStore,
    private conexData:DataService,
    // private shiftServices: ShiftService,
    ){
      this.user         = this._user.userInfo();
      this.id = this.route.snapshot.paramMap.get('id')
      this.datosGenerales();
      this.getData();
    }


  datosGenerales(){
    const state          = this.productStore.getValue();
    this.docTypes        = state.docs;
    this.payTypes        = state.payTypes;

    console.log('paytypes', this.payTypes);

  }

  getData(){
      forkJoin([
        this.conexTrans.getData(`/trans/shift/${this.id}`),
        this.conexSales.traeDatos(`/sales/declaration/${this.id}`),
        this.conexSales.traeDatos(`/sales/ventasXTurno/${this.id}`),
        this.conexSales.traeDatos(`/sales/pagosXTurno/${this.id}`),
        this.conexData.getData('/data/greatGroups'),
        this.conexData.getData('/data/groups'),
        this.conexData.getData('/data/users'),
      ]).subscribe({ 
        next: (data:any) => {
          console.log('data', data);
          this.shift            = data[0];
          this.users            = data[6];
          this.listGrandeGrupos = data[4];
          this.listGrupos       = data[5];
          this.getDeclaration(data[1]);
          this.getVentas(data[2]);
          this.getPagos(data[3]);
          
          this.loading          = false;     


         },
        error: (error) => {
          console.error(error);
          this._m.error('error', error.error);
          this.loading = false
        }
      });
  }

  //
  getDeclaration(d){
        console.log('tiene declaración hecha', d);
        if(!d){
          console.log('no tiene declaración')
          this.shift.declaracion = false;
        } else {
          this.shift.declaracion = true;
          this.getUserDeclaration(d);
        }
        this.validarUser();
  }



  validarUser(){
    console.log('shift', this.shift);
    console.log('valido el user', this.user);
    // level 1 chipe libre
    // level 2 puede ver y editar
    // level 3 Solo puede mirar si la declaración está hecha.
    if (this.user.level > 2 && !this.shift.declaracion){ 
      this._m.warning('Acceso reservado','No puedes ver el cierre si aún no se hace la declaración de caja.')
      this.router.navigateByUrl('general/shift/a');
    }
  }




  getVentas(data){
   console.log('ventas con detalle', data);
   if (!data){
    return;
   }
   for (let v of data){
    
    if (v.status != 2 || v.code == 'DESCUENTO'){
      continue
    }



    const detalle = { id: v.detId,
                      bruto: v.detBruto,
                      discount: v.detDiscount,
                      neto: v.detNeto,
                      iva: v.detIva,
                      price: v.detPrice,
                      quantity: v.detQuantity,
                      total: v.detTotal,
                      sellerId: v.detSeller,
                      sellerName: this.findSeller(v.detSeller),
                      status: v.detStatus,
                      itemCost: v.itemCost,
                      productCode: v.code,
                      name: v.productName,
                      productId: v.productId,
                      grangrupoId: v.greatGroupId, 
                      granGrupoName: this.findGranGrupo(v.greatGroupId),
                      grupoId: v.groupId,
                      grupoName: this.findGrupo(v.groupId)
                    }

    this.calcRanking(detalle);
    this.calcGrupos(detalle);               
    this.calcSellers(detalle);               

     const existeVenta = this.ventas.find( vta => vta.id == v.id);
     if (existeVenta){
      existeVenta.detalle.push(detalle);
     } else {
      const newVta = { id: v.id,
                       total: v.total,
                       saldo: v.saldo,
                       abonado: v.abono,
                       numDoc: v.numDoc,
                       discount: v.discount,
                       docType: v.docType,
                       realDate: v.realDate,
                       closeTime: v.closeTime,
                       saleChannelId: v.saleChannelId,
                       channelName: this.findSalChannel(v.saleChannelId),
                       saleOrigin: v.saleOrigin,
                       tableCode: v.tableCode,
                       detalle: [detalle]
                      }
      this.ventas.push(newVta);                                    
      this.ventaTotal.neto        += v.neto;
      this.ventaTotal.bruto       += v.total; 
      this.ventaTotal.saldo       += v.saldo;
      this.ventaTotal.abono       += v.abono;
      this.ventaTotal.descuentos  += v.discount;                
      this.ventaTotal.iva         += v.IVA;
      this.ventaTotal.npeople     += v.npeople;
      
      this.agregarDoc(v);                  
   }
  };
  
  this.calcularPorcentajes();
  this.ordenarRanking();
}


info(){
  console.log ('encabezado de ventas', this.ventas);
  console.log ('ranking', this.ranking);
  console.log ('docs', this.docs);
  console.log ('grandes grupos', this.granGrupos);
  console.log ('grupos', this.grupos);
  console.log ('sellers', this.sellers);
  console.log ('venta Total', this.ventaTotal);
  


}
calcRanking(det){
  const existe = this.ranking.find( p => p.id == det.productId)
  if (existe){
    existe.ventas.push(det);
    existe.quantity   += det.quantity;
    existe.price       = this.calcPrecioPromedio(existe.ventas);
    existe.totalVenta += det.total;
  } else {
    const newProd = {
      id: det.productId,
      code: det.productCode,
      name: det.name,
      quantity: det.quantity,
      price: det.price,
      greatGroup: det.grangrupoId,
      group: det.grupoId,
      totalVenta: det.total,
      ventas: [det]
    }
    this.ranking.push(newProd);
  }
}


calcPrecioPromedio(lista){
  let totalVenta    = 0;
  let totalCantidad = 0;

  for (let p of lista){
    totalVenta     += p.price * p.quantity
    totalCantidad  += p.quantity;
  }

  const promedio = Math.round(totalVenta / totalCantidad);
  return promedio;
}


calcGrupos(detalle){
  const existeGran = this.granGrupos.find( g => g.id == detalle.grangrupoId);
  if (existeGran){
    existeGran.quantity += detalle.quantity;
    existeGran.total += detalle.total;
  } else {
    const newGG = {
      name: '', // buscar
      id: detalle.grangrupoId,
      quantity: detalle.quantity,
      total: detalle.totalAmount,
      porcentaje: 0
    }
    this.granGrupos.push(newGG);
  }


 
 const existeGrupo = this.grupos.find( gr => gr.id == detalle.grupoId);
  if (existeGrupo){
    existeGrupo.quantity += detalle.quantity;
    existeGrupo.total += detalle.total;
  } else {
    const newG = {
      name: '', // buscar
      id: detalle.grupoId,
      quantity: detalle.quantity,
      total: detalle.totalAmount,
      porcentaje: 0
    }
    this.grupos.push(newG);

  }



  




}       

calcSellers(det){
  const existe = this.sellers.find( s => s.id == det.sellerId );
  if (existe){
    existe.total += det.total;
    existe.quantity += det.quantity;
    existe.ventas.push(det);

  } else {
    const newSeller = { id: det.sellerId,
                        name: det.sellerName,
                        total: det.total,
                        quantity: det.quantity,
                        porcentaje: 0,
                        ventas: [det]
                      };
  this.sellers.push(newSeller);
  }

}            


calcularPorcentajes(){
  for (let gg of this.granGrupos){
    this.calcPorcentaje(this.granGrupos, gg.id);
  }

  for (let g of this.grupos){
    this.calcPorcentaje(this.grupos, g.id);
  }
  
  for (let s of this.sellers){
    console.log('s', s);
    s.porcentaje = this.calcPorcentaje(this.sellers, s.id);
  }

  this.sellers = this.sellers.sort( (a, b) => b.total - a.total);

  this.info();

}


calcPorcentaje(lista, id){

  let totalVenta = 0
  let totalG     = 0
  for (let l of lista){
    console.log('L',l)
    totalVenta += l.total;
    if (l.id == id){
      totalG += l.total
    }
  }

  console.log('totalVenta', totalVenta);
  console.log('totalG', totalG);

  const percent = parseFloat((totalG * 100 / totalVenta).toFixed(1));
  console.log('percent', percent);
  return percent
}


agregarDoc(v){
  const existe = this.docs.find( d => d.type == v.docType);
  if (!existe){
    const newDoc = {type: v.docType,
                    docs: [v.numDoc],
                    docName: this.findDoc(v.docType)
                  };
                    this.docs.push(newDoc);
                  } else {
    existe.docs.push(v.numDoc)
    existe.docs = existe.docs.sort((a, b) => a - b);  
  }


}
  getPagos(data){
    console.log('pagos', data);
    if (!data){
      return;
    }
    for (let p of data){
      if (p.status != 2){ continue }
      const existe = this.payments.find( pay => pay.payType == p.payType);
      if ( existe){
        existe.total += p.amountA;
        existe.pagos.push(p);
      } else {
        const newPay = { payType: p.payType,
                         payName: this.findPay(p.payType),
                         total: p.amountA,
                         pagos: [p]
                        }
                        this.payments.push(newPay);
     }
     this.ventaTotal.arqueoGournet += p.amountA;
     // suma de propinas
     if(p.payType == 'EFE'){
      this.propinas.efectivo += p.tip;
     } else {
      this.propinas.otros += p.tip;
     }
     this.propinas.total += p.tip;
    }

    this.payments = this.payments.sort((a, b) => a.payName.localeCompare(b.payName));

  }


  getUserDeclaration(data){
    console.log('user Declaracionesss', data);
    for (let p of data){
      const existe = this.userDeclaration.find( pay => pay.payType == p.payType);
      if ( existe){
        existe.total += p.total;
        existe.pagos.push(p);
      } else {
        const newPay = { payType: p.payType,
                         payName: this.findPay2(p.payType),
                         total: p.total,
                         pagos: [p],
                         user: this.findSeller(p.userId),
                         date: p.date,
                         time: p.time
                        }
                        this.userDeclaration.push(newPay);
     }
     this.ventaTotal.arqueoUsuario += p.total;
     // suma de propinas
    }

    console.log('PAGOS USUARIO antes', this.userDeclaration);

    this.userDeclaration = this.userDeclaration.sort((a, b) => a.payName.localeCompare(b.payName));
    
    console.log('PAGOS USUARIO', this.userDeclaration);

}

 ordenarRanking(){
  this.ranking.sort((a, b) => b.quantity - a.quantity);

 }


  findSeller(id){
    const seller = this.users.find( s => s.id == id);
    return seller.firstName + ' ' + seller.lastName
   }
  
  findGranGrupo(id){
    const gg = this.listGrandeGrupos.find( s => s.id == id);
    return gg.name
   }

   findGrupo(id){
    const g = this.listGrupos.find( s => s.id == id);
    return g.name
   }

   findSalChannel(id){
    const channel = this.saleChannel.find( c => c.id == id);
    return channel.name
   }

   findDoc(id){
    const doc = this.docTypes.find( c => c.code == id);
    return doc.name;
   }

   findPay(id){
    const pay = this.payTypes.find( p => p.code == id);
    return pay.name
  }
   findPay2(id){
    const pay = this.payTypes.find( p => p.id == id);
    if (pay.name == 'Other'){ 
      console.log('aca', pay)
      return 'Crédito'
    }
    return pay.name
  }


  recibirCierre(v){
    console.log('recibo cierre', v);
    if (v != 'cerrar'){}
    this.modalCierre = false;
    
    this.reloadView()

  }

  reloadView() {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  selectProd(p){
    console.log('producot', p);
  }


  editar(){
    console.log('editar');
    if (this.user.level > 2 ){ 
      this._m.warning('Acceso reservado','Solo un usuario nivel 1 o 2 pueden editar un cierre')
      return;
    }

    console.log('shift', this.shift)
    this.modalCierre = true;
  }




}




