export class userModel {
    id: number;
    storeId: number; 
    firstName: string;
    lastName:string; 
    email: string;
    phone: string; 
    level: number;
    status: number; 
    password: string; 
    image: string;

    constructor() {
        this.id             = 0;
        this.storeId        = 0;
        this.firstName      = '';
        this.lastName       = ''; 
        this.email          = '';
        this.phone          = ''; 
        this.level          = 3;
        this.status         = 1; 
        this.password       = ''; 
        this.image          = '';
    }
}


