import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

import { routesGenerals } from './generals/generals.routes';
import { routesConfig } from './config/config.routes';
import { routesData } from './data/data.routes';
import { routesCaja } from './caja/caja.routes';
import { routesStats } from './stats/stats.routes';
import { routesSalon } from './salon/salon.routes';
import { routesMobilepay } from './mobilepay/mobilepay.routes';



const routes: Routes = [
  {path: 'general', children: routesGenerals},
  {path: 'config', children: routesConfig, canActivate:[AuthGuard] },
  {path: 'data', children: routesData, canActivate: [AuthGuard] },
  {path: 'cajas', children: routesCaja, canActivate: [AuthGuard] },
  {path: 'salon', children: routesSalon, canActivate: [AuthGuard] },
  {path: 'stats', children: routesStats, canActivate: [AuthGuard] },
  {path: 'mobilepay', children: routesMobilepay },
  {path: '', pathMatch: 'full', redirectTo: 'general/login'},
  {path: '**', pathMatch: 'full', redirectTo: 'general/login'}
  // {path: 'transitos', children: rutasTransitos, canActivate: [AuthGuard]},

];


@NgModule({
  // imports: [RouterModule.forRoot(routes)],
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}],
  exports: [RouterModule]
})
export class AppRoutingModule { }
