
<div class="row header azul r0">
    <div class="col-12">
       <h2 class="animate__animated animate__fadeIn">            
         <i class="fa-solid fa-ranking-star icono"></i>
        Ranking de Productos</h2>
    </div>
</div>

<div class="row headMenu animate__animated animate__fadeIn r0 pad20 shadow r0  ">
    <div class="col-12 hvr-forward">
        <p class=" botonMenu animate__animated animate__pulse" data-bs-toggle="offcanvas" data-disableScrolling="false" href="#menuStats" role="button" aria-controls="menuStats"> < Menu estadisticas </p>
    </div>

    <div class="col-12 col-md" > 
        <label>Escoge en local</label>
        <select [(ngModel)]="selectedStore" (change)="onStoreChange($event.target.value)" class="form-select">
            <option *ngFor="let store of stores" [value]="store.id">
                {{ store.name }}
            </option>
        </select>
    </div>

    <div class="col-12 col-md">
        <div class="form-group">
            <label>Fecha Inicial</label>
            <input type="date" class="form-control" placeholder="Inicio Periodo" [(ngModel)]="firstDay" name="fechaIni" required>
        </div>
    </div>
    <div class="col-12 col-md">
        <div class="form-group">
            <label>Fecha Final</label>
            <input type="date" class="form-control" placeholder="Fin Periodo" [(ngModel)]="lastDay" name="fechaFin" required>
        </div>
    </div>
 
    <div class="col-12 col-md">
        <div class="form-group">
            <label>Ordenar por</label>
            <select [(ngModel)]="filtro" class="form-select">
                <option>Ventas</option>
                <option>Cantidad</option>
            </select>        
        </div>
    </div>

    
    <div class="col-12 col-md-2 mt-4">
      <button class="btn btn-primary" (click)="filtrar()">Buscar</button>
    </div>
</div> 



<div class="row ranking r0">
    <div class="col-12">
    
    <div class="row pad20 buscador shadow-sm  animate__animated animate__fadeIn">
            <div class="col-12 col-md-3  hvr-icon-rotate ">
                <p><i class="fa-solid fa-magnifying-glass hvr-icon"></i> Buscador</p>
                <input type="text" class="form-control" placeholder="Escribe el nombre del producto o su código" [(ngModel)]="searchString" >
            </div>

            <div class="col-12 col-md-3 ">
                <p><i class="fa-solid fa-magnifying-glass hvr-icon"></i> Gran Grupo</p>
                <select class="form-select" [(ngModel)]="gg" (change)="selectGG()">
                  <option *ngFor="let g of greatGroups" [ngValue]="g">{{g.name}}</option>
                </select>
            </div>


            <div *ngIf="gg.id !=0" class="col-12 col-md-3 ">
                <p> Filtrar por Grupos</p>
                <select class="form-select" [(ngModel)]="grupo" (change)="selectG()">
                  <option *ngFor="let group of groups" [ngValue]="group">{{group.name}}</option>
                </select>
    
                  <p *ngIf="groups.length < 1" style="font-size:0.7em"> No hay grupos aún para este gran grupo</p>
            </div>

            <div class="col-12 col-md-3">
                <p>.</p>
                <button class="btn btn-success" (click)="exportAsXLSX()"><i class="fa-solid fa-file-lines"></i> Exportar a excel</button>
            </div>
    

    </div>


    <div *ngIf="!loading" class="row cifras">
        <div class="col-12 col-md-4">
            <h1 class="animate__animated animate__fadeIn">Vental Total</h1>
            <h3 class="animate__animated animate__fadeIn">${{cifras.ventaTotal | separator:0}}</h3>
            <p> #{{cifras.cantidadTotal | separator:0}}</p>
            <canvas  
                baseChart
                class="animate__animated animate__fadeIn chart"
                [data]="pieChartData"
                [type]="pieChartType"
                >
            </canvas>

        </div>
        <div *ngIf="gg.id !=0" class="col-12 col-md-4">
            <h1>Venta Gran Grupo</h1>
            <h3> ${{gg.ventas | separator:0}}</h3>
            <p>{{gg.name}}: % {{gg.porcentaje}}</p>
            <canvas  
            baseChart
            class="animate__animated animate__fadeIn chart"
            [data]="pieChartData2"
            [type]="pieChartType"
            >
        </canvas>
        </div>
        <div *ngIf="gg.id != 0 && grupo.id != 0" class="col-12 col-md-4">
            <h1>Venta Grupo </h1>
            <h3> ${{cifras.ventaGR | separator:0 }} </h3>
            <p>{{grupo.name}} # ${{cifras.cantidadGR | separator:0}}</p>
            <p>Participación: </p>
            <p>Dentro del Gran Grupo % {{cifras.porcentajeGR}} </p>
            <p>Dentro de la venta total % {{cifras.porcentajeGRT}}</p>
        </div>
    </div>



    <div class="row">
        <div class="col-10 offset-1 tabla shadow animate__animated animate__fadeIn">

            <table class="table table-striped table-hover">
                <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Producto</th>
                    <th scope="col">Precio Promedio</th>
                    <th scope="col">Total</th>
                    <th scope="col">Participación General</th>
                    <th *ngIf="gg.id != 0" scope="col">Participación Grupo </th>

                </tr>
                </thead>
                <tbody *ngIf="ranking.length > 0">
                <tr *ngFor="let p of ranking | filter : { 'name' : searchString, 'code' : searchString, 'codeTec' : searchString} ; index as i " class="pointer" (click)="selectProd(p)">
                    <th scope="row">{{i + 1}}</th>
                    <td >{{p.name}}</td>
                    <td >  {{p.quantity | separator : 0 }} x $ {{p.price | separator : 0  }}</td>
                    <td > $ {{p.totalVenta | separator : 0  }}</td>
                    <td >% {{p.porcentaje}}</td>
                    <td *ngIf="gg.id != 0 && grupo.id == 0" > % {{p.porcentajeGG}} </td>
                    <td *ngIf="gg.id != 0 && grupo.id != 0" >% {{p.porcentajeGR}}  </td>
                </tr>
                </tbody>
                <p *ngIf="ranking.length < 1">Sin ventas en este cierre</p>
            </table> 

        </div>
    </div>




    </div>
</div>

<app-menu-stats></app-menu-stats>