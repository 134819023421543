<app-loading *ngIf="loading"></app-loading>

<div *ngIf="!loading" class="row  discontent fondoCeleste ">
<div class="col-12 contenido">

    <div class="row">
        <div *ngIf="payment.status != 2" class="col-12 col-lg-6 offset-lg-3 info">
            <h1>Hola!</h1>
            <h2>Tienes un pago por: ${{payment.amountP | separator:0}}</h2>
            <button class="btn btn-primary bg-morado btn-lg w-100" (click)="pagar()">Pagar ${{payment.amountP | separator:0}}</button>
            <div class="text-center">
                <img class="text-center" src="assets/images/webpay.svg" width="150px">
            </div>

            <hr>
        </div>
        <div *ngIf="payment.status == 2" class="col-12 col-lg-6 offset-lg-3 info">
            <h1>Hola!</h1>
            <h2>Ya se hizo un pago por ${{payment.amountP | separator:0}} </h2>

        </div>
    </div>

    <div class="row">
        <div class="col-12 col-md-6 offset-md-3 caja">
            
            <h2>Detalle cuenta completa</h2>
            <div class="row item" *ngFor="let p of detSale">
                <div class="col-6">
                    <p>{{p.productName}}</p>
                </div>
                <div class="col-4">{{p.quantity | number}} x ${{p.price | separator:0}} <span *ngIf="p.discount > 0"> - ${{p.discount | separator:0}}</span></div>
                <div class="col-2">$ {{p.total | separator:0}}</div>
            </div>
            
        </div>
    </div>








    </div>       
</div>
