/// <reference types="@angular/localize" />

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { persistState } from '@datorama/akita';

if (environment.production) {
  enableProdMode();
}


const storage = persistState({
  include: ['products'], // los nombres de los stores que quieres persistir
  key: 'afrodita', // la clave que se usará para almacenar el estado en el almacenamiento local
  storage: localStorage, // el almacenamiento que se usará
});
const providers = [{ provide: 'persistStorage', useValue: storage }];



platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
