import { QueryEntity } from '@datorama/akita';
import { ProductStore, ProductState } from './products.store';
import { productModel } from '../models/data/product.model';
import { priceModel } from '../models/data/price.model';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ProductsQuery extends QueryEntity<ProductState, productModel> {
  prices$ = this.select(state => state.prices);

  constructor(protected store: ProductStore) {
    super(store);
  }
}