import { Routes } from "@angular/router";
import { ConfigComponent } from "./config/config.component";
import { UsuariosComponent } from "./usuarios/usuarios.component";



export const routesConfig: Routes = [
    {path: 'usuarios', component: UsuariosComponent},
    {path: 'config', component: ConfigComponent},
    {path: '**', pathMatch: 'full', redirectTo: 'login'}
  ];
  
  