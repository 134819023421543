export class paymentModel {
    id: string;
    headId: number; // REVI
    storeId: number;
    payType: string; // DE LA TABLA TIPOPAGOS //tarjeta de credito, gift card etc
    payWay: string; // A través de quie se pagó, pos de transbank, de klap, o web a medida que vayamos integrando
    totalAmount: number;
    amountA: number;
    amountP: number;
    tip: number;
    change: number;
    date: string;
    status: number; //0 borrado, // 1 proceso de pago // 2 Pagado // 3 pendiente // 4 anulado                             
    currency: string; // CLP etc
    netoBruto: string;
    shiftId: number;
    cashierId: number; // en que caja se hizo la venta
    sellerId: number; // que persona hizo la venta garzón o cajero
    clientId: number;
    token:string;
    modified_at: string 
    payTypeName:string;
    
    constructor() {
        this.id              = '0';
        this.headId          = 0;
        this.storeId         = 0;
        this.payType         = ''; 
        this.payWay          = 'LOCAL'; // Local si no sabemos, WEBPAY, futuro... KLAP, REDELCOM,
        this.totalAmount     = 0;  // TOTAL DE LA VENTA
        this.amountA         = 0; // MONTO QUE VA DIRECTO A PAGAR LA VENTA
        this.amountP         = 0; // MONTO QUE PAGA LITERAL LA PLATA QUE ENTRA PUEDE IR INCLUIDA PROPINA Y VUELTO
        this.tip             = 0; // PROPINA
        this.change          = 0; // vuelto
        this.date            = '';
        this.status          = 2; //0 borrado, // 1 proceso de pago // 2 Pagado // 3 pendiente // 4 anulado                             
        this.currency        = 'CLP';
        this.netoBruto       = 'B';
        this.shiftId         = 0;
        this.cashierId       = 0; // en que caja se hizo la venta
        this.sellerId        = 0; // que persna hizo la venta garzón o cajero
        this.clientId        = 0; // en que caja se hizo la venta
        this.modified_at     = '';
        this.token           = '';
        // this.docType        = '';
        // this.nboleta        = 0;
       }

}




// payTypes
// PVCM	PAGO EN CHEQUE                                    
// PVEM	PAGO EN EFECTIVO                                  
// PVGM	PAGO CON GIFT CARD                                
// PVKM	PAGO EN TICKET RESTAURANTE                        
// PVNM	PAGO EN RED COMPRA                                
// PVRM	PAGO EN CHEQUE RESTAURANTE                        
// PVSM	PAGO EN CRÉDITO                                   
// PVTM	PAGO EN TARJETA     