export class printerModel {
    id: number;
    storeId: number;
    destinationId: number;
    ip:string;
    port: number; 
    printerOS: string;
    name: string;

    constructor() {
        this.id             = 0;
        this.storeId        = 0;
        this.destinationId  = 0;
        this.ip             = '';
        this.port           = 0; 
        this.printerOS       ='';
        this.name           ='';
    }
}


