export class memoryModel {
    data: {
            greatGroupId: number,
            groupId: number
            }

    constructor() {
        this.data = {
                    greatGroupId: 0,
                    groupId: 0
                    } ;
        }

}