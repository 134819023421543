export class prodprintModel {
    id: number;
    productId: '';
    printDestinationId: number;
    status: number;

    constructor() {
        this.id                      = 0;
        this.productId               = '';
        this.printDestinationId      = 0;
        this.status                  = 1;
    }
}
