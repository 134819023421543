import { Component } from '@angular/core';
import { printerModel } from 'src/app/models/config/printer.model';
import { paramsModel } from 'src/app/models/general/params.model';
import { DataService } from 'src/app/services/data.service';
import { MensajesService } from 'src/app/services/mensajes.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-printers',
  templateUrl: './printers.component.html',
  styleUrls: ['./printers.component.css']
})
export class PrintersComponent {
  loading                                = true;
  stores                                 = [];
  printersAll:any[]                      = [];
  printers:any[]                         = [];
  destinations                           = [];
  user:any                               = {};
  store:any                              = {};
  printer: printerModel                  = new printerModel();
  params                                 = new paramsModel();
  selectedStore: string = '';


  constructor(private conexData: DataService,
              private _user:UserService,
              public _m: MensajesService) { 
              this.user   = this._user.userInfo();
              const puede = this._user.validarNivel(2, this.user);
              
              console.log('soy', this.user, 'puede', puede);
              if (puede){
                this.getParams();
                this.getDestinations();
                this.getStores();
              }

}

getParams(){
  if (localStorage.getItem('paramsGournet')){
    this.params = JSON.parse(localStorage.getItem('paramsGournet'))
  }
}

getStores(){
  this.conexData.getData('/data/stores').subscribe( resp =>{
    console.log('resp stores', resp);
    this.stores            = resp;
    if (this.stores.length < 1){
      this._m.error('error', 'Comunicate con Gour-net por favor');
      this.loading = false;
      return;
    } 
      if (this.params.store.id > 0){
        const existe = this.stores.find( s => s.id == this.params.store.id);
        if (existe){
          this.store = existe;
        } else {
          this.store = this.stores[0];;
        }
      } else {
        this.store = this.stores.find( s => s.id == this.user.storeId);
      }

      this.selectedStore = this.store.id;

      console.log('stores', this.stores);
      console.log('esta Store', this.store);

      this.getPrinters();

  })
} 

getDestinations(){
  this.loading = false;
  this.conexData.getData(`/data/printDestinations`).subscribe({
    next:(data:any) => {
      console.log('destinations', data);
      this.destinations          = data;
      this.printer.destinationId = this.destinations[0].id;

    },
    error:(err:any) => { console.log('error',err); this.loading = false;}
  })
}


getPrinters(){
  this.conexData.getData('/data/printers').subscribe({
    next:(data:any) => { 
      if (!data){
        this.printersAll = [];
      } else {
        this.printersAll = data;
      }

      this.changeLocal(this.store.id);

      // this.printers = this.printersAll.filter( p => p.storeId == this.store.id);        
      console.log('printers', this.printersAll);

      
      this.loading = false;

  }, error: (err:any) => {
    console.log('error', err);
    this.loading = false;
  }
  })
}

newPrint(){
  this.printer = new printerModel();
  this.printer.destinationId = this.destinations[0].id;
}

selectPrinter(u){
  console.log('printer', u);
  this.printer = u;
}

changeLocal(v){
  console.log('cambie', v);

  const existe = this.stores.find( s => s.id == v);
  console.log('existe2', existe);


  console.log('store', this.store);
  this.printers = this.printersAll.filter( p => p.storeId == existe.id);      
  this.newPrint();  

}


onStoreChange(selectedValue: string) {
  this.selectedStore = selectedValue;
  console.log('Tienda seleccionada:', this.selectedStore);
  const existe = this.stores.find( s => s.id == this.selectedStore);
  this.printers = this.printersAll.filter( p => p.storeId == existe.id);      
  this.newPrint();  

}




guardar(){
  if(this.printer.name.length < 2){
    this._m.warning('Nombre incompleto','Debes escribir un nombre por favor');
    return;
  }
 
  if(this.printer.ip.length < 2){
    this._m.warning('Ip incompleto','Si escribes mal la ip puedes tener problemas');
    return;
  }
  
  if(this.printer.port < 1){
    this._m.warning('Puerto incompleto','Si escribes mal el puerto puedes tener problemas');
    return;
  }
  
  this.printer.storeId = this.store.id;

  this.loading = true;

  let tarea = 'insert';
  if (this.printer.id > 0){
    tarea = 'update'
  }
  console.log('voy a guardar', this.printer);

  this.conexData.guardarDato(`/postData/printers/${tarea}`, this.printer).subscribe({
    next:(data:any) => {
      this.getPrinters();
      this._m.ok('Ok','Impresora guardad con éxito');
    },
    error:(err:any) => {console.log('error', err)},
  })
}

borrar(){
  console.log('voy a borrar', this.printer);
}
}
