import { Routes } from "@angular/router";
import { WebpayComponent } from "./webpay/webpay.component";
import { OkComponent } from "./ok/ok.component";
import { ErrorComponent } from "./error/error.component";

export const routesMobilepay: Routes = [
    {path: 'webpay/:saleId/:paymentId', component: WebpayComponent},
    {path: 'ok/:id', component: OkComponent},
    {path: 'error/:id', component: ErrorComponent},
    {path: '**', pathMatch: 'full', redirectTo: 'login'}
  ];
  
  