import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { memoryModel } from 'src/app/models/general/memory.model';
import { DataService } from 'src/app/services/data.service';
import { MensajesService } from 'src/app/services/mensajes.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loading       = false;
  mail:string   =  '';
  pass:string   =  '';
  constructor(private conexData: DataService,
              public mensajes: MensajesService,
              private router: Router) { }

  ngOnInit(): void {
    const memory = new memoryModel();
    localStorage.setItem('memoryGournet', JSON.stringify(memory));
    localStorage.removeItem('paramsGournet');
    localStorage.removeItem('token');
    localStorage.removeItem('afrodita');
  }



  validarMail(form:NgForm){
        if (form.invalid) { return; }
        this.loading = true;

        const body = {
          email: this.mail,
          password: this.pass
        }

        console.log('body', body)


        this.conexData.guardarDato('/api/auth/login', body ).subscribe({
          next:(resp:any) => {
            console.log('logueo0', resp.token);
            localStorage.setItem('token', resp.token);
          },

          error:(error:any) => {
            console.error('Ha ocurrido un error:', error.error);
            this.mensajes.error('No se ha podido loguear', error.error)
            this.loading = false;
          },complete:() => {
            this.passOk();
          }
        })
  }

  passOk(){

    this.router.navigateByUrl('/general/home');
  }



}
