    

<div *ngIf="!loading" class="row  discontent fondoCeleste" id="comprobante">

    <div class="col-10 offset-1 col-lg-4 offset-lg-4 full ">

    <div class="cont row">
        <div class="col-12 info text-center" >
            <h1><img src="assets/images/iconos/cashOk.png" width="100px"></h1>
            <h1>Excelente!</h1>
            <h2>Pago realizado con éxito</h2>
        </div>
    </div>
    <div *ngIf="loading" class="contenido row">
        <div class="col-12 info text-center" >
            <h2>Cargando </h2>
        </div>
    </div>

    <div *ngIf="!loading" class="contenido row ">
        <div class="col-12 info text-center" >
            <table class="table table-hover">
                <tbody>
                <tr>
                    <td class="text-start">Pagado:</td>
                    <td class="text-end">$ {{infoPay.amount | separator:0}}</td>
                </tr>
                <tr>
                    <td class="text-start">Código de autiración:</td>
                    <td class="text-end">{{infoPay.authcode }}</td>
                </tr>
                <tr>
                    <td class="text-start" >Fecha:</td>
                    <td class="text-end"> {{infoPay.realDate | date:'dd/MM/yyyy'}} </td>
                </tr>
                <tr>
                    <td class="text-start" >Hora:</td>
                    <td class="text-end"> {{infoPay.realDate | date:'HH:mm'}} </td>
                </tr>
                <tr>
                    <td class="text-start" >Payment Id:</td>
                    <td class="text-end"> {{infoPay.paymentId }} </td>
                </tr>
             
                </tbody>
            </table>

            <button class="btn btn-primary" (click)="descargarComoPDF()">Descargar como PDF</button>

        </div>
    </div>

    </div>
 </div>       

