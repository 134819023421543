import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FechasService {

  constructor(private datePipe: DatePipe) { }



obtenerHoraActual() {
  let horaActual: string;

  const fechaActual = new Date();
  return horaActual = this.formatearHora(fechaActual);
}


obtenerFechaHoy(): string {
  const fechaHoy = new Date();
  const dd = String(fechaHoy.getDate()).padStart(2, '0');
  const mm = String(fechaHoy.getMonth() + 1).padStart(2, '0'); // El mes es 0-indexado
  const yyyy = fechaHoy.getFullYear();
  
  return `${dd}-${mm}-${yyyy}`;
}

obtenerFechaHoy2(): string {
  const fechaHoy = new Date();
  const dd = String(fechaHoy.getDate()).padStart(2, '0');
  const mm = String(fechaHoy.getMonth() + 1).padStart(2, '0'); // El mes es 0-indexado
  const yyyy = fechaHoy.getFullYear();
  
  return `${yyyy}-${mm}-${dd}`;
}

obtenerFechaHoySQL(): string {
  const fechaHoy = new Date();
  const dd = String(fechaHoy.getDate()).padStart(2, '0');
  const mm = String(fechaHoy.getMonth() + 1).padStart(2, '0'); // El mes es 0-indexado
  const yyyy = fechaHoy.getFullYear();
  
  return `${mm}-${dd}-${yyyy}`;
}



formatearHora(fecha: Date): string {
  const horas = fecha.getHours().toString().padStart(2, '0');
  const minutos = fecha.getMinutes().toString().padStart(2, '0');
  const segundos = fecha.getSeconds().toString().padStart(2, '0');
  
  return `${horas}:${minutos}:${segundos}`;
}

obtenerDatetime(): string {
  const fechaActual = new Date();
  return this.datePipe.transform(fechaActual, 'yyyy-MM-dd HH:mm:ss') || '';
}
}
