import { Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  @Input() isExpanded: boolean = false;
  @Output() toggleSidebar: EventEmitter<boolean> = new EventEmitter<boolean>();
  
  elem: any;
  full: boolean = false;
  constructor(private router: Router,
              public _user:UserService) { 
    this.elem = document.documentElement;
  }
  
  ngOnInit(): void {
  }

  handleSidebarToggle(){
    console.log('click', this.isExpanded);
    this.isExpanded = !this.isExpanded
    console.log('post clickl', this.isExpanded);
    this.toggleSidebar.emit(!this.isExpanded);
  } 




  soporte(){
    console.log('soporte')
    this.router.navigateByUrl('https://www.gour-net.cl/soporte');

  }
   
  fullscreen() {
    if (this.elem.requestFullscreen) {
      this.elem.requestFullscreen();
    } else if (this.elem.mozRequestFullScreen) {
      /* Firefox */
      this.elem.mozRequestFullScreen();
    } else if (this.elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      this.elem.webkitRequestFullscreen();
    } else if (this.elem.msRequestFullscreen) {
      /* IE/Edge */
      this.elem.msRequestFullscreen();
    }
    this.full = true;
  }



}
