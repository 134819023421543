
 <app-loading *ngIf="loading"></app-loading>


 <div class="row header azul r0">
     <div class="col-12">
        <h2 class="animate__animated animate__fadeIn"> <i class="fa-regular fa-comment"></i> Mensajes</h2>
        <p class="botonMenu animate__animated animate__pulse" style="color:white;margin:10px 0 0 0" data-bs-toggle="offcanvas" data-disableScrolling="false" href="#menuData" role="button" aria-controls="menuData"> < Menu Data </p>

     </div>
 </div>





 <div *ngIf="!loading" class="row  pad20" [ngClass]="msg.id == 0 ? 'bg-warning r0' : 'bg-secondary r0'" >
    <div class="col-12 col-md-3 etiqueta">
        <p *ngIf="msg.id == 0">Nuevo Mensaje</p>
        <p *ngIf="msg.id != 0">Editar Mensaje</p>
    </div>
    <div class="col-12 col-md-4">
        <input type="text" class="form-control" [(ngModel)]="msg.message" maxlength="100" placeholder="Escribe aqui el mensaje">
    </div>
    <div class="col-12 col-md-2">
        <button class="btn btn-primary w-100" (click)="guardar()">Guardar</button>
    </div>
    <div class="col-12 col-md-3">
        <button *ngIf="msg.id != 0" class="btn btn-warning w-100" (click)="newMsg()">Nuevo Mensaje</button>
    </div>
 </div>       
 




 <div *ngIf="!loading && mensajes.length > 0" class="row mensajes" style="margin:0">
    <div *ngFor="let m of mensajes" class="col-12 col-md-3 hvr-shrink">
        <div class="msg shadow " (click)="selectMessage(m)" >
            <p>{{m.message}}</p>
        </div>
    </div>
 </div>


 <div *ngIf="!loading && mensajes.length < 1" class="row" style="margin:0">
    <div class="col-12 pad20 text-center" >
        <p>No hay mensajes pre definidos aún</p>
    </div>
 </div>


 <app-menu-data></app-menu-data>
