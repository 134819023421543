import { Component } from '@angular/core';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.css']
})
export class LoadingComponent {
  urlIcono = '';

  constructor() { }

ngOnInit(): void {
  this.azarIcon(10);
}

azarIcon(max){
  const num = Math.floor(Math.random() * max) + 1;
  console.log('num', num)
  
  let url = 'assets/images/loadings/loading' + num.toString() + '.png'
  console.log('url', url);
  this.urlIcono = url 

}
}
