<app-loading *ngIf="loading"></app-loading>
<app-loading *ngIf="loading2"></app-loading>


<div class="row header headerSalon" style="margin-right:0">
    <div class="col-6 col-md-8">
        <h2 class="animate__animated animate__fadeIn">{{selectedSector.name}}</h2>
    </div>
    <div class="col-6 col-md-4">
        <!-- <p>iconos</p> -->
    </div>
</div>



<div *ngIf="!loading" class="row salon" style="margin-right:0">
    <div class="col-12 col-md-9">

        <div class="row d-block d-md-none sectorCel">
            <div class="col-12">
                <label>Sectores</label>
                <select [(ngModel)]="selectedSector" (change)="filtrarSector()"  class="form-select">
                    <option *ngFor="let s of sectores" [ngValue]="s">
                      {{ s.name }}
                    </option>
                  </select>
            </div>
        </div>

        <div *ngIf="mesas.length > 0" class="row mesas animate__animated animate__fadeIn">
            <div class="col-12">
                <div  *ngFor="let m of mesas" 
                class="mesa hvr-grow"
                [ngClass]="{
                    'mesa_libre': m.status      === 1,
                    'mesa_ocupada': m.status    === 2,
                    'mesa_comandada': m.status  === 3,
                    'mesa_hold': m.status       === 4,
                    'mesa_precuenta': m.status  === 5,
                  }"
                (click)="selectMesa(m)">
                    <p  [ngClass]="m.hold == 1 ? 'hold animate__animated animate__tada animate__infinite' : '' " class=""> {{m.code}}</p>
                </div>
            </div>
        </div>
             
        <div *ngIf="mesas.length < 1" class="row mesas animate__animated animate__fadeIn text-center">
            <div  class="col-12 ">
                <p *ngIf="selectedSector.id > 0">No hay mesas creadas en {{selectedSector.name}}</p>
                <p *ngIf="selectedSector.id == 0" >No tienes mesas asociadas en este momento.</p>
            </div>
        </div>

        
    </div>




    <div class="col-md-3 sectores d-none d-md-block animate__animated animate__slideInRight shadow">
     
        <h1>Sectores</h1>
        <ul class="list-group">
            <li class="list-group-item hvr-sweep-to-right" *ngFor="let s of sectores" (click)="selectSector(s)"  [ngClass] = "selectedSector.id === s.id ? 'seleccionado' : 'notSelected' ">{{s.name | titlecase }}</li>
        </ul>

    </div>
</div>

<div *ngIf="modalNpersonas" class="row fondoModal animate__animated animate__fadeIn">
    <div class="col-10 offset-1 col-md-4 offset-md-4 animate__animated animate__fadeInDown">
        
        <div class="npersonas text-center">
        


            <div class="row">
                <div class="col-12 text-end ">
                    <div class="hvr-grow">
                        <i class="fas fa-times hvr-icon text-danger pointer" (click)="clickPersona(999)"></i>
                    </div>
                </div>
            </div>
      
            <div class="row">
                <div class="col-10 offset-1">
                    <h2>¿Cuantas personas son en la mesa?</h2>
                    <input type="number" [(ngModel)]="table.npeople" class="form-control">
                </div>
            </div>
      

            <div class="row botones">
                <div class="col-12 text-center">
                    <button class="btn boton hvr-grow-shadow" (click)="clickPersona(1)">1</button>
                    <button class="btn boton hvr-grow-shadow botonCentro" (click)="clickPersona(2)" >2</button>
                    <button class="btn boton hvr-grow-shadow" (click)="clickPersona(3)" >3</button>
                </div>
            </div>
            <div class="row botones">
                <div class="col-12 text-center">
                    <button class="btn boton hvr-grow-shadow" (click)="clickPersona(4)">4</button>
                    <button class="btn boton hvr-grow-shadow botonCentro" (click)="clickPersona(5)" >5</button>
                    <button class="btn boton hvr-grow-shadow" (click)="clickPersona(6)" >6</button>
                </div>
            </div>
            <div class="row botones">
                <div class="col-12 text-center">
                    <button class="btn boton hvr-grow-shadow" (click)="clickPersona(7)">7</button>
                    <button class="btn boton hvr-grow-shadow botonCentro" (click)="clickPersona(8)" >8</button>
                    <button class="btn boton hvr-grow-shadow" (click)="clickPersona(9)" >9</button>
                </div>
            </div>
            <div class="row botones">
                <div class="col-12 text-center">
                    <button class="btn delete hvr-grow-shadow" (click)="clickPersona(-1)"><i class="fa-solid fa-delete-left"></i></button>
                    <button class="btn boton hvr-grow-shadow botonCentro" (click)="clickPersona(0)" >0</button>
                    <button class="btn boton ok hvr-grow-shadow" (click)="clickPersona(100)" ><i class="fa-solid fa-check"></i></button>
                </div>
            </div>
          
            
        </div>
    </div>
</div>