<div class="sidebar" [ngClass]="{ 'expanded': isExpanded }">
    <div class="header">
      <h1 class="title" style="color:white">
        Gour > net
      </h1>
      <div class="toggle" (click)="handleSidebarToggle()">
        <img src="assets/images/iconoG.svg" width="40px">
      </div>
    </div>

    <div class="content">
     
      <div class="link">
        <div class="content" routerLink="/general/home" [routerLinkActive]="'active'"  >
          <div class="icon">
            <i class="fa-solid fa-house icono" ></i>
          </div>
          <div class="title">Home</div>
        </div>
      </div>
      

      <div class="link">
        <div class="content" [routerLink]="['/salon/mesas']" [routerLinkActive]="'active'">
          <div class="icon">
            <img src="assets/images/table.svg" width="25px">
          </div>
          <div class="title">Mesas</div>
        </div>
      </div>
    
      <div class="link">
        <div class="content" [routerLink]="['/cajas/caja/directa/0']" [routerLinkActive]="'active'">
          <div class="icon">
            <i class="fa-solid fa-cash-register icono"></i>
          </div>
          <div class="title">Venta Directa</div>
        </div>
      </div>

      <div class="link">
        <div class="content"  [routerLink]="['/cajas/cajero']"  [routerLinkActive]="'active'">
          <div class="icon">
            <img src="assets/images/caja.svg" width="30px" >
          </div>
          <div class="title">Caja</div>
        </div>
      </div>


      <div class="link">
        <div class="content" [routerLink]="['/general/shift/menu']" [routerLinkActive]="'active'">
          <div class="icon">
            <i class="fa-regular fa-clock icono"></i>
          </div>
          <div class="title">Turno</div>
        </div>
      </div>
      
      <div class="link">
        <div class="content" [routerLink]="['/data/productos']" [routerLinkActive]="'active'" >
          <div class="icon">
            <img src="assets/images/data.svg" width="30px">
          </div>
          <div class="title">Data</div>
        </div>
      </div>

      <div class="link">
        <div class="content" [routerLink]="['/stats/live']" [routerLinkActive]="'active'">
          <div class="icon">
            <i class="fa-solid fa-chart-simple icono"></i>
          </div>
          <div class="title">Informes</div>
        </div>
      </div>
    
      

      <div class="link">
        <div class="content" [routerLink]="['/config/config']" [routerLinkActive]="'active'">
          <div class="icon">
            <i class="fa-solid fa-gear icono"></i>
          </div>
          <div class="title">Configuración</div>
        </div>
      </div>

      <div class="link">
        <div class="content" [routerLink]="['/general/home']" [routerLinkActive]="'active'">
          <div class="icon">
            <i class="fa-solid fa-inbox icono"></i>
          </div>
          <div class="title">Inbox</div>
        </div>
      </div>
  
      <div class="link">
        <div class="content" (click)="fullscreen()" [routerLinkActive]="'active'">
          <div class="icon">
            <i class="fa-solid fa-expand icono"></i>
          </div>
          <div class="title">Pantalla completa</div>
        </div>
      </div>
 
      <div class="link">
        <a href="http://www.gour-net.cl/clientes">
        <div class="content"  [routerLinkActive]="'active'">
          <div class="icon">
           <i class="fa-solid fa-headset icono"></i>
          </div>
          <div class="title">Soporte</div>
        </div>
      </a>
      </div>
     

 




    </div>




    
    <div class="footerNav fixed-bottom ">
      <div class="link hvr-grow">
        <div class="content" (click)="_user.cerrarSesion()">
          <div class="icon">
            <i class="fa-solid fa-door-open"></i>
          </div>
          <div class="title">Salir</div>
        </div>
      </div>

      
    </div>
  </div>