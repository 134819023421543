import { Component, EventEmitter,Input, Output } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { MensajesService } from 'src/app/services/mensajes.service';
import { UserService } from 'src/app/services/user.service';


@Component({
  selector: 'app-digito-pay',
  templateUrl: './digito-pay.component.html',
  styleUrls: ['./digito-pay.component.css']
})



export class DigitoPayComponent {
  @Input() datoDesdeCaja: any;

  @Output()
  enviarPadre: EventEmitter<object> = new EventEmitter<object>();

  firstTime                       = true;
  destino: string                 = 'total'
  cifras:any                      = {};
  selectedCard                    = {code:'VI', name: 'VISA'}

  creditCards                     = [{code:'VI', name: 'VISA'},{code:'MC', name: 'MASTER CARD'},{code:'AE', name: 'AMERICAN EXPRESS'},{code:'DC', name: 'DINERS CLUB'},{code:'OO', name: 'Otra'}]
  cash                            = 0;
  montoSugerido                   = 0;
  storeId                         = '';

  constructor(public _m: MensajesService,
              private conexData: DataService) {  }

ngOnInit(): void {
  this._m.sonido('menu_flick.mp3');

  Object.assign(this.cifras, this.datoDesdeCaja);
  this.montoSugerido = this.datoDesdeCaja.amountA;

  console.log('datosDesdeCaja', this.datoDesdeCaja)
  console.log('cifras', this.cifras);

  this.calcMonto();

}




btnClick(num:string){
  this._m.sonido('pop.wav');
  let palabra = '';

  if (this.cifras.tipoPago == 'EFE'){
    console.log('aca efe')
    palabra = this.cash.toString();
  } else {
    palabra = this.cifras.amountA.toString();
  }

  
  if (num == 'delete' && palabra.length > 0){
    palabra = palabra.slice(0,-1);
  } else {
    if (this.firstTime){
      palabra     = num
      this.firstTime = false;
    } else {
      palabra += num;
    }
  }

  console.log('palabra', palabra);


  if (this.cifras.tipoPago == 'EFE'){
    this.cash = Number(palabra);
  } else {
    this.cifras.amountA = Number(palabra);
  }
  this.changePago();
}


changePago(){
  console.log('changePago', this.cifras);
  if (this.cifras.amountA > this.cifras.saldo && this.cifras.tipoPago != 'EFE'){
      this._m.warning('ups', 'no puede abonar más de lo que debe')
      this.cifras.amountA = this.cifras.saldo;
    } 
  
if(this.cifras.propina == 0){
  this.calcMonto()
} else {
  this.calcPropina();
}

}


calcMonto(){
  console.log('cifras', this.cifras);
  console.log('cash', this.cash);
  this.cifras.propina = 0;
  this.cifras.vuelto  = 0;
  
  if (this.cifras.tipoPago == 'EFE'){
    if(this.cash > this.montoSugerido){
      this.cifras.vuelto  = this.cash - this.montoSugerido; 
      }
      this.cifras.amountP = this.cash;
      this.cifras.amountA = this.cash - this.cifras.vuelto;

  } else { // otros medios de pago no efectivo
    this.cifras.amountP  = this.cifras.amountA;
  }
}


calcPropina(){
  if (this.cifras.tipoPago == 'EFE'){
    if(this.cash < this.montoSugerido){
      this.cifras.propina = Math.floor(this.cash * 0.1);
      this.cifras.vuelto  = 0;
    } else {
      this.cifras.propina = Math.floor(this.montoSugerido * 0.1);
      this.cifras.vuelto  = this.cash - this.cifras.propina - this.montoSugerido; 
      if (this.cifras.vuelto < 0){
        this.cifras.propina  = this.cash - this.montoSugerido;
        this.cifras.vuelto = 0;
      }
    }
      this.cifras.amountP = this.cash;
      this.cifras.amountA = this.cash - this.cifras.propina - this.cifras.vuelto;
  } else { // otros medios de pago no efectivo
    this.cifras.propina  = Math.floor(this.cifras.amountA * 0.1);
    this.cifras.vuelto   = 0;
    this.cifras.amountP  = this.cifras.amountA + this.cifras.propina;
  }

  if (this.cifras.tipoPago == 'WPC' || this.cifras.tipoPago == 'WPD' ){
    this.cifras.propina  = 0;
    this.cifras.vuelto   = 0;
    this.cifras.amountP  = this.cifras.amountA;
  }



}


borrarPropina(){
  console.log('borrar Propina');
  this.calcMonto();
}


changeTip(){
  console.log('changeTip', this.cifras);
  if (this.cifras.propina < 0){ this._m.warning('ups', 'La propina no puede ser negativa'); this.cifras.propina = 0; return}

  if (this.cifras.propina == null){ this.cifras.propina = 0 ; return}

  
  if (this.cifras.tipoPago != 'EFE'){ 
    if (this.cifras.propina > this.cifras * 0.1){
      this.calcPropina(); return;
    } else {
      this.cifras.amountP = this.cifras.amountA + this.cifras.propina;
      return;
    }
   }

  if (this.cifras.propina > this.cash ){ console.log('no puede superar el pago'); this.calcPropina(); return; }


  if (this.cash > this.montoSugerido){
    this.cifras.vuelto = this.cash - this.cifras.propina - this.montoSugerido;
    if (this.cifras.vuelto < 0){
      this.calcPropina();
    }
  } else {
    if (this.cifras.propina > this.cash * 0.1){
      this.calcPropina();
    } else {
      this.cifras.amountA = this.cash - this.cifras.propina;
    }
  }
}


changeVuelto(){
  console.log('cambio el vuelto', this.cifras); 
}




calcVuelto(){
  if (this.cifras.payType == 'EFE'){
    console.log('calcular vuelto')
  } else {
    // se va a propina
  }
}



borrar0(value){
  console.log('borrar 0', value);
  this.cifras.amountA = Number(value);
  this.calcPropina();
}

pagar(){
  if (this.cifras.amountA < 1){
    this._m.warning('Error Monto', 'El total a pagar debe ser mayor a 0');
    return;
  }

  const newCifra = {
    totalAmount : this.cifras.total,
    amountP: this.cifras.amountP,
    amountA: this.cifras.amountA,
    vuelto: this.cifras.vuelto,
    propina: this.cifras.propina,
    payType: this.cifras.tipoPago,
    cardBrand: this.selectedCard
  }

  if (this.datoDesdeCaja.tipoPago == 'credito' ){
    newCifra.payType = this.selectedCard.code
  }

  
  console.log('haré un pago', newCifra);
  this.enviarPadre.emit(newCifra);

}






cerrar(){
  const body ={
    medioPago:'cerrar'
  }
  this.enviarPadre.emit(body);
}




}
