import { Component } from '@angular/core';
import { printDestinationModel } from 'src/app/models/data/printDestination.model';
import { DataService } from 'src/app/services/data.service';
import { MensajesService } from 'src/app/services/mensajes.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-printdestination',
  templateUrl: './printdestination.component.html',
  styleUrls: ['./printdestination.component.css']
})
export class PrintdestinationComponent {
  loading                                = true;
  user:any                               = {};
  destinations:any[]                     = [];
  printDest: printDestinationModel       = new printDestinationModel();

  constructor(private conexData: DataService,
    private _user:UserService,
    public _m: MensajesService) { 
      this.user = this._user.userInfo();
      this.getDestinations();
}

getDestinations(){
  this.loading = false;
  this.conexData.getData(`/data/printDestinations`).subscribe({
    next:(data:any) => {
      console.log('data', data);
      this.destinations = data;
      this.loading      = false;
    },
    error:(err:any) => { console.log('error',err); this.loading = false;}
  })
}


selectDest(d){
  this.printDest = d;
  console.log('printDest', this.printDest);
}

newDest(){
  this.printDest = new printDestinationModel();
}


desactivar(){
  if (this.printDest.status == 1){
    this.printDest.status = 2
  } else {
    this.printDest.status = 1;
  }
}


guardar(){
  let prints:any = [];

  let url = '/postData/printDestination'
  if (this.printDest.id > 0){
    prints = this.printDest;
    url = '/postData/updateprintDestination';
  } else {
    this.printDest.companyId = this.user.companyId;
    prints.push(this.printDest);

  }



  if(this.printDest.name.length < 2){
    this._m.warning('Nombre incompleto','Debes escribir un nombre por favor');
    return;
  }
 

  console.log('voy a guardar ', prints);

  this.loading = true;

  this.conexData.guardarDato(url, prints).subscribe({
    next:(data:any) => {
      this.getDestinations();
      this._m.ok('Ok', `Datos guardados correctamente`)
      this.loading = false;

    },
    error:(err:any)=>{
      console.log('err', err);
      this.loading = false;
      this._m.error('error grabando', 'Si el problema persiste comunicate con Gour-net');
    },
 
  })

}


borrar(){
  console.log('Borra', this.printDest);
  this.printDest.status = 0;
  this.conexData.guardarDato(`/postData/updateprintDestination`, this.printDest).subscribe({
    next:(data:any) => {
      this.destinations        = this.destinations.filter( u => u.id != this.printDest.id);
      this.printDest = new printDestinationModel();
      this._m.warning('Eliminado', ``)
      this.loading      = false;

    },
    error:(err:any)=>{
      console.log('err', err);
      this.loading = false;
      this._m.error('error grabando', 'Si el problema persiste comunicate con Gour-net');
    },
 
  })
}




}
