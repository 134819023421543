export class headSaleModel {
    id: string;
    storeId: number;
    docType: string; // correlativo segun el docType
    numDoc: number;
    realDate: string;
    endDate: string;
    cashierId: number; //en caja se está haciendo la venta
    clientId: number;  
    sellerId: number // id del cajero.
    neto: number;
    discount: number;
    tax: number;
    iva: number;
    total: number;
    netoBruto: string; // si el total está en neto o bruto
    abono: number; // cuanto se ha abonado. Si el abono es igual al total la ve
    saldo: number; // cuantro falta por pagar.
    saleChannelId: number; // 1:Salon, 2:Vta Directa, 3:Delivery 4:Local,
    saleOrigin: string; // Tienda, uber eats, rappi
    payCondition:string // contado o credito
    tableId: number;
    tableCode: string;
    shift: number // En que turno se hizo la venta
    shiftDate: string;
    npeople: number // Cuantas personas son en la venta
    priceList: number // id de la lista de precio
    comments: string;
    cc: number; //centro de costos
    oc: string; // Orden de compra
    openTime: string;
    closeTime: string;
    status: number; // 0 borrada, 1: pendiente de pago, 2: pagada, 3: anulada

    constructor() {
        this.id             = '0';
        this.storeId        = 0;
        this.docType        = '';
        this.numDoc         = 0;
        this.realDate       = '';
        this.endDate        = '';
        this.cashierId      = 0; 
        this.clientId       = 0;  
        this.sellerId       = 0;
        this.neto           = 0;
        this.discount       = 0;
        this.tax            = 0;
        this.iva            = 0;
        this.total          = 0;
        this.netoBruto      = 'B'; 
        this.abono          = 0; 
        this.saldo          = 0; 
        this.saleChannelId  = 1; 
        this.saleOrigin     = ''; 
        this.payCondition   = 'CONTADO'; 
        this.tableId        = 0;
        this.tableCode      = '';
        this.shift          = 0;
        this.shiftDate      = '';
        this.npeople        = 1;
        this.priceList      = 0; 
        this.comments       = '';
        this.cc             = 0; 
        this.oc             = ''; 
        this.openTime       = '';
        this.closeTime      = '';
        this.status         = 1;
 
        }

}
