import { Component,ViewChild, EventEmitter, Output, ElementRef } from '@angular/core';
import { MensajesService } from 'src/app/services/mensajes.service';
import { ProductsQuery } from 'src/app/storage/products.query';
import { ProductStore } from 'src/app/storage/products.store';

@Component({
  selector: 'app-buscador-productos',
  templateUrl: './buscador-productos.component.html',
  styleUrls: ['./buscador-productos.component.css']
})
export class BuscadorProductosComponent {

  @Output()
  enviarPadre: EventEmitter<object> = new EventEmitter<object>();
  
  @ViewChild('codigoBarra') inputElement: ElementRef;


  productsAll:any         = [];
  products:any            = [];
  searchString            = '';
  codigoBarra             = '';


  selectedProduct:any     = {};
  modalModificador        = false;
  modalCombo              = false;

  producto2: any          = {};

  constructor(private productsQuery: ProductsQuery,
              private productStore: ProductStore,
              public _m: MensajesService) { 
                this.getProducts();     
  }

  ngOnInit(): void{
    console.log('selectec 1', this.selectedProduct)
   }  



  getProducts() {
    this.productsAll        = this.productsQuery.getAll();
    console.log('productos', this.productsAll)
    this.getPrecios();
  }

  getPrecios() {
    const state   = this.productStore.getValue();
    const precios = state.prices;
    console.log('precios', precios);
    this.filtrar(precios);
  }


  filtrar(precios){  
    for (let p of precios){
      const existe = this.productsAll.find( prod => prod.id == p.productId );
      if (existe){
        if(existe.code == 'VALENT004'){
          console.log('exuste', existe)
        }
        const newProduct = {...existe};
        newProduct.price = p.price;
        this.products.push(newProduct);
      } else {
        console.log('no match', p);
      }
    }

    this.products.sort((a, b) => a.name.localeCompare(b.name));
    console.log('productos con precio', this.products);
  }


  //Fin ordenar Data


  selectProd(p:any){
    this.selectedProduct        = p;
    this.selectedProduct.modifs = [];
    console.log('selectedProduct', this.selectedProduct);

    if (p.modifMenu == 1){
      console.log('tiene modif');
      this.modalModificador = true;
    } else if (p.modifMenu == 2){
      console.log('es menu');
      this.modalCombo = true;

    } else {
      this.enviarPadre.emit(p);
    }
  }

  recibirModifs(values){
    console.log('modifs', values)
    this.modalModificador = false;
    this.enviarPadre.emit(values);
  }

  recibirCombo(values){
    console.log('combos', values)
    this.modalCombo = false;
    this.enviarPadre.emit(values);
  }

  escanear(){
    console.log('escanear', this.inputElement.nativeElement.value);
    const p = this.products.find( pr => pr.codeTec == this.inputElement.nativeElement.value);
    console.log('producto', p);
    this._m.sonido('storeScanner.mp3')

    if (p){
      console.log('este', p);
      this.selectProd(p);
      this.inputElement.nativeElement.value = '';
    } else {
      console.log('Sin match, reiniciio');
    }
    setTimeout(() => {
      console.log('Sin match, reiniciio');
      this.inputElement.nativeElement.value = '';
    }, 800);
  }









  cerrar(){
    console.log('cerrar');
    const body ={
      id:0
    }

    this.enviarPadre.emit(body);
  }
}
