import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { turnoModel } from 'src/app/models/data/turno.model';
import { DataService } from 'src/app/services/data.service';
import { MensajesService } from 'src/app/services/mensajes.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-turnos',
  templateUrl: './turnos.component.html',
  styleUrls: ['./turnos.component.css']
})
export class TurnosComponent {

  loading                = true;
  loading2               = false;
  user:any               = {};
  stores:any             = [];
  selectedStore:any      = {};
  turnos:any             = [];
  selectedTurno:any      = {};
  newTurno:any           = new turnoModel();


  memoria:string         = ''
  modalNuevo             = false;
  modalEdit              = false;
  constructor(  private router: Router,
                private conexData: DataService,
                private _user:UserService,
                public mensajes: MensajesService) { 
  this.user    = this._user.userInfo();
  console.log('user', this.user);
    }

  ngOnInit(): void {
    this.getStores();
  }

  getStores(){
    this.conexData.getData('/data/stores').subscribe( resp =>{
      console.log('resp stores', resp);
      this.stores            = resp;
      if (this.stores.length < 1){
        this.mensajes.error('error', 'Comunicate con Gour-net por favor');
        this.loading = false;
        return;
      } 
        this.selectedStore = this.stores.find( s => s.id == this.user.storeId);
        this.getTurnos();
        console.log('store', this.selectedStore);
    })
  }

  getTurnos(){
    this.turnos = [];

    this.conexData.getData(`/data/shifts/${this.selectedStore.id}`).subscribe( (resp:any) => {
      console.log('turnos', resp);
      if (resp == null){
        console.log('es null')
        const newTurno   = new turnoModel();
        newTurno.name    = 'Turno 1';
        newTurno.storeId = this.selectedStore.id;
        newTurno.start    = '08:00';
        newTurno.finish   = '23:00';
        newTurno.position = 1
        console.log('newTurno', newTurno);
        this.newTurno = newTurno;
        this.guardar('insert');
      } else {
        this.turnos  = resp;
        this.modificarTurnos();
        this.loading = false;
      }
    })
  }


  modificarTurnos(){
    for (let t of this.turnos){
      t.start2 = this.modifHora(t.start);
      t.finish2 = this.modifHora(t.finish);
    }
    console.log('this.turnos', this.turnos)
  }

  modifHora(hora){
    const date = new Date(hora);
    const hh = String(date.getUTCHours()).padStart(2, '0');
    const mm = String(date.getUTCMinutes()).padStart(2, '0');
    const timeValue = `${hh}:${mm}`;
    return timeValue
  }



  selectTurno(t:any){
    console.log('t',t);
    this.memoria          = t.name;
    this.selectedTurno    = t;
    this.modalNuevo       = false;
    this.modalEdit        = true;
    console.log('turno', this.selectedTurno);
  }


  onStoreChange() {
    // this.loading = true;
    this.modalEdit  = false;
    this.modalNuevo = false;
    
    console.log('store', this.selectedStore);
    this.getTurnos();
 
  }



  modifStatus(){
    this.newTurno.status = !this.newTurno.status;
  }



  nuevo(){
    this.modalNuevo = true;
    this.modalEdit  = false;
    this.newTurno   = new turnoModel();
  }


  guardar(tarea){
    let turno:any;
    if (tarea == 'insert'){
      turno = this.newTurno;
    } else {
      turno = this.selectedTurno;
      console.log('memoria', this.memoria);
    }

    console.log('guardaré', tarea, turno);

    if(turno.name.length < 3){
      this.mensajes.error('Nombre muy corto', 'Ingresa un nombre más completo por favor')
      return
    }
    
    const nombreRepetido = this.turnos.filter( n => n.name == turno.name);
    console.log('nombreRepetido', nombreRepetido);

    if(nombreRepetido.length > 0 && tarea == 'insert'){
      this.mensajes.error('Ya existe', 'Ingresa un nombre diferente por favor')
      return
    }
   
    if(nombreRepetido.length > 1 && tarea == 'update'){
      this.mensajes.error('Ya existe', 'Ingresa un nombre diferente por favor')
      return
    }

    if(turno.start == '' || turno.finish == '' || turno.start == turno.finish){
      this.mensajes.error('Error Horario', 'Ingresa un horario por favor')
      return
    }

    const isTimeValid = this.validarHorario(turno.start, turno.finish);
    console.log('isTimeValid', isTimeValid);
    if(!isTimeValid){
      this.mensajes.error('Tope de horario', 'Fijate que los horarios no se topen')
      return
    }


    console.log('turno', turno);

    
    if (tarea == 'update'){
      turno.start = turno.start2;
      turno.finish = turno.finish2;
    } else {
      turno.storeId  = this.selectedStore.id;
      turno.start2   = turno.start;
      turno.finish2  = turno.finish;
      turno.position = 1;
      if (this.turnos.length > 0){
        turno.position =  this.turnos[this.turnos.length - 1].position + 1
      }
      console.log('insert', turno);

    }
 
    this.loading2 = true;
    this.conexData.guardarDato(`/postData/shift/${tarea}`, turno).subscribe( (resp:any) => {
     
      turno.start    = `1970-01-01T${turno.start2}:00.000Z`;
      turno.finish   = `1970-01-01T${turno.finish2}:00.000Z`;
      this.mensajes.ok('Ok','Turno guardado con éxito');

        if (tarea == 'insert'){
          turno.id      = resp.recordset[0].id; 
          this.turnos.push(turno);
          this.newTurno =  new turnoModel();
          this.modalNuevo = false;
        } else {
          this.modalEdit = false;
        }
        this.loading2 = false
    }, err => {console.log('error', err); this.mensajes.error('error',err); this.loading2 = false;});
  }






  validarHorario(horaIni, horaFin){
    // Convertir las horas de los turnos a valores numéricos para poder compararlos
    const nuevoTurnoIni = this.horaANumero(horaIni);
    const nuevoTurnoFin = this.horaANumero(horaFin);

    // Recorrer los turnos existentes para ver si hay algún choque de horario
    for (let turno of this.turnos) {
      const turnoIni = this.horaANumero(turno.start2);
      const turnoFin = this.horaANumero(turno.finish2);
  
      // Si el nuevo turno comienza o termina durante el turno existente, hay choque
      if (
        (nuevoTurnoIni >= turnoIni && nuevoTurnoIni < turnoFin) ||
        (nuevoTurnoFin > turnoIni && nuevoTurnoFin <= turnoFin)
      ) {
        return false;
      }
  
      // Si el nuevo turno abarca todo el turno existente, también hay choque
      if (nuevoTurnoIni <= turnoIni && nuevoTurnoFin >= turnoFin) {
        return false;
      }
    }
  
    // Si no se encontró choque de horario, se puede crear el nuevo turno
    return true;
  }
  
  horaANumero(hora: string): number {
    // Convertir una hora en formato HH:MM a un número que representa la cantidad de minutos desde las 00:00
    const partes   = hora.split(":");
    const horas    = parseInt(partes[0]);
    const minutos  = parseInt(partes[1]);
    return horas * 60 + minutos;
  }





}
