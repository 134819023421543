
    <div class="row navbar fixed-bottom footer animate__animated animate__slideInUp" style="margin:0">
        <div class="col-3 col-md-6 text-start">
            <p>v.1.0.2</p>
        </div>
        <div class="col-9 col-md-6  text-end">
            <p (click)="info()">{{params.store.name}} |  {{params.caja.name}} | 
            <span *ngIf="params.turno.id == 0 "> Turno Cerrado <i class="fa-solid fa-circle text-danger"></i> </span>
            <span *ngIf="params.turno.id != 0 "> Turno abierto el {{params.turno.started | date: 'dd-MM-yyyy'}} a las {{params.turno.started | date: 'HH:mm'}}  <i class="fa-solid fa-circle text-success"></i> </span>
        </p>
        </div>
    </div>



