import { Component } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { MensajesService } from 'src/app/services/mensajes.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-sectores',
  templateUrl: './sectores.component.html',
  styleUrls: ['./sectores.component.css']
})
export class SectoresComponent {

  loading                = true;
  loading2                = true;
  user:any               = {};
  stores:any             = [];
  selectedStore:any      = {};

  sectores:any           = [];
  selectedTurno:any      = {};
  sector:any             = { id: 0,  name: '', storeId: '', status: 1}

  memoria                = '';
  modalNuevo             = false;
  modalEdit              = false;


  constructor(  
    // private router: Router,
    private conexData: DataService,
    private _user:UserService,
    public _m: MensajesService) { 
    
      this.user    = this._user.userInfo();
      console.log('user', this.user);
}

ngOnInit(): void {
  this.getStores();
}


getStores(){
  this.conexData.getData('/data/stores').subscribe( resp =>{
    console.log('resp stores', resp);
    this.stores            = resp;
    if (this.stores.length < 1){
      this._m.error('error', 'Comunicate con Gour-net por favor');
      this.loading = false;
      return;
    } 
      this.selectedStore = this.stores.find( s => s.id == this.user.storeId);
      this.getSectores();
      console.log('store', this.selectedStore);
      this.loading = false;
  })
}

onStoreChange() {
  // this.loading = true;
  this.modalEdit  = false;
  this.modalNuevo = false;
  
  console.log('store', this.selectedStore);
  this.getSectores();

}


getSectores(){
  this.sectores = [];

  this.conexData.getData(`/data/salon/sectores/${this.selectedStore.id}`).subscribe( (resp:any) => {
    console.log('sectores', resp);
    if (resp == null){
      console.log('es null')
      this.sector = { id: 0, name: 'Salón Principal', storeId: this.selectedStore.id, status : 1}
      this.guardar()
      // this.newTurno = newTurno;
      // this.guardar('insert');
    } else {
      this.sectores  = resp;
      this.loading2 = false;

    }

  })
}

modifStatus(){
  this.sector.status = !this.sector.status;
}


selectSector(s){
  this.sector           = s;
  this.memoria          = s.name;
  this.modalNuevo       = false;
  this.modalEdit        = true;
  console.log('sector', this.sector);

}


cancelar(){
  console.log('cancelar', this.memoria);
  this.sector.name = this.memoria;
  this.modalEdit  = false;

}


nuevo(){
  this.sector           = { id: 0,  name: '', storeId: this.selectedStore.id, status: 1}
  this.modalNuevo       = true;
  this.modalEdit        = false;
}


guardar(){
  if (this.sector.name.length < 2){
    this._m.warning('No se puede guardar','El sector debe tener un nombre')
    return;
  }

  let tarea = 'insert';
  if (this.sector.id > 0){
    tarea = 'update';
  }

  console.log('voy a guardar', this.sector)
  this.conexData.guardarDato(`/postData/sectores/${tarea}`, this.sector).subscribe({
    next:(datos:any)=> {
      console.log('guardé', this.sector, datos);
      this.getSectores();
    },
    error:(err:any)=> { console.log('err', err)}
  })
}




}
