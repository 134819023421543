export class shiftModel {
    id:number;
    name: string;
    started: string;
    ended: string;
    userId: number;
    storeId: number;
    cashierId: number;
    initial_amount:number;
    cajaName: string;

    constructor(){
        this.id              = 0;
        this.name            = '';
        this.started         = '';
        this.ended           = '';
        this.userId          = 0;
        this.storeId         = 0;
        this.cashierId       = 0;
        this.initial_amount  = 0;
        this.cajaName        = ''; // solo para las vistas no es un campo en la bd
    }
}


