import { Component,EventEmitter, Input, Output } from '@angular/core';
import { MensajesService } from 'src/app/services/mensajes.service';
import { ProductsQuery } from 'src/app/storage/products.query';
import { ProductStore } from 'src/app/storage/products.store';

@Component({
  selector: 'app-botonera',
  templateUrl: './botonera.component.html',
  styleUrls: ['./botonera.component.css']
})
export class BotoneraComponent {

  @Output()
  enviarPadre: EventEmitter<object> = new EventEmitter<object>();
  
  @Input() datoComensales: any;


  


  productsAll:any         = [];
  products:any            = [];
  sets                    = [];
  subSets                 = [];
  prodPosis               = [];

  selectedSet                = {};
  selectedSubSet             = {};

  selectedProduct:any     = {};

  modalModificador        = false;
  modalCombo              = false;
  comensal                = 1

  constructor(private productsQuery: ProductsQuery,
    private productStore: ProductStore,
    public _m: MensajesService) { 
    this.getProdPosis();
    this.getProducts();     
    this.getPantallas();
    
  }
  
  ngOnInit(){
  console.log('comensales', this.datoComensales);
  if (!this.datoComensales){
    this.datoComensales = 1;
  }

}
getProdPosis(){
  const state    = this.productStore.getValue();
  this.prodPosis = state.prodPosis;
  console.log('posis', this.prodPosis);
}


  getProducts() {
    const prods      = this.productsQuery.getAll();
    console.log('productosAll', prods)
    this.getPrecios(prods);
  }



  getPrecios(productos) {
    const state   = this.productStore.getValue();
    const precios = state.prices;
    console.log('precios', precios);
    this.filtrar(precios, productos);
  }

  filtrar(precios, productos){  
    for (let p of precios){
      const existe = productos.find( prod => prod.id == p.productId );
      if (existe){
        const newProduct = {...existe};
        newProduct.price = p.price;
        this.productsAll.push(newProduct);
      } else {
        console.log('no match', p);
      }
    }

    this.productsAll.sort((a, b) => a.name.localeCompare(b.name));
    console.log('productos con precio', this.productsAll);
  }


  getPantallas() {
    let state         = this.productStore.getValue();
    console.log('state', state)
    let sets2         = state.pantallas.slice().sort( (a:any, b:any) => a.secondary - b.secondary);
   
    for (let s of sets2){
        if (s.secondary  < 1){
          let newPantalla          = {...s}
          newPantalla.subpantallas = [];
          this.sets.push(newPantalla)
        } else {
         const padre = this.sets.find( set => set.id == s.fatherSetId );
         console.log('padre', padre);
         padre.subpantallas.push(s);
         if(padre.subpantallas.length > 1){
           padre.subpantallas = padre.subpantallas.slice().sort( (a:any, b:any) => a.position - b.position);
         }
        }

        this.sets = this.sets.slice().sort( (a:any, b:any) => a.position - b.position);
    } 

    console.log('sets', this.sets)
    this.selectPantalla(this.sets[0]);
  }

// ========= FIN GET DATA ======== //


modifComensal(){
  console.log('comensal', this.comensal);
  this.comensal ++
  if (this.comensal > this.datoComensales){
    this.comensal = 1;
  }
}

selectPantalla(set){
  console.log('pantalla', set);
  this.products   = [];
  const posis     = this.prodPosis.filter( s => s.setId == set.setId);
  for (let p of posis ){
    const existe      = this.productsAll.find( prod => prod.id == p.productId);
    if (existe){
      const producto    = {...existe};
      producto.position = p.position;
      this.products.push( producto);
    }
  }

  console.log('productos', this.products)

}



selectProd(p:any){
  this.selectedProduct        = p;
  this.selectedProduct.modifs = [];
  p.eater = this.comensal;
  
  console.log('selectedProduct', this.selectedProduct);
  if (p.modifMenu == 1){
    console.log('tiene modif');
    this.modalModificador = true;
  } else if (p.modifMenu == 2){
    console.log('es menu'); // por programar
    this.modalCombo = true;
  } else {
    this.enviarPadre.emit(p);
  }
}



 
recibirModifs(values){
  console.log('modifs', values)
  this.modalModificador = false;
  this.enviarPadre.emit(values);
}


recibirCombo(values){
  console.log('combos', values)
  this.modalCombo = false;
  this.enviarPadre.emit(values);
}





cerrar(){
  console.log('cerrar');
  const body ={
    id:0
  }

  this.enviarPadre.emit(body);
}


}
