<div class="contenedorMenu row">
    <div class="col-12">

    <div class="row headerCombo">
        <div class="col-10">
            <h3>Combo {{productFrom.name}}</h3>
        </div>
        <div class="col-2 hvr-icon-rotate pointer text-center">
            <h3><i class="fas fa-times hvr-icon" (click)="cerrar()"></i></h3>
        </div>
    </div>

    <div *ngIf="loading" class="row text-center r0 animate__animated animate__fadeIn">
        <div class="col-12 pad20">
            <p>Cargando menu <i class="fas fa-spinner fa-spin"></i></p>
        </div>
    </div>


    <div *ngIf="!loading" class="row productos r0 animate__animated animate__fadeIn">
        <div class="col-12">

            <div *ngFor="let s of secciones" class="row seccion">
                <div class="col">
                    <h2 [ngClass]="s.selected == s.quantity ? 'text-success' : '' ">{{s.pageName}}</h2>
                    <p>Elige {{s.quantity}} - {{s.selected}} / {{s.quantity}}</p>

                    <div *ngFor="let p of s.productos" [ngClass]="p.selected ? 'opcion_selected': 'opcion'" class="row" (click)="selectProd(s, p)">
                        <div class="col-9">
                            <p>{{p.info.name}} <span *ngIf="p.info.stockXsale >= 0">{{p.info.stockXsal}}</span></p>
                        </div>
                        <div class="col-2 text-end">
                            <p>$ {{p.price}}</p>
                        </div>
                        <div class="col-1 text-end pointer">
                            <p><i [ngClass]="p.selected ? 'fa-regular fa-square-check' : 'fa-regular fa-square' "></i></p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row ">
                <div class="col-12">
                    <button *ngIf="okGrabar" class="btn btn-lg btn-success w-100 boton" (click)="aceptar()">Guardar</button>
                    <button *ngIf="!okGrabar" class="btn btn-lg btn-dark w-100 boton" >Faltan items por escoger</button>
                </div>
            </div>
            
        </div>
    </div>


    

</div>
</div>


<div *ngIf="modalModificador"class="row fondoModal2 modificadores bg-dark  animate__animated animate__slideInRight">
    <div class="col-12"> 
        <app-modificadores [productFrom]="selectedProduct" (enviarPadre)="recibirModifs($event)"></app-modificadores>
    </div>
</div>
