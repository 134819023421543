import { Component } from '@angular/core';

@Component({
  selector: 'app-menu-stats',
  templateUrl: './menu-stats.component.html',
  styleUrls: ['./menu-stats.component.css']
})
export class MenuStatsComponent {

}
