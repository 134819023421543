export class declaracionModel {
    id: number;
    storeId: number;
    cashierId: number;
    payType: number;
    total: number;
    shiftId: number;
    userId: number;
    date: string;           
    time: string; 
    // update: boolean;

    constructor(){
        this.id                  = 0;
        this.storeId             = 0;
        this.cashierId           = 0;
        this.payType             = 0;
        this.total               = 0;
        this.shiftId             = 0;
        this.userId              = 0;
        this.date                = '';           
        this.time                = ''; 
        // this.update              = false;
    }
 }

