<div class="row header bg-dark r0">
    <div class="col-12">
       <h2 class="animate__animated animate__fadeIn"> <i class="fa-solid fa-gear hvr-icon" ></i> Config </h2>
    </div>
</div>

<app-refresh-data *ngIf="refreshData" (enviarPadre)="recibirRefresh($event)" ></app-refresh-data>

<app-menu-config></app-menu-config>
<div class="row headMenu animate__animated animate__fadeIn r0">
    <div class="col-6 pad20 hvr-forward">
        <p class=" botonMenu animate__animated animate__pulse" data-bs-toggle="offcanvas" data-disableScrolling="false" href="#menuConfig" role="button" aria-controls="menuConfig"> < Menu Config </p>
    </div>
    <div class="col-5 text-end " style="padding:30px 20px 0 0 "> 
        <!-- <button class="btn btn-outline-primary animate__animated animate__heartBeat animate__infinite animate__slower" routerLink="/data/producto/new">Crear Producto</button> -->
    </div>
</div> 




<div class="row">
    <div class="col-10 offset-1 col-md-8 offset-md-2 caja shadow">
       
        <div class="row">
            <div class="col-12 col-md-6">
                <label>Escoge en local te encuentras</label>
                <select [(ngModel)]="params.store" (change)="onChangeStore()"  class="form-select">
                    <option *ngFor="let store of stores" [ngValue]="store">
                      {{ store.name }}
                    </option>
                  </select>
            </div>
         
            <div class="col-12 col-md-6">
                <label>Escoge una Caja</label>
                <select  *ngIf="!loadingCajas" [(ngModel)]="params.caja" (change)="guardarParams()"  class="form-select">
                    <option *ngFor="let c of cajas" [ngValue]="c">
                      {{ c.name }}
                    </option>
                  </select>
                  <p *ngIf="loadingCajas" style="font-size:0.8em" class="text-info"><i class="fas fa-spinner fa-spin mt-3"></i> Cargando cajas</p>
            </div>
        </div>
    </div>
</div>
