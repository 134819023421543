<div class="row header azul  r0">
   <div class="col-12">
     <h2 *ngIf="id == 'new'"  class="animate__animated animate__fadeIn"> <img src="assets/images/data.svg" width="35px" class="colorsvg" (click)="info()"> Crear Producto </h2>
     <h2 *ngIf="id != 'new'"  class="animate__animated animate__fadeIn"> <img src="assets/images/data.svg" width="35px" class="colorsvg" (click)="info()"> Editar Producto </h2>
  </div>
</div>

<div class="row pad20 animate__animated animate__fadeIn r0">
    <div class="col-6">
        <p class="hvr-bubble-float-left botonMenu" data-bs-toggle="offcanvas" data-disableScrolling="false" href="#menuData" role="button" aria-controls="menuData"> < Menu Data </p>
           
    </div>
   
    <div class="col-6 text-end">
        <p class="hvr-bubble-float-left botonMenu pointer" routerLink="/data/productos"> < Volver</p>
    </div>
</div>


<div class="row r0">

    <div *ngIf="loading" class="col-10 col-lg-5 offset-1 izq ">
        <p>loading</p>
    </div>

    


<div *ngIf="!loading" class="col-10 col-lg-5 offset-1 izq ">



    
    <div class="row cajaProducto shadow animate__animated animate__pulse">
        <div class="col-12 formulario">

            <!-- Titulo producto -->
            <div class="row titulos ">
                <div class="col-12">
                    <div class="form-group">
                        <input type="text" class="form-control formu tituloProducto"[(ngModel)]="product.name" name="descripcion" maxlength="20"  placeholder="Aqui el nombre Producto" required>
                    </div>       
         
                    <div class="form-group mt-3" >
                        <input type="text-area" class="form-control formu" maxlength="250" [(ngModel)]="product.info"  name="campomemo" placeholder="Descripción del producto">
                    </div>   
                    
                </div>
            </div>              
            
            
   
             <!-- Titulo opciones obligatorias -->
            <div class="row ">
                <div class="col-12 col-md-6 mt-3">
                    <label>Gran Grupo</label>
                    <select class="form-select" [ngModelOptions]="{standalone: true}" [(ngModel)]="product.greatGroupId" (change)="filtrarGrupos($any($event.target).value)">
                        <option [value]="p.id" *ngFor="let p of greatGroups">{{p.name | titlecase}} </option>
                    </select>
                </div>

                <div class="col-12 col-md-6  mt-3">
                    <label>Precio</label>
                    <input type="number" class="form-control"  [(ngModel)]="price.price" name="precio" placeholder="$1000">
                </div>
                
                <div class="col-12 col-md-6  mt-3">
                    <label>Grupo</label> <span class="botoncrear hvr-float" data-bs-toggle="offcanvas" data-disableScrolling="false" data-bs-target="#offGroups" aria-controls="offGroups" ><i class="fa-solid fa-plus"></i> Crear Grupo</span>
                    <select *ngIf="groups.length > 0" class="form-select" [(ngModel)]="product.groupId" [ngModelOptions]="{standalone: true}" (change)="selectGrupo($any($event.target).value)">
                        <option [value]="p.id" *ngFor="let p of groups">{{p.name | titlecase}} </option>
                    </select>

                    <p *ngIf="groups.length < 1">No hay grupos creados para este gran grupo</p>

              

                </div>
                <div class="col-12 col-md-6  mt-3">
                    <label>Pantallas</label> <span class="botoncrear hvr-float"  routerLink="/data/pantallas"><i class="fa-solid fa-plus"></i> Crear Pantallas</span>
                    <!-- <select *ngIf="sets.length > 0" class="form-select" [(ngModel)]="pantalla" [ngModelOptions]="{standalone: true}" (change)="selectPantalla(pantalla)">
                        <option [value] ="p" *ngFor="let p of sets">{{p.name | titlecase}} </option>
                    </select> -->

                    <select class="form-select" [(ngModel)]="pantalla" (change)="selectPantalla()">
                        <option *ngFor="let p of sets" [ngValue]="p">{{p.name}}</option>
                      </select>
                </div>
            </div>

               <!-- impresoras -->
                <div class="row impresoras">
                        <div class="col-12">
                            <p><i class="fa-solid fa-print"></i> Impresoras</p>
                        </div>   

                        <div *ngFor="let print of printDestinations" class="col-12 col-md-4 col-lg-3">
                            <p (click)="selectPrinter(print)" class="pointer hvr-grow"><i [ngClass]="print.isConnected ? 'fa-regular fa-square-check' : 'fa-regular fa-square'"></i> {{print.name}}</p>
                        </div>
                 
                        <!-- <div class="col-12 col-md-4 col-lg-3">
                            <p><i class="fa-regular fa-square"></i> Cocina Fria</p>
                        </div> -->
                  
        
                </div>

                <div class="row mt-2">
                    <div class="col-12 hvr-icon-hang">
                        <p class="botonMenu" data-bs-toggle="collapse" href="#opcionesExtra" role="button" aria-expanded="false" aria-controls="opcionesExtra">Opciones extra <i class="fa-solid fa-chevron-down "></i></p>

                          <div class="collapse" id="opcionesExtra">
                            <div class="row opciones">
                                <div class="col-12 col-md-6">
                                    <label>Unidad</label>
                                    <select class="form-select"  [(ngModel)]="product.unit" [ngModelOptions]="{standalone: true}" (change)="selectUnidad($any($event.target).value)">
                                      <option value="UN">Unidad</option>
                                      <option value="KG">Kilo</option>
                                      <option value="LT">Litros</option>
                                    </select>
                                </div>

                                                                
                                <div class="col-12 col-md-6">
                                    <label>Código</label>
                                    <div class="form-group">
                                        <input type="text" class="form-control" name="codigo" [(ngModel)]="product.code" maxlength="20"  disabled>
                                    </div>       
                                </div>
                             
                                <div class="col-12 col-md-6 mt-3">
                                    <label>Tipo</label>
                                    <select class="form-select"  [(ngModel)]="product.role" [ngModelOptions]="{standalone: true}" (change)="selectRole($any($event.target).value)">
                                        <option value="V">Venta</option>
                                      <option value="CV">Compra Venta</option>
                                    </select>
                                </div>
                      

                                
                                <div class="col-12 col-md-6 mt-3">
                                    <label>Código Técnico</label>
                                    <div class="form-group">
                                        <input type="text" class="form-control" name="codigoTec" [(ngModel)]="product.codeTec" maxlength="20">
                                    </div>       
                         
                                </div>

                                
                            </div>
                          </div>

                    </div>
                </div>

                <div *ngIf="product.id != 'new' " class="row botones mt-2">
                    
                    <div class="col pointer " (click)="abrirModal('modificador')">
                            <div class="boton salmon  text-center hvr-rotate">
                                <i class="fa-solid fa-paperclip"></i>
                            </div>
                            <p class="text-center">Modificador</p>
                    </div>
                    <div class="col pointer" (click)="irCombo()">
                            <div class="boton celeste text-center hvr-rotate">
                                <i class="fa-solid fa-burger"></i>
                            </div>
                            <p class="text-center">Menu</p>
                            <p class="text-center">o combo</p>
                    </div>

                    <div class="col pointer">
                        <div class="boton naranja text-center hvr-rotate">
                            <i class="fa-solid fa-lemon"></i>
                        </div>
                        <p class="text-center">Receta</p>
                </div>
        

                    <div class="col pointer">
                        <div class="botonPadre">
                            <div class="boton verde text-center hvr-rotate">
                                <i class="fa-solid fa-dollar-sign"></i>
                            </div>
                            <p class="text-center">Precios</p>
                        </div>
                    </div>
                    
            
                    
                   
                    <div class="col pointer">
                            <div class="boton gris text-center hvr-rotate">
                                <i class="fa-regular fa-hand-point-up"></i>
                            </div>
                            <p class="text-center">Botonera</p>
                    </div>
                </div>


                <div class="row azul pointer guardar " (click)="preGuardar()">
                    <div class="col-12 hvr-sweep-to-right">
                        <p class="hvr-grow">Guardar</p>
                    </div>
                </div>

               
        
        </div>
        <!-- Fin formulario -->

        
    </div>
    <div class="row mt-2" *ngIf="this.id != 'new' ">
        <div class="col-6 text-start">
            <p class="hvr-grow pointer" (click)="newProduct()"><i class="fas fa-add"></i> Crear Nuevo Producto</p>
        </div>
        <div class="col-6 text-end">
            <p class="hvr-grow pointer text-danger" (click)="borrarItem()"><i class="fas fa-trash"></i> Borrar Item</p>
        </div>
    </div>
</div>



    <!-- Modificador -->
    <div *ngIf="modalModificador" class="col-12 col-lg-5 blanco modalOpcion  animate__animated animate__fadeInLeft ">
            <div class="row salmon modalHeader">
                <div class="col-10">
                    <h1 class="hvr-grow" (click)="info()">Modificador</h1>
                </div>
                <div class="col-2 text-end hvr-icon-back">
                    <i class="fa-solid fa-xmark fa-2x pt-1 hvr-icon pointer" (click)="cerrarModal('modificador')"></i>
                </div>
            </div>

            <div *ngIf="product.modifMenu == 2" class="row bg-warning text-center ojo">
                <div class="col-12">
                    <h1><i class="fa-solid fa-triangle-exclamation"></i></h1>
                    <p>Ojo, este producto <span>es un Combo</span>, no funcionarán los modificadores.</p>
                    <p>Si quieres cambiar esto, puedes editar el combo</p>
                    <button class="btn btn-primary" (click)="irCombo()">Editar Combo</button>
                </div>
            </div>

            <div class="row pad20 blanco shadow">
                <div class="col-12 col-md-4 d-grid gap-2">
                    <span>Buscador de productos</span>
                    <button class="btn btn-outline-success btn-block" data-bs-toggle="offcanvas" data-disableScrolling="false" data-bs-target="#modalProductos" aria-controls="modalProductos"><i class="fas fa-search"></i> Buscar</button>
                </div>
                <div class="col-12 col-md-4 d-grid gap-2">
                    <span>Clonar de otro producto</span>
                    <button class="btn btn-outline-warning btn-block" data-bs-toggle="offcanvas" data-disableScrolling="false" data-bs-target="#modalClonar" aria-controls="modalClonar"><i class="fas fa-copy"></i> Clonar </button>
                </div>
    
                <div class="col-12 col-md-4 ">
                    <span>Cantidad Obligatoria 
                    </span>
                    <input type="number" class="form-control mt-2" name="maxmodifi"  [(ngModel)]="product.maxModifi" (blur)="updateCantObligatoria()">
                </div>
            </div>


            
            <div *ngIf="loadingModif" class="row text-center" style="padding-top:10%">
                <div class="col">
                    <p>Cargando Modificadores <i class="fa-solid fa-hotdog fa-spin text-primary"></i> </p>
                </div>
            </div>

            <div class="row modifHeader ">
                    <div class="col-1">
                        <p>#</p>
                    </div>
                    <div class="col-5">
                        <p>Producto</p>
                    </div>
                    <div class="col-3">
                        <p>Precio</p>
                    </div>
            </div>

            <div *ngIf="!loadingModif" class="row cajaModif">
                <div *ngIf="modificadores.length > 0" class="col-12">

                    <div  *ngFor="let m of modificadores" class="row modif">
                        <div class="col-1">
                            <p>{{m.position}}</p>
                        </div>
                        <div class="col-5">
                            <p>{{m.name}}</p>
                        </div>
                        <div class="col-3">
                            <input type="number" class="form-control" min="0" [(ngModel)]="m.price" (blur)="updatePrecioModif(m)">
                        </div>
                        <div class="col-1 text-center pointer hvr-icon-grow pt-1"  (click)="moverModif(m, 'subir')">
                            <i  *ngIf="m.position > 1" class="fa-solid fa-arrow-up text-primary fa-1x hvr-icon"></i>                        
                        </div>
                        <div class="col-1 hvr-icon-grow pointer pt-1" (click)="moverModif(m, 'bajar')">
                            <i *ngIf="m.position < modificadores.length  "class="fa-solid fa-arrow-down text-primary hvr-icon"></i>
                        </div>
                        <div class="col-1 hvr-icon-grow pointer pt-1" (click)="moverModif(m, 'delete')">
                            <i class="fa-solid fa-times text-danger hvr-icon"></i>
                        </div>
                    </div>
                </div>
                <div *ngIf="modificadores.length < 1"class="col-12 text-center">
                    <p> Sin modificadores aún</p>
                    <button class="btn btn-outline-success btn-block" data-bs-toggle="offcanvas" data-disableScrolling="false" data-bs-target="#modalProductos" aria-controls="modalProductos"><i class="fas fa-search"></i> Buscar</button>

                </div>
            </div>
    </div>




        
    <!-- Menu -->
    <div *ngIf="modalMenu" class="col-10 col-lg-5">
        <div [ngClass]="modalMenu? 'animate__animated animate__fadeInLeft' : 'animate__animated animate__fadeOutLeft' " class=" modalOpcion">
            <div class="row celeste modalHeader">
                <div class="col-10">
                    <h1 class="hvr-grow">Menu</h1>
                </div>
                <div class="col-2 text-end hvr-icon-back">
                    <i class="fa-solid fa-xmark fa-2x pt-1 hvr-icon pointer" (click)="cerrarModal('menu')"></i>
                </div>
            </div>

            <div class="row">
                <div class="col"></div>
            </div>
        </div>
    </div>

</div>


<app-menu-data></app-menu-data>






  
  <div class="offcanvas offcanvas-start" tabindex="-1" id="modalProductos" aria-labelledby="modalProductosLabel">
    <app-sharedproducts (enviarProducto)='recibirModif($event)' ></app-sharedproducts>
  </div>
  
  <div *ngIf="id != 'new' "class="offcanvas offcanvas-start" tabindex="-1" id="modalClonar" aria-labelledby="modalClonarLabel">
    <app-con-modif (enviarClones)='recibirClones($event)' ></app-con-modif>
  </div>


<!-- MAntenedor de Grupos -->
 

  <div class="offcanvas offcanvas-start" tabindex="-1" id="offGroups" aria-labelledby="offGroupsLabel">
    <div class="offcanvas-header">
      <h5 class="offcanvas-title" id="offGroupsLabel">Grupos</h5>
      <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body">
        
        <div class="row">
            <div class="col-12">
                <label>Escoge un gran grupo</label>
                <select class="form-select" (change)="filtrarGrupos($any($event.target).value)" [ngModel]="product.greatGroupId" >
                    <option [value]="p.id" *ngFor="let p of greatGroups">{{p.name | titlecase}} </option>
                </select>
            </div>
        </div>

        <div class="row" style="margin-bottom:20px">
            <div class="col-12">
                <form (ngSubmit)="guardarGrupo(f)" #f="ngForm" class="animated fadeInLeft">

                    <div class="campo" style="margin-top:10px">
                        <label>Grupo</label>
                        <span *ngIf="f.submitted && f.status == 'INVALID' " class="text-danger animated fadeIn"> Grupo debe tener más de 3 caracteres</span>
                        <input class="form-control" type="text" name="group"  ngModel required minlength="3"  maxlength="99" placeholder="Grupo">
                    </div>

                    <button class="btn w-100 btn btn-primary mt-3" type="submit">
                        Guardar
                    </button>
            
                </form>
            </div>
        </div>

        <div *ngIf="groups.length > 0 " class="row">
            <hr>
            <div class="col-12">
                <h5>Grupos actuales</h5>
                <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Nombre</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let g of groups; index as i">
                        <th scope="row">{{ i + 1}}</th>
                        <td>{{g.name}}</td>
                      </tr>
                    </tbody>
                  </table>
            </div>
        </div>
    </div>
  </div>