import { Component, OnInit } from '@angular/core';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import { forkJoin } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { MensajesService } from 'src/app/services/mensajes.service';
import { UserService } from 'src/app/services/user.service';
import { NgForm } from '@angular/forms';
import { setModel } from 'src/app/models/data/set.model';
import { configsetModel } from 'src/app/models/data/configset.model';


@Component({
  selector: 'app-pantallas',
  templateUrl: './pantallas.component.html',
  styleUrls: ['./pantallas.component.css']
})
export class PantallasComponent implements OnInit {

  
  modalSubpantallas: Boolean = false;

  subpantallas:any[]      = []
  acciones:any[]          = [];
  // ========================= // 

  loading                       = true;
  loading2                      = true;
  saleChannels:any[]            = [];
  selectedChannel:any           = {};
  stores:any                    = [];
  selectedStore:any             = {};
  user:any                      = {};
  selectedSet:any               = {};
  selectedConfig:any            = {};
  memoria                       = '';
  pantallasAll      = [];
  pantallas         = [];
  sets              = [];
  isSecondary       = 0;
  fatherId          = 0;
  modalBorrar       = false;

  offcanvasElement  = document.querySelector('[data-bs-dismiss="offcanvas"]');

  constructor(  private conexData: DataService,
                public mensajes: MensajesService,
                private _user:UserService,) { 
                this.user = this._user.userInfo();
              }

  ngOnInit(): void {
    this.getStores();
  }

info(){
  console.log('store', this.selectedStore)
  console.log('canal', this.selectedChannel)
  console.log('pantallas All', this.pantallasAll);
  console.log('pantallas', this.pantallas);
  console.log('sets', this.sets);
}

getStores(){
  this.conexData.getData('/data/stores').subscribe( resp =>{
    this.stores            = resp;
    this.selectedStore     = this.stores[0]; // esto despues cambiarlo segun el usuario. // debe tener una tienda asocoaida
    console.log('this. selected storw', this.selectedStore);
    this.getData();
  })
}

getData(){
  forkJoin([
    this.conexData.getData('/data/saleChannel'),
    this.conexData.getData('/data/configSet/' + this.selectedStore.id),
    this.conexData.getData('/data/sets/' + this.selectedStore.id),
  ]).subscribe({ 
    next: (data:any) => {
      console.log('data', data);
      this.pantallasAll    = data[1];
      this.saleChannels    = data[0];
      this.selectedChannel = this.saleChannels[0];
      this.sets            = data[2];
      this.onChannelChange();
      this.loading = false;
    },
    error: (error) => {
      console.error(error);
    }
  });

  
}

onChannelChange() {
  console.log('select canal de venta', this.selectedChannel);
  console.log('pantallasall', this.pantallasAll);

  this.pantallas = [];

  if (this.pantallasAll.length > 0){
    this.pantallas = this.pantallasAll.filter( p => p.saleChannelId == this.selectedChannel.id && !p.secondary);
    this.ordenarPantallas();
  }
  this.loading2 = false;
}

onStoreChange() {
  this.loading2 = true;
  console.log('store', this.selectedStore);
  this.conexData.getData('/data/configSet/' + this.selectedStore.id).subscribe( resp => {
    console.log('configset', resp);
    if (resp == null){
      console.log('soy null')
      this.pantallasAll    = [];
    } else {
      this.pantallasAll    = resp
    }
    this.onChannelChange();
    this.getSets();
  })
}

getSets(){
  this.conexData.getData('/data/sets/' + this.selectedStore.id).subscribe( resp => {
    if (resp == null){
      this.sets = [];
    } else {
      this.sets = resp;
    }
   });
}

ordenarPantallas(){
  this.pantallas = this.pantallas.sort((a, b) => a.position - b.position);
}


// =============================================== //
// =============================================== //
// =============================================== //
// ============= FIN TRAER DATA=================== //
// =============================================== //
// =============================================== //
// =============================================== //


guardarPantalla(f:NgForm){
  console.log('f', f.value);
  const nombre = f.value.setName;
  const repetido = this.sets.find( s => s.name.toLowerCase() == nombre.toLowerCase());
  if (repetido){
    this.mensajes.error('Repetido','Ya existe una pantalla con ese nombre.');
    return;
  }

  const newSet   = new setModel();
  newSet.name    = nombre;
  newSet.storeId = this.selectedStore.id;

  console.log('grabar', newSet);

  this.conexData.guardarDato('/postData/set/insert', newSet).subscribe( (resp:any) => {
    newSet.id = resp.recordset[0].id;
    this.sets.unshift(newSet);
  },
  (error) => {
    console.error('Ha ocurrido un error:', error.error);  
  })
}

selectPantalla(pantalla){
  console.log('escogi', pantalla);
  console.log('selectedConfig', this.selectedConfig);

  const newConfigSet           = new configsetModel();
  newConfigSet.storeId         = this.selectedStore.id;
  newConfigSet.saleChannelId   = this.selectedChannel.id;
  newConfigSet.setId           = pantalla.id;
  newConfigSet.position        = this.pantallas.length + 1;
  newConfigSet.secondary       = this.isSecondary;
  newConfigSet.fatherSetId     = this.fatherId;
  newConfigSet.name            = pantalla.name;

  if(this.isSecondary){
    const repetido = this.subpantallas.find( s => s.setId == pantalla.id);
    if (repetido){
      this.mensajes.error('Ya existe esa pantalla', 'Vuelve a seleccionar otra quizas')
      return;
    }

    if(this.selectedConfig.setId == newConfigSet.setId){
      this.mensajes.error('Es la misma pantalla', 'Vuelve a seleccionar otra quizas')
      return;
    } 
    newConfigSet.position = this.subpantallas.length + 1;
    this.subpantallas.push(newConfigSet);
  } else {
    const repetido = this.pantallas.find( s => s.setId == pantalla.id);
    if (repetido){
      this.mensajes.error('Ya existe esa pantalla', 'Vuelve a seleccionar otra quizas')
      return;
    }
    this.pantallas.push(newConfigSet);
  }

  console.log('grabaré', newConfigSet);
  this.guardarConfigSet(newConfigSet, 'insert');
}

guardarConfigSet(pantalla, tarea){

  console.log('guardo configset', pantalla, tarea);
  this.conexData.guardarDato(`/postData/configset/${tarea}`, pantalla).subscribe( 
    (resp:any) => {
      if ( tarea == 'insert'){
        pantalla.id = resp.recordset[0].id;
        this.pantallasAll.push(pantalla);
        if (this.isSecondary){
          
        }else {
          this.onChannelChange();
        }
      }
    },
    (error) => {
      console.error('Ha ocurrido un error:', error.error);  
    })
  
    
    console.log('pantallas', this.pantallas);
  
}

// =============================================== //
// =============================================== //
// =============================================== //
// =========== DRAGIN ============================ //
// =============================================== //
// =============================================== //
// =============================================== //

  cerrarModal(){
    this.modalSubpantallas = false;
  }


  drop(event: CdkDragDrop<any[]>, tarea) {
    console.log('tarea', tarea);
    console.log('event', event);
    console.log('data',  event.container.data);
    console.log('previous index',  event.previousIndex);
    const item = event.previousContainer.data[event.previousIndex];
    console.log('item', item);

    this.isSecondary = item.secondary;
    console.log('isSecondary?', this.isSecondary);

    if (event.previousContainer === event.container) {
      console.log('me movi dentro del mismo array. es decir re ordeno');
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      this.cambiarPositions();
    } else {
      console.log('me cambié de array', tarea);
      console.log('este cambio de array', item);
      if (tarea == 'borrar'){
        console.log('Borrar pantalla');
        this.borrarConfigSet(item);
     }

     if (tarea == 'edit'){
      
     }
     
     if (tarea == 'submenu'){
      this.modalSubpantallas = true;
     }
   }


  }


  dragging(){
    console.log('draging');
  }
  


  boton(){
    console.log('boton');
  }




  cambiarPositions(){
    let contador = 1;
    if (this.isSecondary){
      for (let p of this.subpantallas){
        p.position = contador;
        this.guardarConfigSet(p, 'position');
        contador ++;
      }
    } else{
      for (let p of this.pantallas){
        p.position = contador;
        this.guardarConfigSet(p, 'position');
        contador ++;
      }
    }
   
    console.log('actualise posiciones');
    this.loading = false;
  }


  borrarConfigSet(pantalla){
    this.loading = true;
    console.log('borraré', pantalla);

    this.conexData.guardarDato('/postData/configSet/delete', pantalla).subscribe( resp => {
      console.log('borré', resp);
      this.pantallasAll = this.pantallasAll.filter( p => p.id != pantalla.id);
      if (this.isSecondary){
        this.subpantallas    = this.subpantallas.filter( p => p.id != pantalla.id);

      } else {
        this.pantallas    = this.pantallas.filter( p => p.id != pantalla.id);
      }
      this.cambiarPositions();
      this.modalBorrar = false;
      
      if (this.isSecondary){

      } else {
        this.borrarSubpantallas(pantalla.id);
        this.modalSubpantallas = false;
      }
    })

  }

  borrarSubpantallas(id){
    const hijos = this.pantallasAll.filter( sub => sub.fatherSetId == id);
    console.log('hijos', hijos);
    if (hijos.length > 0){
      const body = {fatherSetId:id}
    
      this.conexData.guardarDato('/postData/borrarHijos', body).subscribe( (resp:any) => {
        console.log('borré hijos', resp); 
        this.pantallasAll = this.pantallasAll.filter( p => p.fatherSetId != id);
      });
    }
  }


  edit(p:any){
    console.log('edito', p);
    this.isSecondary    = p.secondary;
    this.selectedSet    = this.sets.find( s => s.id == p.setId);
    this.memoria        = p.name;
  }

  editarPantalla(f:NgForm){
    const nombre   = this.selectedSet.name;
    const repetidos = this.sets.filter( s => s.name.toLowerCase() == nombre.toLowerCase());
    if (repetidos.length > 1){
      console.log('repetidos', repetidos);
      this.mensajes.error('Repetido','Ya existe una pantalla con ese nombre.');
      this.selectedSet.name = this.memoria;
      return;
    }  
    
    console.log('guardaré', this.selectedSet);
    this.conexData.guardarDato('/postData/set/update', this.selectedSet).subscribe( resp => {
      console.log('updated', resp);
      this.mensajes.ok('ok','Actualizado con exito');
      let este: any;
      console.log('soy secundario', this.isSecondary)
      if (this.isSecondary > 0){
        este = this.subpantallas.find( p => p.setId == this.selectedSet.id);
      } else {
        este = this.pantallas.find( p => p.setId == this.selectedSet.id);
      }
      console.log('este',este);
      este.name  = this.selectedSet.name;
    })
  }


  verSubMenu(p){
    console.log('padre', p);
    this.fatherId         = p.id;
    this.selectedConfig   = p;
    this.subpantallas     = this.pantallasAll.filter( sub => sub.fatherSetId == p.id);
    console.log('subpantallas', this.subpantallas);

    this.modalSubpantallas = true;
  }

  cerrarSubMenu(){
  console.log('aca', this.modalSubpantallas)
  this.modalSubpantallas = false;
 }



}
