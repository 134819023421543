import { Routes } from "@angular/router";
import { LiveComponent } from "./live/live.component";
import { VentasComponent } from "./ventas/ventas.component";
import { CierreComponent } from "./cierre/cierre.component";
import { RankingComponent } from "./ranking/ranking.component";
import { PagosComponent } from "./pagos/pagos.component";

export const routesStats: Routes = [
    {path: 'live', component: LiveComponent},
    {path: 'ventas', component: VentasComponent},
    {path: 'ranking', component: RankingComponent},
    {path: 'pagos', component: PagosComponent},
    {path: 'cierre/:id', component: CierreComponent},
    {path: '**', pathMatch: 'full', redirectTo: 'login'}
  ];
  