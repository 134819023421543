import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { forkJoin } from 'rxjs';
import { groupModel } from 'src/app/models/data/group.model';
import { DataService } from 'src/app/services/data.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-sharedproducts',
  templateUrl: './sharedproducts.component.html',
  styleUrls: ['./sharedproducts.component.css']
})
export class SharedproductsComponent implements OnInit {
  
  @Output()
  enviarProducto: EventEmitter<object> = new EventEmitter<object>();

  loading            = true;
  searchString       = '';
  productosAll       = [];
  productos          = [];
  user:any           = {};

  greatGroups        = [];
  groupsAll          = [];
  groups             = [];
  gg:any             = {};
  grupo:any          = {};


  constructor(private conexData: DataService,
              private _user:UserService) {
              }
              
 ngOnInit(): void {          
    this.user = this._user.userInfo();
    this.getdata();
  }



  getdata(){
    forkJoin([
      this.conexData.traeDatos(`/data/products`),
      this.conexData.getData('/data/greatGroups'),
      this.conexData.getData('/data/groups'),

    ]).subscribe(
      (data:any) => {
        this.filtrarData(data);
      },

      (error) => {
        console.error(error);
      }
    );
  }


  filtrarData(data){

    this.getProducts(data[0]) 
 
    const newgg      = {id:0, name:'Todos', type:'V', code: 'ALL'};
    this.gg          = newgg; 
    this.greatGroups = data[1].filter( g => g.type == 'V');
    this.greatGroups.unshift(newgg);
    this.groupsAll   = data[2];
     
    this.selectGG();
   }


   getProducts(resp){
    this.loading = true;
        if (resp){
          this.productosAll = resp;
          this.productos    = resp.filter( p => p.role != 'C' );
          this.loading      = false;
        } else{
          this.productosAll = [];
          this.loading      = false;
        }

        console.log('productos ACA', this.productos);
  }

  selectGG(){
    this.groups            = this.groupsAll.filter( g => g.greatGroupId == this.gg.id ) ;

  
    const newgrupo         = new groupModel();
    newgrupo.name          = 'Todos';
    this.grupo             = newgrupo;
    this.groups.unshift(newgrupo);

    if( this.gg.id == 0){
      this.grupo = this.groups[0];
    } else {
      this.productos = this.productosAll.filter( p => p.greatGroupId == this.gg.id && p.role !='C');
    }

    
    this.selectG();

  }

  selectG(){
    if (this.grupo.id == 0){
      if (this.gg.id == 0){
        this.productos = this.productosAll;
      } else {
        this.productos = this.productosAll.filter( p => p.greatGroupId == this.gg.id && p.role !='C');
      }
    } else {
      this.productos = this.productosAll.filter( p => p.groupId == this.grupo.id && p.role !='C');
    }
    this.selectOrdernar('abc');  
    
  }


  selectOrdernar(value) {
    let compararObjetivos;
    switch (value) {
      case 'abc':
        compararObjetivos = (a, b) => a.name.localeCompare(b.name);
        break;
      case 'grangrupo':
        compararObjetivos = (a, b) => a.greatGroupId - b.greatGroupId;
        break;
      case 'grupos':
        compararObjetivos = (a, b) => a.groupId - b.groupId;
        break;
      case 'id':
        compararObjetivos = (a, b) => a.id - b.id;
        break;
      default:
        return;
    }
    this.ordenarProductos(compararObjetivos);
  }


  ordenarProductos(comparar) {
    this.productos.sort(comparar);
    this.productos = [...this.productos];
    console.log('ordenados', this.productos);
  }

// =============== FIN GET INFO ============ //


  selectProd(p){
    console.log('seleccioné', p); 
    this.enviarProducto.emit(p);
  }


}
