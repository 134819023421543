<app-loading *ngIf="loading"></app-loading>

<app-declaracion *ngIf="modalCierre" [datoDesdeListado]="shift" (enviarPadre)="recibirCierre($event)" ></app-declaracion>


<div class="row head animate__animated animate__fadeIn r0">
    <div class="col-12 col-md-6">
        <h1>{{shift.name}}</h1>
        <h3>{{shift.storeName}} - {{shift.cajaName}} </h3>
        <p *ngIf="shift.declaracion" (click)='editar()' class="pointer"><i class="fa-solid fa-pencil"></i> Editar Declaración</p>
    </div>
    <div class="col-12 col-md-6 text-end">
        <!-- <p class="text-success">Abierto {{shift.started | date : 'dd/MM/yyyy'}} a las {{shift.started | date: 'HH:mm'}}</p> -->
        <p class="text-success">Abierto: {{shift.started | date:'EEEE d':'':'es' | titlecase }} de {{shift.started | date:'MMMM':'':'es'  }} |  {{shift.started | date: 'HH:mm'}}</p>
        <p *ngIf="shift.ended != '' " class="text-danger">Cerrado: {{shift.ended | date:'EEEE d':'':'es' | titlecase }} de {{shift.ended | date:'MMMM':'':'es'  }} |  {{shift.ended | date: 'HH:mm'}}</p>
        <p *ngIf="!shift.declaracion"> Declaración pendiente</p>
        <p class="hvr-grow pointer" routerLink="/general/shift/m"><i class="fa-solid fa-arrow-left"></i> Volver </p>
    </div>
</div>

<hr>

<div class="row contenedor">
    <div class="col-12 col-md-6 col-lg-4 cuadratura shadow">

        <div class="row total animate__animated animate__flipInX">
            <div class="col-4">
                <h1>Venta <br>Total</h1>
            </div>
            <div class="col-8 text-end">
                <h2>${{ventaTotal.bruto | separator : 0 }}</h2>
                <h3>neto: ${{ventaTotal.neto | separator : 0 }}</h3>
            </div>
        </div>


        <div class="row docs animate__animated animate__fadeIn">
            <table class="col-12 table table-hover">
                <tbody>
                <tr *ngFor="let doc of docs; index as i " class="pointer">
                    <td scope="row">{{i + 1}}</td>
                    <td >{{doc.docName | titlecase}}</td>
                    <td class="text-end">Docs: {{doc.docs[0]}} al {{doc.docs[doc.docs.length - 1]}} | #{{doc.docs.length}}</td>
                </tr>
                </tbody>
            </table>
        </div>

        <div class="row arqueo">
            <div class="col-12 bg-dark text-light animate__animated animate__flipInX">
                <h2>Arqueo Gour-net</h2>
            </div>
            <table class="col-12 table table-hover animate__animated animate__fadeIn">
                    <tbody>
                    <tr *ngFor="let pay of payments; index as i " class="pointer">
                        <td class="text-start">{{pay.payName | titlecase}}</td>
                        <td class="text-end"> $ {{pay.total | separator : 0}} | {{pay.pagos.length }}</td>
                    </tr>
                    <tr>
                        <td scope="row"class="text-start" style="font-weight:700">Total</td>
                        <td class="text-end" style="font-weight:700"> $ {{ventaTotal.arqueoGournet | separator : 0}}</td>
                    </tr>
                    </tbody>
            </table>
        </div>

        <div *ngIf="shift.declaracion" class="row arqueo">
            <div class="col-12 bg-dark text-light animate__animated animate__flipInX">
                <h2>Arqueo por {{userDeclaration[0].user}} | <span style="font-size:0.7em">{{userDeclaration[0].time | date: "HH:mm"}}</span></h2>
            </div>
            <table class="col-12 table table-hover animate__animated animate__fadeIn">
                    <tbody>
                    <tr *ngFor="let pay of userDeclaration; index as i " class="pointer">
                        <td class="text-start">{{pay.payName | titlecase}}</td>
                        <td class="text-end"> $ {{pay.total | separator : 0}} | {{pay.pagos.length }}</td>
                    </tr>
                    <tr>
                        <td scope="row" class="text-start" style="font-weight:700">Total</td>
                        <td class="text-end" style="font-weight:700"> $ {{ventaTotal.arqueoUsuario | separator : 0}}</td>
                    </tr>
                    </tbody>
            </table>
        </div>


        <div class="row animate__animated animate__fadeIn dif">
            <table class="col-12 table table-hover">
                <tbody>
                <tr  class="pointer">
                    <td scope="row">Diferencia</td>
                    <td [ngClass]="ventaTotal.arqueoUsuario - ventaTotal.arqueoGournet >= 0 ? 'text-success' : 'text-danger' " class="text-end">$ {{ventaTotal.arqueoUsuario - ventaTotal.arqueoGournet | separator : 0}}</td>
                </tr>
                </tbody>
            </table>
        </div>


        <div class="row arqueo">
            <div class="col-12 bg-dark text-light animate__animated animate__flipInX">
                <h2> Extra sobre el turno</h2>
            </div>
            <table class="col-12 table table-hover">
                <tbody>
                
                <tr  class="pointer">
                    <td scope="row">Anulaciones Mesa</td>
                    <td class="text-end"> pendiente</td>
                </tr>
                <tr  class="pointer">
                    <td scope="row">Caja inicial</td>
                    <td class="text-end"> $ {{shift.initial_amount | separator : 0}}</td>
                </tr>
                </tbody>
            </table>
        </div>




        <div class="row animate__animated animate__fadeIn dif">
            <table class="col-12 table table-hover">
                <tbody>
                <tr  class="pointer">
                    <td scope="row">Propinas Efectivo</td>
                    <td class="text-end">$ {{propinas.efectivo | separator : 0}}</td>
                </tr>
                <tr  class="pointer">
                    <td scope="row">Propinas Otros</td>
                    <td class="text-end">$ {{propinas.otros | separator : 0}}</td>
                </tr>
                
                <tr  class="pointer" style="font-weight:700">
                    <td scope="row " >Total Propinas</td>
                    <td class="text-end" >$ {{propinas.total | separator : 0}}</td>
                </tr>

                </tbody>
            </table>
        </div>


        <div class="row arqueo">
            <div class="col-12 bg-dark text-light animate__animated animate__flipInX">
                <h2>Ventas del Team</h2>
            </div>
            <table class="col-12 table table-hover">
                <tbody>
                <tr *ngFor="let s of sellers; index as i " class="pointer">
                    <td scope="row">{{i + 1}}</td>
                    <td >{{s.name | titlecase}}</td>
                    <td >$ {{s.total | separator: 0}} | {{s.quantity | separator : 0}}</td>
                    <td >%{{s.porcentaje }}</td>
                </tr>
                </tbody>
            </table>
        </div>





    </div>
<!-- FIN CUADRATURA -->
<!-- FIN CUADRATURA -->
<!-- FIN CUADRATURA -->
<!-- FIN CUADRATURA -->
<!-- FIN CUADRATURA -->
<!-- FIN CUADRATURA -->
<!-- FIN CUADRATURA -->
<!-- FIN CUADRATURA -->
<!-- FIN CUADRATURA -->



    <div class="col-12 col-md-6 col-lg-8" style="padding:0 20px">
        <div class="row cifras">

            <div class="container px-4 text-center">
                <div class="row ">
         
                  <div class="col">
                        <div class="caja rosado">
                            <h2 class="text-start">Personas</h2>
                            <h1 class="text-end">{{ventaTotal.npeople}}</h1>
                        </div>
                  </div>

                  <div class="col">
                    <div class="caja celeste">
                        <h2 class="text-start">Tickets </h2>
                        <h1 class="text-end">{{ventas.length | separator : 0}}</h1>
                     </div>
                </div>

                 <div class="col">
                    <div class="caja verde">
                        <h2 class="text-start">Ticket Promedio</h2>
                        <h1 *ngIf="ventaTotal.bruto > 0" class="text-end">$ {{ventaTotal.bruto / ventaTotal.npeople | separator : 0 }}</h1>
                        <h1 *ngIf="ventaTotal.bruto < 1" class="text-end">$ 0</h1>
                     </div>
                </div>
              
                </div>
            </div>
        </div>

        <div class="row ranking">
            <div class="col-12">
            
            <div class="row pad20 buscador shadow-sm  animate__animated animate__fadeIn">
                    <div class="col-12 col-md-12  hvr-icon-rotate ">
                        <p><i class="fa-solid fa-magnifying-glass hvr-icon"></i> Buscador</p>
                        <input type="text" class="form-control" placeholder="Escribe el nombre del producto o su código" [(ngModel)]="searchString" >
                    </div>
            </div>


            <table class="table table-striped table-hover">
                <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Producto</th>
                    <th scope="col">Precio Promedio</th>
                    <th scope="col">Total</th>

                </tr>
                </thead>
                <tbody *ngIf="ranking.length > 0">
                <tr *ngFor="let p of ranking | filter : { 'name' : searchString, 'code' : searchString, 'codeTec' : searchString} ; index as i " class="pointer" (click)="selectProd(p)">
                    <th scope="row">{{i + 1}}</th>
                    <td >{{p.name}}</td>
                    <td >  {{p.quantity | separator : 0 }} x $ {{p.price | separator : 0  }}</td>
                    <td > $ {{p.totalVenta | separator : 0  }}</td>
                </tr>
                </tbody>
                <p *ngIf="ranking.length < 1">Sin ventas en este cierre</p>
            </table> 



            </div>
        </div>

    </div>
</div>



<!-- ME FALTA EL BUSCADOR PARA EL RANKING -->
<!-- LISTA DE VENTAS POR GARZON -->
<!-- Grafica venta por grupos -->