import { Component, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { turnoModel } from 'src/app/models/data/turno.model';
import { paramsModel } from 'src/app/models/general/params.model';
import { shiftModel } from 'src/app/models/venta/shiftHistory.model';
import { DataService } from 'src/app/services/data.service';
import { MensajesService } from 'src/app/services/mensajes.service';
import { ShiftService } from 'src/app/services/shift.service';
import { TransactionsService } from 'src/app/services/transactions.service';
import { UserService } from 'src/app/services/user.service';
import { ProductStore } from 'src/app/storage/products.store';
import { SalesService } from 'src/app/services/sales.service';
import * as moment from 'moment';
import Swal from 'sweetalert2'


@Component({
  selector: 'app-shift',
  templateUrl: './shift.component.html',
  styleUrls: ['./shift.component.css']
})
export class ShiftComponent {


  loading                = true;
  loading2               = false;
  loadingData            = true;
  user:any               = {};
  params                 = new paramsModel();
  turnos:any[]           = [];
  turno:turnoModel       = new turnoModel(); // TURNO es el tipo de shift
  shift: shiftModel      = new shiftModel(); // shift corresponde al registro de un turno (shifthistory)
  abrirVista             = false;
  origen:string          = '';
  elapsed:any            = {};
  firstDay: string       = '';
  lastDay: string        = '';
  history                = [];
  totalDeclarado         = 0;

  stores: any[]    = [];
  cajasAll: any[]  = [];
  cajas: any[]     = [];
  store: any;
  caja: any;
  loadingCajas: boolean = true;

  selectedShift: any = new shiftModel();
  // payList:any            = []


  modalCierre = false;


  constructor(public _user:UserService, 
              private conexData:DataService,
              private conexSales:SalesService,
              private shiftServices: ShiftService,
              private conexTrans: TransactionsService,
              private router: Router,
              private _m: MensajesService,
              private route: ActivatedRoute,
              private productStore: ProductStore){

    this.origen = this.route.snapshot.paramMap.get('origen');
    if (this.origen == 'menu'){ this.abrirVista = true; }
    
    this.getDates();
    
    this.user   = this._user.userInfo();
    this.params = this._user.getParams();
    this.getMemory();
    this.getTurnos();
    this.getHistory();
  }




  getMemory(){
      const state          = this.productStore.getValue();
      this.stores          = state.stores;
      this.cajasAll        = state.cajas;
      this.store           = this.stores.find( s => s.id == this.params.store.id);
      this.cajas           = this.cajasAll.filter( c => c.storeId == this.store.id);
      this.caja            = this.cajas.find( ca => ca.id == this.params.caja.id);
      // this.payTypes        = state.payTypes;      
  }




  guardarParams(){
    localStorage.setItem('paramsGournet', JSON.stringify(this.params));
  }

  onChangeStore(): void {
    this.cajas          = this.cajasAll.filter( c => c.storeId == this.store.id);
    this.caja           = this.cajas[0];
  }



  getDates(){
    const date          = new Date();
    const firstDayDate  = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastDayDate   = new Date(date.getFullYear(), date.getMonth() + 1, 0); // 0 indica el día anterior al primer día del mes siguiente
    this.firstDay       = this._user.formatoFechaSql(firstDayDate);
    this.lastDay        = this._user.formatoFechaSql(lastDayDate);
  }
  

  getTurnos(){
    this.conexData.getData(`/data/shifts/${this.params.store.id}`).subscribe( (resp:any) => {
      if (resp == null){
        this.turnos = [];
       return 'error';
      } else {
        this.turnos = this.shiftServices.modificarTurnos(resp)      };
        this.openShift();
    })
  }

  // BUSCO TURNOS ABIERTOS
  openShift(){

    this.conexTrans.getData(`/trans/openShift/${this.params.store.id}/${this.params.caja.id}`).subscribe({
      next: (data: any) => {
        if (data == null){
          console.log('No hay turnos Abierto');
          this.params.turno  = new shiftModel();
          this.abrirVista    = true;
          this.ofrecerTurno();

          
        } else {
          console.log('Hay TURNO', data)
          this.shift         = data;
          this.params.turno  = this.shift;
          this.elapsed       = this.calculateElapsedTime(data.started);
          if (this.elapsed.horas > 24){
            this._m.warning('Turno Abierto hace más de 24 horas', `${this.elapsed.horas} horas y ${this.elapsed.minutos} minutos`)
          }

        this.getDeclaration(this.shift);

        }
        this.loading  = false;
        this.loading2 = false;
        this.guardarParams();
      },
      error: (error) => {
        console.error(error);
        this._m.error('Error Turno', 'No se pudo conectar a la BD');
        this.router.navigateByUrl('/home');
      }
    });
  }







// PUEDE HABER MAS DE UN TURNO, LE PROPONGO EL TURNO ADECUADO A LA HORA
  ofrecerTurno(){
    const turnoActual:any = this.getCurrentTurno();
    if (turnoActual != 'no'){
      this.turno = turnoActual;
    } else {
      this._m.warning('ojo', 'No hay turnos en este horario');
      this.turno = this.nextTurno(this.turnos);

    }
  }


  changeShift(t){
    const resultado = this.checkTurno(t);
    if (!resultado){
      this._m.warning('Fuera de plazo', "El turno que escogiste no corresponde a la hora, puedes buscar otro turno que coincida o abrirlo de igual modo");
    }
  }

  newShift(){
    if (this.shift.initial_amount < 0){
      this._m.error('Nope', 'la caja inicial no puede tener un valor negativo')
      return;
    }

    this.shift.storeId     = this.params.store.id;
    this.shift.cashierId   = this.params.caja.id;
    this.shift.name        = this.turno.name;
    this.shift.started     = this._user.dateNow('completa');
    this.shift.userId      = this.user.id;


    this.conexTrans.guardarDato('/postTrans/shiftH/insert', this.shift).subscribe({
      next: (data: any) => {
        this.shift.id       = data.recordset[0].id;
        this.params.turno   = this.shift;
        this.guardarParams();
        this.abrirVista = false;
      },
      error: (error) => {
        console.error(error);
        this._m.error('error', error.error);
      }
    });
  }

  validar0(){
    if (this.shift.initial_amount < 0){
      this.shift.initial_amount = 0;
      return;
    }
  }

  close(){
    if (this.origen != 'menu' && this.shift.started == ''){
      this._m.warning('Debes abrir un turno para vender','');
    } 
      // this.router.navigateByUrl('/general/home')
      this.abrirVista = false;

  }
 
  
  closeShift(){
    this.loading2 = true;
    this.shift.ended = this._user.dateNow('completa');
    this.conexTrans.guardarDato('/postTrans/shiftH/update', this.shift).subscribe({
      next: (data: any) => {
        this.shift = new shiftModel();
        this.getTurnos();
      },
      error: (error) => {
        console.error(error);
        this._m.error('error', error.error);
      }
    });
  
  }


  hacerCierre(h){
    this.selectedShift = h;
    console.log('hacer un cierre para ', this.selectedShift);
    if (!h.declaracion){
      this.modalCierre = true;
    }
  }

// ============================
// ============================
// ============================
// ============================
// Informes
// ============================
// ============================
// ============================
// ============================



  getHistory(){
    this.history = [];
    this.conexTrans.getData(`/trans/shifts/${this.store.id}/${this.caja.id}/${this.firstDay}/${this.lastDay}`).subscribe({
      next: (data: any) => {
        console.log('ACA', data)
        if (!data){
          this.history = [];
          this.loadingData = false;
          return;
        }
        this.history     = data;
        this.loadingData = false;
        if (this.shift.id < 1){
          if (this.shift.id != this.history[0].id){
            this.selectTurno(this.history[0])
          }
        }
      },
      error: (error) => {
        console.error(error);
        this._m.error('error', error.error);
        this.loadingData = false;
      }
    });
  }


  selectTurno(h){
    this.selectedShift = h;
    this.selectedShift.cajaName = this.cajasAll.find( c => c.id == h.cashierId).name
    this.getDeclaration(h);
  }


  getDeclaration(h){
    console.log('h',h)
    this.conexSales.traeDatos(`/sales/declaration/${h.id}`).subscribe({
      next: (data: any) => {
        console.log('tiene declaración hecha', data);
        if(!data){
          h.declaracion = false;
        } else {
          h.declaracion = true;
        }
      },
      error: (error) => {
        console.error(error);
        this._m.error('error', error.error);
        this.loadingData = false;
      }
    });
  }


  recibirCierre(v){
    console.log('recibo cierre', v);
    if (v != 'cerrar'){
      this.getDeclaration(v);

    }
    this.modalCierre = false;
  }


verCierre(s){
  this.router.navigateByUrl(`/stats/cierre/${s.id}`);
}





  

// ============================
// ============================
// ============================
// ============================
//CALCULOS DE TIEMPO
// ============================
// ============================
// ============================
// ============================



getCurrentTurno(): string {
  const currentTime       = new Date();
  const currentHour       = currentTime.getHours();
  const currentMinute     = currentTime.getMinutes();
  const currentTimestamp  = currentHour * 60 + currentMinute;

  for (const turno of this.turnos) {
    const startHour = Number(turno.start2.split(':')[0]);
    const startMinute = Number(turno.start2.split(':')[1]);
    const startTimestamp = startHour * 60 + startMinute;

    const finishHour = Number(turno.finish2.split(':')[0]);
    const finishMinute = Number(turno.finish2.split(':')[1]);
    const finishTimestamp = finishHour * 60 + finishMinute;

    if (currentTimestamp >= startTimestamp && currentTimestamp <= finishTimestamp) {
      return turno
    }
  }
  return  'no'
}


nextTurno(shifts) {
  const now         = new Date();
  const currentTime = now.getHours() * 60 + now.getMinutes(); // Convertir hora actual a minutos desde medianoche
  let nextShift = null;
  for (let shift of shifts) {
      const start = parseInt(shift.start2.split(':')[0]) * 60 + parseInt(shift.start2.split(':')[1]);
      if (currentTime < start ) {
          nextShift = shift;
      }
  }
  return nextShift;
}





checkTurno(turno: any): boolean {
  const currentTime = new Date();
  const currentHour = currentTime.getHours();
  const currentMinute = currentTime.getMinutes();
  const currentTimestamp = currentHour * 60 + currentMinute;

  const startHour = Number(turno.start2.split(':')[0]);
  const startMinute = Number(turno.start2.split(':')[1]);
  const startTimestamp = startHour * 60 + startMinute;

  const finishHour = Number(turno.finish2.split(':')[0]);
  const finishMinute = Number(turno.finish2.split(':')[1]);
  const finishTimestamp = finishHour * 60 + finishMinute;

  return currentTimestamp >= startTimestamp && currentTimestamp <= finishTimestamp;
}



  calculateElapsedTime(started: string): object {
    const now = moment(); // Obtiene la fecha y hora actual
    const startedMoment = moment(started); // Convierte el valor "started" en un objeto moment
  
    // Calcula la diferencia de tiempo entre "started" y la fecha y hora actual
    const duration = moment.duration(now.diff(startedMoment));
  
    // Formatea el tiempo transcurrido en horas, minutos y segundos
    const hours = Math.floor(duration.asHours());
    const minutes = Math.floor(duration.asMinutes()) % 60;
    const seconds = Math.floor(duration.asSeconds()) % 60;
  
    // Retorna el tiempo transcurrido formateado
    
    const objeto = {
      horas: hours,
      minutos: minutes,
      seconds: seconds
    }

    return objeto
  }



pregunta(){
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-danger'
    },
    buttonsStyling: false
  })
  
  swalWithBootstrapButtons.fire({
    title: 'El turno no tiene declaración de caja',
    text: "¿Quieres hacer la declaración?",
    icon: 'question',
    showCancelButton: true,
    confirmButtonText: 'Si',
    cancelButtonText: 'No',
    reverseButtons: true
  }).then((result) => {
    if (result.isConfirmed) {
     this
    } else if (
      /* Read more about handling dismissals below */
      result.dismiss === Swal.DismissReason.cancel
    ) {
      swalWithBootstrapButtons.fire(
        'Cancelled',
        'Your imaginary file is safe :)',
        'error'
      )
    }
  })
}




  
}
