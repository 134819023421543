import { Component, ViewChild } from '@angular/core';
import { paramsModel } from 'src/app/models/general/params.model';
import { MensajesService } from 'src/app/services/mensajes.service';
import { SalesService } from 'src/app/services/sales.service';
import { UserService } from 'src/app/services/user.service';
import { ProductStore } from 'src/app/storage/products.store';
import { forkJoin } from 'rxjs';

import { ChartConfiguration, ChartData, ChartEvent, ChartType } from 'chart.js';


@Component({
  selector: 'app-pagos',
  templateUrl: './pagos.component.html',
  styleUrls: ['./pagos.component.css']
})
export class PagosComponent {

  loading                  = true;
  loadingDetalle           = true;
  user:any                 = {};

  date                     = new Date();
  firstDay                 = new Date(this.date.getFullYear(), this.date.getMonth(), 1).toString();
  lastDay                  = new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0).toString();
  params                   = new paramsModel();

  payTypes              = [];
  stores                = [];
  store:any             = {};
  selectedStore         = '';
  pagos                 = [];
  agrupados             = [];

  
  totalVentas             = 0;

  saleChannels             = [{id:1 , name:'SALON'},{id:2 , name:'VENTA_DIRECTA'},{id:3 , name:'DELIVERY'},{id:4 , name:'LOCAL'}]
  
  detalleVenta             = [];
  venta:any                    = {};
  detPayments              = [];

  public pieChartLabels: string[] = [];
  public pieChartData: ChartData<'pie'>;
  public pieChartType: ChartType = 'pie';
  

  //OJO QUEDÉ ACÁ
  // ME FALTA VER QUE LOS PAGOS CONSIDEREN DE LOS DESCUENTOS



  constructor(  
                   private productStore: ProductStore,
                   private _user:UserService,
                   public _m: MensajesService,
                   private conexSales: SalesService,
                // private conexData: DataService,
                // private conexTrans: TransactionsService,
                // private route: ActivatedRoute,
                // private router: Router,
                // private datePipe: DatePipe,
                // private shiftServices: ShiftService,
                ) { 
                  this.firstDay  = this._user.formatoFechaSql(this.firstDay);
                  this.lastDay   = this._user.formatoFechaSql(this.lastDay);
                  this.user      = this._user.userInfo();
                  
                  if (localStorage.getItem('paramsGournet')){
                    this.params = JSON.parse(localStorage.getItem('paramsGournet'))
                  }

                  this.getdata();
                }



info(){
  console.log('user', this.user);
  console.log('stores', this.stores);
  console.log('payType', this.payTypes);
  console.log('pagos', this.pagos);
  console.log('agrupados', this.agrupados);
}

getdata(){
  const state          = this.productStore.getValue();
  this.payTypes        = state.payTypes;
  this.stores          = state.stores;

  if (this.params.store.id > 0){
    const existe = this.stores.find( s => s.id == this.params.store.id);
        if (existe){
          this.store = existe;
        } 
    } else {
        this.store = this.stores.find( s => s.id == this.user.storeId);
    }
    this.selectedStore = this.store.id;



  this.filtrar();
}


onStoreChange(selectedValue: string) {
  this.selectedStore = selectedValue;
  const existe       = this.stores.find( s => s.id == this.selectedStore);
  this.store         = existe;

  this.filtrar();

}


filtrar(){
  this.loading     = true;
  this.pagos       = [];
  this.agrupados   = [];
  this.totalVentas = 0;

  if (this.lastDay < this.firstDay ){
    this._m.error('Rango de fecha equivocado', 'la fecha inicial debe ser inferior a la fecha final');
    return;
  }

  this.conexSales.traeDatos(`/stats/pagosXfecha/${this.store.id}/${this.firstDay}/${this.lastDay}`).subscribe({
    next:(data:any) => {
      if (data && data.length > 0){
        this.recorrerPagos(data);
        this.pagos = data;
        console.log('data',data);
      } else {
        this.totalVentas = 0;
        console.log('sin ventas');
        this.loading = false;
      }
    },
    error:(err:any) =>{
      console.log('error', err)
    }
  })

}


recorrerPagos(data){
  for (let p of data ){
    const tipo        = this.payTypes.find( pay => pay.code == p.payType );
    p.payName         = tipo.name;
    p.family          = tipo.type;
    // p.storeName       = this.getStoreName(p);

    if (p.status != 2){
      console.log('el pago no está confirmado', p);
      continue;
    }

    if (p.payType == 'NC'){
      
      this.totalVentas += p.amountA * -1;
    }  else {
      this.totalVentas += p.amountA;
    }


    const existe = this.agrupados.find( a => a.code  == p.payType );
    if (existe){
      existe.tip += p.tip;
      existe.total += p.amountA;
      existe.pagos.push(p);
    } else {
      const medioPago = { name: p.payName,
                          code: p.payType,
                          tip: p.tip,
                          total: p.amountA,
                          pagos: [p]
                        }
      this.agrupados.push(medioPago);
    }

  }

  this.loading = false;
  this.info();
  this.dibujar();
}



getStoreName(p){
  const este = this.stores.find( s => s.id == p.storeId);
  return este.name;
}


private dibujar() {
  const cifras        = [];
  this.pieChartLabels = [];

  for (let p of this.agrupados){
    this.pieChartLabels.push(p.name);
    cifras.push(p.total);
  }

  this.pieChartData = {
    labels: this.pieChartLabels,
    datasets: [
      { data: cifras },
    ]
  };
}

 


selectPay(p){
  this.loadingDetalle = true;
  forkJoin([
    this.conexSales.traeDatos(`/sales/headById/${p.headId}`),
    this.conexSales.traeDatos(`/stats/detSaleByHead/${p.headId}`),
    this.conexSales.traeDatos(`/sales/paymentByHead/${p.headId}`)
  ]).subscribe({
    next: (data: any) => {
      console.log('info venta', data);
      this.venta            = data[0];
      this.detalleVenta     = data[1];

      for (let p of data[2]){
        // p.seller   = this.users.find ( u => u.id == p.sellerId);
        p.tipoPago = this.payTypes.find ( pay => pay.code == p.payType);
        this.detPayments.push(p);
      }
      console.log('payments', this.detPayments);
      this.loadingDetalle = false;

    },
    error: (error) => {
      console.error(error);
      this._m.error('Error conectando', 'No se pudo traer la data');
      this.loadingDetalle = false;
    }


  })

}






}

