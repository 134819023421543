import { Component, EventEmitter, Output } from '@angular/core';
import { modifModel } from 'src/app/models/data/modificador.model';
import { DataService } from 'src/app/services/data.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-con-modif',
  templateUrl: './con-modif.component.html',
  styleUrls: ['./con-modif.component.css']
})
export class ConModifComponent {

@Output()
enviarClones: EventEmitter<object[]> = new EventEmitter<object[]>();


loading       = false;
productos     = [];
clones        = [];
ultimo:any    = {id:0};
user:any      = {};


constructor(private conexData: DataService,
            private _user:UserService) {

              if(localStorage.getItem('ultimoClon')){
                this.selectProd( JSON.parse(localStorage.getItem('ultimoClon')));
              }
  }
  
ngOnInit(): void {          
this.user = this._user.userInfo();
console.log('user', this.user);
this.getClones();
}


info(){
  console.log('this.productos', this.productos)
}
getClones(){
  this.conexData.getData(`/data/productosConModif`).subscribe( resp => {
    console.log('con modificadores', resp);
    if (resp){
      this.productos = resp;
    }
    this.loading   = false;
  })
}

selectProd(product){
  this.ultimo = product;
  this.conexData.getData(`/data/modificadoresXproducto/${product.id}`).subscribe( resp => {
    console.log('modificadores de', product, 'son', resp);
    this.clones = resp;
  })
}


exportarClones(){
  this.enviarClones.emit([this.clones,this.ultimo]);
}




}
