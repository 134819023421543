export class groupModel {
    id: number;
    companyId: number;
    greatGroupId: number;
    name: string;
    status: number;
    code: string;

    constructor() {
        this.id             = 0;
        this.companyId      = 0;
        this.greatGroupId   = 0;
        this.name           = '';
        this.status         = 1;
        this.code           = '';
    }
}
