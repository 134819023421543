import { Component } from '@angular/core';
import { messageModel } from 'src/app/models/data/message.model';
import { DataService } from 'src/app/services/data.service';
import { MensajesService } from 'src/app/services/mensajes.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-message-crud',
  templateUrl: './message-crud.component.html',
  styleUrl: './message-crud.component.css'
})
export class MessageCrudComponent {
  loading                 = true;
  user:any                = {};
  mensajes:any            = [];
  msg:messageModel        = new messageModel();


  constructor(
    private conexData: DataService,
    private _user:UserService,
    public _m: MensajesService
    ) { 
      this.user = this._user.userInfo();
      this.getData();
    }

    ngOnInit(): void {
    }
  
    getData(){
      this.conexData.getData(`/data/messages`).subscribe({
        next:(resp:any) => { 
          console.log('messages',resp); 
          if (!resp){
            this.mensajes = [];
          } else {
            this.mensajes = resp;
          }
          this.loading = false
        },
        error:(error:any) => { console.log('err',error)}
      })
    }

    guardar(){
      console.log('guardar', this.msg);
  

      if (this.msg.message.length < 2){
        this._m.error('Ups','Debes ingresar un mensaje más largo')
        return;
      }

      let tarea = 'insert';
      if (this.msg.id != 0){
        tarea = 'update'
      }


      this.msg.companyId = this.user.companyId;
      this.loading       = true;
      
      this.conexData.guardarDato(`/postData/messages/${tarea}`, this.msg).subscribe({
        next:(resp:any) => { 
          console.log('grabado', resp);
          this.getData();
          this.msg = new messageModel();
        },
        error:(err:any) => { console.log('error', err);
        }
      })
    }

    selectMessage(m:messageModel){
      this.msg = m;
      console.log('message', this.msg);
    }


    newMsg(){
      this.msg = new messageModel();
    }
  }





