import { Component, OnInit } from '@angular/core';
import { paramsModel } from 'src/app/models/general/params.model';
import { DataService } from 'src/app/services/data.service';
import { MensajesService } from 'src/app/services/mensajes.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
  styleUrls: ['./config.component.css']
})
export class ConfigComponent {

  user:any               = {};
  stores:any[]           = [];
  cajas:any[]            = [];
  loading                = true;
  loadingCajas           = true;
  params                 = new paramsModel();
  refreshData            = false;


  constructor(private _user:UserService,
    public _m:MensajesService,
    private conexData: DataService,
    public mensajes: MensajesService ) { }


    ngOnInit(): void {
      this.getParams();
      this.user = this._user.userInfo();
      this.getStores();
    }



 getParams(){
      if (localStorage.getItem('paramsGournet')){
        this.params = JSON.parse(localStorage.getItem('paramsGournet'))
      }
    }

getStores(){
  this.conexData.getData('/data/stores').subscribe( resp =>{
    console.log('resp stores', resp);
    this.stores            = resp;
    if (this.stores.length < 1){
      this.mensajes.error('error', 'Comunicate con Gour-net por favor');
      this.loading = false;
      return;
    } 
      if (this.params.store.id > 0){
        const existe = this.stores.find( s => s.id == this.params.store.id);
        if (existe){
          this.params.store = existe;
        } else {
          this.params.store = this.stores[0];;
        }
      } else {
        this.params.store = this.stores.find( s => s.id == this.user.storeId);
      }
      this.getCajas();
      console.log('stores', this.stores);
  })
}



getCajas(){
  this.cajas        = [];
  this.loadingCajas = true;
  this.conexData.getData(`/data/cashiers/${this.params.store.id}`).subscribe( (resp:any) => {
    console.log('resp', resp);
    if (resp == null){
      console.log('es null, no han creado cajas aún')
    } else {
      this.cajas         = resp.filter( c => c.status > 0);
     
      
      if (this.params.caja.id > 0){
        const existe = this.cajas.find( c => c.id == this.params.caja.id);
        if (existe){
          console.log('existe caja', existe);
          this.params.caja  = existe;
        } else {
          this.params.caja   = this.cajas[0];
        }
      } else {
        this.params.caja   = this.cajas[0];
      }



      this.loading      = false;
      this.loadingCajas = false;

      this.guardarParams();
      this.info();
    }
    });

  }

    info(){
      console.log('params', this.params);

    }

    onChangeStore(){
      console.log('traigo cajas');
      this.getCajas();
    }


      guardarParams(){
        localStorage.setItem('paramsGournet', JSON.stringify(this.params));
        console.log('params', this.params);
        this.refreshData = true;
    }



    recibirRefresh(event){
      console.log('termino de refrescar data', event);
      this.refreshData = false;
    }




}
