import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { detmenuModel } from 'src/app/models/data/detmenu.model';
import { productModel } from 'src/app/models/data/product.model';
import { DataService } from 'src/app/services/data.service';
import { MensajesService } from 'src/app/services/mensajes.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-combo',
  templateUrl: './combo.component.html',
  styleUrls: ['./combo.component.css']
})
export class ComboComponent {

  loading             = true;
  fatherId            = '';
  father:productModel = new productModel();
  user:any            = {};
  detalleMenu         = [];
  
  seccion:any         = {};
  secciones           = [];
  productos           = [];


    constructor(private route: ActivatedRoute,
                private _user:UserService,
                private router: Router,
                private conexData: DataService,
                private _m: MensajesService) { 

    this.fatherId     = this.route.snapshot.paramMap.get('id');
    this.user         = this._user.userInfo();
    this.getFather();
}


info(){
  console.log('user', this.user);
  console.log('father', this.father);
  console.log('detalleMenu', this.detalleMenu);
  console.log('secciones', this.secciones);
}

getFather(){
  this.conexData.traeDatos(`/data/products`).subscribe({
      next:(data:any) =>{
        this.productos = data;
        this.father    = this.productos.find( p => p.id == this.fatherId);
        this.getDetalleMenu();

    },
    error:(err:any) =>{ console.log('err', err)}
  })

}




getDetalleMenu(){
  this.conexData.traeDatos(`/data/productosMenu/${this.fatherId}`).subscribe({
    next:(data:any) =>{
      if (data){
        this.detalleMenu = data;
        this.armarMenu();
      } else {
        this.detalleMenu = [];
        this.info();
        this.loading = false;
      }
      this.evaluarSiEsMenu();
    },
    error:(err:any) =>{ console.log('err', err)}
  })
}


armarMenu(){
  for (let m of this.detalleMenu){

    const prod    = this.productos.find( p => p.id == m.productId);
    m.productName = prod.name;

    const existe = this.secciones.find( sec => sec.pageOrder == m.pageOrder);
    if (existe){
      existe.productos.push(m);
    } else{
      const newSec = {pageName: m.pageName, pageOrder: m.pageOrder, quantity: m.quantity, productos:[m]}
      this.secciones.push(newSec);
    }
  }
  this.info();
  this.loading = false;
}


nuevaSeccion(f:NgForm){
  console.log('f', f.value.name);

  if(f.value.name.length < 3){
    this._m.warning('Nombre muy corto','Escoge un nombre para la sección')
    return;
  }
  let order = 1
  if (this.detalleMenu.length > 0){
   order =  this.detalleMenu[this.detalleMenu.length - 1].pageOrder + 1;
  }

  const newSec = {pageName: f.value.name, pageOrder: order, quantity: 1, productos:[]}
  this.secciones.push(newSec);

  console.log('det', this.detalleMenu);

  }

  buscarProducto(p){
    console.log('busco para la sección', p);
    this.seccion = p;
  }


  recibirProducto(p){
    console.log('recibo', p);

    if (p.id == this.fatherId || p.modifMenu == 2){
      this._m.warning('Escoge otro producto','No puedes escoger un producto que sea un combo a su vez');
      return;
    }
    console.log('seccion', this.seccion);
    const newDet        = new detmenuModel();
    newDet.fatherId     = this.fatherId;
    newDet.productId    = p.id;;
    newDet.pageOrder    = this.seccion.pageOrder;
    newDet.pageName     = this.seccion.pageName;
    newDet.price        = 0;
    newDet.quantity     = this.seccion.quantity;
    newDet.notCount     = 0;
    newDet.mandatory    = 0;
    newDet.position     = 1;
    newDet.status       = 1;
    newDet.productName  = p.name;

    if(this.seccion.productos.length > 0){
      newDet.position = this.seccion.productos[this.seccion.productos.length - 1].position + 1;
    }

    console.log('meter producto', newDet);
    this.conexData.guardarDato(`/postData/menuProd/insert`, newDet).subscribe({
      next:(data:any) =>{
        console.log('guardé', data.recordset[0].id);
        newDet.id = data.recordset[0].id;
        this.seccion.productos.push(newDet);
        this.evaluarSiEsMenu();
        this.loading = false;
      },
      error:(err:any) =>{ console.log('err', err)}
    
    })

  } 



  // CAMBIOS
  // CAMBIOS
  // CAMBIOS
  // CAMBIOS
  // CAMBIOS
  // CAMBIOS
  // CAMBIOS
  // CAMBIOS


changeName(s){
  console.log('cambia nombre', s);
  this.seccion = s;
  if (this.seccion.pageName.length < 3){
    this._m.warning('Nombre muy corto','Escoge otro por favor');
    this.seccion.pageName = this.seccion.productos[0].pageName;
    return;
  }

  for (let p of this.seccion.productos){
    p.pageName = this.seccion.pageName;
  }

  this.updateSeccion(this.seccion.productos[0], 'order')


}

changeCantidad(s){
  console.log('cambia cantidad', s);
  this.seccion = s;
  if (this.seccion.quantity < 0){
    this.seccion.quantity = this.seccion.productos[0].quantity;
    return;
  }

  for (let p of this.seccion.productos){
    p.quantity = this.seccion.quantity;
  }
  this.updateSeccion(this.seccion.productos[0], 'pageName');
}


moverSeccion(s,tarea){
  this.seccion = s;
  if (tarea == 'up'){
    const anterior = this.secciones.find ( sec => sec.pageOrder == this.seccion.pageOrder - 1);
    console.log('anterior', anterior);
    anterior.pageOrder += 1;
    for (let p of anterior.productos){
      p.pageOrder += 1;
    }
   this.updateSeccion(anterior.productos[0], 'pageName')

    this.seccion.pageOrder -= 1;
    for (let p of this.seccion.productos){
      p.pageOrder -= 1;
    }
    this.updateSeccion(this.seccion.productos[0], 'pageName')
  } else {
    const siguiente = this.secciones.find ( sec => sec.pageOrder == this.seccion.pageOrder + 1);
    console.log('siguiente', siguiente);
    siguiente.pageOrder -= 1;
    for (let p of siguiente.productos){
      p.pageOrder -= 1;
    }
   this.updateSeccion(siguiente.productos[0], 'pageName')

    this.seccion.pageOrder += 1;
    for (let p of this.seccion.productos){
      p.pageOrder += 1;
    }
    this.updateSeccion(this.seccion.productos[0], 'pageName')
  }

  this.reOrdenarSecciones();

}


updateSeccion(p, tipo){
  this.conexData.guardarDato(`/postData/updateSeccion/${tipo}`, p).subscribe({
    next:(data:any) =>{
      console.log('guardé', data);
    },
    error:(err:any) =>{ console.log('err', err)}
  
  })
}


  
  changePrice(p){
    console.log('nuevo precio p', p);
    if (p.price < 0){
      p.price = 0;
    }
    p.price = Math.round(p.price);
    console.log('nuevo precio p2', p);
    this.updateProducto(p);
  }


  moverProducto(s, p, tarea){
    this.seccion = s;

    if (tarea == 'up'){
      const anterior = this.seccion.productos.find( prod => prod.position == p.position - 1);
      anterior.position += 1;
        this.updateProducto(anterior);
        p.position -= 1;
        this.updateProducto(p);
        this.reOrdenar();
    } else {
        const siguiente = this.seccion.productos.find( prod => prod.position == p.position + 1);
        siguiente.position -= 1;
        this.updateProducto(siguiente);
        p.position += 1;
        this.updateProducto(p);
        this.reOrdenar();

    }
  }


  reOrdenar(){
      this.seccion.productos = this.seccion.productos.sort((a, b) => {
        return a.position - b.position;
    })
  }

  reOrdenarSecciones(){
    this.secciones = this.secciones.sort( (a,b) => {
      return a.pageOrder - b.pageOrder;
    })
  }




  updateProducto(p){
    this.conexData.guardarDato(`/postData/menuProd/update`, p).subscribe({
      next:(data:any) =>{
        console.log('guardé', data);
      },
      error:(err:any) =>{ console.log('err', err)}
    
    })
  }
 
 borrarProducto(s,i, p){
  this.seccion = s;

  console.log('seccion', this.seccion.productos);
  console.log('index', i, p);

  let nuevoArreglo = this.seccion.productos.filter((element, index) => {
    return index !== i;
});



this.conexData.guardarDato(`/postData/menuProd/delete`, p).subscribe({
      next:(data:any) =>{
        console.log('borré', data);
        this.seccion.productos = nuevoArreglo;
        this.reEnumerar();
      },
      error:(err:any) =>{ console.log('err', err)}
    
    })
  }


  reEnumerar(){
    let i = 0;
    for(let m of this.seccion.productos){
      i++
      m.position = i;
      this.updateProducto(m);
    }

    console.log('update listo');
    // this.updateDetalles(cambios);
  }


  // tengo que actualizar si el producto "Padre" tiene productos para marcarlo como que es un menu o no. 
evaluarSiEsMenu(){
  if (this.detalleMenu.length > 0 && this.father.modifMenu != 2 ){
    console.log('tengo que ponerle modifMenu = 2');
    this.father.modifMenu = 2
    this.updateFather();
    return;
  }
  
  if (this.detalleMenu.length < 1 && this.father.modifMenu == 2 ){
    console.log('tengo que ponerle modifMenu = 0');
    this.father.modifMenu = 0
    this.updateFather();
    return;
  }
   console.log('nada que cambiar en el father');
  }
  
  
  
  updateFather(){
    this.conexData.guardarDato(`/postData/product/update`, this.father).subscribe({
      next: (resp: any) => {
        console.log('father  actualizado', resp); 
      },
      error: (error) => {
        console.error('Ha ocurrido un error:', error.error);
        this.loading = false;     
      }
    });
  
  }
  




volver(){
  this.router.navigateByUrl('/data/producto/' + this.fatherId );
}
}
