import { Injectable } from '@angular/core';
import { printDestinationModel } from '../models/data/printDestination.model';
import { priceListModel } from '../models/data/priceList.model';
import { groupModel } from '../models/data/group.model';
import { setModel } from '../models/data/set.model';

import { DataService } from './data.service';
import { configsetModel } from '../models/data/configset.model';

@Injectable({
  providedIn: 'root'
})
export class KickoffService {

  constructor(private conexData:DataService) { }


  // si hay alguna tabla vacia le creamos un registro de 
  evaluarFirstUse(user, data){
    // grandes grupos
    // grupos
    let i = 0;

    for (let d of data){      
      switch(i){
        case 1: 
          console.log(i, 'evaluo los grupos ', d);
          if (!d){this.firstGroup(user);}
          break;
        case 2: 
          console.log(i, 'evaluo destinos de impresion ', d);
          if (!d){this.firstPrintDestination(user);}
        break;
        case 3: 
          console.log(i, 'evaluo Listas de precio', d);
          if (!d){this.priceList(user);}
        break;
        case 4: 
          console.log(i, 'evaluo pantallas', d);
          if (!d){this.firstSet(user);}
        break;
      }

      i++
    }
  }




  firstGroup(user){
    console.log('crear grupos');

    function createGroup(user, name, code, greatGroupId) {
      const group = new groupModel();
      group.companyId = user.companyId;
      group.name = name;
      group.code = code;
      group.greatGroupId = greatGroupId;
      return group;
    }
    
    const body = [
      createGroup(user, 'Entradas', 'ENT', 1),
      createGroup(user, 'Bebidas', 'BEB', 2),
      createGroup(user, 'Masas y Panes', 'MYP', 3),
      createGroup(user, 'Carnes y Cecinas', 'CYC', 3),
      createGroup(user, 'Huevos y Lacteos', 'HYL', 3),
      createGroup(user, 'Abarrotes', 'ABA', 3),
      createGroup(user, 'Frutas y Verduras', 'FYV', 3),
      createGroup(user, 'Jugos y Gaseosas', 'JYG', 4)
    ];

    console.log('body', body);

    this.conexData.guardarDato('/postData/groups', body).subscribe(
      (resp:any) => {
        console.log('grupos guardados', resp);      
      },
      (error) => {
        console.error('Ha ocurrido un error:', error.error);
      })
  }

  firstPrintDestination(user){

    console.log('crear destinos de impresion')
    function createDestination(user, name) {
      const printDest = new printDestinationModel();
      printDest.companyId = user.companyId;
      printDest.name      = name;
      return printDest;
    }
    
    const body = [
      createDestination(user, 'Cocina Caliente'),
      createDestination(user, 'Cocina Fria'),
      createDestination(user, 'Bar')
    ];

    console.log('body', body);
 ;
    this.conexData.guardarDato('/postData/printDestination', body).subscribe(
      (resp:any) => {
        console.log('destinos de impresion', resp);      
      },
      (error) => {
        console.error('Ha ocurrido un error:', error.error);
      })
  }
 
  priceList(user){

    console.log('crear destinos de impresion')

    function createPriceList(user, name, netoBruto, type) {
      const priceList = new priceListModel();
      priceList.companyId = user.companyId;
      priceList.name      = name;
      priceList.netoBruto = netoBruto;
      priceList.type      = type;
      return priceList;
    }
    
    const body = [
      createPriceList(user, 'Principal Venta', 'B', 'P' ),
      createPriceList(user, 'Costos', 'N', 'C')
    ];

    console.log('body', body);
 ;
    this.conexData.guardarDato('/postData/priceLists', body).subscribe(
      (resp:any) => {
        console.log('Listas de precio', resp);      
      },
      (error) => {
        console.error('Ha ocurrido un error:', error.error);
      })
  }
 
 
  firstSet(user){
    console.log('crear pantallas')

      const newSet    = new setModel();
      newSet.storeId  = user.storeId;
      newSet.name     = 'Aperitivos';
    
 ;
    this.conexData.guardarDato('/postData/set/insert', newSet).subscribe(
      (resp:any) => {
        console.log('nuevo Set', resp);  
        const id = resp.recordset[0].id;
        this.firstConfigSet(user,id);
            
      },
      (error) => {
        console.error('Ha ocurrido un error:', error.error);
      })
  }
 

  firstConfigSet(user, id){
    console.log('vincular configSet')

      const newConfigSet          = new configsetModel();
      newConfigSet.storeId        = user.storeId;
      newConfigSet.saleChannelId  = 1;
      newConfigSet.setId          = id;
      newConfigSet.position       = 1;
      
  
    this.conexData.guardarDato('/postData/configSet/insert', newConfigSet).subscribe(
      (resp:any) => {
        console.log('nuevo Set', resp);  
      },
      (error) => {
        console.error('Ha ocurrido un error:', error.error);
      })
  }
 




}
