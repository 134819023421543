import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { DatePipe } from '@angular/common';
import { FilterPipe } from './pipes/filter.pipe';
import { SeparatorPipe } from './pipes/separator.pipe';

import { ProductStore } from 'src/app/storage/products.store';
import { registerLocaleData } from '@angular/common';
import {NgChartsModule} from 'ng2-charts';
import localeEs from '@angular/common/locales/es';

import { AppComponent } from './app.component';

import { LoginComponent } from './generals/login/login.component';
import { HomeComponent } from './generals/home/home.component';
import { RefreshDataComponent } from './generals/refresh-data/refresh-data.component';
import { ShiftComponent } from './generals/shift/shift.component';
import { DeclaracionComponent } from './generals/declaracion/declaracion.component';

import { ProductosComponent } from './data/productos/productos.component';
import { PrintdestinationComponent } from './data/printdestination/printdestination.component';
import { PrintersComponent } from './data/config/printers/printers.component';
import { SectoresComponent } from './data/config/sectores/sectores.component';
import { ComboComponent } from './data/combo/combo.component';
import { ClientesComponent } from './data/clientes/clientes.component';
import { MesasComponent } from './data/config/mesas/mesas.component';
import { ProductoComponent } from './data/producto/producto.component';
import { MenuDataComponent } from './data/menu-data/menu-data.component';
import { PantallasComponent } from './data/pantallas/pantallas.component';
import { ListasprecioComponent } from './data/listasprecio/listasprecio.component';
import { ConModifComponent } from './shared/data/con-modif/con-modif.component';
import { GruposComponent } from './data/grupos/grupos.component';
import { TurnosComponent } from './data/config/turnos/turnos.component';
import { CajasComponent } from './data/config/cajas/cajas.component';

import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { MobilenavComponent } from './shared/mobilenav/mobilenav.component';
import { InfoFooterComponent } from './shared/info-footer/info-footer.component';
import { SharedproductsComponent } from './shared/sharedproducts/sharedproducts.component';
import { LoadingComponent } from './shared/loading/loading.component';
import { NotificacionesComponent } from './shared/notificaciones/notificaciones.component';

import { UsuariosComponent } from './config/usuarios/usuarios.component';
import { ConfigComponent } from './config/config/config.component';
import { MenuConfigComponent } from './config/menu-config/menu-config.component';

import { CajaComponent } from './caja/caja/caja.component';
import { DigitoPayComponent } from './caja/digito-pay/digito-pay.component';
import { BuscadorProductosComponent } from './caja/buscador-productos/buscador-productos.component';
import { BotoneraComponent } from './caja/botonera/botonera.component';
import { ModificadoresComponent } from './caja/modificadores/modificadores.component';

import { LiveComponent } from './stats/live/live.component';
import { VentasComponent } from './stats/ventas/ventas.component';
import { MenuStatsComponent } from './stats/menu-stats/menu-stats.component';
import { CierreComponent } from './stats/cierre/cierre.component';





import { RankingComponent } from './stats/ranking/ranking.component';
import { PagosComponent } from './stats/pagos/pagos.component';
import { SharedComboComponent } from './shared/shared-combo/shared-combo.component';
import { NotaCreditoComponent } from './caja/nota-credito/nota-credito.component';
import { SharedClientesComponent } from './shared/shared-clientes/shared-clientes.component';
import { TablesComponent } from './salon/tables/tables.component';
import { WebpayComponent } from './mobilepay/webpay/webpay.component';
import { OkComponent } from './mobilepay/ok/ok.component';
import { ErrorComponent } from './mobilepay/error/error.component';
import { MesaComponent } from './salon/mesa/mesa.component';
import { PendienteComponent } from './shared/caja/pendiente/pendiente.component';
import { MessageModalComponent } from './shared/message-modal/message-modal.component';
import { MessageCrudComponent } from './data/message-crud/message-crud.component';

registerLocaleData(localeEs, 'es')



@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    ProductosComponent,
    SidebarComponent,
    MobilenavComponent,
    ProductoComponent,
    InfoFooterComponent,
    MenuDataComponent,
    SharedproductsComponent,
    PantallasComponent,
    ListasprecioComponent,
    UsuariosComponent,
    ConfigComponent,
    MenuConfigComponent,
    FilterPipe,
    ConModifComponent,
    GruposComponent,
    LoadingComponent,
    TurnosComponent,
    CajasComponent,
    CajaComponent,
    NotificacionesComponent,
    DigitoPayComponent,
    RefreshDataComponent,
    BuscadorProductosComponent,
    BotoneraComponent,
    ModificadoresComponent,
    ShiftComponent,
    LiveComponent,
    VentasComponent,
    MenuStatsComponent,
    SeparatorPipe,
    CierreComponent,
    DeclaracionComponent,
    PrintdestinationComponent,
    PrintersComponent,
    RankingComponent,
    PagosComponent,
    SectoresComponent,
    ComboComponent,
    SharedComboComponent,
    NotaCreditoComponent,
    ClientesComponent,
    SharedClientesComponent,
    MesasComponent,
    TablesComponent,
    WebpayComponent,
    OkComponent,
    ErrorComponent,
    MesaComponent,
    PendienteComponent,
    MessageModalComponent,
    MessageCrudComponent
     ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSlideToggleModule,
    DragDropModule,
    HttpClientModule,
    FormsModule,
    NgChartsModule

  ],
  providers: [
    ProductStore,
    DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
