

<app-refresh-data (enviarPadre)="recibirRefresh($event)" ></app-refresh-data>

<div class="row config shadow animate__animated animate__slideInDown" style="margin:0">
    <div class="col-10 ">
        <p>{{ currentTime | date:'HH:mm:ss a' }} - {{ currentTime | date:'dd-MM-yyyy' }}</p>
    </div>
    <div class="col-2 text-end hvr-icon-spin">
        <p> <i class="fa-solid fa-gear hvr-icon pointer" [routerLink]="['/config/config']"></i></p>
    </div>
</div>



<div class="row">
    <div class="col-12 pad20">
        <h1 class="animate__animated animate__fadeIn">👋🏼 Hola {{user.firstName}} {{user.lastName}} </h1>
    </div>
</div>









<app-info-footer></app-info-footer> 
