import { DatePipe, Location } from '@angular/common';
import { Subscription, forkJoin } from 'rxjs';

import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { paramsModel } from 'src/app/models/general/params.model';
import { detSaleModel } from 'src/app/models/venta/detSale.model';
import { headSaleModel } from 'src/app/models/venta/headSale.model';
import { orderModel } from 'src/app/models/venta/order.model';
import { DataService } from 'src/app/services/data.service';
import { MensajesService } from 'src/app/services/mensajes.service';
import { SalesService } from 'src/app/services/sales.service';
import { TransactionsService } from 'src/app/services/transactions.service';
import { UserService } from 'src/app/services/user.service';
import { ProductStore } from 'src/app/storage/products.store';
import { paymentModel } from 'src/app/models/venta/payment.model';
import { ShiftService } from 'src/app/services/shift.service';
import { clientModel } from 'src/app/models/data/client.model';
import { CancellationModel } from 'src/app/models/venta/cancellation.model';
import { BoletasService } from 'src/app/services/boletas.service';
import Swal from 'sweetalert2'
import * as QRCode from 'qrcode-generator';


@Component({
  selector: 'app-caja',
  templateUrl: './caja.component.html',
  styleUrls: ['./caja.component.css']
})
export class CajaComponent {
  refreshData            = false;
  esRedondeado           = false;
  id: any                = 0;
  tipo:string            = '';
  user: any              = {};
  selectedDocType:any    = {};
  
  loading       = true;
  loadingPago   = false;

  propina         = 0;
  vuelto          = 0;
  porAbonar       = 0;
  comensal        = 0;
  
  
  modalPago       = false;
  modalBuscar     = false;
  modalBotonera   = false;
  modalCortesias  = false;
  modalPendientes = false;
  modalWP         = false;
  modalAnular     = false;
  
  preOrders:any[]       = []; // agregados a la comanda pero aún no son "comandados"
  orders:any[]          = []; // ya comandados pero no se ha hecho la venta aún.
  detalleVenta:any[]    = []; // detalle de una venta, no se pueden agregar productos.


  datoCaja:any          = {};
  pagos                 = [];
  docTypes              = [];
  payTypes              = [];
  integraciones         = [];
  prePago:any           = {};
  
  pagoParcial            = false; // si quiere pagar parte o la cuenta completa
  
  params                     = new paramsModel();
  headSale:headSaleModel     = new headSaleModel();
  payment:paymentModel       = new paymentModel();            


  qrCode: string;
  urlPago             = '';
  payId               = '';
  selectedPayment:any = {};
  modalClientes       = false;
  client:clientModel  = new clientModel();

  dejarPendiente                  = false;
  hasWebPay                       = false;
  hasIngefactura                  = false;
  loadingWebPay                   = false;
  esperandoPagoWP                 = false;

  porAnular:any                   = {};
  //Check pago
  private intervalId: any;
  private subscription: Subscription = new Subscription();

  constructor(  private conexData: DataService,
                private conexTrans: TransactionsService,
                private route: ActivatedRoute,
                private router: Router,
                public _m: MensajesService,
                private _user:UserService,
                private conexSales: SalesService,
                private datePipe: DatePipe,
                private productStore: ProductStore,
                private shiftServices: ShiftService,
                private location: Location,
                private boleta:BoletasService
                ) { 
                  this.user      = this._user.userInfo();
                  this.id        = this.route.snapshot.paramMap.get('id');
                  this.tipo      = this.route.snapshot.paramMap.get('tipo');

                }

ngOnInit(): void {

  
  setInterval(() => {
    this._user.isTokenExpired();
  }, 60000);


  if(localStorage.getItem('paramsGournet')){
    this.params = JSON.parse(localStorage.getItem('paramsGournet'))
  } else {
    this._m.error('Cuidado', 'Faltan los parametros la caja');
    this.router.navigateByUrl('/home');
  }
  
  this.shiftServices.openShift(this.params.store.id, this.params.caja.id);
  this.getPayTypes();
  this.getDoctypes();
  this.getIntegraciones();
  // this.info();

    // lo actualizo para tener bien la fecha del shift 
    this.params = JSON.parse(localStorage.getItem('paramsGournet'))  
}

ngOnDestroy() {
  this.detenerCheckPagoWeb(); // Asegúrate de limpiar cuando el componente se destruye
}


// isTokenExpired

info(){
  console.log('headSale', this.headSale);
  console.log('preOrders', this.preOrders);
  console.log('payTypes', this.payTypes);
  console.log('payments', this.pagos);
  console.log('comensales', this.comensal);
  console.log('-----------------------');
  console.log('user', this.user);
  console.log('params', this.params);
  console.log('integraciones', this.integraciones);
}

cambiarRadius(){
  this.esRedondeado = !this.esRedondeado;
}



getPayTypes(){
  const state          = this.productStore.getValue();
  this.payTypes        = state.payTypes;
}


getDoctypes(){
  const state            = this.productStore.getValue();
  this.docTypes          = state.docs.filter( doc => doc.docType == 'V');
  this.selectedDocType   = this.docTypes.find( d => d.code == 'VBIM');
  this.headSale.docType  = this.selectedDocType.code;

  if (this.id == '0'){
    this.loading = false;
    return;
  }
  
  if (this.id != '0') {
    this.getVenta();
  } 


}


changeDocType(){
this.headSale.docType = this.selectedDocType.code;
}

// ====================================================== //
// ====================================================== //
// ====================================================== //
// ================   Get venta    ======================== //
// ====================================================== //
// ====================================================== //
// ====================================================== //

//Entro acá si llegúe a la caja por abrir una venta antigua

getVenta(){
  forkJoin([
    this.conexSales.traeDatos(`/sales/headById/${this.id}`),
    this.conexSales.traeDatos(`/stats/detSaleByHead/${this.id}`),
    this.conexSales.traeDatos(`/sales/paymentByHead/${this.id}`)
  ]).subscribe({ 
    next: (data:any) => {
      this.pagos = [];
      console.log('data', data);
      if (data[2]){
        for (let p of data[2]){
          let tipoPago    = this.payTypes.find ( pay => pay.code == p.payType);
          p.payTypeName   = tipoPago.name;
          p.tipoPago      = tipoPago
          this.pagos.push(p);
        }
      }
      this.asociarVenta(data[0], data[1]);

    },
    error: (error) => {
      console.error(error);
    }
  });
}

asociarVenta(v, detalle){

  this.headSale        = v[0];
  this.selectedDocType = this.docTypes.find( d => d.code == this.headSale.docType);

  if (this.headSale.clientId != 0){
    this.getClient(this.headSale.clientId);
  }

  this.orders = [];
  console.log('detalle', detalle);
  for (let d of detalle){
    console.log('d',d);
    d.esModif = !Number.isInteger(d.numel);
    
    if(d.esModif){
      const numelPadre = Math.floor(d.numel)
      console.log('numel padre', numelPadre);
      const padre = this.orders.find( o => o.numel == numelPadre);
      padre.modifs.push(d);
    } else {
      d.modifs = [];
      this.orders.push(d);
    }
    
  }

  console.log('ORDERS', this.orders);
  this.revisarAbonos();


}



// Aqui reviso si ha cambiado el listado de pagos para acutalizar el headSale

revisarAbonos(){
  let abono     = 0;
  this.propina  = 0;
  this.vuelto   = 0;

  for (let p of this.pagos){
    abono          += p.amountA;
    this.propina   += p.tip;
    this.vuelto    += p.change;
    
    if (p.payType == 'WPC' && p.status != 2 || p.status == 4){
      abono          -= p.amountA;
      this.propina   -= p.tip;
      this.vuelto    -= p.change;
    }
  }

  if (this.headSale.abono != abono){
    // console.log('hay que actualizar el abono');
    this.headSale.abono = abono;
  }
 
  if (this.headSale.saldo != (this.headSale.total - abono)){
    this.headSale.saldo = (this.headSale.total - abono);
  }

  if (this.headSale.total == this.headSale.abono && this.headSale.saldo == 0){
    if (this.headSale.status < 2){
      // console.log('venta completa pagada', this.headSale);
      this._m.info('Abono completo', 'Graba la venta para cerrarla por favor.')
    } else{
      // console.log('venta terminada');
    }
  } else {
    for (let p of this.pagos){
      console.log('pagos', p);
      if (p.payType == 'WPC' && p.status == 3){
        this.verPago(p); 
    }
    }
    // console.log('pendiente de pago, pero hay que actualizar');
  }

  this.loading = false;

}


getClient(id:any){
  this.conexSales.traeDatos(`/client/${id}`).subscribe({
    next:(resp:any) => { 
      this.client = resp[0];
    },
    error:(err:any) => { console.log('error', err) },
  })
}





// ====================================================== //
// ====================================================== //
// ============== ACTUALIZAR VENTA ====================== //
// ====================================================== //
// ====================================================== //






// es la info que mando para pagar.
abrirPago(tipo){
 
  this.datoCaja = {
        tipoPago: tipo,
        total: this.headSale.total,   // TOTAL DE LA VENTA
        amountA: this.porAbonar,     // Lo que escogi pagar
        saldo: this.headSale.saldo,   // lo que falta por pagar
        abonado: this.headSale.abono, // lo que ya va pagado/abonado
        propina: 0,
        vuelto:0,
        pagoXitems: false
        }

  if ( !this.pagoParcial){
    this.datoCaja.amountA = this.headSale.saldo;
  } else {
    this.datoCaja.pagoXitems = true; // esto para que no puedan modificar el abono y se pueda asociar a los items por pagar
  }
  this.modalPago = true;

}

recibirRefresh(event){
  this.refreshData = false;
}



holdPay(){
  if (this.headSale.id != '0'){
    this.updateVenta2(true);
  } else {
    this.getNumDoc();
  }
}

// ============================================== //
// ============================================== //
// ============================================== //
// ========== BOTONES
// ============================================== //
// ============================================== //
// ============================================== //

selectProducto(p:any){
 //funcion para pago en mesas

  // return;
  // //0 Borrado, 1:Seleccionado, 2:Comandado, 3:pagado, 4:seleccionadoParaPago, 9:anulado
  // console.log('select producto',p);
  // if (p.isMessage || p.isModif){
  //   return;
  // }
  // const modifs = this.preOrders.filter( mod =>  mod.numel > p.numel && mod.numel < p.numel + 1);

  // if (p.status == 1 || p.status == 2){
  //   console.log('cambio', p);
  //   p.status = 4;
  //   this.porAbonar += p.total;
  //   if(modifs.length > 0){
  //     for (let m of modifs){
  //       console.log('m',m)
  //       m.status = 4
  //       this.porAbonar += m.total;
  //     }
  //   }
  // } else {
  //   if (p.status == 4){
  //     p.status = 1;
  //     this.porAbonar -= p.total;
  //     if(modifs.length > 0){
  //       for (let m of modifs){
  //         m.status = 1
  //         this.porAbonar -= m.total;
  //       }
  //     }
  //   }
  // }


}

borrarItem(i){  
  const este     = this.preOrders[i]
  if(!this.evualarDelete(este)){
    // console.log('no se puede borrar');
    return;
  }

  const modifs = this.preOrders.filter( mod =>  mod.numel > este.numel && mod.numel < este.numel + 1);
  //esto es para restar el "por abonar" en el caso de que borro un producto seleccionado para pago.
  if(este.status == 4){
    this.porAbonar -= este.total;
    if(modifs.length > 0){
      for (let m of modifs){
        m.status = 1
        this.porAbonar -= m.total;
      }
    }
  }


  this.preOrders = this.preOrders.filter( prods =>  prods.numel < este.numel || prods.numel >= este.numel + 1);
  this.calcTotal();
}

anularItem(i){
  //items de una venta que ya existe.
  this.porAnular     = this.orders[i];
  const user         = this._user.userInfo();

  if (user.level > 1){
    this._m.info('Ya fue vendido','Este producto solo lo puede anular un administrador.');
    return;
  }

  const newSaldo = this.headSale.saldo - this.porAnular.total;

  if (newSaldo < this.headSale.abono ){
    this._m.warning('Ojo','Si anulas ese item habrá un abono mayor al saldo.');
    return;
  }


  this.modalAnular = true;
  console.log('borrar este', this.porAnular);

}

anularItem2(motivoAnulacion: string){
  console.log('anular');
  const anulaciones:any[] = [];

  if (!motivoAnulacion) {
    this._m.error("Epaa","Debes proporcionar un motivo de anulación.");
    return;
}

  this.porAnular.status = 3;
  const anulacion = this.newCancelation(this.porAnular, motivoAnulacion);
  anulaciones.push(anulacion);

    for (let m of this.porAnular.modifs){
      m.status = 3;
      const anulacion2 = this.newCancelation(m, motivoAnulacion);
      anulaciones.push(anulacion2);
    }


  console.log('orders', this.orders);
  console.log('anulaciones', anulaciones);

  this.loading = true;


  this.conexSales.guardarDato(`/postSales/cancellationsLote`, anulaciones).subscribe({
    next:(resp:any) => { 
      console.log('resp', resp);
      this.modalAnular = false;
      this.updateVenta2(false);
    },
    error:(err:any) => {console.log('err', err);}
  })

}


newCancelation(o, motivo){
  const user         = this._user.userInfo();
  const newCan              = new CancellationModel();
  newCan.headId             = o.headId;
  newCan.detSaleId          = o.id;
  newCan.sellerId           = o.sellerId;
  newCan.storeId            = o.storeId;
  newCan.productName        = o.productName;
  newCan.total              = o.total;
  newCan.itemCost           = o.itemCost;
  newCan.reason             = motivo;
  newCan.authorizedBy       = user.id;
  return newCan;
}


evualarDelete(p){
const existe   = this.preOrders.find( p => p.productCode == 'DESCUENTO');
const newSaldo = this.headSale.saldo - p.total;

if(p.code == 'DESCUENTO'){
  return true;
}


if( newSaldo < this.headSale.abono){
  this._m.warning('No se puede borrar este item','Quedaría un saldo negativo, elimina un pago');
  return false;
}

if (!existe && this.headSale.abono == 0){
  return true;
}
if (existe && this.headSale.abono == 0 && newSaldo == 0){
  this._m.warning('No se puede borrar este item','La cuenta no puede quedar en 0');
  return false;
}
if(existe && this.preOrders.length == 2){
  this._m.warning('No se puede borrar este item','Borra primero el descuento');
  return false;
}

if(existe && this.headSale.abono == 0){
  if(newSaldo + existe < 0){
    this._m.warning('No se puede borrar este item','Borra primero el descuento');
    return false;
  }
};

return true;
}

sumaResta(value, i){
  const este = this.preOrders[i]

  if (value == 'sumar'){
    este.quantity ++;
    // if (este.discount > 0){
    //   este.discountPercent =  parseFloat(( este.discount * 100 / (este.bruto )   ).toFixed(1));
    // }
   
    este.discount = este.discount * este.quantity;
    este.bruto    = este.price  * este.quantity - este.discount;
    este.neto     = Math.round(este.bruto / 1.19);
    este.IVA      = este.bruto - este.neto
    este.total    = este.bruto; // descuento a la linea
  
    this.revisarModifs(este, value);
  }
  
  if (value == 'restar'){
    if (este.quantity > 1){

      if (este.discount > 0 && este.quantity > 1){
        este.quantity --
        const puede = this.evaluarDscto(este, este.discount);
        este.quantity ++;
        if( !puede){
          return;
        }
      }

      este.quantity --;
      este.discount = este.discount * este.quantity;
      este.bruto    = este.price  * este.quantity - este.discount;
      este.neto     = Math.round(este.bruto / 1.19);
      este.IVA      = este.bruto - este.neto
      este.total    = este.bruto; // descuento a la linea
      this.revisarModifs(este, value);
    } else {
      this.borrarItem(i);
    }

  }

  this.calcTotal();
}

borrarDscto(p){
  if (p.discount <= 0){
    return;
  }
  p.discount        = 0;
  p.discountPercent = 0;

  p.bruto = p.price  * p.quantity;
  p.neto  = Math.round(p.bruto / 1.19);
  p.IVA   = p.bruto - p.neto;
  p.total = p.bruto; // descuento a la linea
  this.calcTotal();
}

evaluarDscto(p, desc){
  const porcentaje = parseFloat(( desc * 100 / p.bruto * p.quantity   ).toFixed(1));
  if (Number(desc) >= p.bruto  || !desc) {
    Swal.fire(
      'Descuento no aplicable',
      'El descuento debe ser inferior al total',
      'warning'
    )
    return false
  } 
  
  if(desc < 1){
    Swal.fire(
      'Descuento no aplicable',
      'El descuento no puede ser negativo',
      'warning'
    )
    return false
  }
  
  if (porcentaje > 90){
    Swal.fire(
      'Descuento no aplicable',
      'El descuento no puede ser mayor al 90% del precio',
      'warning'
    )
    return false
  }

  return true;
}


revisarModifs(prod, value){
    const modifs = this.preOrders.filter( mod =>  mod.numel > prod.numel && mod.numel < prod.numel + 1);    
    if (value == 'sumar'){
      for (let m of modifs){
        m.quantity ++
        m.bruto = m.price  * m.quantity;
        m.neto  = Math.round(m.bruto / 1.19);
        m.IVA   = m.bruto - m.neto;
        m.total = m.bruto; // descuento a la linea
      }
    }
   
    if (value == 'restar'){
      for (let m of modifs){
        m.quantity --
        m.bruto = m.price  * m.quantity;
        m.neto  = Math.round(m.bruto / 1.19);
        m.IVA   = m.bruto - m.neto;
        m.total = m.bruto // descuento a la linea
      }
    }

 
}

mensaje(){
  console.log('mensaje');
}




recibirProducto(prod){
  let numel = 1
  if (prod.id == 0){
    this.modalBuscar   = false
    this.modalBotonera = false
  } else {

    if (this.preOrders.length > 0){
      const ultimo = this.preOrders[this.preOrders.length - 1]
      numel = Math.floor(ultimo.numel);
      numel ++;
    }
    
    this.asociarItem(prod, numel);

    
  }
}

verComensal(){
  console.log('comensales', this.headSale.npeople)
  console.log('comensal', this.comensal)
}

asociarItem(prod, numel){

  const newOrder        = new orderModel();
  newOrder.quantity     = 1; if(prod.code == 'DESCUENTO'){newOrder.quantity = -1};
  newOrder.storeId      = this.params.store.id;
  newOrder.ncomanda     = 0 // TRAER DE algun lado
  newOrder.tableCode    = 'Caja';
  newOrder.productId    = prod.id;
  newOrder.productCode  = prod.code;
  newOrder.productName  = prod.name;
  newOrder.price        = prod.price;
  newOrder.bruto        = prod.price * newOrder.quantity;
  newOrder.neto         = Math.round(newOrder.bruto / 1.19);
  newOrder.IVA          = newOrder.bruto - newOrder.neto;
  newOrder.total        = newOrder.bruto;
  newOrder.isMessage    = 0;
  newOrder.isModif      = 0;
  newOrder.sellerId     = this.user.id
  newOrder.numel        = numel;
  newOrder.eater        = prod.eater;
  newOrder.date         = this.params.turno.started; //'fecha del shift';
  newOrder.realDate     = this.datePipe.transform(new Date(), 'dd/MM/yyyy HH:mm')
  newOrder.created_at   = this.datePipe.transform(new Date(), 'dd/MM/yyyy HH:mm')
  
  this.preOrders.push(newOrder);

  for (let m of prod.modifs){
      numel += 0.01;
      const newModif        = {...newOrder};
      newModif.storeId      = this.user.storeId;
      newModif.productId    = m.productId;
      newModif.productCode  = '';
      newModif.productName  = '--> ' + m.name;
      newModif.price        = m.price;
      newModif.bruto        = m.price * newModif.quantity;
      newModif.neto         = Math.round(newModif.bruto / 1.19);
      newModif.IVA          = newModif.bruto - newModif.neto;
      newModif.total        = newModif.bruto * newModif.quantity;

      newModif.isModif      = 1;
      newModif.numel        = numel;
      this.preOrders.push(newModif);

  }
 
  this._m.sonido('pop.wav')
  this._m.alertAgrega(`Agregaste ${prod.name}`)

  console.log('preorders', this.preOrders);
  this.calcTotal();

}




calcTotal(){
  this.headSale.total = 0;
  for (let p of this.preOrders){
    this.headSale.total += p.total;
  }
  for (let o of this.orders){
    if (o.status == 3){
      continue;
    }
    this.headSale.total += o.total;
    if (o.modifs.length > 0){
      for (let m of o.modifs){
        this.headSale.total += m.total;
      }
    }
  }

  // 

  this.headSale.saldo = this.headSale.total - this.headSale.abono;
}







modifComensal(){
  this.porAbonar = 0;
  this.comensal ++
  if (this.comensal > this.headSale.npeople){
    this.comensal = 0;
  }
  
  for (let p of this.preOrders){
      if (p.status == 4){
        p.status = 1
      } 
    }

    for (let p of this.preOrders){
      if (this.comensal > 0 && p.eater == this.comensal){
        this.selectProducto(p);
      }
    }
}


// ============================================== //
// ============================================== //
// ============================================== //
// ========== Descuentos
// ============================================== //
// ============================================== //
// ============================================== //

async descuentoProducto(p){
  if(this.user.level > 2){
    this._m.warning('No se puede hacer descuento','Los puede hacer un usuario con accesos de administrador.');
    return;
  }

  if(this.pagos.length > 0){
    this._m.warning('No se puede hacer descuento','Solo se pueden aplicar descuentos antes de ingresar un pago');
    return;
  }

  const { value: desc } = await Swal.fire({
    title: 'Agregar descuento a',
    input: 'number',
    inputLabel: p.productName,
    inputPlaceholder: '0',
  })



const puedeDescuento = this.evaluarDscto(p, desc);

if (puedeDescuento){
    
  const porcentaje  = parseFloat(( desc * 100 / p.price  ).toFixed(1));
  p.discount        = Number(desc) * p.quantity;
  p.discountPercent = porcentaje
  p.bruto           = p.price * p.quantity - p.discount
  p.total           = p.bruto;
  this.calcTotal();
}
   
  

}


cortesias(){
  console.log('cortesias')
  if(this.headSale.total < 1){
    return;
  }

  if(this.user.level > 2){
    this._m.warning('No se puede hacer descuento','Los puede hacer un usuario con accesos de administrador.');
    return;
  }

  if(this.pagos.length > 0){
    this._m.warning('No se puede hacer descuento','Solo se pueden aplicar descuentos antes de ingresar un pago');
    return;
  }

  const existe = this.preOrders.find( p => p.productCode == 'DESCUENTO');
  if(existe){
    this._m.warning('No se puede hacer descuento','Solo se puede aplicar un sólo descuento por cuenta.');
    return;
  }

  this.modalCortesias = true;
}

evaluarCortesia(form:any){
  const dcto = form.value.dcto
  if (dcto < 0  || dcto == '' ){
    this._m.warning('Descuento no aplicable','El descuento no puede ser negativo');
    return;
  }

  const porcentaje = parseFloat((dcto * 100 / this.headSale.saldo).toFixed(1));

  if (porcentaje > 90 ){
    this._m.warning('Descuento no aplicable','El descuento no puede mayor al 90% del saldo');
    return;
  }

  this.agregarDcto(dcto);

}

dscto(cant){
  const dcto = this.headSale.saldo * (cant / 100);
  this.agregarDcto(dcto);
}


agregarDcto(dcto){
  this.conexData.getData('/data/dcto/' + this.user.companyId).subscribe({
    next: (data)=>{
      console.log('recibo', data);
      const prod = data[0];
      prod.price = dcto;
      prod.eater  = 0;
      prod.modifs = [];
      console.log('dcto?', prod);
      this.recibirProducto(prod);
      this.modalCortesias = false;
    },
    error: (err)=> {
      console.log('err', err);
    }
  })
}
// ============================================== //
// ============================================== //
// ============================================== //
// ========== CREAR PAGO
// ============================================== //
// ============================================== //
// ============================================== //


recibirTotal(event){
  this.modalPago = false

  if (event.medioPago == 'cerrar'){
    return;
  }

  this.loadingPago = true;
  this.prePago      = event;

  // this.hacerVenta();


  const newPay        = new paymentModel();
  newPay.headId       = this.id;
  newPay.storeId      = this.params.store.id;
  // newPay.payType      = event.tipoPago;
  newPay.totalAmount  = event.totalAmount;
  newPay.amountA      = event.amountA; // MONTO QUE VA DIRECTO A PAGAR LA VENTA
  newPay.amountP      = event.amountP; // MONTO QUE PAGA LITERAL LA PLATA QUE ENTRA PUEDE IR INCLUIDA PROPINA Y VUELTO
  newPay.change       = event.vuelto;
  newPay.tip          = event.propina;
  newPay.status       = 1;
  newPay.cashierId    = this.params.caja.id;
  newPay.date         = this._user.dateNow('completa');
  newPay.clientId     = 0;
  newPay.shiftId      = this.params.turno.id;
  newPay.sellerId     = this.user.id;
  newPay.payType      = event.payType;
  newPay.payTypeName  = this.payTypes.find( p => p.code == event.payType).name
  
//



// STAND BY ESTO POR QUE NO SE POR QUE LO GRABO ALTIRO SI YA TIENE ID
// if (this.id != '0'){
//   this.newPayment(newPay);
//   return;
// }

// Camino de pago webpay -- No lleva propina
// Dejo la venta en pendiente de pago y la grabo - Actualizo el id de la venta.
// Grabo un pago en pendiente de pago.
// Reviso si el pago se efectuó.
// Recalculo.

if (event.payType == 'WPC' || event.payType == 'WPD'){
  newPay.status = 3; // creado pero pendiente de pago
  this.pagos.push(newPay);
  if (this.headSale.id == '0'){
    this.getNumDoc();
  } else {
    this.updateVenta2(false);
  }
  this.loadingPago = false;
  return;
}

this.headSale.saldo -= newPay.amountA;
this.headSale.abono += newPay.amountA

this.pagos.push(newPay);
this.calcPropinaVuelto();
this.loadingPago = false;

}

calcPropinaVuelto(){
  this.propina = 0;
  this.vuelto  = 0;
  for (let p of this.pagos){
    this.propina += p.tip;
    this.vuelto  +=  p.change;
  }
}

borrarPago(p, i){
  if (this.pagos.length == 1){
    this.pagos = [];
  } else {
    const remuevo = this.pagos.splice(i, 1);
  }

  this.headSale.saldo += p.amountA;
  this.headSale.abono -= p.amountA;
  this.propina -= p.tip;
  this.vuelto -= p.change;

}



seguroAnular(p){

  console.log('user',this._user.userInfo);

  Swal.fire({
    title: "Estás seguro de anular?",
    text: "No podrás desanularlo!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, anula no más!"
  }).then((result) => {
    if (result.isConfirmed) {
     this.anularPago(p);
    }
  });
}

anularPago(p){
  p.status = 4;
  // No recalculo por que los en espera no se consideran como abonados.
  this.conexSales.guardarDato(`/postSales/payment/update`, p).subscribe({
    next:(resp:any) => { 
      this.recalcularHeadSale();
  },
    error:(err:any) => { console.log('err', err);}
  })
}

recalcularHeadSale(){
  this.headSale.saldo = 0;
  this.headSale.abono = 0;

  for (let o of this.orders){
    this.headSale.total += o.total;
    if (o.modifs.length > 0){
      for (let m of o.modifs){
        this.headSale.total += m.total;
      }
    }
  }

  for (let pre of this.preOrders){
    this.headSale.saldo += pre.total;
  }

  for (let p of this.pagos){
    if (p.status < 3){
      this.headSale.abono += p.total;
    }
  }

  this.updateVenta2(false);
}

guardarVenta(){

  if (this.headSale.id != '0'){
    this.updateVenta2(true);
    return;
  }
    if(this.pagos.length < 1 ){
    this._m.warning('No has ingresado pagos aún', 'Escoge un medio de pago');
    return;
  }

  if (this.headSale.saldo > 0  ){
    this._m.warning('Saldo Pendiente',`Queda $ ${this.headSale.saldo} por pagar` );
  }

  // if (this.headSale.id != '0'){
  //   this.updateVenta();
  //   return;
  // }

  if (this.headSale.abono == this.headSale.total && this.headSale.saldo == 0){
   this.getNumDoc();
  }

  
}

getNumDoc(){
  this.loading = true;
  this.conexSales.traeDatos(`/sales/lastDoc/${this.params.store.id}/${this.headSale.docType}`).subscribe({ 
    next: (data:any) => {
      const nextDoc = data['lastDoc'] + 1;
      this.hacerVenta(nextDoc);
    },
    error: (error) => {
      console.error(error);
      this.loading = false
      }
  });
}



hacerVenta(nextDoc){
    let dscto = 0;
  for (let det of this.preOrders){
    if(det.price * det.quantity < 0){
      dscto += (det.price * det.quantity);
    }
  }


  let newVenta          = new headSaleModel();
  newVenta.storeId      = this.params.store.id;
  newVenta.docType      = this.selectedDocType.code;
  newVenta.numDoc       = nextDoc;
  newVenta.realDate     = this._user.dateNow('dia');
  newVenta.endDate      = this._user.dateNow('dia');
  newVenta.cashierId    = this.params.caja.id;
  newVenta.sellerId     = this.user.id;
  newVenta.neto         = Math.round(this.headSale.total / 1.19);
  newVenta.discount     = dscto;
  newVenta.iva          = this.headSale.total - Math.round(this.headSale.total / 1.19);
  newVenta.total        = this.headSale.total;
  newVenta.abono        = this.headSale.abono; // en venta directa se paga completa la venta
  newVenta.saldo        = this.headSale.saldo; // en venta directa se paga completa la venta
  newVenta.shift        = this.params.turno.id;
  newVenta.shiftDate    = this.params.turno.started;
  newVenta.npeople      = this.headSale.npeople;
  newVenta.priceList    = this.params.priceListId; // Falta programar el uso de listas de precios por horas etc.
  newVenta.openTime     = this._user.dateNow('hora');
  newVenta.closeTime    = this._user.dateNow('hora');
  newVenta.clientId     = this.headSale.clientId;
  newVenta.status       = 2; // 0 borrada, 1: pendiente de pago, 2: pagada, 3: anulada

  if (this.tipo == 'directa'){
    newVenta.saleChannelId = 2
    newVenta.saleOrigin    = "Tienda"  
    newVenta.tableCode     = "CAJA"  
  }


 
  let dejarPendiente = this.validarPendiente();
  if (dejarPendiente){
    newVenta.status = 1;
  }
  const paquete = {
                    headSale: {newVenta},
                    detVenta: [],
                    payments: []
                  }



  this.armarDetalle(paquete, this.id);


}

validarPendiente(){
  if (this.headSale.saldo > 0){
    return true
  }
  return false;
}


calcNeto(total){
  const neto = Math.round(total / 1.19);
  return neto
}


armarDetalle(paquete:any, id:any){
  
  console.log('armar detalle', this.preOrders);
  console.log('paquete', paquete);
  
  let dejarPendiente = this.validarPendiente();



  for (let v of this.preOrders){
    v.status = 2;
    if (dejarPendiente){ v.status = 1 }
    paquete.detVenta.push(this.crearDetVenta(v));
  }

  for (let p of this.pagos){
    p.status = 2;
    paquete.payments.push(p);
  }

  this.guardarPaquete(paquete);
}



armarDetalle2(preOrders){
  let dejarPendiente = this.validarPendiente();
  const items = []

  for (let v of preOrders){
    v.status = 2;
    if (dejarPendiente){ v.status = 1 } // dejo impago.
    items.push(this.crearDetVenta(v));
  }
  return items;
}

armarPagos(pagos){
  const newPagos = [];
  for (let p of pagos){
    if (p.id == '0' && p.status < 3){
      p.status = 2;
      newPagos.push(p);
    }

    if (p.id == '0' && p.status == 3){
      newPagos.push(p);
    }
  }
  return newPagos;
}





crearDetVenta(v:any){
  const newDetSale = new detSaleModel();
  newDetSale.headId          = this.id; // viene del inserted id
  newDetSale.storeId         = this.params.store.id;
  // newDetSale.docType         = this.selectedDocType.code;
  newDetSale.sellerId        = v.sellerId // Esto deberia cambiar si es una mesa
  newDetSale.numel           = v.numel;
  newDetSale.productId       = v.productId;
  newDetSale.productName     = v.productName;
  newDetSale.price           = v.price;
  newDetSale.quantity        = v.quantity;
  newDetSale.discount        = v.discount;
  newDetSale.discountPercent = v.discountPercent;
  newDetSale.neto            = Math.round(v.neto);
  newDetSale.porIva          = Math.round(v.IVA);
  newDetSale.bruto           = v.bruto;
  newDetSale.total           = v.total;

  // newDetSale.itemCost     = // pendiente para cuando tenga inventario

  console.log('devuelvo', newDetSale);

  return newDetSale
}


guardarPaquete(paquete){
  console.log('mandaré', paquete);
  this.conexSales.guardarDato(`/postSales/newVenta`, paquete).subscribe({ 
    next: (data:any) => {
      console.log('data grabar venta', data);
      for(let p of paquete.payments){
        if (p.payType == 'WPD' || p.payType == 'WPC'){
          if (p.status == 3){
            //Este es un truquito para recargar la vista, pero ahora como una venta pendiente de pago.
            this.router.navigateByUrl('/ruta-ficticia').then(() => {
              this.router.navigate(['/cajas/caja/directa', data.headSaleID]);
            });
            return;
          }
        }
      }

      if (this.hasIngefactura && this.selectedDocType.code == 'VBPE'){
        this.emitirBoleta(data.headSaleID, paquete);
      } else {
        this._m.ok('Ok', 'Guardado con éxito');
        this.limpiarData();
        this.loading = false;
      }

    },
    error: (error) => {
      console.error(error);
      this._m.error('Ups', 'Se ha producido un error guardanda la venta' + error.error);
      this.loading = false    }
  });
}


emitirBoleta(headId, paquete){
  console.log('emitir boleta');
  const boleta = this.boleta.boletaIngefectura(this.selectedDocType.codDocEle, headId, paquete);
  console.log('BOLETA', boleta);

  this.boleta.saveData('/emitirBoletaNP', boleta).subscribe({
    next:(resp:any) => { 
      console.log('resp', resp);
      this._m.ok('Ok', 'Bolets emitida' + resp);
    },
    error:(err:any) => { 
      console.log('error', err);
      this._m.error('error', 'Error emitiendo Boleta');

    },
    complete:() => {
      this.limpiarData();
      this.loading = false;
  }

  })



}

testBoleta(){
  console.log('emitir boleta');
  const body = {};

  this.boleta.saveData('/emitirBoletaTest', body).subscribe({
    next:(resp:any) => { console.log('resp', resp)},
    error:(err:any) => { console.log('error', err)}
  })

  this._m.ok('Ok', 'Bolets emitida');
        this.loading = false;
}

newPayment(newPay){
  this.loadingPago = false;
  
  if (newPay.payType == 'WPC' || newPay.payType == 'WPD'){
    console.log('quiero hacer este pago pendiente', newPay);
    newPay.status = 1;
  }
  
this.conexSales.guardarDato(`/postSales/payment/insert`, newPay).subscribe({
  next:(data:any) => {
    console.log('cree este pago.', data);
    newPay.id = data.recordset[0].id;
    this.pagos.push(newPay);
    this.calcPropinaVuelto();

    if (newPay.payType == 'WPD' || newPay.payType == 'WPC'){
      if (newPay.status == 1){

        this.verPago(newPay);
      }
    }
    this.loadingPago = false;
  },
  error:(err:any) => {
    console.error('error',err);
      this._m.error('Ups', 'Se ha producido un error guardanda la venta' + err.error);
      this.loadingPago = false;
    }
})


}


updateVenta(){
  console.log('actualizar Venta');
  console.log('head', this.headSale);
  console.log('head saldo', this.headSale.saldo);
  console.log('pagos', this.pagos);


  const body = {
    updatesDetSale : [],
    updateHeadSale : {}
  }

  
  for (let p of this.pagos){
    if (p.status != 2){
      // no se si este caso puede suceder;
      this._m.warning('ojo','pago pendiente de guardar');
      console.log('pago pendiente de grabar', p);
      
      return
    };
  };

    if (this.preOrders.length > 0){
      this._m.warning('ojo','hay productos nuevos en la canasta, no manejamos este caso');
      return
    }

    console.log('orders', this.orders);
    for (let o of this.orders){
      if (o.status = 1){
        o.status = 2;
        body.updatesDetSale.push(o);

        if (o.modifs.length > 0){
          for (let m of o.modifs){
            m.status = 2;
            body.updatesDetSale.push(m);
          }
        }

      }

      };


      this.headSale.status = 2;
      body.updateHeadSale   = this.headSale;

      console.log('body', body);
  
      this.conexSales.guardarDato('/postSales/updateVenta', body).subscribe({
        next:(data:any)=> { console.log('fin venta', data)},
        error: (err:any) => { console.log('err',err)}
      })
  }


  updateVenta2(salir){
    this.calcTotal();
    console.log('actualizar Venta');
    console.log('head', this.headSale);
    console.log('head saldo', this.headSale.saldo);
    console.log('pagos', this.pagos);
  
    const isComplete = this.headSale.saldo == 0 ;

    this.headSale.status = 1;
    if (isComplete){
      this.headSale.status = 2;
    }


    // if (!changes && !isComplete){ 
    //   this._m.warning('No hay cambios para grabar', 'añade un producto o medio de pago.')
    //   return }


  
    const body = {
      headSale: this.headSale,
      detSales : this.armarDetalle2(this.preOrders),
      payments: this.armarPagos(this.pagos),
      orders: this.orders,
      cerrarVenta: isComplete
    }


    console.log('isComplete?', isComplete);
    console.log('headSale', this.headSale);
    console.log('body para hacer update', body);
   
   

    this.conexSales.guardarDato('/postSales/updateVentaPendiente', body).subscribe({
          next:(data:any)=> { console.log('data', data);
                              this._m.ok('Ok', 'Guardado con éxito');
                              if (salir){
                                this.limpiarData();
                                this.nuevaVenta();
                              } else{
                                // VEO si tengo que levantar el pago webpay
                                
                                for (let p of this.pagos){
                                  console.log('pagos', p);
                                  if (p.payType == 'WPC' && p.status == 3){
                                    this.verPago(p); 
                                  }
                                }
                              }

                              


                              this.loading = false;},
          error: (err:any) => { console.log('err',err)}
        })
    }
  
  
  getPagos(){
    this.pagos = [];
    this.conexSales.traeDatos(`/sales/paymentByHead/${this.id}`).subscribe({
      next:(resp:any) => {
        for (let p of resp['datos']){
          console.log('p', p.payType);
          let tipoPago    = this.payTypes.find ( pay => pay.code == p.payType);
          p.payTypeName   = tipoPago.name;
          p.tipoPago      = tipoPago
          this.pagos.push(p);
        }
      },
      error:(err:any) => {
        console.log('error',err)
      }
    })

    

  }



    // ========================================== //
    // ========================================== //
    // ========================================== //
    // ========================================== //
    // ========================================== //
    // ========================================== //
    // ========================================== //
    // ========================================== //
    // ========================================== //


abrirProductos(tipo:string){
  console.log('abrir, productos', this.id);
  if ( tipo == 'botonera'){
    this.modalBotonera = true;
  } else if ( tipo == 'buscar') {
    this.modalBuscar = true;  
  } else {
    this._m.warning('No puedes agregar más productos', 'Tendrias que hacer una nueva venta.')
  }
  // console.log('abrir, productos', this.id);
  // if (this.id == '0' && tipo == 'botonera'){
  //   this.modalBotonera = true;
  // } else if (this.id == '0' && tipo == 'buscar') {
  //   this.modalBuscar = true;  
  // } else {
  //   this._m.warning('No puedes agregar más productos', 'Tendrias que hacer una nueva venta.')
  // }
}





// ============================================= //
// ============================================= //
// ============================================= //
// =============      WEB PAY       ============ //
// ============================================= //
// ============================================= //
// ============================================= //

levantarPagoWebpay(){
  this.modalWP = true;
}


verPago(p){
  console.log('pago',p);
  this.payId = p.id;
  this.selectedPayment = p;
  if (p.payType == 'WPC'){
    this.modalWP = true;


  // crear qr
   this.urlPago = `https://admin.gour-net.cl/#/mobilepay/webpay/${this.id}/${p.id}`
   if (this._user.imInDev()){
    this.urlPago = `http://localhost:4200/#/mobilepay/webpay/${this.id}/${p.id}`
     // this.urlPago = `/mobilepay/webpay/${this.id}/${p.id}`

   }

  const qr  = QRCode(0, 'M'); // Crea un código QR
  console.log('url', this.urlPago);
  qr.addData(this.urlPago);
  qr.make();

  this.qrCode = qr.createDataURL(); // Obtiene la URL de datos del código QR

  if (this.selectedPayment.status == 3){
    console.log('está pendiente', this.selectedPayment);
    this.checkPagoWeb(p.id);
  } else {
    console.log('ya pagó', this.selectedPayment);
    
  }

  }
}


// Revisa cada 20 segundos si el estado del pago cambió
checkPagoWeb(id) {
  console.log('Iniciando chequeo de pago...');
  this.esperandoPagoWP = true;
  this.intervalId = setInterval(() => {
    console.log('Consultando estado del pago...');
    this.conexSales.traeDatos(`/sales/paymentById/${id}`).subscribe({
      next: (result: any) => {
        console.log('Resultado del pago:', result[0]);
        if (result[0].status == 2) {
          console.log('Pago completado. Deteniendo chequeo...');
          this.detenerCheckPagoWeb();
          this._m.ok('Pago recibido', '');
          this.modalWP = false;
          this.getVenta();
        }
      },
      error: (err: any) => {
        console.error('Error al consultar el estado del pago:', err);
      }
    });
  }, 10000);
}

detenerCheckPagoWeb() {
  if (this.intervalId) {
    clearInterval(this.intervalId);
    this.intervalId = null; // Asegurarse de resetear el intervalId
    console.log('corté el interval de webpay');
    this.esperandoPagoWP = false;
  }
}




verComprobante(){
  this.router.navigateByUrl(`/mobilepay/ok/${this.selectedPayment.token}`)
}


cerrarWP(){
  this.detenerCheckPagoWeb();
  this.modalWP = false;
}

limpiarData(){
  this.headSale          = new headSaleModel();
  this.headSale.docType  = this.selectedDocType.code
  this.orders            = [];
  this.preOrders         = [];
  this.pagos             = [];
  this.propina           = 0;
  this.vuelto            = 0;
  this.porAbonar         = 0;
  this.comensal          = 1;
  this.detenerCheckPagoWeb();

  
}


nuevaVenta(): void {
  const currentUrl = 'cajas/caja/directa/0';
  this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
    this.router.navigate([currentUrl]); // Navega a la URL actual
  });

}


recibirCliente(value){

  if (value.id == 0){
    this.modalClientes = false;
    return;
  }
  console.log('value', value);

  console.log('headSale', this.headSale);
  this.headSale.clientId = value.id;
  this.client            = value;
  this.modalClientes     = false;
}


recibirPendiente(value){
  this.modalPendientes = false;
}





getIntegraciones(){

  const state            = this.productStore.getValue();
  this.integraciones     = state.integraciones;

  if (this.integraciones.length < 1){
    return;
  }

  // VEO QUE TENGA BOLETA ELECTRONICA Y defino la boleta electronica como por defecto
  const ingefactura = this.integraciones.find( int => int.name == 'Ingefactura');;
  if (!ingefactura){
    this.hasIngefactura    = false;
  } else {
    this.hasIngefactura    = true;
    this.selectedDocType   = this.docTypes.find( d => d.code == 'VBPE');
    this.headSale.docType  = this.selectedDocType.code;
  }


  // Veo si tiene pago con celulares transbank.
  const webpay = this.integraciones.find( int => int.name == 'Webpay Mall');
  if (!webpay){
    this.hasWebPay = false;
  } else {
    this.hasWebPay = true;
  }


}



modifNpeople(tipo){
  console.log('tipo', tipo);
  if (tipo == 'rest' && this.headSale.npeople > 1){
    this.headSale.npeople -= 1;
  } 
  if (tipo == 'sum'){
    this.headSale.npeople += 1;
  }
  console.log('headSAle', this.headSale);
}



}