<div class="row">
    <div class="col-12 col-md-5 col-lg-4 usuarios shadow pad20 tablaUsuarios animate__animated animate__slideInLeft">


        <div class="row ">
            <div class="col-6">
                <div class="hvr-forward">
                    <h1>Clientes</h1>
                </div>
             </div>
             <div class="col-5 text-end">
                <button class="btn btn-danger rosado text-light"  data-bs-toggle="offcanvas" data-disableScrolling="false" data-bs-target="#offCliente" aria-controls="offCliente" (click)="newClient()">Nuevo Cliente</button>
            </div>
             <div class="col-1 text-center hvr-icon-grow">
                <i class="fas fa-times fa-2x hvr-icon pointer" (click)="cerrar()"></i>
            </div>

            <div class="col-12">
                <hr>
                <p style="margin-bottom:0"><i class="fa-solid fa-magnifying-glass hvr-icon"></i> Buscador</p>
                <input type="text" class="form-control" placeholder="Escribe el nombre del producto o su código" [(ngModel)]="searchString">
            </div>
        </div>


        <div *ngIf="clientes.length < 1" class="row">
            <div class="col-12 text-center">
                <hr>
                <p style="font-size:0.8em">No hay clientes registrados aún</p>
            </div>
        </div>



        <div class="row pad20 " *ngIf="clientes.length > 0">
            <table class="table table-hover  ">
                <tbody>

                <tr *ngFor="let u of clientes | filter : { 'firstName' : searchString, 'lastName' : searchString, 'rut' : searchString, 'email' : searchString, 'phone' : searchString} ; index as i " class="pointer" (click)="selectClient(u)" class="animate__animated animate__flipInX pointer">
                    <th scope="row" >{{i + 1}}</th>
                    <td>{{u.firstName}} {{u.lastName}}</td>
                    <td>{{u.rut}}</td>
                </tr>              
                </tbody>
            </table>
        </div>
    </div>


</div>





  <div class="offcanvas offcanvas-end" tabindex="-1" id="offCliente" aria-labelledby="offClienteLabel">
        <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="offClienteLabel">Nuevo Cliente</h5>
            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">

            <div class="row">
    
                    <div class="col-12 campo">
                        <label>Nombre</label>
                        <input type="text" class="form-control" placeholder="Alexis..." aria-label="Username" [(ngModel)]="client.firstName" aria-describedby="basic-addon1">
                    </div>
                    <div class="col-12 campo">
                        <label>Apellido</label>
                        <input type="text" class="form-control" placeholder="Sanchez..." aria-label="lastname" [(ngModel)]="client.lastName" aria-describedby="basic-addon1">
                    </div>
               
                    <div class="col-12 campo">
                        <label>Rut</label>
                        <input type="text" class="form-control" maxlength="12" placeholder="16.XXX.XXX-X" aria-label="rut" [(ngModel)]="client.rut" aria-describedby="basic-addon1" (blur)="cambioRut()">
                    </div>
                 </div>
                  
                 
    
                    <div class="col-12 campo">
                        <label>Celular</label>
                        <input type="text" class="form-control" placeholder="+569" aria-label="phone" [(ngModel)]="client.phone" aria-describedby="basic-addon1">
                    </div>
    
                    <div class="col-12 campo">
                        <label>E-mail</label>
                        <input type="email" class="form-control" placeholder="@" aria-label="email" [(ngModel)]="client.email" aria-describedby="basic-addon1">
                    </div>
               
                    <div class="col-12 campo text-end ">
                        <button style="font-size:0.8em" class="btn btn-primary w-100 mt-4" (click)="guardarClient()">Guardar</button>
                    </div>
        </div>
  </div>