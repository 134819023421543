import { Component, OnInit } from '@angular/core';
import { paramsModel } from 'src/app/models/general/params.model';
import { MensajesService } from 'src/app/services/mensajes.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  user:any               = {};
  loading                = true;
  params                 = new paramsModel();
  currentTime: Date;
  constructor(private _user: UserService,
              public _m: MensajesService,
              public mensajes: MensajesService
              ) { }

  ngOnInit(): void {
    this._m.sonido('intro.mp3');
    this.user = this._user.userInfo();
    this.updateTime(); // Actualizar la hora inmediatamente al cargar el componente

    // Actualizar la hora cada segundo
    setInterval(() => {
      this.updateTime();
    }, 1000);



  }
 
  recibirRefresh(event){
    console.log('termino de refrescar data', event);
  }


  updateTime() {
    this.currentTime = new Date();
  }



  
}
