import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MensajesService } from 'src/app/services/mensajes.service';
import { SalesService } from 'src/app/services/sales.service';
import * as html2pdf from 'html2pdf.js';

@Component({
  selector: 'app-ok',
  templateUrl: './ok.component.html',
  styleUrls: ['./ok.component.css']
})

export class OkComponent {
  
  loading       = true;
  token         = '';
  payment:any   = {id:0}
  infoPay:any   = {};


   constructor(  
    private conexSales: SalesService,
    private route: ActivatedRoute,
    public _m: MensajesService,
    // private _user:UserService,
    // private conexData: DataService
    // private conexTrans: TransactionsService,
    // private router: Router,
    // private datePipe: DatePipe,
    // private productStore: ProductStore,
    // private shiftServices: ShiftService,
    ) { 
      this.token       = this.route.snapshot.paramMap.get('id');
      this.getPayment(this.token);
    }



    getPayment(token){
      console.log('token', token);
      this.conexSales.traeDatos(`/sales/paymentByToken/${token}`).subscribe({
        next:(data:any) =>{
          console.log('data', data);
          this.payment = data[0];
          this.getInfoPay(this.payment.id);
        },
        error:(err:any) =>{
          console.error(err);
          this._m.error('error', err.error);
          this.loading = false
        },
      })
    }
    
    getInfoPay(paymentId){
      console.log('paymentId', paymentId);
      this.conexSales.traeDatos(`/sales/infoPay/${paymentId}`).subscribe({
        next:(data:any) =>{
          console.log('data', data);
          this.infoPay = data[0];
          this.loading = false;
        },
        error:(err:any) =>{
          console.error(err);
          this._m.error('error', err.error);
          this.loading = false
        },
      })
    }



    descargarComoPDF() {
      const opciones = {
        margin: 10,
        filename: 'comprobante.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
      };
      
      console.log('descargar')
      const contenido = document.getElementById('comprobante');  // Asegúrate de tener un div con id "elemento-a-convertir" que englobe todo lo que quieres exportar a PDF
      html2pdf().from(contenido).set(opciones).save();
    }
  



}
