<app-loading *ngIf="loading"></app-loading>


<div class="row header azul text-light r0">
    <div class="col-10" (click)="info()">
        <h1 style="margin-bottom:0">Combo</h1>
    </div>
    <div class="col-2 text-right">
        <p class="pointer mt-1" (click)="volver();"><i class="fas fa-arrow-circle-left"></i> Volver</p>
    </div>
 </div>



<div  *ngIf="detalleMenu.length < 1" class="row explicacion r0">
    <div class="col-12 text-center">
        <p>Los combo o menu permiten crear productos de ventas con varias secciones y opciones.</p>
        <p>Por ejemplo: "Menu ejecutivo" que tenga "Entrada", "Fondo" y "Postre". </p>
        <p> O un producto tipo "Arma tu Sandwich" donde podrias crear las secciones
    "Tipo de Pan", "Agregados", "Salsa", "Bebida" </p>
    </div>
    <div class="col-12 mt-2">
        <!-- <button class="btn btn-primary" (click)="crearMenu()">Crear Menu</button> -->
    </div>
</div>


<div *ngIf="secciones.length > 0" class="row r0">
    <div class="col-12 col-md-10 col-lg-8 offset-lg-2 offset-md-1 secciones ">

        <div *ngFor="let s of secciones; index as i" class="row seccion shadow animate__animated animate__flipInX shadow">
            <div class="col-12">
                <div class="row seccionHead">
                    <div class="col-6">
                        <label>Opción {{s.pageOrder}}</label>
                        <input type="text" class="form-control from-buffer" name="seccion" [(ngModel)]="s.pageName"  (change)="changeName(s)">

                    </div>
                    <div class="col-4">
                        <p style="display:inline">Cantidad obligatoria</p>
                        <input type="number" class="form-control from-buffer formNumber" min='0' name="cantidad" [(ngModel)]="s.quantity" (change)="changeCantidad(s)" >
                    </div>
                    <div class="col-2 ">
                        <p>
                            <i *ngIf="s.pageOrder > 1" (click)="moverSeccion(s, 'up')" class="iconoHead text-success fa-solid fa-chevron-up"></i>
                            <i *ngIf="s.pageOrder < secciones.length" (click)="moverSeccion(s, 'down')" class="iconoHead text-danger fa-solid fa-chevron-down"></i>
                            <i (click)="borrarSeccion(s)" class="iconoHead fa-solid fa-trash-can"></i>
                        </p>
                    </div>
                </div>

                <div  *ngIf="s.productos.length > 0" >
                    <div *ngFor="let p of s.productos; index as i" class="row prod animate__animated animate__flipInX">
                        <div class="col-6">
                            <h2>{{p.productName}}</h2>
                        </div>
                        <div class="col-4"> 
                            <div class="input-group">
                                <span class="input-group-text" id="basic-addon3">$</span>
                                <input type="number" class="form-control" min='0' id="basic-url" aria-describedby="basic-addon3 basic-addon4"  [(ngModel)]="p.price" (change)="changePrice(p)" >
                            </div>
                        </div>
                        <div class="col-2">
                            <p>
                                <i *ngIf="p.position > 1" (click)="moverProducto(s, p, 'up')" class="icono2 fa-solid fa-chevron-up"></i>
                                <i *ngIf="p.position < s.productos.length" (click)="moverProducto(s, p, 'down')" class="icono2 fa-solid fa-chevron-down"></i>
                                <i (click)="borrarProducto(s,i,p)" class="fa-solid fa-trash-can icono2"></i>
                            </p>
                        </div>
                    </div>  
                </div>

                <div class="row agregar">
                    <div class="col-12 agregaOp text-center">
                        <h3 (click)="buscarProducto(s)" data-bs-toggle="offcanvas" data-disableScrolling="false" data-bs-target="#modalProductos" aria-controls="modalProductos"><i class="fas fa-plus"></i> Agregar un producto</h3>
                    </div>
                </div>
            </div>
            
        
        </div>

    </div>
</div>


<div  class="row animate__animated animate__flipInX shadow r0">
    <div class="col-12 col-lg-8 offset-lg-2  col-md-10 offset-md-1 nuevaSeccion bg-warning">

        <h1>Nueva Sección</h1>
        <form (ngSubmit)="nuevaSeccion(f)" #f="ngForm" class="row animate__animated animate__fadeIn">
            <div class="col-10 col-md-6 campo" style="margin-top:10px">
                <span *ngIf="f.submitted && f.status == 'INVALID' " class="text-danger animated fadeIn"> La Sección debe tener más de 3 caracteres</span>
                <input class="form-control" type="text" name="name"  ngModel required minlength="3"  maxlength="99" placeholder="ej: Entradas">
            </div>
            <div class="col-2">
                <button class=" btn w-100 btn-primary mt-2" type="submit">
                    Agregar
                </button>
            </div>

       
    
        </form>
   
    </div>
</div>



<div class="offcanvas offcanvas-start" tabindex="-1" id="modalProductos" aria-labelledby="modalProductosLabel">
    <app-sharedproducts (enviarProducto)='recibirProducto($event)' ></app-sharedproducts>
  </div>
  