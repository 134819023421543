import { Component } from '@angular/core';
import { BoletasService } from 'src/app/services/boletas.service';
import { DataService } from 'src/app/services/data.service';
import { SalesService } from 'src/app/services/sales.service';
import { TransactionsService } from 'src/app/services/transactions.service';

@Component({
  selector: 'app-nodes',
  standalone: true,
  imports: [],
  templateUrl: './nodes.component.html',
  styleUrl: './nodes.component.css'
})
export class NodesComponent {

  data:any  = {}
  sales:any = {}
  trans:any = {}

  constructor(
    private conexTrans: TransactionsService,
    private conexSales:SalesService,
    private conexData:DataService,
    private conexBoleta: BoletasService
    ){

     this.getData();
     this.getSales();
     this.getTrans();
    }

    getDocumentos(){
      this.conexBoleta.getData('/documentos').subscribe({
        next:(resp:any) => {console.log('resp boletas',resp);
        this.data = resp['datos'];
      },
        error:(err:any) => {console.log('err', err ),
        this.data = 'error'
      },
    })
    }


    getData(){
      this.conexData.getData('/test').subscribe({
        next:(resp:any) => {console.log('resp Data',resp);
        this.data = resp['datos'];
      },
        error:(err:any) => {console.log('err', err ),
        this.data = 'error'
      },
    })
  }
    getSales(){
      this.conexSales.traeDatos('/test').subscribe({
        next:(resp:any) => {console.log('resp SAles ',resp);
        this.sales = resp['datos'];
      },
        error:(err:any) => {
          console.log('err', err );
          this.sales = 'error'
        },
    })
  }
    getTrans(){
      this.conexTrans.getData('/test').subscribe({
        next:(resp:any) => {console.log('resp trans',resp);
        this.trans = resp['datos'];
      },
        error:(err:any) => {console.log('err', err );
        this.trans = 'error'
      },
    })
  }
}
