import { Component } from '@angular/core';
import { userModel } from 'src/app/models/config/user.model';
import { paramsModel } from 'src/app/models/general/params.model';
import { DataService } from 'src/app/services/data.service';
import { MensajesService } from 'src/app/services/mensajes.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.css']
})
export class UsuariosComponent {
  loading                      = true;
  user:any                     = {};
  store:any                    = {};
  searchString                 = '';

  users:any[]                  = [];
  stores:any[]                 = [];
  selectedUser:userModel       = new userModel();
  niveles                      = [{id:1, name:'Administrador'}, {id:2, name:'Intermedio'}, {id:3, name:'Básico'}]
  params                       = new paramsModel();


    constructor(private conexData: DataService,
              private _user:UserService,
              private _m: MensajesService) { 
              this.user   = this._user.userInfo();
              const puede = this._user.validarNivel(2, this.user);
              console.log('soy', this.user, 'puede', puede);
              
              if (puede){
                this.getStores();
              }

    }

    getParams(){
      if (localStorage.getItem('paramsGournet')){
        this.params = JSON.parse(localStorage.getItem('paramsGournet'))
      }
    }

    getStores(){
      this.conexData.getData('/data/stores').subscribe( resp =>{
        console.log('resp stores', resp);
        this.stores            = resp;
        if (this.stores.length < 1){
          this._m.error('error', 'Comunicate con Gour-net por favor');
          this.loading = false;
          return;
        } 
          if (this.params.store.id > 0){
            const existe = this.stores.find( s => s.id == this.params.store.id);
            if (existe){
              this.store = existe;
            } else {
              this.store = this.stores[0];;
            }
          } else {
            this.store = this.stores.find( s => s.id == this.user.storeId);
          }
          console.log('stores', this.stores);
          console.log('esta Store', this.store);
          this.getUsers();

      })
    }    


getUsers(){
      this.conexData.getData('/data/users').subscribe({
        next:(data:any) => { 
          this.users = data;
          console.log('users', this.users);
          this.selectedUser.storeId = this.store.id;
          this.loading = false;
      }, error: (err:any) => {
        console.log('error', err);
        this.loading = false;
      }
      })
    }

selectUser(u){
  console.log('user', u);
  this.selectedUser = u;
}

desactivar(){
  if (this.selectedUser.status == 1){
    this.selectedUser.status = 2
  } else {
    this.selectedUser.status = 1;
  }
}


newUser(){
  this.selectedUser         = new userModel();
  this.selectedUser.storeId = this.store.id;
}

guardarUser(){

  let tarea = 'insert';
  if (this.selectedUser.id > 0){
    tarea = 'update';
  }


  if(this.selectedUser.firstName.length < 2){
    this._m.warning('Nombre incompleto','Debes escribir un nombre por favor');
    return;
  }
 
  if(this.selectedUser.lastName.length < 2){
    this._m.warning('Apellido incompleto','Debes escribir un apellido por favor');
    return;
  }
 

  if (this.selectedUser.storeId == 0){
    this._m.warning('Falta seleccionar una tienda','o comunicate con Gour-net');
    return;
  }

  const passOk = this.validarPassword(this.selectedUser.password, tarea);
  if (!passOk){
    return;
  }

  console.log('voy a guardar ', this.selectedUser);

  this.loading = true;


  this.conexData.guardarDato(`/postData/user/${tarea}`, this.selectedUser).subscribe({
    next:(data:any) => {
      this.getUsers();
      this._m.ok('Ok', `Datos de ${this.selectedUser.firstName}  guardados correctamente`)
      this.loading = false;

    },
    error:(err:any)=>{
      console.log('err', err);
      this.loading = false;
      this._m.error('error grabando', 'Si el problema persiste comunicate con Gour-net');
    },
 
  })

}

borrar(){
  console.log('Borra', this.selectedUser);
  this.conexData.guardarDato(`/postData/user/delete`, this.selectedUser).subscribe({
    next:(data:any) => {
      this.users        = this.users.filter( u => u.id != this.selectedUser.id);
      this.selectedUser = new userModel();
      this._m.warning('Eliminado', `${this.selectedUser.firstName} eliminado con exito`)
      this.loading      = false;

    },
    error:(err:any)=>{
      console.log('err', err);
      this.loading = false;
      this._m.error('error grabando', 'Si el problema persiste comunicate con Gour-net');
    },
 
  })
}



validarPassword(pass, tarea){
  console.log('users', this.users);
  const existen = this.users.filter( p => p.password == this.selectedUser.password);
  console.log('password repetido', existen);

  for (let p of pass){
    console.log('p',p);
    // if (isNaN(p)){
    //   this._m.warning('No es un password numerico','Ingresa un password de 4 digitos por favor');
    //   console.log('no es un numero',p);
    //   return;
    // } 
  }

  if (pass.length < 3){
    this._m.warning('Largo de password incorrecto','Ingresa un password con más caracteres por favor');
    return false
  }


  // if (tarea == 'update' && existen.length > 1){
  //   this._m.warning('Password repetido','Busca otro password por favor');
  //   return false
  // }
 
  // if (tarea == 'insert' && existen.length > 0){
  //   this._m.warning('Password repetido','Busca otro password por favor');
  //   return;
  // }

return true;
}

}
