import { Component } from '@angular/core';
import { TransactionsService } from 'src/app/services/transactions.service';
import { UserService } from 'src/app/services/user.service';
import { forkJoin } from 'rxjs';
import { SalesService } from 'src/app/services/sales.service';
import { MensajesService } from 'src/app/services/mensajes.service';
import { DataService } from 'src/app/services/data.service';
import { paramsModel } from 'src/app/models/general/params.model';
import { groupModel } from 'src/app/models/data/group.model';
import { ExcelService } from 'src/app/services/excel.service';
import { ChartConfiguration, ChartData, ChartEvent, ChartType } from 'chart.js';

@Component({
  selector: 'app-ranking',
  templateUrl: './ranking.component.html',
  styleUrls: ['./ranking.component.css']
})
export class RankingComponent {



  loading                  = true;
  loadingRanking           = true;
  searchString             = '';
  ranking                  = [];
  user:any                 = {};

  date                     = new Date();
  firstDay                 = new Date(this.date.getFullYear(), this.date.getMonth(), 1).toString();
  lastDay                  = new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0).toString();

  saleChannels             = [{id:1 , name:'SALON'},{id:2 , name:'VENTA_DIRECTA'},{id:3 , name:'DELIVERY'},{id:4 , name:'LOCAL'}]
  
  stores                   = [];
  ids                      = [];
  store:any                = {};
  selectedStore: string    = '';
  params                   = new paramsModel();



  filtro                    = 'Ventas';
  ventas:any[]              = [];
   
  cifras = { ventaTotal:0,
             cantidadTotal:0,
             ventaGG: 0,
             porcentajeGG: 0,
             cantidadGG: 0,
             ventaGR:0,
             cantidadGR:0,
             porcentajeGR:0,
             porcentajeGRT:0
             }


  greatGroups        = [];
  groupsAll          = [];
  groups             = [];
  gg:any             = {};
  grupo:any          = {};


  public pieChartLabels: string[] = [];
  public pieChartLabels2: string[] = [];
  public pieChartData: ChartData<'pie'>;
  public pieChartData2: ChartData<'pie'>;
  
  public pieChartType: ChartType = 'pie';



  constructor(
    public _user:UserService, 
    private conexTrans: TransactionsService,
    private conexSales:SalesService,
    private _m: MensajesService,
    private conexData:DataService,
    public excelService: ExcelService
    ){
      this.firstDay = this._user.formatoFechaSql(this.firstDay);
      this.lastDay  = this._user.formatoFechaSql(this.lastDay);
      this.user     = this._user.userInfo();

      if (localStorage.getItem('paramsGournet')){
        this.params = JSON.parse(localStorage.getItem('paramsGournet'))
      }
      this.getStores();
      this.getData();
    }

    getStores(){
      this.conexData.getData('/data/stores').subscribe({
        next:(resp:any) => { 
          this.stores            = resp;
          
          if (this.stores.length < 1){
            this._m.error('error', 'Comunicate con Gour-net por favor');
            this.loading = false;
            return;
          } 
    
          if (this.params.store.id > 0){
              const existe = this.stores.find( s => s.id == this.params.store.id);
              if (existe){
                this.store = existe;
              } 
          } else {
              this.store = this.stores.find( s => s.id == this.user.storeId);
          }
  
            this.selectedStore = this.store.id;
            this.ids = [this.selectedStore ];
            
            if(this.stores.length > 1){
              this.stores.unshift({id:0, name:'Todos'});
            }

            this.filtrar();
        }, error:(err:any) => {
          console.log('error', err);
        }
      })
        
        
      

    }

   getData(){
    forkJoin([
      this.conexData.getData('/data/greatGroups'),
      this.conexData.getData('/data/groups'),
    ]).subscribe({
      next:(data:any)  => {
       const newgg      = {id:0, name:'Todos', type:'V', code: 'ALL'};
       this.gg          = newgg; 
       this.greatGroups = data[0].filter( g => g.type == 'V');
       this.greatGroups.unshift(newgg);
       this.groupsAll   = data[1];
        for( let gg of this.greatGroups){
          gg.ventas = 0;
          gg.porcentaje = 0;
        }
        for( let g of this.groupsAll){
          g.ventas = 0;
          g.porcentaje = 0;
        }
      },
     error: (error) => {
        console.error(error);
      }
    });




   } 
  
  onStoreChange(selectedValue: string) {
    this.ids = [];

    this.selectedStore = selectedValue;
    const existe = this.stores.find( s => s.id == this.selectedStore);  
    if (existe.id == 0){
      for (let s of this.stores){
        this.ids.push(s.id)
      }
    } else {
      this.ids.push(this.selectedStore);
    }

    this.filtrar();

  }


  filtrar(){
    this.limpiarData();

    if (this.lastDay < this.firstDay ){
      this._m.error('Rango de fecha equivocado', 'la fecha inicial debe ser inferior a la fecha final');
      return;
    }

    this.conexSales.traeDatos(`/stats/ventasXfecha/${this.ids}/${this.firstDay}/${this.lastDay}`).subscribe({
      next:(data:any) => {
        console.log('ventas', data);

        if (data && data.length > 0){
          this.formatoItem(data);
        } else {
          this.ranking = [];
        }
      },
      error:(err:any) =>{
        console.log('error', err)
      }
    })

  }

 selectProd(p){
  console.log('producto', p);
 }



 formatoItem(data){

  for (let v of data){
    if (v.status == 3){
      console.log('aca', v) // PORQUE 3?
    }

    if (v.status != 2){ // porque 2 ?
      continue;
    }
    
    const det = {     id: v.detId,
                      bruto: v.detBruto,
                      discount: v.detDiscount,
                      neto: v.detNeto,
                      iva: v.detIva,
                      price: v.detPrice,
                      quantity: v.detQuantity,
                      total: v.detTotal,
                      status: v.detStatus,
                      itemCost: v.itemCost,
                      productCode: v.code,
                      name: v.productName,
                      productId: v.productId,
                      docType: v.docType,
                      grangrupoId: v.greatGroupId, 
                      granGrupoName: this.findGranGrupo(v.greatGroupId),
                      grupoId: v.groupId,
                      grupoName: this.findGrupo(v.groupId)
                    }


  // if( det.productCode == 'DESCUENTO'){
  //   console.log('descuento', det);
  //   continue;
  // }                 

  let esNC = false; // es nota de crédito?

  if (det.docType == 'VNCE' || det.docType == 'VNCM'){
    esNC = true;
    det.bruto     = det.bruto * -1
    det.neto      = det.neto * -1
    det.iva       = det.iva * -1
    det.quantity  = det.quantity * -1
    det.total     = det.total * -1
    det.itemCost  = det.itemCost * -1

  } 

    this.cifras.ventaTotal       += det.total;
    this.cifras.cantidadTotal    += det.quantity;

  

  const existe = this.ventas.find( p => p.id == det.productId)
        if (existe){
                      existe.ventas.push(det);
                      existe.quantity   += det.quantity;
                      existe.price       = this.calcPrecioPromedio(existe.ventas);
                      existe.totalVenta += det.total;
        } else {
                      const newProd = {
                              id: det.productId,
                              code: det.productCode,
                              name: det.name,
                              quantity: det.quantity,
                              price: det.price,
                              greatGroupId: det.grangrupoId,
                              groupId: det.grupoId,
                              greatGroup: det.granGrupoName,
                              group: det.grupoName,
                              totalVenta: det.total,
                              ventas: [det]
                      }
                      this.ventas.push(newProd);

        }
      
       
  }
  // AHORA TENGO QUE FILTRAR 
  // ENTONCES 
  // 1. Traigo todas las ventas y las pongo en formato para el informe
  // 2. A partir del arreglo que arme (this.ventas) las ordeno por grangrupo o grupo etc.
  // ME queda ahora pasarlo por el filtro de los grupos y calcular los porcentajes.

  this.selectGG();
 }





 selectGG(){
  this.groups                   = this.groupsAll.filter( g => g.greatGroupId == this.gg.id);
  const newgrupo                = new groupModel();
  newgrupo.name                 = 'Todos';
  this.grupo                    = newgrupo;
  this.groups.unshift(newgrupo);

  this.limpiarCifras();

  if( this.gg.id == 0){
    this.grupo = this.groups[0];
    this.ranking = this.ventas;
  } else {
    this.ranking = this.ventas.filter( p => p.greatGroupId == this.gg.id);
  }

  

  this.reOrdenar();
  this.prepararGrafico();

}

selectG(){

  if (this.grupo.id == 0){
    this.ranking = this.ventas.filter( p => p.greatGroupId == this.gg.id);
  } else {
    this.ranking = this.ventas.filter( p => p.groupId == this.grupo.id);
  }


  this.reOrdenar();
}








calcPrecioPromedio(lista){
  let totalVenta    = 0;
  let totalCantidad = 0;

  for (let p of lista){
    totalVenta     += p.price * p.quantity
    totalCantidad  += p.quantity;
  }

  const promedio = Math.round(totalVenta / totalCantidad);
  return promedio;
  // if (Number.isNaN(promedio)){
  //   return 0
  // } else {
  //   return promedio;
  // }
}


reOrdenar(){
  this.limpiarCifras();

  let field         = 'totalVenta';
  let totalpercent = 0;

  this.ranking = this.ranking.filter( r => r.quantity != 0);

    if (this.gg.id != 0){
        const prodGG = this.ventas.filter( v => v.greatGroupId == this.gg.id && v.quantity != 0);
        console.log('prodGG', prodGG)
        for (let p of prodGG){ 
        this.cifras.cantidadGG  += p.quantity;
        this.cifras.ventaGG     += p.totalVenta;
      }
    }


  for (let p of this.ranking){
      
      if (this.grupo.id != 0){
        this.cifras.cantidadGR  += p.quantity;
        this.cifras.ventaGR     += p.totalVenta;
      }

      if(this.filtro == 'Ventas'){
        field = 'totalVenta';
        p.porcentaje   = Number((p.totalVenta * 100 / this.cifras.ventaTotal).toFixed(2))
        p.porcentajeGG =  Number((p.totalVenta * 100 / this.cifras.ventaGG).toFixed(2))
        p.porcentajeGR = Number((p.totalVenta * 100 / this.cifras.ventaGR).toFixed(2))
      } else {
        field = 'quantity';
        p.porcentaje   = Number((p.quantity * 100 / this.cifras.cantidadTotal).toFixed(2));
        p.porcentajeGG = Number((p.quantity * 100 / this.cifras.cantidadGG).toFixed(2));
        p.porcentajeGR = Number((p.quantity * 100 / this.cifras.cantidadGR).toFixed(2));
      }

    totalpercent += p.porcentaje;
  }

  console.log('reordenar', this.filtro, field);

  const newRanking = this.ranking.sort((a, b) => {
                if (a[field] > b[field]) return -1;
                if (a[field] < b[field]) return 1;
                return 0;
              });

   if(this.filtro == 'Ventas'){
     this.cifras.porcentajeGG = Number((this.cifras.ventaGG * 100 / this.cifras.ventaTotal).toFixed(2));           
     this.cifras.porcentajeGRT = Number((this.cifras.ventaGR * 100 / this.cifras.ventaTotal).toFixed(2));           
     this.cifras.porcentajeGR = Number((this.cifras.ventaGR * 100 / this.cifras.ventaGG).toFixed(2));           
    } else {
     this.cifras.porcentajeGG = Number((this.cifras.cantidadGG * 100 / this.cifras.cantidadTotal).toFixed(2));      
     this.cifras.porcentajeGRT = Number((this.cifras.cantidadGR * 100 / this.cifras.cantidadTotal).toFixed(2));           
     this.cifras.porcentajeGR = Number((this.cifras.cantidadGR * 100 / this.cifras.cantidadGG).toFixed(2));        
   }


   for (let p of this.ranking){
    if(this.filtro == 'Ventas'){
      field = 'totalVenta';
      p.porcentajeGG =  Number((p.totalVenta * 100 / this.cifras.ventaGG).toFixed(2))
      p.porcentajeGR = Number((p.totalVenta * 100 / this.cifras.ventaGR).toFixed(2))
    } else {
      field = 'quantity';
      p.porcentajeGG = Number((p.quantity * 100 / this.cifras.cantidadGG).toFixed(2));
      p.porcentajeGR = Number((p.quantity * 100 / this.cifras.cantidadGR).toFixed(2));
    }

   }



  console.log('total percente', totalpercent);
  console.log('cifras', this.cifras);
  console.log('new Ranking', newRanking);
  this.ranking = newRanking
  this.loading = false;
  console.log('Ranking', this.ranking);

}






exportAsXLSX(): void {
  let titulo = 'Ranking de Productos;' + this.firstDay + 'to' + this.lastDay;
  this.excelService.exportAsExcelFile(this.ranking, titulo);
}





limpiarData(){
  this.ranking = [];
  this.ventas  = [];

  this.cifras = { ventaTotal:0,
                  cantidadTotal:0,
                  ventaGG: 0,
                  porcentajeGG: 0,
                  cantidadGG: 0,
                  ventaGR:0,
                  cantidadGR:0,
                  porcentajeGR:0,
                  porcentajeGRT:0
                }
}

limpiarCifras(){
  this.cifras.cantidadGG    = 0;
  this.cifras.cantidadGR    = 0;
  this.cifras.porcentajeGG  = 0;
  this.cifras.porcentajeGR  = 0;
  this.cifras.porcentajeGRT  = 0;
  this.cifras.ventaGG       = 0;
  this.cifras.ventaGR       = 0;
}

findGrupo(id:number){
  const este = this.groupsAll.find( gr => gr.id == id);
  return  este.name;
}

findGranGrupo(id:number){
  const este = this.greatGroups.find( gg => gg.id == id);
  return  este.name;
}


prepararGrafico(){
    for( let gg of this.greatGroups){
      gg.ventas = 0;
    }
    
    for( let g of this.groupsAll){
      g.ventas = 0;
    }

  for (let p of this.ventas){
    const existe = this.greatGroups.find( gg => gg.id == p.greatGroupId);
    if (existe) { 
      existe.ventas      += p.totalVenta;
      existe.porcentaje  = Number((existe.ventas * 100 / this.cifras.ventaTotal).toFixed(2))
    } else {
      console.log('no coincide')
    }
  }

  for (let v of this.ranking){
    const existe = this.groupsAll.find( g => g.id == v.groupId);
    if (existe) { 
      existe.ventas      += v.totalVenta;
      existe.porcentaje  = Number((existe.ventas * 100 / this.gg.ventas).toFixed(2))
    } else {
      console.log('no coincide')
    }
  }

this.dibujar();
this.dibujar2();


}

private dibujar() {

  const cifras        = [];
  this.pieChartLabels = [];

  for (let p of this.greatGroups){
    if (p.code != 'ALL' && p.ventas > 0){
      this.pieChartLabels.push(p.name);
      cifras.push(p.porcentaje);
    }
  }

  this.pieChartData = {
    labels: this.pieChartLabels,
    datasets: [
      { data: cifras },
    ],


  };
}

private dibujar2() {
  console.log('dibujar2');
  
  const cifras2         = [];
  this.pieChartLabels2  = [];

  for (let p of this.groups){
    if (p.code != 'ALL' && p.ventas > 0){
      this.pieChartLabels2.push(p.name);
      cifras2.push(p.porcentaje);
    }
  }

  console.log('dibujar2', cifras2, this.pieChartLabels2);


  this.pieChartData2 = {
    labels: this.pieChartLabels2,
    datasets: [
      { data: cifras2 },
    ]
  };
}



}
