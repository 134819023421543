export class modifModel {
    id: number;
    fatherId: '';
    productId: '';
    name: string;
    status: number;
    position: number;
    price:number;
    companyId: number;

    constructor() {
        this.id        = 0;
        this.fatherId  = '';
        this.productId = '',
        this.name      = '';
        this.status    = 1;
        this.position  = 0;
        this.price     = 0;
        this.companyId = 0;
    }
}
