import { Component, EventEmitter, Output } from '@angular/core';
import { clientModel } from 'src/app/models/data/client.model';
import { MensajesService } from 'src/app/services/mensajes.service';
import { SalesService } from 'src/app/services/sales.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-shared-clientes',
  templateUrl: './shared-clientes.component.html',
  styleUrls: ['./shared-clientes.component.css']
})
export class SharedClientesComponent {


  @Output()
  enviarPadre: EventEmitter<object> = new EventEmitter<object>();


  loading                                = true;
  searchString                           = '';
  form
  clientes                               = [];
  client                                 = new clientModel();
  user:any                               = {};
  memoria:any     


  constructor(private conexSales: SalesService,
    private _user:UserService,
    public _m: MensajesService) { 
    this.user   = this._user.userInfo();
    
    this.getClients();

}

getClients(){
  this.conexSales.traeDatos(`/clients`).subscribe({ 
    next:(data:any) => {
      console.log('clients', data);
      if(!data){
        this.clientes = [];
      } else {
        this.clientes = data.filter( u => u.status > 0);
      }
      this.loading = false;
    }, 
    error:(err:any) => {
      console.log('error',err);
      this.loading = false;
    }
  })
}


selectClient(c){
  console.log('este cliente', c);
  this.enviarPadre.emit(c);

}

newClient(){
  this.client           = new clientModel();
  console.log('new', this.client);
}

desactivar(){
  console.log('desactivar');
  if (this.client.status == 1){
    this.client.status = 2
  } else {
    this.client.status = 1;
  }
}

guardarClient(){
  let tarea = 'update';
  if (this.client.id == 0){
    tarea = 'insert';
    this.client.companyId = this.user.companyId;
  }


  if (this.client.firstName.length < 3){
    this._m.warning('Nombre muy corto','Ingresa su nombre completo');
    this.client = this.memoria
    this.getClients();
    return;
  }

  if(this.client.rut.length == 0 && this.client.email.length == 0 && this.client.phone.length == 0){
    this._m.warning('Perfil incompleto','Agrega más info del cliente por favor ');
    this.client = this.memoria
    this.getClients();
    return;
  }

  this.loading = true;


  console.log('giuardar', this.client);
  this.conexSales.guardarDato(`/postSales/clients/${tarea}`, this.client).subscribe({
    next:(data:any)=>{ 
      console.log('grabé', data.recordset); 
      this.getClients();
      this.newClient();
      this._m.ok('Cliente grabado con exito','');
      this.loading = false},
    error:(err:any) => { console.log('error', err); this.loading = false;}
  })
}

borrar(){
  console.log('borrar', this.client)
  this.client.status = 0;
  this.guardarClient();
}


cambioRut(){
  console.log('rut', this.client.rut);
  const rut    = this._user.formatoRut(this.client.rut);
  const rutOk  = this._user.validarRut(rut);
  if(!rutOk){
    this._m.error('Formato de rut invalido','Ingresa el rut de nuevo por favor');
    this.client.rut = '';
  } else {
    this.client.rut = rut;
  }
}


cerrar(){
  const c = {id:0};
  this.enviarPadre.emit(c);

}


}
