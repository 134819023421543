import { Routes } from "@angular/router";
import { CajaComponent } from "./caja/caja.component";
import { NotaCreditoComponent } from "./nota-credito/nota-credito.component";
import { CajeroComponent } from "./cajero/cajero.component";

export const routesCaja: Routes = [
    {path: 'caja/:tipo/:id', component: CajaComponent},
    {path: 'nc/:headId', component: NotaCreditoComponent},
    {path: 'cajero', component: CajeroComponent},
    {path: '**', pathMatch: 'full', redirectTo: 'login'}
  ];
  