<div class="row" style="margin:0">
    <div class="col-10 offset-1 col-md-6 offset-md-2  col-lg-3 offset-lg-4 offset-lg-3 text-center login ">
        <img src="assets/images/logoRosado.svg" class="logo animate__animated animate__flipInX">
        <h1 class="animate__animated animate__fadeIn">Bienvenido</h1>


        <form *ngIf="!loading" (ngSubmit)="validarMail(f)" #f="ngForm" class="animate__animated animate__fadeInUp">

            <div class="campo">
                <span *ngIf="f.submitted && f.controls['email'].errors" class="text-danger animate__animated animate__fadeIn">El correo es obligario</span>
                <input class="form-control" type="email" name="email" [(ngModel)]="mail" required minlength="3" email placeholder="Email">
            </div>

            <div class="campo" data-validate="Password is required">
                <span *ngIf="f.submitted && f.controls['pass'].errors" class="text-danger animate__animated animate__fadeIn">El password es obligatorio</span>
                <input class="form-control" type="password" name="pass" [(ngModel)]="pass" required minlength="4" placeholder="Password">
            </div>

            <button class="btn w-100  text-light mt-3" type="submit">
                  Ingresar
            </button>

            <!-- <div class="campo mb-4">
                <div class="form-check text-start">
                    <input [(ngModel)]="recordarme" class="form-check-input" id="ckb1" type="checkbox" name="remember-me">
                    <label class="form-check-label text-light" for="defaultCheck1">
                        Recordarme
                    </label>
                </div>
            </div> -->
            <!-- <div>
                <a routerLink="/registro" class="txt1 text-light">
                  ¿No tienes cuenta?
                </a>
            </div>
            <div>
                <a routerLink="/resetpass" class="txt1 text-light">
                  Olvidé mi contraseña
                </a>
            </div> -->

        </form>

        <div *ngIf="loading"class="loading animate__animated animate__fadeIn text-primary">
            <h1><i class="fa-solid fa-egg fa-spin"></i></h1>
            <p class="animate__animated animate__pulse animate__infinite">Validando Datos</p>
        </div>



      
    </div>
  

</div>

<div class="fixed-bottom der text-end">
    <p>1.1.3</p>
</div>