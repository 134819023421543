import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { forkJoin } from 'rxjs';
import { tableModel } from 'src/app/models/data/table.model';
import { paramsModel } from 'src/app/models/general/params.model';
import { orderModel } from 'src/app/models/venta/order.model';
import { MensajesService } from 'src/app/services/mensajes.service';
import { ShiftService } from 'src/app/services/shift.service';
import { TransactionsService } from 'src/app/services/transactions.service';
import { UserService } from 'src/app/services/user.service';
import { ProductsQuery } from 'src/app/storage/products.query';
import { ProductStore } from 'src/app/storage/products.store';
import { DataService } from 'src/app/services/data.service';
import Swal from 'sweetalert2'
import { SalesService } from 'src/app/services/sales.service';

@Component({
  selector: 'app-mesa',
  templateUrl: './mesa.component.html',
  styleUrls: ['./mesa.component.css']
})

export class MesaComponent {
  @Output()
  enviarPadre: EventEmitter<object> = new EventEmitter<object>();
  
  // @Input() datoComensales: any;



  loading                 = true;
  user:any                = {};
  table:tableModel        = new tableModel();

  productsAll:any         = [];
  products:any            = [];
  sets                    = [];
  subSets                 = [];
  prodPosis               = [];
  selectedSet             = {};
  selectedSubSet          = {};

  selectedProduct:any     = {};
  modalModificador        = false;
  modalCombo              = false;
  modalMensaje            = false;
  comensal                = 1;

  orders:any[]    = []
  preOrders:any[] = [];

  params                     = new paramsModel();
  total                      = 0; 
  numel                      = 0;
  modalBuscar                = false;
  modalMesa                  = false;
  dataMesa:any               = {};
  ncomanda                   = 0;
  constructor(  
    private route: ActivatedRoute,
    private router: Router,
    private _user:UserService,
    private conexData: DataService,
    private conexTrans: TransactionsService,
    private conexSales: SalesService,
    private productsQuery: ProductsQuery,
    private productStore: ProductStore,
    public _m: MensajesService,
    private shiftServices: ShiftService,
    private datePipe: DatePipe,
    // private _f: FechasService,
    ) { 
    this.table.id       = this.route.snapshot.paramMap.get('id');
    this.user           = this._user.userInfo();

}







ngOnInit(): void {
  this.getProdPosis();
  this.getProducts();     
  this.getPantallas();
  this.getData();
  // this.turno = JSON.parse(localStorage.getItem('paramsGournet')).turno
  // console.log('turno',this.turno)

  if(localStorage.getItem('paramsGournet')){
    this.params                 = JSON.parse(localStorage.getItem('paramsGournet'))
   } else {
     this._m.error('Cuidado', 'Faltan los parametros la caja');
     this.router.navigateByUrl('/home');
   }

   this.shiftServices.openShift(this.params.store.id, this.params.caja.id);
   // lo actualizo para tener bien la fecha del shift 
   this.params = JSON.parse(localStorage.getItem('paramsGournet'))


}

ngOnDestroy(){
  console.log('SALIERON') // limpiar si salen
}

info(){
  console.log('this.table', this.table);
  console.log('preorders', this.preOrders);
  console.log('orders', this.orders);
}

getProdPosis(){
  const state    = this.productStore.getValue();
  this.prodPosis = state.prodPosis;
}

getProducts() {
  const prods      = this.productsQuery.getAll();
  this.getPrecios(prods);
}
getPrecios(productos) {
  const state   = this.productStore.getValue();
  const precios = state.prices;
  this.filtrar(precios, productos);
}

filtrar(precios, productos){  
  for (let p of precios){
    const existe = productos.find( prod => prod.id == p.productId );
    if (existe){
      const newProduct = {...existe};
      newProduct.price = p.price;
      this.productsAll.push(newProduct);
    } else {
      console.log('no match', p);
    }
  }

  this.productsAll.sort((a, b) => a.name.localeCompare(b.name));
}


getPantallas() {
  let state         = this.productStore.getValue();
  let sets2         = state.pantallas.slice().sort( (a:any, b:any) => a.secondary - b.secondary);
 
  for (let s of sets2){
      if (s.secondary  < 1){
        let newPantalla          = {...s}
        newPantalla.subpantallas = [];
        this.sets.push(newPantalla)
      } else {
       const padre = this.sets.find( set => set.id == s.fatherSetId );
       padre.subpantallas.push(s);
       if(padre.subpantallas.length > 1){
         padre.subpantallas = padre.subpantallas.slice().sort( (a:any, b:any) => a.position - b.position);
       }
      }

      this.sets = this.sets.slice().sort( (a:any, b:any) => a.position - b.position);
  } 
  this.selectPantalla(this.sets[0]);
}




getData(){
  forkJoin([
    this.conexTrans.getData(`/mesas/mesa/${this.table.id}`),
    this.conexTrans.getData(`/mesas/orders/${this.table.id}`),
  ]).subscribe({ 
    next: (data:any) => {
      this.table = data[0]
      console.log('data forkJoin', data );
      if (data[1]){
        this.recorrerOrders(data[1]);
      } else {
        this.loading = false;
      }
    },
    error: (error) => {
      console.error(error);
    }
  });
}


recorrerOrders(data:any){
  console.log('data', data);
  this.orders = [];
  for (let d of data){
    d.esModif = !Number.isInteger(d.numel);
    
    this.ncomanda = d.ncomanda;

    if(d.esModif){
      const numelPadre = Math.floor(d.numel)
      console.log('numel padre', numelPadre);
      const padre = this.orders.find( o => o.numel == numelPadre);
      padre.modifs.push(d);
    } else {
      d.modifs = [];
      this.orders.push(d);
    }
}

const ultimo = this.orders[this.orders.length - 1];
this.numel   = Math.floor(ultimo.numel);

this.calcTotal();
this.loading = false;
console.log('orders', this.orders);
}

// =============================== //
// =============================== //
// =============================== //
// ========= FIN GET DATA ======== //
// =============================== //
// =============================== //
// =============================== //

modifComensal(){
  console.log('comensal', this.comensal);
  this.comensal ++
  if (this.comensal > this.table.npeople){
    this.comensal = 1;
  }
}

selectPantalla(set){
  console.log('pantalla', set);
  this.products       = [];
  const posis         = this.prodPosis.filter( s => s.setId == set.setId);
  for (let p of posis ){
    const existe      = this.productsAll.find( prod => prod.id == p.productId);
    if (existe){
      const producto    = {...existe};
      producto.position = p.position;
      this.products.push( producto);
    }
  }

  console.log('productos', this.products)

}


selectProd(p:any){
  this.selectedProduct        = p;
  this.selectedProduct.modifs = [];
  this.selectedProduct.eater  = this.comensal;
  
  console.log('selectedProduct', this.selectedProduct);
  if (p.modifMenu == 1){
    console.log('tiene modif');
    this.modalModificador = true;
  } else if (p.modifMenu == 2){
    console.log('es menu'); // por programar
    this.modalCombo = true;
  } else {
    this.agregarAcomanda(this.selectedProduct);
  }
}

recibirModifs(values){
  console.log('modifs', values)
  this.agregarAcomanda(values);
  this.modalModificador       = false;
}

recibirCombo(values){
  console.log('combos', values)
  if (values.id != 0){
    this.agregarAcomanda(values);
  };
  
  this.modalCombo = false;
}

recibirMensaje(values){
  this.modalMensaje = false;

  if (values.message == ''){ return;}

  let newNumel:any    = 0;
  const numelIni  = this.selectedProduct.numel;
  const numelEnd  = this.selectedProduct.numel + 1;
  const numeles   = this.preOrders.filter( p => p.numel > numelIni && p.numel <  numelEnd);
  let indice      = 0

  
  if (numeles.length < 1){
    newNumel = numelIni + 0.01;
    indice = this.preOrders.findIndex(objeto => objeto.numel === numelIni);
  } else {
    const esteNumel = numeles[numeles.length - 1].numel
    newNumel =  esteNumel + 0.01;
    indice = this.preOrders.findIndex(objeto => objeto.numel === esteNumel);

  }
  newNumel = parseFloat(newNumel.toFixed(2));

  console.log('INDICE', indice);

  const newOrder        = new orderModel();
  newOrder.quantity     = 1;
  newOrder.storeId      = this.params.store.id;
  newOrder.ncomanda     = this.ncomanda;
  newOrder.tableCode    = this.table.code;
  newOrder.tableId      = this.table.id;
  newOrder.productId    = '00000000-0000-0000-0000-000000000000';
  newOrder.productCode  = 'MESSAG000';
  newOrder.productName  = '--> ' +  values.message; 
  newOrder.price        = 0;
  newOrder.bruto        = 0;
  newOrder.neto         = 0;
  newOrder.IVA          = 0;
  newOrder.total        = 0;
  newOrder.isMessage    = 1;
  newOrder.isModif      = 0;
  newOrder.sellerId     = this.user.id
  newOrder.numel        = newNumel;
  newOrder.eater        = this.comensal;
  newOrder.date         = this.params.turno.started; //'fecha del shift';
  newOrder.realDate     = this.datePipe.transform(new Date(), 'dd/MM/yyyy HH:mm')
  newOrder.created_at   = this.datePipe.transform(new Date(), 'dd/MM/yyyy HH:mm')
  newOrder.hasModifs    = false;

  // this.agregarAcomanda(values);

  this.selectedProduct.hasModifs = true;

  this.preOrders.splice(indice + 1, 0, newOrder);

  //  this.preOrders.push(newOrder);

   this._m.sonido('pop.wav')
   this._m.alertAgrega(`Agregaste ${values.message}`)

  console.log('preorders', this.preOrders);
  this.calcTotal();
// =======================================//
// =======================================//
// =======================================//
// =======================================//
// =======================================//
// ======= AQUI QUEDÉ               ======//
// =======================================//
// =======================================//
// =======================================//
// =======================================//
// =======================================//
// =======================================//
// =======================================//












}



agregarAcomanda(p){
  console.log('Agregar a Comanda', p)
  this.numel ++
  this.asociarItem(p, this.numel)
}



cerrar(){
  console.log('cerrar');
  const body ={
    id:0
  }

  this.enviarPadre.emit(body);
}





// ================================================ //
// ================================================ //
// ================================================ //
// =========== COMANDA              =============== //
// ================================================ //
// ================================================ //
// ================================================ //

asociarItem(prod, numel){

  const newOrder        = new orderModel();
  newOrder.quantity     = 1; if(prod.code == 'DESCUENTO'){newOrder.quantity = -1};
  newOrder.storeId      = this.params.store.id;
  newOrder.ncomanda     = this.ncomanda;
  newOrder.tableCode    = this.table.code;
  newOrder.tableId      = this.table.id;
  newOrder.productId    = prod.id;
  newOrder.productCode  = prod.code;
  newOrder.productName  = prod.name;
  newOrder.price        = prod.price;
  newOrder.bruto        = prod.price * newOrder.quantity;
  newOrder.neto         = Math.round(newOrder.bruto / 1.19);
  newOrder.IVA          = newOrder.bruto - newOrder.neto;
  newOrder.total        = newOrder.bruto;
  newOrder.isMessage    = 0;
  newOrder.isModif      = 0;
  newOrder.sellerId     = this.user.id
  newOrder.numel        = numel;
  newOrder.eater        = prod.eater;
  newOrder.date         = this.params.turno.started; //'fecha del shift';
  newOrder.realDate     = this.datePipe.transform(new Date(), 'dd/MM/yyyy HH:mm')
  newOrder.created_at   = this.datePipe.transform(new Date(), 'dd/MM/yyyy HH:mm')
  
  if (prod.modifs.length > 0){
    newOrder.hasModifs = true;
  }
  
  this.preOrders.push(newOrder);
  console.log('new Order', newOrder);
 

  for (let m of prod.modifs){
    console.log('m', m);
      numel += 0.01;
      const newModif        = {...newOrder};
      newModif.storeId      = this.user.storeId;
      newModif.productId    = m.productId;
      newModif.productCode  = '';
      newModif.productName  = '--> ' + m.name;
      newModif.price        = m.price;
      newModif.bruto        = m.price * newModif.quantity;
      newModif.neto         = Math.round(newModif.bruto / 1.19);
      newModif.IVA          = newModif.bruto - newModif.neto;
      newModif.total        = newModif.bruto * newModif.quantity;

      newModif.isModif      = 1;
      newModif.numel        = Number(numel.toFixed(2));
      this.preOrders.push(newModif);
      console.log('newModif', newModif);

  }
 
  this._m.sonido('pop.wav')
  this._m.alertAgrega(`Agregaste ${prod.name}`)

  console.log('preorders', this.preOrders);
  this.calcTotal();
}



calcTotal(){
  this.total = 0;
  for (let o of this.orders){
    if (o.status != 3){
      this.total += o.total;
    }
  }
  for (let p of this.preOrders){
    this.total += p.total;
  }

  // for (let o of this.orders){
  //   this.total += o.total;
  // }

  // this.headSale.saldo = this.headSale.total - this.headSale.abono;
}



recibirProducto(prod){
  this.modalBuscar   = false

  if (prod.id == 0){
    return;
  }


  console.log('recibi PRODUCTO', prod);
  prod.eater = this.comensal;
  if (prod.hasModifs){
    for (let m of prod.modifs){
      m.eater = this.comensal;
    }
  }
  this.agregarAcomanda(prod);

  
}




// ================================================ //
// ================================================ //
// ================================================ //
// =========== FUNCIONES & ACCIONES =============== //
// ================================================ //
// ================================================ //
// ================================================ //



enviarComanda(){

  if (this.preOrders.length < 1){
    return;
  }

  this.loading = true;
  console.log('enviar comanda', this.ncomanda);
  if (this.ncomanda != 0){
    console.log('ya hay una ncomanda -->' , this.ncomanda);
    this.enviarComanda2();
  } else { 
    
    // CAMINO SI ES UNA NUEVA VENTA, TENGO QUE PASARLE UN NCOMANDA
    this.conexSales.traeDatos(`/sales/correlativo/${this.params.store.id}`).subscribe({
      next:(resp:any) => {
        console.log('resp correltivo', resp);
        console.log('resp correltivo', resp.lastCor);
        if ( resp.lastCor == null){
          this.ncomanda = 1;
          console.log('Primera ncomanda de la vida', this.ncomanda);
          for (let p of this.preOrders){
            p.ncomanda = this.ncomanda;
          }

          this.guardarNcomanda('insert', 1);

          
        } else {
          this.ncomanda =  resp.lastCor + 1;
          console.log('tiene que usar',  resp.lastCor + 1)
          for (let p of this.preOrders){
            p.ncomanda = this.ncomanda;
          }

          this.guardarNcomanda('update', 1);
        }

       

       
      },
      error:(err:any) => { console.log( 'err correltivo',err)}
    })
  }
}


guardarNcomanda(tarea:string, estado:number){
  const body = {
    CompanyId: this.params.store.companyId,
    StoreId:  this.params.store.id,
    lastCor: this.ncomanda,
    Used: estado
  }


  this.conexSales.guardarDato(`/postSales/correlativos/${tarea}`, body).subscribe({
    next:(resp:any) => {
      console.log('guarder correlativo', resp);
      this.enviarComanda2();
    },
    error:(err:any) => { console.log( 'err correltivo',err)}
  })




}




enviarComanda2(){
  this.conexTrans.guardarDato(`/mesas/ordersLotes`, this.preOrders).subscribe({
    next:(resp:any) => {
      console.log('resp', resp);
      this._m.toast('Comanda enviada');
      this.updateMesa(3, 'go');
    },
    error:(err:any) => { console.log( 'err',err)}
  })
}


updateOrders(orders){

  if (orders.length < 1){
    return;
  }

  this.loading = true;
  console.log('orders', orders);



  this.conexTrans.guardarDato(`/mesas/updateOrders`, orders).subscribe({
    next:(resp:any) => {
      console.log('resp', resp);
      this._m.toast('Comanda Actualizada');
      this.loading = false;
    },
    error:(err:any) => { console.log( 'err',err)}
  })
}

enviarPrecuenta(){
  console.log('enviar precuenta');
}

pagar(){
  console.log('pagar');
}

buscar(){
  console.log('buscar');
  this.modalBuscar = true;
}


retenerItem(p:any, index:number){
  console.log('retener Item',p, index);
  
  if (p.await < 1){
    p.await = 1
  } else {
    p.await = 0
  }

  if (p.hasModifs){ 
    const nextNumel  = p.numel + 1;
    const modifs     = this.preOrders.filter ( o => o.numel > p.numel && o.numel < nextNumel);
    for (let m of modifs){
      
  if (m.await < 1){
        m.await = 1
      } else {
        m.await = 0
      }
    }
  }


  if (p.id != ''){
    const orders =[p];
    this.updateOrders(orders);
    this.updateMesa(3, 'stay');
  }


}


abrirMensaje(p:any, index:number){
  this.selectedProduct = p;
  console.log('abrir Mensaje', p, index);
  this.modalMensaje = true;
}



sumarItem(p:any, index:number){
  console.log('sumar', p, index);
  
  p.quantity ++;
  p.bruto = p.quantity * p.price;
  p.neto  = Math.round(p.bruto / 1.19);
  p.IVA   = p.bruto - p.neto;
  p.total = p.quantity * p.price;


  if (p.hasModifs){
    console.log('p.numel', p.numel);
    const nextNumel  = p.numel + 1;
    const modifs     = this.preOrders.filter ( o => o.numel > p.numel && o.numel < nextNumel);
    console.log('modifs', modifs);
    for (let m of modifs){
      m.quantity ++;
      m.bruto = m.quantity * m.price;
      m.neto  = Math.round(m.bruto / 1.19);
      m.IVA   = m.bruto - m.neto;
      m.total = m.quantity * m.price;
    }
  }


  this.calcTotal();
}

restarItem(p:any, index:number){
  console.log('restar', p, index);
  

  p.quantity --;

  if (p.quantity == 0){
    this.borrarItem(p, index);
    return;
  }

  p.bruto = p.quantity * p.price;
  p.neto  = Math.round(p.bruto / 1.19);
  p.IVA   = p.bruto - p.neto;
  p.total = p.quantity * p.price;


  if (p.hasModifs){
    console.log('p.numel', p.numel);
    const nextNumel  = p.numel + 1;
    const modifs     = this.preOrders.filter ( o => o.numel > p.numel && o.numel < nextNumel);
    console.log('modifs', modifs);
    for (let m of modifs){
      m.quantity --;
      m.bruto = m.quantity * m.price;
      m.neto  = Math.round(m.bruto / 1.19);
      m.IVA   = m.bruto - m.neto;
      m.total = m.quantity * m.price;
    }
  }


  this.calcTotal();
}


borrarItem(p:any, index:number){
  console.log('Borrar Item', p, index);
  const nextNumel  = p.numel + 1;
  
  const startIndex = this.preOrders.findIndex(order => order.numel == p.numel);
  let endIndex     = this.preOrders.findIndex(order => order.numel == p.numel);

  if (p.hasModifs){
    const modifs      = this.preOrders.filter ( o => o.numel > p.numel && o.numel < nextNumel);
    console.log('modifs', modifs);

    const ultimoModif = modifs[modifs.length - 1];
    console.log('ultimomodif', ultimoModif);
    endIndex          = this.preOrders.findIndex( o => o.numel == ultimoModif.numel);
  }
  

  console.log('start', startIndex)
  console.log('end', endIndex)

  const nuevoArreglo = this.preOrders.filter( pre => pre.numel < p.numel || pre.numel >= nextNumel);
  console.log('nuevo ARrelgo', nuevoArreglo);
  this.preOrders = nuevoArreglo;
  
  this.actualizarNumels()
}


actualizarNumels(){
  this.numel = 0;
  if (this.orders.length < 1){
    if(this.preOrders.length < 1){
      this.numel = 0;
    } 
  } else {
    this.numel = this.orders[this.orders.length - 1].numel;
  }

  for (let o of this.preOrders){
    if (!o.isModif  && !o.isMessage){
      this.numel = Math.floor(this.numel);
      this.numel ++;
      o.numel = this.numel;
    } else {
      this.numel = this.numel += 0.01;
      o.numel = Number(this.numel.toFixed(2));
    }
  }

  this.numel = Math.floor(this.numel);
  console.log('numel', this.numel);
  
  this.calcTotal();


}



updateMesa(estado:number, tarea:string){
 //estado    0 borrado 1 libre, 2 ocupada, 3 comandada, 5 precuenta
  console.log('debo cambiar estado de m', this.table);
    this.table.status         = estado;
    this.table.isOpen         = 0;
    this.table.hold           = this.isInHold();

  console.log('table despues', this.table);
  this.conexTrans.guardarDato(`/mesas/updateTable`, this.table).subscribe({
    next:(resp:any) =>{
      console.log('update mesa', resp);
      
    },
    error:(err:any) =>{
      console.log('error', err);
      this._m.error('error', err);
    },
    complete: () => { 

      if (tarea != 'stay'){
        this.router.navigateByUrl(`/salon/mesas`);
      }
      this.loading = false;     
    }
  })

}

isInHold(){
  let isHold = 0;
  for (let p of this.preOrders){
    if (p.await == 1){
      isHold = 1;
    }
  }
  for (let p of this.preOrders){
    if (p.await == 1){
      isHold = 1;
    }
  }

  return isHold;
}




volver(){
  console.log('Volver');
  
  // Caso mesa vacia:
  if (this.orders.length < 1 && this.preOrders.length < 1){
    this.limpiarMesa();
    this.updateMesa(1, 'go');
  }
  
  if (this.orders.length > 0 && this.preOrders.length < 1){
    this.updateMesa(3, 'go');
  }


  //Productos sin comandar.
  if (this.preOrders.length > 0){
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-warning mr-2"
      },
      buttonsStyling: false
    });
    swalWithBootstrapButtons.fire({
      title: "Tienes items sin comandar.",
      text: "¿Quieres enviar la comanda?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Enviar Comanda",
      cancelButtonText: "Volver",
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        this.enviarComanda();
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        if (this.orders.length < 1){
          this.limpiarMesa();
          this.updateMesa(1, 'go');
        } else {
          this.updateMesa(3, 'go');
        }
      }
    });
  }


 
}

anularItem(p:any, index:number){
console.log('p',p);
console.log('index', index);
console.log('user', this.user);
if (this.user.level > 2){
  this._m.warning('No puedes anular un producto comandado','Un usuario nivel administrador puede...');
  return;
}

Swal.fire({
  title: "¿Estás seguro?",
  text: "No podrás desanularlo.",
  icon: "warning",
  showCancelButton: true,
  confirmButtonColor: "#3085d6",
  cancelButtonColor: "#d33",
  confirmButtonText: "Si, anula no más"
}).then((result) => {
  if (result.isConfirmed) {
  console.log('borrar')
  }
});

}

infoMesa(){
  console.log('table', this.table);
  this.conexData.getData(`/data/userById/${this.table.userId}`).subscribe({
    next: (resp:any) => { 
      this.dataMesa.tiempo = this.calcTiempo(this.table.openedAt);
      this.dataMesa.user   = resp;
      this.dataMesa.mesa   = this.table;
      this.modalMesa       = true;

    },
    error: (err:any) => { console.log('err', err)}
  })

}


calcTiempo(fecha){
  const fechaDadaString         = fecha;
  const fechaDada               = new Date(fechaDadaString).getTime(); // Convertir a milisegundos
  const fechaActual             = new Date().getTime(); // Convertir a milisegundos
  const diferenciaMilisegundos  = fechaActual - fechaDada;
  
  const segundosTotales         = Math.floor(diferenciaMilisegundos / 1000);
  const dias                    = Math.floor(segundosTotales / (3600 * 24));
  const horas                   = Math.floor((segundosTotales % (3600 * 24)) / 3600);
  const minutos                 = Math.floor((segundosTotales % 3600) / 60);
  const segundos                = segundosTotales % 60;
  
  const tiempo = {
            dias:dias,
            horas: horas,
            minutos: minutos,
            segundos: segundos
        }
  
        console.log("Han pasado " + tiempo.dias + " días, " + tiempo.horas % 24 + " horas, " + tiempo.minutos % 60 + " minutos y " + tiempo.segundos % 60 + " ");
        return tiempo; 
}

limpiarMesa(){
  this.table.userId       = 0;
  this.table.openedAt     = '';
  this.table.date         = '';
  this.table.realDate     = '';
  this.table.isOpen       = 0;
  this.table.openFrom     = '';
  this.table.hold         = 0;
  this.table.comment      = '';
  this.table.npeople      = 0;
}

}
