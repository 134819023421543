<app-loading *ngIf="loading"></app-loading>


<div class="row subheader azul r0" >
    <div class="col-12">
   
        <h5 class="estiloDani"> <img src="assets/images/mesa.svg" width="25px" (click)="info()"> MESA</h5>
    </div>
</div>

<div class="row r0">
    
    <div class="col-12 col-md-4 comandera shadow ">
    
        <div class="row comanderaHead ">
            <div class="col-1 text-center" >
                <p>#</p>
            </div>
            <div class="col-6 ">
                <p>Producto</p>
            </div>
            <div class="col-2 text-end">
                <p>Cant.</p>
            </div>
            <div class="col-3">
                <p>Total</p>
            </div>
        </div> 

        <div class="row comandas">
            <div class="col-12">
            
                <div *ngIf="!loading && orders.length > 0">
                    
                    <div *ngFor="let o of orders; index as i"
                        [ngClass]= "o.await ? 'row order item await' : 'row order item' ">
                        

                        <div class="col-1 text-center animate__animated animate__fadeIn" style="padding: 0;">
                            <p *ngIf="!o.isModif && !o.isMessage">{{o.eater}} <i class="fa-solid fa-user"></i></p>
                        </div>


                        <div class="col-6 animate__animated animate__fadeIn" [ngClass]="o.status == 3 ? 'tachado' : '' "
                        data-bs-toggle="collapse" [attr.href]="'#colap' + i" [attr.data-bs-target]="'#colapOrder' + i" role="button" aria-expanded="false" aria-controls="collapse" data-bs-parent="#orders">
                            <p> {{o.productName}}
                                <span *ngIf="o.await"><i class="fa-solid text-warning fa-hand hvr-icon animate__animated animate__jello animate__infinite"></i> </span>
                            </p>
                            <h5 class="text-info" *ngIf="!o.isModif && !o.isMessage">${{o.price | separator:0}}</h5>
                        </div>

                        <div class="col-2 animate__animated animate__fadeIn" [ngClass]="o.status == 3 ? 'tachado' : '' ">
                            <p >x {{o.quantity | number}}</p>
                        </div>
                        <div class="col-3 animate__animated animate__fadeIn" [ngClass]="o.status == 3 ? 'tachado' : '' ">
                            <p >${{o.total | number}}</p>
                        </div>


                        <div *ngIf="o.modifs.length > 0 " class="col-12 modif2">
                            <div *ngFor="let m of o.modifs" [ngClass]="o.status == 3 ? 'tachado' : '' " class="row">
                                <div  class="col-6 offset-1">
                                    <p>{{m.productName}} <i *ngIf="m.isMessage" class="fa-regular fa-comment"></i> </p>
                                </div>
                                <div class="col-4 text-end">
                                    <p>${{m.total | number}}</p>
                                </div>
                            </div>
                        </div>


                        <div *ngIf="!o.isModif && !o.isMessage && o.await" class="collapse" [attr.id]="'colapOrder' + i">
                            <div class="row opciones">
                               
                                <div *ngIf="o.await"class="col text-center hvr-icon-grow" style="padding-right: 0;" (click)="retenerItem(o,i)">
                                    <h1 *ngIf="!o.await"><i class="fa-regular fa-hand hvr-icon"></i></h1>
                                    <h1 *ngIf="o.await" class="animate__animated animate__jello animate__infinite"><i class="fa-regular fa-thumbs-up hvr-icon"></i></h1>
                                    <p *ngIf="!o.await" >Retener</p>
                                    <p *ngIf="o.await" >Liberar</p>
                                </div>
                                <!-- <div class="col text-center hvr-icon-buzz-out" style="padding-left:0"(click)="anularItem(o,i)">
                                    <h1><i class="fa-solid fa-trash hvr-icon"></i></h1>
                                    <p>Anular</p>
                                </div> -->
                            </div>
                        </div>  



                    </div>  
        </div>
        



           
            <div *ngFor="let o of preOrders; index as i" 
                            [ngClass]="{
                                        'modif': o.isModif || o.isMessage, 
                                        'item': !o.isModif && !o.isMessage,
                                        }"
        
                            class="row preorder" 
                            >
                    
                    <div class="col-1 text-center" style="padding: 0;">
                        <p *ngIf="!o.isModif && !o.isMessage">{{o.eater}} <i class="fa-solid fa-user"></i></p>
                    </div>
                    <div class="col-6" style="padding-left:0" data-bs-toggle="collapse" [attr.href]="'#colap' + i" [attr.data-bs-target]="'#colap' + i" role="button" aria-expanded="false" aria-controls="collapse" data-bs-parent="#preorders">
                        <p *ngIf="!o.isModif && !o.isMessage">{{o.productName}}
                            <i *ngIf="o.await" class="text-warning fa-solid fa-hand hvr-icon animate__animated animate__jello animate__infinite"></i>
                        </p>
                        <h5 class="text-info" *ngIf="!o.isModif && !o.isMessage">${{o.price | separator:0}}</h5>
                        <h4 *ngIf="o.isModif || o.isMessage">{{o.productName}}</h4>
                    </div>
                    <div class="col-2 text-end" data-bs-toggle="collapse" [attr.href]="'#colap' + i" [attr.data-bs-target]="'#colap' + i" role="button" aria-expanded="false" aria-controls="collapse" data-bs-parent="#preorders">
                        <p *ngIf="!o.isModif && !o.isMessage">x {{o.quantity}}</p>
                        <h4 *ngIf="o.isModif || o.isMessage">x {{o.quantity}}</h4>
                    </div>
                    <div class="col-3" data-bs-toggle="collapse" [attr.href]="'#colap' + i" [attr.data-bs-target]="'#colap' + i" role="button" aria-expanded="false" aria-controls="collapse" data-bs-parent="#preorders">
                        <p *ngIf="!o.isModif && !o.isMessage">${{o.total | separator:0}}</p>
                        <h4 *ngIf="o.isModif || o.isMessage">${{o.total | separator:0}}</h4>
                    </div>

                    <div *ngIf="!o.isModif && !o.isMessage" class="collapse" [attr.id]="'colap' + i">
                        <div class="row opciones">
                           
                            <div class="col text-center hvr-icon-grow" style="padding-right: 0;" (click)="retenerItem(o,i)">
                                <h1 *ngIf="!o.await"><i class="fa-regular fa-hand hvr-icon"></i></h1>
                                <h1 *ngIf="o.await" class="animate__animated animate__jello animate__infinite"><i class="fa-regular fa-thumbs-up hvr-icon"></i></h1>
                                <p *ngIf="!o.await" >Retener</p>
                                <p *ngIf="o.await" >Liberar</p>
                            </div>
                            <div class="col text-center hvr-icon-grow" style="padding-right: 0; padding-left:0" (click)="abrirMensaje(o,i)">
                                <h1><i class="fa-regular fa-comment hvr-icon"></i></h1>
                                <p>Mensaje</p>
                            </div>
                            <div class="col text-center hvr-icon-sink" style="padding-right: 0; padding-left:0" (click)="restarItem(o,i)">
                                <h1><i class="fa-solid fa-minus hvr-icon"></i></h1>
                                <p>Restar</p>
                            </div>
                            <div class="col text-center hvr-icon-float" style="padding-right: 0; padding-left:0"(click)="sumarItem(o,i)">
                                <h1><i class="fa-solid fa-plus hvr-icon"></i></h1>
                                <p>Sumar</p>
                            </div>
                            <div class="col text-center hvr-icon-buzz-out" style="padding-left:0"(click)="borrarItem(o,i)">
                                <h1><i class="fa-solid fa-trash hvr-icon"></i></h1>
                                <p>Eliminar</p>
                            </div>
                        </div>
                    </div>  
            </div>

            
            </div>    
        </div>

        <div class="row total">
            <div class="col-12 text-end">
                <p>Total: $ {{total | separator:0}}</p>
            </div>
        </div>

 



    </div>


 

    <div class="col-12 col-md-7  ">
        <div class="row botonera">
            
     
        <div class="col-4 col-md-4 izq shadow animate__animated animate__fadeIn">
            <div class="boton text-center headerPantallas">
                <p>Pantallas</p>
            </div>

            <div *ngIf="!loading" class="pantallas">
                
                <div *ngFor="let pantalla of sets" class="pantalla accordion">
                    <div *ngIf="pantalla.secondary == 0" class="pointer" >
                        <div class="card-header" [id]="'heading' + pantalla.id" (click)="selectPantalla(pantalla)" [attr.data-bs-toggle]="'collapse'" [attr.data-bs-target]="'#cPantalla' + pantalla.id" aria-expanded="false" [attr.aria-controls]="'cPantalla' + pantalla.id">
                            <h2 class="">{{pantalla.name | titlecase}} </h2>
                        </div>
    
                        <div *ngIf="pantalla.subpantallas.length > 0" [id]="'cPantalla' + pantalla.id" class="collapse subpantallas" [attr.aria-labelledby]="'heading' + pantalla.id">
                            <div  *ngFor="let sub of pantalla.subpantallas" class="subpa" (click)="selectPantalla(sub)" >
                                <p>{{sub.name}}</p>
                            </div>
                        </div>
                    </div>
                </div>
       
            </div>
        </div>
        <!-- FIN PANTALLAS -->

        <div class="col-8 productos">
            <div class="row">
                <div *ngFor="let p of products" class="col-6 col-md-4 text-center  hvr-grow">
                    
                    <div class="btnProducto shadow animate__animated animate__flipInX position-relative pointer" (click)="selectProd(p)">
                        <span *ngIf="p.stockXsale >= 0" class="position-absolute top-0 end-0 badge bg-primary">{{p.stockXsale}}</span>
                        <p>{{p.name}}</p>
                        <h5>$ {{p.price | number}}</h5>
                    </div>
    
                </div>
            </div>
        </div>


        </div>
    </div>

    <div class="col-12 col-md-1 botones containerBTN animate__animated animate__slideInRight">
       <div class="boton" (click)="enviarComanda()">
            <h1 class="hvr-icon-rotate "><i class="fa-solid fa-paper-plane hvr-icon"></i></h1>
            <p class="hvr-grow ">Enviar Comanda</p>
        </div>
       <div class="boton" (click)="enviarPrecuenta()">
            <h1 class="hvr-icon-rotate"> <i class="fa-solid fa-receipt hvr-icon"></i></h1>
            <p class="hvr-grow">Enviar Precuenta</p>
        </div>
       <div class="boton" (click)="pagar()">
            <h1 class="hvr-icon-rotate"> <i class="fa-solid fa-money-bill-wave hvr-icon"></i></h1>
            <p class="hvr-grow">Pagar</p>
        </div>
   
        <div class="boton" (click)="modifComensal()">
            <h1 class="hvr-icon-rotate"> {{comensal}} <i class="fa-solid fa-person hvr-icon"></i></h1>
            <p class="hvr-grow">Comensal</p>
        </div>
      
        <div class="boton" (click)="buscar()">
            <h1 class="hvr-icon-rotate"><i class="fa-solid fa-magnifying-glass hvr-icon"></i></h1>
            <p class="hvr-grow">Buscar item</p>
        </div>
      
        <div class="boton" (click)="infoMesa()">
            <h1 class="hvr-icon-rotate"><i class="fa-solid fa-circle-info hvr-icon"></i></h1>
            <p class="hvr-grow">Info Mesa</p>
        </div>
       
        <div class="boton" (click)="volver()">
            <h1 class="hvr-icon-rotate"><i class="fa-solid fa-arrow-left-long hvr-icon"></i></h1>
            <p class="hvr-grow">Volver</p>
        </div>

    </div>
</div>



<!-- <i class="fa-solid fa-money-bill-wave"></i><i class="fa-solid fa-wallet"></i> -->


<div *ngIf="modalModificador"class="row fondoModal2 modificadores bg-dark  animate__animated animate__slideInRight r0">
    <div class="col-10"> 
        <app-modificadores [productFrom]="selectedProduct" (enviarPadre)="recibirModifs($event)"></app-modificadores>
    </div>
</div>




<div *ngIf="modalCombo"class="row  fondoModal3 modificadores   animate__animated animate__fadeIn r0">
    <div class="col-12 col-md-6 offset-md-3"> 
        <app-shared-combo [productFrom]="selectedProduct" (enviarPadre)="recibirCombo($event)"></app-shared-combo>
       
    </div>
</div>


<div  *ngIf="modalBuscar" class="row fondoModal animate__animated animate__fadeIn animate__faster" style="margin-right: 0;">
    <div class="col-12 col-md-10 offset-md-2 col-lg-8 offset-lg-4 animate__animated animate__slideInRight">
        <app-buscador-productos (enviarPadre)="recibirProducto($event)" ></app-buscador-productos>
    </div>
</div>

<div *ngIf="modalMensaje"class="row  fondoModal  animate__animated animate__fadeIn r0">
    <div class="col-12 col-md-10 offset-md-2 col-lg-8 offset-lg-4 animate__animated animate__slideInRight"> 
        <app-message-modal (enviarPadre)="recibirMensaje($event)" ></app-message-modal>
    </div>
</div>



<div *ngIf="modalMesa" class="row  fondoModal  animate__animated animate__fadeIn r0">
    <div class="col-12 col-md-5 offset-md-7 col-lg-4 offset-lg-8 animate__animated animate__slideInRight salmon"> 
        <div class="row headInfoMesa">
            <div class="col-10">
                <h1>Info Mesa</h1>
            </div>
            <div class="col-2 hvr-icon-rotate" (click)="modalMesa = false">
                <i class="fas fa-times fa-2x hvr-icon pointer"></i>
            </div>
        </div>
        <hr>
        <div class="row dataMesa">
            <div class="col-12">
               <p>Mesa:{{dataMesa.mesa.code}}</p>
               <p>Abierta el: {{dataMesa.mesa.openedAt | date:'dd/MM/yyyy'}} as las {{dataMesa.mesa.openedAt  | date:'HH:mm':'Z'}} </p>
               <p>Tiempo Abierta: <span *ngIf="dataMesa.tiempo.dias > 0">{{dataMesa.tiempo.dias}} Dias  {{dataMesa.tiempo.horas}} horas y {{dataMesa.tiempo.minutos}} minutos </span>
           
               <p>Atiende: {{dataMesa.user.firstName}} {{dataMesa.user.lastName}}</p>
            </div>
        </div>
       
    </div>
</div>









<!-- [productFrom]="selectedProduct" (enviarPadre)="recibirMensaje($event)" -->