import { Routes } from "@angular/router";
import { TablesComponent } from "./tables/tables.component";
import { MesaComponent } from "./mesa/mesa.component";



export const routesSalon: Routes = [
    {path: 'mesas', component: TablesComponent},
    {path: 'mesa/:id', component: MesaComponent},
    {path: '**', pathMatch: 'full', redirectTo: 'login'}
  ];
  
  