import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

import { UserService } from 'src/app/services/user.service';
import { KickoffService } from 'src/app/services/kickoff.service';
import { productModel } from 'src/app/models/data/product.model';
import { groupModel } from 'src/app/models/data/group.model';
import { NgForm } from '@angular/forms';
import { setModel } from 'src/app/models/data/set.model';
import { MensajesService } from 'src/app/services/mensajes.service';
import { priceModel } from 'src/app/models/data/price.model';
import { prodposiModel } from 'src/app/models/data/prodposi.model';
import { prodprintModel } from 'src/app/models/data/prodprint.model';
import { modifModel } from 'src/app/models/data/modificador.model';
import Swal from 'sweetalert2'

@Component({

  selector: 'app-producto',
  templateUrl: './producto.component.html',
  styleUrls: ['./producto.component.css']
})
export class ProductoComponent implements OnInit {

  // companyId              =  1
  loading                = true;
  loadingKickOff         = false;
  loadingSet             = false;
  loadingModif           = false;


  product:productModel   = new productModel();
  greatGroups            = [];
  groupsAll              = [];
  groups                 = [];
  printDestinations      = [];
  prodPrints             = [];
  prodPosis              = [];
  priceLists             = [];
  prices                 = [];
  sets                   = [];
  configSets             = [];
  prodposi               = [];
  id: string             = 'new'
  user: any              = {};

  pantalla:any           = null
  price                  = new priceModel();


  modalModificador = false;
  modalMenu        = false;
  modalReceta      = false;
  modalPrecios     = false;
  modalBotonera    = false;
  modalProductos   = false;

  idListaPrecioPrincipal = 0
  firstLoad              = true;

  modificadores    = [];


  constructor(private route: ActivatedRoute,
              private router: Router,
              private conexData: DataService,
              private _user:UserService,
              private kickOff: KickoffService,
              private mensajes: MensajesService) { 

    this.id           = this.route.snapshot.paramMap.get('id');
    this.user         = this._user.userInfo();

    if (this.id != 'new'){
      this.product.id = this.id;
    }



    const observables = [
      this.conexData.getData('/data/greatGroups'),
      this.conexData.getData('/data/groups'),
      this.conexData.getData('/data/printDestinations'),
      this.conexData.getData('/data/priceLists'),
      this.conexData.getData(`/data/sets/${this.user.storeId}`),
      this.conexData.getData(`/data/configset/${this.user.storeId}`)
    ];
    
    if (this.id != 'new') {
      observables.push(
        this.conexData.getData('/data/prodPrint/' + this.product.id),
        this.conexData.getData('/data/prodPosiByProduct/' + this.product.id),
        this.conexData.getData('/data/productPrices/' + this.product.id)
      );
    }

    forkJoin(observables).subscribe({
      next: (data: any[]) => {
        console.log('data del forkJoin', data);
        this.kickOff.evaluarFirstUse(this.user, data);
        this.greatGroups        = data[0].filter(gr => gr.type == 'V');
        this.groupsAll          = data[1];
        this.printDestinations  = data[2];

        this.priceLists         = data[3];
        this.sets               = data[4];
        this.configSets         = data[5];
        this.loadingKickOff     = false;
    
        this.price.priceListId  = this.priceLists[0].id;
        this.pantalla           = this.sets[0];
    
        if (this.product.id == 'new') { // nuevo



          this.product.companyId = this.user.companyId;
          if (localStorage.getItem('memoryProduct')){
            this.asociarItemMemoria();
          } else {
            this.selectPantalla();
            this.filtrarGrupos(1);
          }
       
        } else { // ya existe
          this.getProduct();
          if (data[6] != null) { this.prodPrints = data[6] }; // impresoras asociadas al producto
          if (data[7] != null) { this.prodPosis = data[7] }; // las pantallas en las que participa ese producto
          if (data[8] != null) { this.prices = data[8] }; // precios asociados al producto
        }
    
        this.marcarImpresoras();
    
        // agrego una pantalla "vacia"
        const setDefault = new setModel();
        setDefault.name = 'sin Asignar';
        this.sets.unshift(setDefault);
      },
      error: (error) => {
        console.error(error);
      }
    });

  }

  ngOnInit(): void {}

  info(){
    console.log('product', this.product);
    // console.log('print destinations', this.printDestinations);
    // console.log('prodPrint', this.prodPrints);
    // console.log('pantalla', this.pantalla);
    // console.log('prices', this.prices);
    // console.log('user', this.user);
    console.log('modificadores', this.modificadores);
  }

  asociarItemMemoria(){

    const memoria = JSON.parse(localStorage.getItem('memoryProduct'));
    console.log('memoria', memoria);
    this.filtrarGrupos(memoria.greatGroup);
    this.selectGrupo(memoria.group);
    this.price.price  = memoria.price;
    this.product.unit = memoria.unit;
    this.pantalla     = this.sets.find( p => p.id == memoria.pantalla);
    this.selectPantalla();
    console.log('el producto va', this.product);
  }



  getProduct(){
    this.conexData.getData(`/data/productId/${this.product.id}`).subscribe({
      next:(resp:any) => {
        console.log('resp producto', resp);
        this.product = resp;

        console.log('prodPosis', this.prodPosis);

        if (this.prodPosis.length > 0){
          const prodPosiPrincipal = this.prodPosis.find( pd => pd.status == 2); // busco cual de las pantallas asignadas es la principal (venta salon)
          if (!prodPosiPrincipal ){
            this.pantalla = this.sets[0];
          } else {
            this.pantalla = this.sets.find( p => p.id == prodPosiPrincipal.setId); // asocia cual es el id de esa pantalla al listado de pantallas
          }
        } else {
          this.pantalla = this.sets[0];
        }
      
        if (this.prices.length > 0){
          this.price = this.prices.find( p => p.priceListId == this.priceLists[0].id);
        }
        this.filtrarGrupos(this.product.greatGroupId);        
      },
      error:(err:any)=>{        
        console.error('Ha ocurrido un error:', err.error);
      }
      
      
    })
  }




  // marca las impresoras asociadas al producto
  marcarImpresoras(){
    for (let p of this.printDestinations){
      p.isConnected = false;
      if (this.prodPrints.length > 0){
        const existe = this.prodPrints.find( d => d.printDestinationId == p.id);
        if (existe){
          p.isConnected = true;
        }
      } 
    }
  }

  filtrarGrupos(value){
    console.log('filtro el grupo', value)
    this.product.greatGroupId = value;
    this.groups               = this.groupsAll.filter( gr => gr.greatGroupId == value);

    if (this.product.id != 'new' && this.firstLoad){
      this.selectGrupo(this.product.groupId);
    } else {
      this.selectGrupo( this.groups[0].id)
    }
    this.loading   = false;
    this.firstLoad = false;

  }

  selectGrupo(value){
    this.product.groupId = value;
    if (this.product.id == 'new'){
      this.generarCode();
    }
  }

// Un producto puede estas asociado a más de una pantalla
// pero para hacer más facil el manejo al cliente asumimos que una pantalla será la "principal"
// a esa pantalla le ponemos el status = 2 y consideramos que siempre es en el salón.


selectPantalla(){
  if (this.pantalla.id == 0){
    console.log('no quiere pantalla', this.pantalla);
    //no quiere asociar a pantalla
    return;
  }

  this.loadingSet = true;
  this.conexData.getData(`/data/prodposi/${this.pantalla.id}`).subscribe(
    (resp:any) => {
      if (resp != null){
        this.prodposi = resp;
      } else {
        this.prodposi = [];
      }
    
      this.pantalla.position = this.conexData.givePosition(this.prodposi);
      this.pantalla.status   = 2; // status 2 significa que es la pantalla "principal" ya que puede participar de varias otras
      this.loadingSet = false;
      console.log('this.pantalla', this.pantalla);
    },
    (error) => {
      console.error('Ha ocurrido un error:', error.error);
      this.loadingSet = false;
    })

}

selectPrinter(value){
  const print = this.printDestinations.find( p => p.id == value.id );
  print.isConnected = !print.isConnected;
}


selectUnidad(value){
  this.product.unit = value;
}

selectRole(value){
  this.product.role = value;
  if (value == 'CV'){
    this.product.type = 1;
  } else {
    this.product.type = 2;
  }
}

generarCode(){
  const grangrupo = this.greatGroups.find( g=> g.id == this.product.greatGroupId);
  const grupo     = this.groupsAll.find( g=> g.id == this.product.groupId);
  const code      = grangrupo.code + grupo.code;

  console.log('code code', code);

  this.conexData.getData(`/data/lastCode/${code}`).subscribe({
    next:(resp:any) => {
      console.log('resp codigo', resp);
      if (resp == null){
        this.product.code = code + '001';
      } else {
        this.product.code = this.conexData.newCode(resp[0].code);
      }
      this.product.codeTec = this.product.code;
    },
    error:(error) => {
      console.error('Ha ocurrido un error:', error.error);
    }
   });
}


// =========================================================================================== //
// =========================================================================================== //
// ============= GRABAR ====================================================================== //
// =========================================================================================== //
// =========================================================================================== //

guardarGrupo(f:NgForm){
    if (f.status == 'INVALID') { return; }
    if (f.value.group.length < 3){
      this.mensajes.error('Muy corto', 'El nombre del grupo debe ser mas largo.');
      return;
    }


    const repetido = this.groupsAll.find( g => g.name == f.value.group);
    if (repetido){
      this.mensajes.error('Grupo Repetido', 'Ya existe un grupo con ese nombre');
      return;
    }


    const newGroup         = new groupModel();
    newGroup.name          = f.value.group;
    newGroup.code          = this.generarCodeGroup(f.value.group, 1);
    newGroup.companyId     = this.user.companyId;    
    newGroup.greatGroupId  = this.product.greatGroupId;
    const body = [newGroup];

    console.log('voy a grabar', body);

    this.conexData.saveData('/postData/groups', body).subscribe({
      next: (resp: any) => {
        newGroup.id = resp.recordset[0].id;
        this.groupsAll.push(newGroup);
        this.groups.push(newGroup);
      }, 
        error: (error) => {
        console.error('Ha ocurrido un error:', error.error);
      }
  });

}
  

generarCodeGroup(name, contador) {
  let newCode = name.toString().substring(0, 3).toUpperCase();
  console.log('new', newCode);

  const repetido = this.evaluarRepetido(newCode);

  if (!repetido) {
    newCode = name.toString().substring(0, 2).toUpperCase() + contador.toString();
    console.log('repetido', 'probaré con este', newCode);
    // Incrementa contador antes de la llamada recursiva
    contador++;
    return this.generarCodeGroup(newCode, contador);
  } else {
    console.log('ya este sí', newCode);
    return newCode;
  }
}

  evaluarRepetido(code){
    const existe = this.groupsAll.find( g => g.code == code);
    if (existe){
      return false;
    } else{
      return true;
    }
  }






  // EMPIEZA EL PROCESO DE GRABAR o UPDATE de un producto
  preGuardar(){
    console.log('voy a guardar', this.product);
   
    if (this.product.name.length < 3){
      this.mensajes.error('Error creando el producto', 'Debes escribir un nombre del producto que sea por lo menos de 3 caracteres')
      return;
    }

    this.validarCodigoTec();
  }

  // El código Tecnico es una codigo alternativo, sirve por ejemplo para los codigos de barra.
  // Validamos que no esté repetido antes de grabar.
  validarCodigoTec(){
    this.loading = true;

    this.conexData.getData(`/data/validarCodigoTec/${this.product.codeTec}`).subscribe({
      next: (resp: any) => {

        console.log('codigo TEcnico', resp);
        if(resp != null && this.product.id == 'new'){
            this.mensajes.error('Error creando el producto', 'Ya existe un producto con ese codigo tecnico, por favor modificalo.')
            this.loading = false;
            return        
          } 
    
        if(resp != null && this.product.id != 'new'){
            if (resp[0].id == this.product.id){
              // ok estamos modificando el codigo de ese producto
            } else {
              this.mensajes.error('Error actualizando el producto', 'Ya existe un producto con ese codigo tecnico, por favor modificalo.')
              this.loading = false;
              return        
            }
          } 

        console.log('ok con el código tecnico, continuar');
        this.guardarProducto();
      },
      error: (error) => {
        console.error('Ha ocurrido un error:', error.error);
      }
    })
  }
      


  guardarProducto(){
    let tarea = 'insert';

    if (this.product.id != 'new'){ tarea = 'update'; }
    
    this.conexData.saveData(`/postData/product/${tarea}`, this.product).subscribe({
      next: (resp: any) => {
        console.log('producto Guardado', resp); 
        
        if(tarea == 'insert'){
          this.product.id = resp.recordset[0].id;
        }

        this.mensajes.ok('Producto Guardado', '');
        this.guardarPrecioPrincipal();
      },
      error: (error) => {
        console.error('Ha ocurrido un error:', error.error);
        this.loading = false;     
      }
    });

  }

  //Gour-net permite manejar varias listas de precios.
  //El precio principal corresponde a la lista 01P
  guardarPrecioPrincipal(){
    let tarea = 'insert';
    // ver si no existe un precio antes
    const existe = this.prices.find( pr => pr.priceListId == this.priceLists[0].id );
    if(existe){
      tarea = 'update';
    } else {
      this.price.productId = this.product.id;
    }

    console.log('voy a guardar precio principal', this.price, 'tarea', tarea);

    this.conexData.saveData(`/postData/price/${tarea}`, this.price).subscribe(
      (resp:any) => {
        console.log('precio Guardado', resp); 
        if (tarea == 'insert'){
          this.price.id = resp.recordset[0].id;
        }

        this.guardarPantalla();
        // this.mensajes.ok('precio Guardado', '');
      },
      (error) => {
        console.error('Ha ocurrido un error:', error.error);
        this.loading = false;

      })
  }
 
  guardarPantalla(){
    let newProdposi   = new prodposiModel();
    let tarea         = 'insert';

    console.log('prodPosis', this.prodPosis);
    //tengo que ver en prodposis si el producto ya esta asociado a una pantalla y si esta es "principal" osea que es la de venta salon 
    // eso lo determino con el status = 2
    // Si no hay ninguno status 2 entonces es un insert, de lo contrario es un update.

    if(this.prodPosis.length > 0){
        const existe = this.prodPosis.find( p => p.status == 2 );
        if(existe){
                  //reviso si es la misma, es decir que no hubo cambios
                  if( existe.setId == this.pantalla.id){
                    tarea = 'no grabar'
                  } else if(this.pantalla.id == 0){
                      tarea = 'delete'
                      newProdposi = existe;
                  } else {
                    tarea = 'update';
                    newProdposi           = existe;
                    newProdposi.setId     = this.pantalla.id;
                    newProdposi.position  = this.pantalla.position;
                    newProdposi.companyId = this.user.companyId;
                  }
        } 
    } else {
      newProdposi.position  = this.pantalla.position;
      newProdposi.productId = this.product.id;
      newProdposi.setId     = this.pantalla.id;
      newProdposi.status    = 2;
      newProdposi.companyId = this.user.companyId;
    }

    console.log('voy a guardar prodposi', newProdposi, 'tarea', tarea);
    
    if (tarea == 'no grabar' || newProdposi.setId == 0){
      this.guardarImpresoras();
    } else {
    this.conexData.saveData(`/postData/prodPosi/${tarea}`, newProdposi).subscribe({
      next: (resp:any) => {
        console.log('prodPosi guardado', resp); 
        if(tarea == 'insert'){
          newProdposi.id = resp.recordset[0].id;
          this.prodposi.push(newProdposi);
        }
        this.guardarImpresoras();
        // this.mensajes.ok('Botón Guardado', '');
      },
      error: (error) => {
        console.error('Ha ocurrido un error:', error.error);
        this.loading = false;
      }
    });
     
    }
  }

  guardarImpresoras(){
  
    const operaciones: Observable<any>[] = [];
 
   for (let p of this.printDestinations){
    let tarea = 'insert'
    const existe         = this.prodPrints.find( pr => pr.printDestinationId == p.id);
   
    let newProdPrint     = new prodprintModel();
    if (existe && p.isConnected == false ){
        tarea = 'delete';
        operaciones.push(this.conexData.saveData(`/postData/prodPrint/${tarea}`, existe))
    }
    
    if(!existe && p.isConnected){
        console.log('------ACA-------');
        console.log('nueva impresora');
        newProdPrint.productId          = this.product.id;
        newProdPrint.printDestinationId = p.id;
        operaciones.push(this.conexData.saveData(`/postData/prodPrint/${tarea}`, newProdPrint))
      }
   }

   console.log('cambios en impresoras', operaciones);

   if (operaciones.length > 0){
    forkJoin([...operaciones]).pipe(
      map(resultados => {
        // Manejar los resultados aquí
        console.log('// Manejar los resultados aquí', resultados)
        return resultados;
      })
    ).subscribe({
      next: (resp:any) => {
        console.log('ok prints', resp); 
        this.fin();
        },
      error: (error) => {
        console.error('Ha ocurrido un error:', error.error);
        this.fin();
      }
      }
    );
      } else {
        this.mensajes.ok('Ok', 'Cambios realizados con éxito');
        this.fin();
      };
  }

  fin(){
    this.loading = false;

    if (this.id != 'new'){ return};
    const extras: NavigationExtras = { replaceUrl: true };
    this.router.navigate(['/data/producto/' + this.product.id], extras);
  }



      //   forkJoin([cambios]).subscribe({
      //     next:  (data:any) => {
      //       console.log('data IMPRESORAS', data);
      //       this.mensajes.ok('Ok', 'Cambios realizados con éxito');
      //       this.fin();
      //     },
      //     error: (error) => {
      //       this.loading = false;
      //       console.error(error);
      //     }
      //  })


  // ============================================ //
  // ============================================ //
  // ================= Opciones ================= //
  // ============================================ //
  // ============================================ //
  // ============================================ //


  irCombo(){
    if (this.product.id == 0){
      return;
    }
    this.router.navigateByUrl(`/data/combo/${this.product.id}`);
  }

  abrirModal(modal){
    this.cerrarModal('todos');

    switch (modal){
      case "modificador" :
        this.modalModificador = true;
        this.getModificadores();
        break;        
    }
    
    switch (modal){
      case "menu" :
        this.modalMenu = true;
        break;        
    }


  }

  cerrarModal(modal){
    console.log('cierro', modal);

    if (modal == 'todos'){
      this.modalModificador = false;
      this.modalMenu        = false;
      return;
    } 

    switch (modal){
      case "modificador" :
        this.modalModificador = false;
        break;        
     
        case "menu" :
        this.modalMenu = false;
        break;        
    } 


  }




  // ============================================ //
  // ============================================ //
  // ================= Modificadores ============ //
  // ============================================ //
  // ============================================ //
  // ============================================ //

  getModificadores(){
    this.loadingModif = true;
    this.conexData.getData('/data/modificadoresXproducto/' + this.product.id).subscribe( resp => {
      if (resp != null){
        this.modificadores = resp;
      } 
      this.loadingModif  = false;
      console.log('modificares', this.modificadores);

    })
  }


  recibirModif(p){
    console.log('recibo', p);
    const newModif     = new modifModel();
    newModif.fatherId  = this.product.id;
    newModif.productId = p.id;
    newModif.position  = this.modificadores.length + 1;
    newModif.name      = p.name;
    newModif.companyId = this.product.companyId;

    console.log('newModif', newModif);
    
    this.insertModif(newModif);
  }

  insertModif(modif){
    this.conexData.saveData(`/postData/modificador/insert`, modif).subscribe( 
      (resp:any) => {
      console.log('guardé', resp);
      modif.id            = resp.recordset[0].id;
      this.modificadores.push( modif);
      
      if (this.product.modifMenu == 0){
        this.product.modifMenu = 1;
        this.updateProduct();
      }

      this.reordenar();
    },(error) => {
      console.error('Ha ocurrido un error:', error.error);
    })
  }

  recibirClones(data:any){
    console.log('recibo', data)
   
    localStorage.setItem('ultimoClon', JSON.stringify(data[1]))
    let newPosition = this.modificadores.length + 1;
   
    let clones = data[0];
    if (clones[0].fatherId == this.id){
      return;
    }

    for (let c of clones){
      const repetido = this.modificadores.find( m => m.productId == c.productId);
      if (repetido){
        return;
      }

      const newModif = new modifModel();
      newModif.fatherId  = this.product.id;
      newModif.productId = c.productId;
      newModif.position  = newPosition;
      newModif.name      = c.name;
      newModif.companyId = this.product.companyId;
      newModif.price     = c.price;

      console.log('guardaré', newModif)
      this.insertModif(newModif);
      newPosition ++;
    }
    
  }





  updatePrecioModif(m:any){
    console.log('nuevo precio', m);
    if(m.price < 0){
      m.price = 0;
    }

    this.updateModif(m, 'update');
  }
 
  updateCantObligatoria(){
    console.log('modif obligatorio', this.product);
    if(this.product.maxModifi < 0){
      this.product.maxModifi = 1;
    }

    this.updateProduct();
  }

  moverModif(modif:any, tipo:string){
    console.log('modif', modif, tipo)
    let tarea = 'update';
    

    if (tipo == 'subir'){
      const anterior = this.modificadores.find( m1 => m1.position == modif.position - 1 );
      anterior.position ++
      modif.position --;
      this.updateModif(anterior, 'update');
      this.updateModif(modif, 'update');
   }
   
    if (tipo == 'bajar'){
      const siguiente = this.modificadores.find( m2 => m2.position == modif.position + 1 );
      siguiente.position --
      modif.position ++;
      this.updateModif(siguiente, 'update');
      this.updateModif(modif, 'update');
    }
  
    if (tipo == 'delete'){
      this.modificadores = this.modificadores.filter( m => m.id != modif.id);

      const siguientes = this.modificadores.filter( m3 => m3.position > modif.position );      
      if (siguientes.length < 1){
        this.product.modifMenu = 0;
        this.updateProduct();
      } else {
        for (let s of siguientes){
          s.position --;
          this.updateModif(s, 'update');
        }
      }

      this.updateModif(modif, 'delete');
    }

    this.reordenar();
  }

  reordenar(){
    this.modificadores.sort((a, b) => a.position - b.position);

  }

  updateModif(modif:any, tarea:string){
    this.conexData.saveData(`/postData/modificador/${tarea}`, modif).subscribe
    ({
      next: (v:any) => { console.log('updated modif', v)},
      error: (e) => console.error(e),
      complete: () => {  } 
  })
    }

  updateProduct(){
    this.conexData.saveData(`/postData/product/update`, this.product).subscribe(
      (resp) => {
        console.log('actualisé el producto', resp);
        if (this.product.status == 0){
          this.router.navigateByUrl('/data/productos');
        }
      },(error) => {
        console.error('Ha ocurrido un error:', error.error);
      })
  }

  




newProduct(): void {
  console.log('product', this.product);
  let prodMemoria = {
    code: this.product.code,
    greatGroup: this.product.greatGroupId,
    group: this.product.groupId,
    unit: this.product.unit,
    pantalla: this.pantalla.id,
    price: this.price.price

  } 

  localStorage.setItem('memoryProduct', JSON.stringify(prodMemoria));
  
  const currentUrl = '/data/producto/new';
  this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
    this.router.navigate([currentUrl]); // Navega a la URL actual
  });

}

borrarItem(){
  Swal.fire({
    title: "¿Seguro que de borrar este Item?",
    text: "",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, borra no más!"
  }).then((result) => {
    if (result.isConfirmed) {
      this.product.status = 0;
      this.updateProduct();
    }
  });
}

}
