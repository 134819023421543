import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {


  // public url   = 'http://localhost:20002';
  public url   = 'https://g2.clubgournet.cl:20002';

  token               = localStorage.getItem('token');
  datos:HttpHeaders   = new HttpHeaders({
    'Authorization': 'Bearer ' + this.token,
    'Content-Type': 'application/json' // Si es necesario, también configura otros encabezados aquí
  });


  constructor(private http: HttpClient) { }



  reload(){
    console.log('el token', this.token);
    this.token = localStorage.getItem('token');
    this.datos = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token,
      'Content-Type': 'application/json' // Si es necesario, también configura otros encabezados aquí
    });
  }

  traeDatos( ruta:string ) {
    return this.http.get( this.url + ruta, { headers:this.datos} );
  }

  guardarDato(ruta, body) {
    const options = { headers: this.datos }; 
    return this.http.post( this.url + ruta , body, options );
  }

  getData(ruta:string): Observable<any> {
    console.log('get', ruta);
    const options = { headers: this.datos }; // Configura los encabezados en un objeto de opciones
    return this.http.get<any>(this.url + ruta, options);
  }

  saveData(ruta:string, body): Observable<any> {
    console.log('SAVE DATA', ruta)
    const options = { headers: this.datos }; // Configura los encabezados en un objeto de opciones
    return this.http.post<any>(`${this.url}${ruta}`, body, options);
  }


  newCode(codigo){
    console.log('recibo', codigo);

    let newNum = '';
    let code    = codigo.slice(0,6);
    let num     = codigo.slice(-3);
    num ++
    newNum = num.toString();
    
    if(newNum.length == 2){
      newNum = '0'+ newNum;
    }
   
    if(newNum.length == 1){
      newNum = '00'+ newNum;
    }
    console.log('aca ', code);

    const newCode = code + newNum;
    
    return newCode
  }

 // recorre las posiciones del prodPosi para ver asignar un espacio disponible
  givePosition(prods){
    console.log('prods', prods);
    if (prods.length < 1){
      return 1;
    }
    let posicionMaxima = Math.max(...prods.map(producto => producto.position)); // Obtener la posición más alta
    let posicionDisponible = 0; // Inicializar la posición disponible en -1
  
    for (let i = 1; i < posicionMaxima; i++) { // Recorrer las posiciones de 1 a la posición más alta
      const productoConPosicionI = prods.find(producto => producto.position === i); // Buscar si existe un producto con posición i
      if (!productoConPosicionI) { // Si no existe, entonces esa posición está disponible
        posicionDisponible = i;
        break;
      }
    }
  
    if (posicionDisponible == 0){ posicionDisponible = posicionMaxima + 1}
  
    console.log('posible disponible', posicionDisponible)
    return posicionDisponible;
  }


  actualizarHeaders(){
 
  }

}
