import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';
import { MensajesService } from 'src/app/services/mensajes.service';
import { ProductsQuery } from 'src/app/storage/products.query';
import { ProductStore } from 'src/app/storage/products.store';

@Component({
  selector: 'app-modificadores',
  templateUrl: './modificadores.component.html',
  styleUrls: ['./modificadores.component.css']
})
export class ModificadoresComponent {
  @Input() productFrom: any;

  @Output()
  enviarPadre: EventEmitter<object> = new EventEmitter<object>();
  
  loading                 = true;
  modifCounts             = 0;
  modificadores:any       = [];
  productoOriginal:any    = {};
  constructor(private productsQuery: ProductsQuery,
              private productStore: ProductStore,
              public _m: MensajesService){

              console.log('product aca', this.productFrom);
   
              }

ngOnInit(): void {
                this.productoOriginal = { ...this.productFrom, modifs: [] };

                console.log('product aca',  this.productoOriginal);

                this.getModificadores(this.productoOriginal.id);
              }
   
   getModificadores(id) {
    this.modifCounts   = 0;
    const state        = this.productStore.getValue(); // traigo desde la memoria virtual.
    this.modificadores = state.modificadores.filter( m => m.fatherId == id); 
    this.modificadores = this.modificadores.sort( (a:any, b:any)=>  a.position - b.position );

    console.log('modificadores',this.modificadores);
    console.log('productoFRom', this.productoOriginal);
    this.loading = false;
  }

  selectModif(m){
    console.log('modif',m);
    if (m == 'cerrar'){
      this._m.warning('Wait', 'No puedes cerrar hasta escoger el o los modificadores necerarios')
      // this.productoOriginal  = {};
      // this.productoOriginal  = false;
      // this.enviarPadre.emit(this.productoOriginal);
      return;
    }
  
    if (m == 'siguiente'){
      this.enviarPadre.emit(this.productoOriginal);
      return;
    }

    this.modifCounts ++;

    let newModif =  {...m};
    newModif.selected = this.modifCounts;

    this.productoOriginal.modifs.push(newModif);
    
    console.log('productFrom', this.productoOriginal);
    console.log('counts', this.modifCounts);

    if(this.modifCounts >= this.productoOriginal.maxModifi){
      console.log('iguales', this.productoOriginal);
      this.enviarPadre.emit(this.productoOriginal);
    }
  }
  


  remover(s){
    console.log('remover', s);    
    this.productoOriginal.modifs = this.productoOriginal.modifs.filter( m => m.selected != s.selected);
    this.modifCounts --;
    this.reEnumerarModifs();
  }


  reEnumerarModifs(){
    let contador = 0;
    for (let m of this.productoOriginal.modifs){
      contador ++;
      m.selected = contador;
    }
    console.log(this.productoOriginal);
  } 


  cerrar(){
    console.log('cerrar');
    const body ={
      id:0
    }

    this.enviarPadre.emit(body);
  }
}
