import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class TransactionsService {

  // public url   = 'http://localhost:20003';
  public url   = 'https://g2.clubgournet.cl:20003';
  
  token               = localStorage.getItem('token');
  datos:HttpHeaders   = new HttpHeaders({
    'Authorization': 'Bearer ' + this.token,
    'Content-Type': 'application/json' // Si es necesario, también configura otros encabezados aquí
  });
  
  constructor(private http: HttpClient) { }


  getData(ruta:string): Observable<any> {
    console.log('get', ruta);
    const options = { headers: this.datos }; // Configura los encabezados en un objeto de opciones
    return this.http.get<any>(this.url + ruta, options);
  }

  guardarDato(ruta, body) {
    const options = { headers: this.datos }; 
    return this.http.post( this.url + ruta , body, options );
  }



}
