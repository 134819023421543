
<div class="row header azul r0">
    <div class="col-11">
       <h2 class="animate__animated animate__fadeIn">  <i class="fa-solid fa-layer-group"></i> Grupos</h2>
    </div>
    <div class="col-1 text-right hvr-icon-bounce">
        <h2><i class="fa-solid fa-circle-question hvr-icon pointer" (click)="mensajes.info('Grupos', 'Cada producto debe pertenecer a un gran grupo y un subgrupo. Por ejemplo un chacarero estaria en venta de alimentos / sandwichs. Esto te ayudará a sacar informes más completos y tener tu base de datos ordenada.')"></i></h2>
    </div>
</div>

<div class="row headMenu animate__animated animate__fadeIn r0">
    <div class="col-6 pad20 hvr-forward">
        <p class=" botonMenu animate__animated animate__pulse" data-bs-toggle="offcanvas" data-disableScrolling="false" href="#menuData" role="button" aria-controls="menuData"> < Menu Data </p>
    </div>
    <div class="col-5 text-end " style="padding:30px 20px 0 0 "> 
        <!-- <button *ngIf="grupos.length > 0" class="btn btn-outline-primary animate__animated animate__heartBeat animate__infinite animate__slower" routerLink="/data/producto/new">Crear Producto</button> -->
    </div>
</div> 


<div class="row">
    <div class="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3 opciones shadow">
       
        <div class="row">
            <div class="col-12 col-md-4">
                <label> Filtrar por Gran Grupo</label>
                <select class="form-select" [(ngModel)]="gg" (change)="selectGG()">
                  <option *ngFor="let g of greatGroups" [ngValue]="g">{{g.name}}</option>
                </select>
            </div>
            <div *ngIf="group.id > 0" class="col-6 col-md-4">
                <label> Editar Grupo</label>
                <input type="text" class="form-control" [(ngModel)]="group.name" >
            </div>
            <div *ngIf="group.id > 0" class="col-6 col-md-4 mt-4">
                <button class="btn btn-success" (click)="updateGrupo()">Guardar</button>
            </div>
            <div *ngIf="gg.id > 0" class="col-12 col-md-4 mt-4  animate__animated animate__flipInX">
                <button class="btn btn-primary" (click)="modalGrupo = true">Nuevo Grupo</button>
            </div>
            <div *ngIf="gg.id == 0" class="col-12 col-md-8 mt-4 animate__animated animate__fadeIn">
                <p>  <i class="fa-solid fa-left-long animate__animated animate__headShake animate__infinite"></i> Para crear un grupo debes seleccionar un gran grupo primero.</p>
            </div>
        </div>
      
    </div>
</div>

<div *ngIf="modalGrupo && gg.id > 0"  class="row">
    <div class="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3 nuevoGrupo animate__animated animate__flipInX nuevoGrupo shadow">
       
        <div class="row">
            <div class="col-6 col-md-4">
                <label>Nuevo Grupo</label>
                <input type="text" class="form-control"  placeholder="Escribe aqui" [(ngModel)]="newGroup.name" >
            </div>
            <div class="col-6 col-md-8 mt-4">
                <button class="btn btn-success" style="margin-right: 10px;" (click)="insertGrupo()">Guardar</button>
                <button class="btn btn-danger" (click)="modalGrupo = false">Cancelar</button>

            </div>

        </div>
      
    </div>
</div>


<div class="row ">
    <div class="col-10 offset-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3 listado shadow">
        <table class="table table-hover">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Nombre</th>
              </tr>
            </thead>
            <tbody *ngIf="groups.length > 0">
              <tr *ngFor="let g of groups | filter : { 'name' : searchString} ; index as i " class="pointer" (click)="selectG(g)">
                <th scope="row">{{i + 1}}</th>
                <td >{{g.name}}</td>
              </tr>
       
 
            </tbody>
            <p *ngIf="groups.length < 1">No hay grupos para este filtro</p>
          </table>
    </div>
</div>



<app-menu-data></app-menu-data>
