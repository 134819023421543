import { Routes } from "@angular/router";
import { ListasprecioComponent } from "./listasprecio/listasprecio.component";
import { PantallasComponent } from "./pantallas/pantallas.component";
import { ProductoComponent } from "./producto/producto.component";
import { ProductosComponent } from "./productos/productos.component";
import { GruposComponent } from "./grupos/grupos.component";
import { CajasComponent } from "./config/cajas/cajas.component";
import { TurnosComponent } from "./config/turnos/turnos.component";
import { PrintdestinationComponent } from "./printdestination/printdestination.component";
import { PrintersComponent } from "./config/printers/printers.component";
import { SectoresComponent } from "./config/sectores/sectores.component";
import { ComboComponent } from "./combo/combo.component";
import { ClientesComponent } from "./clientes/clientes.component";
import { MesasComponent } from "./config/mesas/mesas.component";
import { MessageCrudComponent } from "./message-crud/message-crud.component";


export const routesData: Routes = [
    {path: 'productos', component: ProductosComponent},
    {path: 'producto/:id', component: ProductoComponent},
    {path: 'combo/:id', component: ComboComponent},
    {path: 'pantallas', component: PantallasComponent},
    {path: 'listasprecio', component: ListasprecioComponent},
    {path: 'grupos', component: GruposComponent},
    {path: 'printDestinations', component: PrintdestinationComponent},
    {path: 'clientes', component: ClientesComponent},
    {path: 'config/cajas', component: CajasComponent},
    {path: 'config/turnos', component: TurnosComponent},
    {path: 'config/printers', component: PrintersComponent},
    {path: 'config/sectores', component: SectoresComponent},
    {path: 'config/mesas', component: MesasComponent},
    {path: 'mensajes', component: MessageCrudComponent},
    {path: '**', pathMatch: 'full', redirectTo: 'login'}
  ];
  
  