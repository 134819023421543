<app-loading *ngIf="loading"></app-loading>

<div class="row header azul r0">
    <div class="col-8">
       <h2 class="animate__animated animate__fadeIn">            
        <i class="fa-regular fa-file-lines icono"></i>
        Nota de Crédito</h2>
    </div>
    <div class="col-4 hvr-icon-grow pointer text-end">
        <p routerLink="/stats/ventas" class="text-light"> <i class="fa-solid fa-arrow-left hvr-icon"></i> Volver</p>
    </div>
</div>


<div *ngIf="!loading" class="row r0">
    <!-- <div class="col-12 col-md-3 encVenta">
        <p><span>Tienda: </span>{{headSale.storeName}}</p>
        <p><span>Caja: </span>{{headSale.cajaName}}</p>
        <p><span>Fecha Turno: </span>{{headSale.shiftDate | date: 'dd-MM-yyyy'}}</p>
        <p><span>Fecha Real: </span>{{headSale.realDate | date: 'dd-MM-yyyy'}}</p>
        <p><span>Hora: </span>{{headSale.openTime}}</p>
        <p><span>Canal: </span>{{headSale.canal}}</p>
        <p><span>Documento: </span>{{headSale.docName | titlecase}}</p>
        <p><span>Cantidad de personas: </span>{{headSale.npeople}}</p>
        <p><span >Origen: </span>{{headSale.saleOrigin}}</p>
        <p><span>Total: </span>$ {{headSale.total | separator:0}}</p>
    
        <p *ngIf="headSale.saleChannel == 1"><span >Mesa</span>{{headSale.mesaCode}}</p>
    </div> -->



    <div class="col-10 offset-1 col-md-6 offset-md-3 data">

        <div class="row encVenta">
            <div class="col-12 col-md-6  ">
                <p><span>Tienda: </span>{{headSale.storeName}}</p>
                <p><span>Caja: </span>{{headSale.cajaName}}</p>
                <p><span>Fecha Turno: </span>{{headSale.shiftDate | date: 'dd-MM-yyyy'}}</p>
                <p><span>Fecha Real: </span>{{headSale.realDate | date: 'dd-MM-yyyy'}}</p>
                <p><span>Hora: </span>{{headSale.openTime}}</p>
            </div>
            <div class="col-12 col-md-6  ">
                <p><span>Canal: </span>{{headSale.canal}}</p>
                <p><span>Documento: </span>{{headSale.docName | titlecase}}</p>
                <p><span>Cantidad de personas: </span>{{headSale.npeople}}</p>
                <p><span >Origen: </span>{{headSale.saleOrigin}}</p>
                <p><span>Total: </span>$ {{headSale.total | separator:0}}</p>
            
                <p *ngIf="headSale.saleChannel == 1"><span >Mesa</span>{{headSale.mesaCode}}</p>
            </div>
        
        </div>


      

        <div *ngIf="paquete.detVenta.length > 0" class="row vistaprevia shadow animate__animated animate__fadeIn r0 ">
            <div class="col-12">
                    <div class="row headeVP">
                        <div class="col">
                            <h3>Vista previa</h3> 
                            <h1>Nota de crédito</h1>
                        </div>
                        <div class="col text-end">
                            <p class="mt-4" style="font-size:1.5em"> <span style="font-weight: 700;">Total: </span>$ {{paquete.headSale.newVenta.total | separator:0}}</p>
                        </div>

                    </div>

                    <div class="row infoCliente">
                        <div class="col-8">
                            <label style="font-size:0.8">Cliente</label>
                            <p *ngIf="paquete.headSale.newVenta.clientId != 0 ">{{paquete.headSale.newVenta.clientName}}</p>
                            <p *ngIf="paquete.headSale.newVenta.clientId == 0 " class="text-info"> <i class="fa-solid fa-triangle-exclamation text-warning"></i> Falta escoger a quien va la Nota de crédito</p>
                        </div>
                        <div class="col-4 text-end">
                            <button class="btn btn-outline-light" (click)="modalClientes = true ">Buscar Cliente</button>
                        </div>

                    </div>

                    <hr>


                    <div *ngFor="let p of paquete.detVenta" [ngClass]="p.esModif ? 'itemModif' : 'item2'" class="row">
                                <div class="col-6">
                                    <p>{{p.productName}}</p>
                                </div>
                                <div class="col">
                                    <p>{{p.quantity}} x ${{p.price | separator:0}} <span *ngIf="p.discount > 0" class="text-danger"><br> - $ {{p.discount}}</span></p>
                                </div>
                                <div class="col text-end">
                                    <p>$ {{p.total | separator:0}} </p>
                                </div>
                    </div>

                    <div class="row mt-2">
                        <div class="col-10 text-center">
                            <hr>
                            <button class="btn btn-outline-light w-100" (click)="guardarNC()">Guardar</button>
                        </div>
                        <div class="col-2">
                            <hr>
                            <button class="btn btn-danger" (click)="limpiarData()">Borrar</button>
                        </div>
                    </div>
            </div>
        </div>

        <div *ngIf="!loadingNc" class="row bg-light shadow mt-2 pad20"> 
            <div class="col-12">
                <h1>Notas de Credito</h1>
                
                <div *ngFor="let item of notasCreditos; let i = index">
                    <div class="row nc pointer" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapseExample' + i" aria-expanded="false" [attr.aria-controls]="'collapseExample' + i">
                        <div class="col">
                            <p>Doc #{{item.numDoc}}</p>
                        </div>
                        <div class="col">
                            <p>$ {{item.total | separator:0}}</p>
                        </div>
                        <div class="col text-end">
                            <p>{{item.realDate | date: 'dd-MM-yyyy'}}</p>
                        </div>
                    </div>
                    <div class="collapse" [attr.id]="'collapseExample' + i">
                        <div class="card card-body">
                            <table class="table table-hover" style="border-bottom: rgba(0,0,0,0);">
                                <tbody>
                                <tr *ngFor="let nc of item.detalle ; index as i " class="pointer">
                                    <th scope="row">{{i + 1}}</th>
                                    <td>{{nc.productName}}
                                        <div class="modif" *ngIf="nc.modifs.length > 0">
                                            <tr *ngFor="let m of nc.modifs">
                                                <td>{{m.productName}}</td>
                                            </tr>
                                        </div>
                                    </td>
                                    <td>{{nc.quantity | separator:0}} x $ {{nc.price | separator:0}} <span *ngIf="nc.discount > 0">$ {{nc.discount}}</span>
                                        <div class="modif" *ngIf="nc.modifs.length > 0">
                                            <tr *ngFor="let m of nc.modifs">
                                                <td>{{m.quantity}} x ${{m.price | separator:0}}</td>
                                            </tr>
                                        </div>
                                    </td>
                                    <td>$ {{nc.total | separator:0}}
                                        <div class="modif" *ngIf="nc.modifs.length > 0">
                                            <tr *ngFor="let m of nc.modifs">
                                                <td>$ {{m.total | separator:0}}</td>
                                            </tr>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                                <p *ngIf="notasCreditos.length < 1">No hay notas de Crédito para esta venta</p>
                            </table>                     
                        </div>
                    </div>

                </div>
                


            
            </div>
        </div>

        <div *ngIf="!loadingNc" class="row boton animate__animated animate__flipInX">
            <div class="col-12 text-center ">
                <button class="btn btn-dark btn-lg w-100"  (click)="evaluarAnulaciones()" data-bs-toggle="offcanvas" data-disableScrolling="false" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">Hacer Nota de Crédito</button>
            </div>
        </div>

        <div *ngIf="loadingNc" class="row">
            <div class="col-12 text-center">
                <p>Cargando notas de crédito</p>
            </div>
        </div>

    
        
    </div>
        

</div>





  
  <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
    <div class="offcanvas-header">
      <h5 class="offcanvas-title" id="offcanvasExampleLabel">Detalle Venta</h5>
      <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>

  

    <div *ngIf="!loadingAnulaciones" class="offcanvas-body">

        <div class="row bg-dark pad20">
            <div class="col-12">
                <label class="text-light">Tipo de documentos</label>
                <select [(ngModel)]="selectedDocType" class="form-control formuSin" (change)="changeDocType()">
                    <option *ngFor="let d of noteTypes" [ngValue]="d">{{ d.name }}</option>
                  </select>
            </div>
        </div>


      <div class="row detalle pad20">
        <div class="col-12">

            <div class="table-responsive">
                <table class="table">
                    <thead>
                    <tr>
                        <th scope="col">Producto</th>
                        <th scope="col">Precio</th>
                        <th scope="col">Anuladas</th>
                        <th scope="col">Total</th>
                    </tr>
                    </thead>
                    <tbody class="animate__animated animate__fadeIn">
                    <tr *ngFor="let d of detalleVenta; index as i" [ngClass]="d.anulado ? 'bg-warning':'pointer' " (click)="selectItem(d, 1)">
                        <td >{{d.productName}} 
                            <div class="modif" *ngIf="d.modifs.length > 0">
                                <tr *ngFor="let m of d.modifs">
                                    <td>{{m.productName}}</td>
                                </tr>
                            </div>
                          
                        </td>
                        <td >{{d.quantity}}  x  $ {{d.price | separator:0}}
                            <div class="modif" *ngIf="d.modifs.length > 0">
                                <tr *ngFor="let m of d.modifs">
                                    <td>{{m.quantity}} x $ {{m.price | separator:0}}</td>
                                </tr>
                            </div>
                        </td>
                        
                        <td class="text-danger">{{d.Qanulada}} </td>

                        
                        <td> $ {{d.total | separator:0}} <span *ngIf="d.discount > 0" class="text-danger" style="font-size:0.6em"> ( - $ {{d.discount | separator:0 }} )</span>
                            <div class="modif" *ngIf="d.modifs.length > 0">
                                <tr *ngFor="let m of d.modifs">
                                    <td>$ {{m.total | separator:0}}</td>
                                </tr>
                            </div>
                        </td>
                    </tr> 
            
                    </tbody>
                </table>
            </div>


        </div>
      </div>



      <div class="row">
        <div class="col-12 text-center">
            <button *ngIf="porAnular.length > 0 " class="btn btn-primary"  data-bs-dismiss="offcanvas" aria-label="Close" (click)="getNumDoc()">Guardar</button>
        </div>
      </div>

      <div class="row">
        <div class="col-12 text-center">
            <p>Pincha los items por los que quieres hacer la nota de crédito.</p>
            <button class="btn btn-secondary" type="button" (click)="selectAll()" > Seleccionar todos los items </button>
        </div>
      </div>

    </div>

    <div *ngIf="loadingAnulaciones" class="row">
        <div class="col-12 text-center">
            <p>Evaluando Notas de crédito <i class="fas fa-spinner fa-spin"></i></p>
        </div>
    </div>
  </div>






<div *ngIf="modalClientes" class="row fondoModal animate__animated animate__fadeIn animate__faster" style="margin-right: 0;">
    <div class="col-12">
        <app-shared-clientes (enviarPadre)="recibirCliente($event)"> </app-shared-clientes>
    </div>
</div> 

<!-- <div *ngIf="modalClientes" class="row fondoModal animate__animated animate__fadeIn animate__faster" style="margin-right: 0;">
    <div class="col-12 col-md-8 offset-md-4 col-lg-6 offset-lg-6 animate__animated animate__slideInRight">
        <app-digito-pay [datoDesdeCaja]="datoCaja" (enviarPadre)="recibirTotal($event)" ></app-digito-pay>
    </div>
</div>   -->
