import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { paymentModel } from 'src/app/models/venta/payment.model';
import { MensajesService } from 'src/app/services/mensajes.service';
import { SalesService } from 'src/app/services/sales.service';
import { forkJoin } from 'rxjs';
import { UserService } from 'src/app/services/user.service';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-webpay',
  templateUrl: './webpay.component.html',
  styleUrls: ['./webpay.component.css']
})
export class WebpayComponent {

  loading         = true;
  loadingPago     = false;
  headId:any      = '';
  payId:any       = '';
  headSale:any    = {};
  store:any       = {};
  payment:any     = {};
  detSale:any[]   = [];
  commerce:any    = {id:0};
  constructor(  
    private conexSales: SalesService,
    private route: ActivatedRoute,
    public _m: MensajesService,
    private _user:UserService,
    private conexData: DataService
    // private conexTrans: TransactionsService,
    // private router: Router,
    // private datePipe: DatePipe,
    // private productStore: ProductStore,
    // private shiftServices: ShiftService,
    ) { 
      this.headId       = this.route.snapshot.paramMap.get('saleId');
      this.payId        = this.route.snapshot.paramMap.get('paymentId');
    }

ngOnInit(): void {
  this.getData();
}


getData(){
  console.log('get data', 'headId', this.headId);
  forkJoin([
    this.conexSales.traeDatos(`/sales/headById/${this.headId}`),
    this.conexSales.traeDatos(`/stats/detSaleByHead/${this.headId}`),
    this.conexSales.traeDatos(`/sales/paymentById/${this.payId}`),
  ]).subscribe({ 
    next: (data:any) => {
      console.log('data', data);
      this.headSale = data[0][0];
      this.detSale  = data[1];
      this.payment  = data[2][0];
      this.getCommerce();


      console.log('headSale', this.headSale)
      console.log('detSale', this.detSale)
     },
    error: (err:any) => {
      console.log('error',err);
      this._m.error('error', err.message);
      this.loading = false
    }
  });
}


getCommerce(){
  this.conexData.getData(`/integraciones/integraciones/${this.headSale.storeId}`).subscribe({ 
    next: (data:any) => {
      console.log('integraciones', data);
      const webpay = data.find( int => int.name == 'Webpay Mall');
      if (!webpay){
        this._m.warning('Este local no tiene código de comercio','No se podrá hacer el pago.')
        this.loading = false;
        return;
      } else {
        this.commerce = webpay;
        console.log('commerce', this.commerce);
      }

      this.loading  = false;     
     },
    error: (error) => {
      console.error(error);
      this._m.error('error', error.error);
      this.loading = false
    }
});
}



//paso 1 
  // updatePayment(){
  //   this.loading = true;

  //   // const newPay        = new paymentModel();
  //   // newPay.headId       = this.headId;
  //   // newPay.storeId      = this.headSale.storeId;
  //   // newPay.totalAmount  = this.headSale.total;
  //   // newPay.amountA      = this.headSale.saldo; // MONTO QUE VA DIRECTO A PAGAR LA VENTA
  //   // newPay.amountP      = this.headSale.saldo; // MONTO QUE PAGA LITERAL LA PLATA QUE ENTRA PUEDE IR INCLUIDA PROPINA Y VUELTO
  //   // newPay.change       = 0;
  //   // newPay.tip          = 0;
  //   // newPay.status       = 1;
  //   // newPay.cashierId    = this.headSale.cashierId;
  //   // newPay.date         = this._user.dateNow('completa');
  //   // newPay.clientId     = 0;
  //   // newPay.shiftId      = this.headSale.shift
  //   // newPay.sellerId     = this.headSale.sellerId;
  //   // newPay.payType      = 'PEN';
  //   // newPay.payWay       = 'WebPay';
  //   this.payment.date      = this._user.dateNow('completa');

  //   console.log('pagar',  this.payment);

  //   this.conexSales.guardarDato(`/postSales/payment/update`,  this.payment).subscribe({
  //     next:(data:any)=>{ console.log('data', data); 
  //     console.log(' this.payment',  this.payment);
  //     this.pagar( )
      
  //   },
  //     error:(err:any)=>{ console.log('err', err); this.loading = false},
  //   })
  //   // newPay.payTypeName  = this.payTypes.find( p => p.code == event.payType).name

  // }





// PIDO UN TOKEN A TB PARA HACER LA VENTA
pagar(){
  const body = {
            amount: this.payment.amountA,
            buyOrder: this.payment.id.split('-').slice(-1)[0],
            sessionId: 'S' + this.payment.id.split('-').slice(-1)[0],
            commerceCode: this.commerce.nativeId,
            quotes: 1
    };

    let response = {
            token: '',
            url: '',
            orderId: '',
        };

   this.conexSales.guardarDato(`/webpay/pagoWebPay`, body).subscribe({
    next:(data:any) =>{ 

      console.log('pagoKobra', data);

      response.token    = data['token'];
      response.url      = data['url'];
      response.orderId  = this.payment.id;
      console.log('response', response);
        this.updateToken(response);
  
    },
    error:(error:any) =>{ 
      console.log('error webpay',error);
      this.borrar(this.payment.id)
    } 
   })



}

// RECIBO Y GRABO ESE TOKEN PARA SEGUIMIENTO EN EN LA TABLA PAYMENTS
updateToken(response){
  const body:any = {
    token: response.token,
    id: response.orderId
  }

  this.conexSales.guardarDato(`/postSales/payment/token`, body).subscribe({
    next:(data:any)=>{ console.log('data', data); 
      console.log('token guardado', data);
      this.postear(response);    
    },
    error:(err:any)=>{ 
      this.borrar(response.orderId)
      console.log('err', err); this.loading = false 
    },
  })

}




// ME VOY A LA INTERFAZ DE TB
postear(resp){
  const mapForm = document.createElement('form');
  mapForm.method = 'POST';
  mapForm.action = `${resp.url}`;
  mapForm.style.display = 'none';
  
  const mapInput = document.createElement('input');
  mapInput.type = 'hidden';
  mapInput.name = 'token_ws';
  mapInput.value = resp.token;
  mapForm.appendChild(mapInput);
  
  const mapInput1 = document.createElement('input');
  mapInput1.type = 'hidden';
  mapInput1.name = 'orderId';
  mapInput1.value = resp.orderId;
  mapForm.appendChild(mapInput1);
  
  const mapInput2 = document.createElement('input');
  mapInput1.type = 'hidden';
  mapInput1.name = 'storeId';
  mapInput1.value = '1'; // HACER DINAMICO
  mapForm.appendChild(mapInput2);
  
  document.body.appendChild(mapForm);
  
  mapForm.submit();
  }


  borrar(id){
    this.loading = true;
    const payment        = new paymentModel();
    payment.id           = id;
    console.log('borraré', payment)
    this.conexSales.guardarDato(`/postSales/payment/delete`, payment).subscribe({
      next:(data:any)=>{ console.log('borrado', data);     
      this.loading = false},
      error:(err:any)=>{ console.log('err', err); this.loading = false},
    })
  }

}


