import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import jwt_decode from 'jwt-decode';
import { MensajesService } from './mensajes.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  
  
  constructor(private router: Router,
              private _m: MensajesService
              ) { }


userInfo(){
  const token = localStorage.getItem('token');
  if (token) {
    const tokenInfo:any = jwt_decode(token);
    this.isTokenExpired()
    return tokenInfo.infoUser;
  } 

}

getParams(){
  if (localStorage.getItem('paramsGournet')){
    return  JSON.parse(localStorage.getItem('paramsGournet'))
  } else {
    this.router.navigateByUrl('/home');
  }
}



isTokenExpired(){
  const token         = localStorage.getItem('token');
  const tokenInfo:any = jwt_decode(token);
  const now = Math.floor(Date.now() / 1000);

  const expiro = tokenInfo.exp < now;
  if (expiro){
    this._m.info('Sesión Expirada', '');
    this.cerrarSesion();
  } else {
    console.log('Sesión activa. El token expira a las:', new Date(tokenInfo.exp * 1000));
  }
}


cerrarSesion(){
  localStorage.removeItem('token');
  localStorage.removeItem('paramsGournet');
  localStorage.removeItem('token');
  localStorage.removeItem('afrodita');
  this.router.navigateByUrl('/general/login');
}



validarNivel(nivel, user){
  if (user.level > nivel){ 
    this._m.warning('Acceso reservado','Comunicate con el administrador por favor')
    this.router.navigateByUrl('/general/home');
  } else {
    return true;
  }

}



dateNow(tipo) {
  const fechaActual = new Date();

  const anio    = fechaActual.getFullYear();
  const mes     = this.padLeft(fechaActual.getMonth() + 1, 2);
  const dia     = this.padLeft(fechaActual.getDate(), 2);
  const hora    = this.padLeft(fechaActual.getHours(), 2);
  const minuto  = this.padLeft(fechaActual.getMinutes(), 2);
  const segundo = this.padLeft(fechaActual.getSeconds(), 2);

  const fechaSQLServer = `${anio}-${mes}-${dia} ${hora}:${minuto}:${segundo}`;

  if (tipo == 'completa'){
    return fechaSQLServer
  }

  if (tipo == 'dia'){
    const day = `${anio}-${mes}-${dia}`
    return day
  }
 
  if (tipo == 'hora'){
    const time = `${hora}:${minuto}:${segundo}`
    return time
  }
}

padLeft(value: number, length: number) {
  return String(value).padStart(length, '0');
}





formatoFechaSql(fecha){
const fechaOriginal = new Date(fecha);
const year = fechaOriginal.getFullYear();
const month = String(fechaOriginal.getMonth() + 1).padStart(2, "0"); // Se suma 1 al mes, ya que los meses en JavaScript son base 0.
const day = String(fechaOriginal.getDate()).padStart(2, "0");

// Paso 3: Crear la cadena de fecha en el formato deseado (YYYY-MM-DD)
const fechaFormateada = `${year}-${month}-${day}`;
return fechaFormateada;
}




formatAmount(amount:any): string {
  const parsedAmount = parseFloat(amount);
  
  if (isNaN(parsedAmount) || !(/^\d+(\.\d{1,2})?$/.test(amount))) {
    return '0';
  }
    return parsedAmount.toLocaleString('es-CL');
  // return parsedAmount.toLocaleString('en-US', { minimumFractionDigits: 2 }); // SI quisiera tener decimales

  
}


parseFormattedAmount(value: string): number {
  if (value == ''){
    return 0;
  }
  return +value.replace(/\D/g, ''); // Elimina los separadores y convierte a número
}


formatoRut(rut){
  
  const rutLimpio   = rut.replace(/[^0-9kK]/g, ''); // Eliminar caracteres no numéricos y la k/K final si existe
  console.log('rutlimpio', rutLimpio)
  let rutFormateado = '';

 if (rutLimpio.length < 8){
   rutFormateado = rutLimpio;
 }

 if(rutLimpio.length == 8){
   rutFormateado = rutLimpio.substring(0,1) + '.' + rutLimpio.substring(1,4) + '.' + rutLimpio.substring(4,7) + '-' + rutLimpio[rutLimpio.length - 1];
 }

 if(rutLimpio.length == 9){
   rutFormateado = rutLimpio.substring(0,2) + '.' + rutLimpio.substring(2,5) + '.' + rutLimpio.substring(5,8) + '-' + rutLimpio[rutLimpio.length - 1];
 }

 if(rutLimpio.length > 9){
   rutFormateado = rut.slice(0,-1);
 }
 
console.log('rut Formateado', rutFormateado);
 // Actualizamos el valor del campo de texto
 return rutFormateado;
}




validarRut(rut){
  const rutLimpio         = rut.replace(/[^0-9kK]/g, ''); // Eliminar caracteres no numéricos y la k/K final si existe
  const codigoVerificador = rutLimpio[rutLimpio.length - 1];  

  const rutInvertido      = rutLimpio.slice(0,-1).split('').reverse();

  let sumatoria = 0;

  let contador = 2;
  for (let n of rutInvertido){
    if (contador > 7){
      contador = 2;
    };
    const r = Number(n) * contador;
    contador ++
    sumatoria += r;
  }


  const div11             = sumatoria / 11;
  const por11             = Math.trunc(div11) * 11;
  let comparador:any      = 11 - (sumatoria - por11);
  comparador              = comparador.toString();

  if (comparador == '11' &&  codigoVerificador == '0' ){
    return true;
  }
 
  if (comparador == '10' &&  codigoVerificador == 'K' ){
    return true;
  }
  
  if (comparador == codigoVerificador ){
    return true;
  } else {
    return false;
  }

}

imInDev(){
  const currentUrl = window.location.href;
  console.log('current', currentUrl);
  if (currentUrl.includes('localhost')) {
    console.log('La URL contiene "localhost".');
    return true
  } else {
    console.log('La URL no contiene "localhost".');
    return false
  }
}

}
