import { Component, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { clientModel } from 'src/app/models/data/client.model';
import { paramsModel } from 'src/app/models/general/params.model';
import { DataService } from 'src/app/services/data.service';
import { MensajesService } from 'src/app/services/mensajes.service';
import { SalesService } from 'src/app/services/sales.service';
import { UserService } from 'src/app/services/user.service';
import { ProductStore } from 'src/app/storage/products.store';
// declare var bootstrap: any;

@Component({
  selector: 'app-ventas',
  templateUrl: './ventas.component.html',
  styleUrls: ['./ventas.component.css']
})
export class VentasComponent {
  loading                  = true;
  loadingHistorial         = false;
  loadingDetalle           = true;
  searchString             = '';
  user:any                 = {};
  params                   = new paramsModel();
  stores:any[]             = [];
  cajasAll:any[]           = [];
  cajas:any[]              = [];
  docTypes:any[]           = [];
  payTypes:any[]           = [];

  store:any                = {};
  ids:any[]                = [];

  date                     = new Date();
  firstDay                 = new Date(this.date.getFullYear(), this.date.getMonth(), 1).toString();
  lastDay                  = new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0).toString();

  ventas                   = [];
  venta:any                = {};
  client:any               = {};
  detalleVenta             = [];
  
  users                    = [];
  payments                 = [];



  cifras = {
    cantidad:0,
    totalVentas: 0,
    pagadas:{total: 0,cantidad:0},
    impagas:{total: 0,cantidad:0},
    anuladas:{total: 0,cantidad:0},
    nc:{total:0,cantidad:0}
  }

  saleChannels             = [{id:1 , name:'SALON'},{id:2 , name:'VENTA_DIRECTA'},{id:3 , name:'DELIVERY'},{id:4 , name:'LOCAL'}]
                     
  constructor(private _user:UserService, 
              private conexData:DataService,
              private conexSales: SalesService,
              private _m: MensajesService,
              private productStore: ProductStore,
              private router: Router,
              private elementRef: ElementRef){

                this.user = this._user.userInfo();

                if (localStorage.getItem('paramsGournet')){
                  this.params = JSON.parse(localStorage.getItem('paramsGournet'))
                }
                this.firstDay = this._user.formatoFechaSql(this.firstDay);
                this.lastDay  = this._user.formatoFechaSql(this.lastDay);

                this.getUsers();
                this.getDoctypes();
                this.getStores();

  }


  // ngAfterViewInit(): void {
  //   const popoverTriggerList = [].slice.call(this.elementRef.nativeElement.querySelectorAll('[data-bs-toggle="popover"]'));
  //   popoverTriggerList.map((popoverTriggerEl: any) => {
  //     return new bootstrap.Popover(popoverTriggerEl);
  //   });
  // }



  getDoctypes(){
    const state          = this.productStore.getValue();
    console.log('state', state);

    this.docTypes        = state.docs;
    this.payTypes        = state.payTypes;

  }

  getUsers(){
    this.conexData.getData(`/data/users`).subscribe({
      next: (data: any) => {
       console.log('users', data);
       this.users = data;
      },
      error: (error) => {
        console.error(error);
        this._m.error('Error conecntado', 'No se pudo traer la data');

      }
    })
  }

  getStores(){
    this.conexData.getData('/data/stores').subscribe( resp =>{
      console.log('resp stores', resp);
      this.stores            = resp;
      if (this.stores.length < 1){
        this._m.error('error', 'Comunicate con Gour-net por favor');
        this.loading = false;
        return;
      } 

      if (this.params.store.id > 0){
          const existe = this.stores.find( s => s.id == this.params.store.id);
          if (existe){
            this.store = existe;
          } 
      } else {
          this.store = this.stores.find( s => s.id == this.user.storeId);
      }

        console.log('stores', this.stores);
        this.ids = [];
        for (let s of this.stores){
          this.ids.push(s.id);
        }
        this.getCajas(this.ids);
    })
  }



  getCajas(ids){
    this.cajasAll        = [];
    this.conexData.getData(`/data/cashiers/${ids}`).subscribe( (resp:any) => {
      console.log('resp', resp);
      if (resp == null){
        console.log('es null, no han creado cajas aún')
        this.loading = false;
      } else {
        this.cajasAll         = resp;
        console.log('cajasAll', this.cajasAll);
        // return
        this.onChangeStore();
      }
      });
  
    }

    


    onChangeStore(){
      console.log('cambie la tienda', this.store)
      if (this.store == 'Todos'){
        console.log('escogio todas las tiendas')
          this.cajas = this.cajasAll;
      } else {
        this.cajas = this.cajasAll.filter( c => c.storeId == this.store.id);
      }

      console.log('cajas', this.cajas);
      this.filtrar();
    }


    filtrar(){
      let ids = this.ids;
      console.log('filtrar');
      if (this.store != 'Todos'){
        ids = this.store.id;
      }

      const last_con_hora = this.lastDay + ' 23:59:59.999';

      this.conexSales.traeDatos(`/stats/headByDate/${ids}/${this.firstDay}/${last_con_hora}`).subscribe({
        next: (data: any) => {

          if (!data){
            this.ventas = [];
          } else {
            this.ventas = data;
          }

          this.calcular();
        },
        error: (error) => {
          console.error(error);
          this._m.error('Error conecntado', 'No se pudo traer la data');

        }
      });
    }


    calcular(){
      this.limpiarCifras();
      if (this.ventas.length < 1){
        this.loadingHistorial = false;
        return;
      } 
      for (let v of this.ventas){


        if (v.docType == 'VNCE' || v.docType == 'VNCM'){
          console.log('v',v);
          if (v.status == 2 ){
            this.cifras.nc.total += v.total;
            this.cifras.nc.cantidad ++
          }
        } else {
          this.cifras.cantidad ++;
          this.cifras.totalVentas += v.total;
          if (v.status == 1 ){
            this.cifras.impagas.total += v.total;
            this.cifras.impagas.cantidad ++
          }
          if (v.status == 2 ){
            this.cifras.pagadas.total += v.total;
            this.cifras.pagadas.cantidad ++
          }
          if (v.status == 3 ){
            this.cifras.anuladas.total += v.total;
            this.cifras.anuladas.cantidad ++
          }
        
        }

        
        const canal  = this.saleChannels.find( c => c.id == v.saleChannelId );
        v.canal      = canal.name;
        const doc    = this.docTypes.find( d => d.code == v.docType);
        v.docName    = doc.name;
        const tienda = this.stores.find( s => s.id == v.storeId);
        v.storeName  = tienda.name;
        const caja   = this.cajasAll.find( ca => ca.id == v.cashierId);
        v.cajaName   = caja.name;
      }
      console.log('cifras', this.cifras);
      console.log('ventas', this.ventas);

      this.loadingHistorial = false;
    }



    selectSale(v){
      console.log('v',v);
      this.loadingDetalle = true;
      this.venta          = v;


      if (this.venta.clientId != 0){
        this.client = new clientModel();
        this.getClient(this.venta.clientId);
      }
      this.getPayments(v.id);
      this.conexSales.traeDatos(`/stats/detSaleByHead/${v.id}`).subscribe({
        next: (data: any) => {
          console.log('detalle', data);
          this.detalleVenta   = data;
          this.loadingDetalle = false;

        },
        error: (error) => {
          console.error(error);
          this._m.error('Error conectando', 'No se pudo traer la data');
          this.loadingDetalle = false;
        }
      });
    }

    getPayments(id){
      this.payments = [];
      this.conexSales.traeDatos(`/sales/paymentByHead/${id}`).subscribe({
        next: (data: any) => {
          console.log('pagos', data);
          if (!data){
            console.log('no hay pagos');
            return;
          }
          for (let p of data){
            p.seller   = this.users.find ( u => u.id == p.sellerId);
            p.tipoPago = this.payTypes.find ( pay => pay.code == p.payType);
            this.payments.push(p);
          }
          console.log('payments', this.payments);
        },
        error: (error) => {
          console.error(error);
          this._m.error('Error conectando', 'No se pudo traer la data');
        }
      });
    }


    getClient(id:any){
      this.conexSales.traeDatos(`/client/${id}`).subscribe({
        next:(resp:any) => { 
          console.log('resp cliente', resp);
          this.client = resp[0];

      },
        error:(err:any) => { console.log('error', err) },
      })
    }



    abrirEnCaja(v){
      let tipo = 'directa';
      
      if (v.saleChannelId == 1){
        tipo = 'salon';
      }

      this.router.navigateByUrl(`/cajas/caja/${tipo}/${v.id}`);
    }


    hacerNC(v){
      console.log('anular', v);
      setTimeout(() => {
        this.router.navigateByUrl(`/cajas/nc/${v.id}`);
      }, 300);
    }

    buscarCliente(value:any){
      console.log('buscar Cliente', value);
      this.conexSales.traeDatos(`/client/${value}`).subscribe({
        next:(resp:any) => { 
          console.log('resp cliente', resp);
         const client = resp[0];
          this._m.toastNeutro(`${client.firstName} ${client.lastName}` );
      },
        error:(err:any) => { console.log('error', err) },
      })
    }


    limpiarCifras(){
      this.cifras = {
        cantidad:0,
        totalVentas: 0,
        pagadas:{total: 0,cantidad:0},
        impagas:{total: 0,cantidad:0},
        anuladas:{total: 0,cantidad:0},
        nc:{total:0,cantidad:0}

      }
    }
}
