export class detmenuModel {
    id:number;
    fatherId: string;
    productId: string;
    pageOrder: number;
    pageName:string;
    price: number;
    quantity: number;
    notCount: number;
    mandatory: number;
    position: number;
    status: number;
    created_at: string;
    productName: string;

constructor() {
    this.id                 = 0;
    this.fatherId           = '';
    this.productId          = '';
    this.pageOrder          = 0;
    this.pageName           = ''; // nombre de la seccion
    this.price              = 0;
    this.quantity           = 1; // cuantos items son obligatorios. 
    this.notCount           = 0; // no se considera cuando se pincha como para pasar de seccion
    this.mandatory          = 0; 
    this.position           = 0;
    this.status             = 0;
    this.created_at         = '';
    this.productName        = '';

    }
}


