<app-loading *ngIf="loading"></app-loading>


<div class="row header azul r0">
    <div class="col-11">
       <h2 class="animate__animated animate__fadeIn">  <i class="fa-solid fa-cash-register"></i> Cajas</h2>
    </div>
    <div class="col-1 text-right hvr-icon-bounce">
        <h2><i class="fa-solid fa-circle-question hvr-icon pointer" (click)="mensajes.info('Cajas', 'Las cajas corresponden a donde se recibe el dinero. Un local puede tener uno o más cajas. A tener en cuenta que el cierre o arqueo de caja debe hacerse por separado en cada caja.')"></i></h2>
    </div>
</div>
<div class="row headMenu animate__animated animate__fadeIn r0">
    <div class="col-6 pad20 hvr-forward">
        <p class=" botonMenu animate__animated animate__pulse" data-bs-toggle="offcanvas" data-disableScrolling="false" href="#menuData" role="button" aria-controls="menuData"> < Menu Data </p>
    </div>
    <div class="col-5 text-end " style="padding:30px 20px 0 0 "> 
        <!-- <button *ngIf="grupos.length > 0" class="btn btn-outline-primary animate__animated animate__heartBeat animate__infinite animate__slower" routerLink="/data/producto/new">Crear Producto</button> -->
    </div>
</div> 

<div *ngIf="stores.length > 1" class="row r0">
    <div class="col-10 offset-1  col-lg-8 offset-lg-2 opciones shadow">
        <label>Selecciona el local</label>
        <select [(ngModel)]="selectedStore" (change)="onStoreChange()"  class="form-select">
            <option *ngFor="let store of stores" [ngValue]="store">
              {{ store.name }}
            </option>
          </select>
    </div>
</div>



<div *ngIf="modalEdit" class="row r0">
    <div class="col-10 offset-1  col-lg-8 offset-lg-2 opciones shadow animate__animated animate__flipInX">
       
        <div class="row">
            <div class="col-10 col-md-11">
                <h5>Editar Caja</h5>
            </div>
            <div class="col-2 col-md-1 text-right hvr-icon-rotate cruz">
                <i class="fas fa-times hvr-icon" (click)="modalEdit = false"></i>
            </div>
        </div>


        <div class="row">
            <div *ngIf="caja.id > 0" class="col-6 col-md-8">
                <input type="text" class="form-control" [(ngModel)]="caja.name" >
            </div>
            <div *ngIf="caja.id > 0" class="col-6 col-md-4">
                <button class="btn btn-success" (click)="guardarCaja(caja)">Guardar</button>
            </div>

        </div>
    </div>
</div>

<div *ngIf="modalNuevo"  class="row r0">
     <div class="col-10 offset-1  col-lg-8 offset-lg-2 listado shadow animate__animated animate__flipInX">
        
            <div class="row">
                <div class="col-10 col-md-11">
                    <h5>Nueva Caja</h5>
                </div>
                <div class="col-2 col-md-1 text-right hvr-icon-rotate cruz">
                    <i class="fas fa-times hvr-icon" (click)="modalNuevo = false"></i>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-8">
                <input type="text" class="form-control"  placeholder="Escribe aqui" [(ngModel)]="newCaja.name" >
            </div>
            <div class="col-12 col-md-4">
                    <button class="btn btn-success" style="margin-right: 10px;" (click)="guardarCaja(newCaja)">Guardar</button>
             </div>
           </div>
    </div>
</div>


<div class="row r0">
    <div class="col-10 offset-1 col-lg-8 offset-lg-2 listado shadow">
        <table class="table table-hover">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Nombre</th>
              </tr>
            </thead>
            <tbody *ngIf="cajas.length > 0">
              <tr *ngFor="let c of cajas ; index as i " class="pointer" (click)="selectCaja(c)">
                <th scope="row">{{i + 1}}</th>
                <td >{{c.name}}</td>
              </tr>
            </tbody>
            
            <p *ngIf="cajas.length < 1">No hay grupos para este filtro</p>
        </table>
        <button  class="btn btn-outline-primary w-100" (click)="nuevo()">Nueva Caja</button>

    </div>
</div>






<app-menu-data></app-menu-data>
