<app-loading *ngIf="loading"></app-loading>


<div class="row header azul r0">
    <div class="col-11">
       <h2 class="animate__animated animate__fadeIn" (click)="info()"> <i class="fa-regular fa-hand-pointer"></i> Pantallas  <span> o Botoneras</span></h2>
       <p class="botonMenu animate__animated animate__pulse" style="color:white;margin:10px 0 0 0" data-bs-toggle="offcanvas" data-disableScrolling="false" href="#menuData" role="button" aria-controls="menuData"> < Menu Data </p>
    </div>
    <div class="col-1 text-right hvr-icon-bounce">
        <h2><i class="fa-solid fa-circle-question hvr-icon pointer" (click)="mensajes.info('Pantallas', 'Las pantallas son botones que contienen productos.
            Por ejemplo si quiero digitar el producto |Paila de huevos| probablemente esté dentro de un botón |Desayunos| que agrupe todos los productos de ese tipo. 
            Las pantallas pueden a su vez tener sub pantallas. Por ejemplo: si el local maneja muchos vinos puedo ordenarlos como Pantalla Principal |Vinos| y subpantallas |Tintos| , |Blancos| ')"></i></h2>
    </div>
</div>


<div *ngIf="!loading" class="row negro text-light r0 " style="padding:20px ">
    <div class="col-12 col-md-4 col-lg-3 animate__animated animate__flipInX">
        <label>Tipo de venta</label>
          <select [(ngModel)]="selectedChannel" (change)="onChannelChange()"  class="form-select">
            <option *ngFor="let channel of saleChannels" [ngValue]="channel">
              {{ channel.name }}
            </option>
          </select>
        </div>
    <div class="col-12 col-md-4 col-lg-3 animate__animated animate__flipInX">
        <label>Local</label>
        <select [(ngModel)]="selectedStore" (change)="onStoreChange()"  class="form-select">
            <option *ngFor="let store of stores" [ngValue]="store">
              {{ store.name }}
            </option>
          </select>
    </div>
</div>


<div *ngIf="!loading2"class="row contenedor r0">

    <div class="col-12 col-md-5 amarillo principales shadow animate__animated animate__fadeIn">
        <h1>Pantallas Principales</h1>
        <div class=""
            cdkDropList 
            #productosList = "cdkDropList"
            [cdkDropListData]="pantallas"
            [cdkDropListConnectedTo]="[editList, deleteList, submenuButton]"
            (cdkDropListDropped)="drop($event, 'ordernar')"
            (click)="dragging()">
            
            <div class="example-box row boton shadow" *ngFor="let p of pantallas; index as i" cdkDrag>
                <div class="col-6 col-md-9">
                    {{p.position}} - {{p.name}}</div>
                <div class="col-2 col-md-1 hvr-icon-grow-rotate" data-bs-toggle="offcanvas" data-disableScrolling="false" data-bs-target="#editaPantalla" aria-controls="editaPantalla" (click)="edit(p)">
                    <i class="fa-solid fa-pen pointer hvr-icon icono"></i>       
                </div>
                <div class="col-2 col-md-1 hvr-icon-grow-rotate" (click)="verSubMenu(p)">
                    <i class="fa-regular fa-folder-open pointer hvr-icon icono"></i>     
                </div>
                <div class="col-2 col-md-1 hvr-icon-grow-rotate"  (click)="this.modalBorrar = !this.modalBorrar">  
                    <i class="fa-solid fa-trash-can pointer hvr-icon icono"></i>    
                </div>
             </div>   
        </div>



        <div class="row boton2" data-bs-toggle="offcanvas" data-disableScrolling="false" data-bs-target="#nuevaPantalla" aria-controls="nuevaPantalla" (click)="isSecondary = 0">
            <div class="col-12">
                <p >Agregar Pantalla</p>
            </div>
        </div>

        <div [ngClass]="modalBorrar ? 'animate__animated animate__slideInUp' : 'animate__animated animate__slideOutDown' " class="fixed-bottom delete "
                cdkDropList
                #deleteList = "cdkDropList"
                [cdkDropListConnectedTo]="[productosList]"
                (cdkDropListDropped)="drop($event, 'borrar')">
                <div class=" text-center py-2">
                    <h1>  <i class="fa-solid fa-trash-can"></i> </h1>
                    <p> Arrastra aqui la pantalla que quieres borrar </p>
                </div>
          </div>
    </div>

    <div  *ngIf="modalSubpantallas"  class="col-12 col-md-5 offset-md-2  verde50 shadow modalSubpantalla animate__animated animate__fadeInRight">
        <div class="row">
            <div class="col-10">
                <h1>SubPantallas de</h1>
            </div>
            <div class="col-2 hvr-icon-rotate" (click)="cerrarSubMenu()">
                <i class="fa-solid fa-xmark fa-2x pt-1 hvr-icon pointer" ></i>
            </div>
        </div>
        
        <h2>{{selectedConfig.name}}</h2>


        <div class=""
        cdkDropList 
        #subList = "cdkDropList"
        [cdkDropListData]="subpantallas"
        [cdkDropListConnectedTo]="[deleteList]"
        (cdkDropListDropped)="drop($event, 'ordernar')"
        (click)="dragging()">
        
        <div class="example-box row boton shadow" *ngFor="let sub of subpantallas; index as i" cdkDrag>
            <div class="col-6 col-md-10">
                {{sub.position}} - {{sub.name}}</div>
            <div class="col-2 col-md-1 hvr-icon-grow-rotate" data-bs-toggle="offcanvas" data-disableScrolling="false" data-bs-target="#editaPantalla" aria-controls="editaPantalla" (click)="edit(sub)">
                <i class="fa-solid fa-pen pointer hvr-icon icono"></i>       
            </div>
            <div class="col-2 col-md-1 hvr-icon-grow-rotate"  (click)="this.modalBorrar = !this.modalBorrar">  
                <i class="fa-solid fa-trash-can pointer hvr-icon icono"></i>    
            </div>
         </div>   
    </div>




  
        <div class="row boton2" data-bs-toggle="offcanvas" data-disableScrolling="false" data-bs-target="#nuevaPantalla" aria-controls="nuevaPantalla" (click)="isSecondary = 1">
            <div class="col-12">
                <p >Agregar Sub Pantalla</p>
            </div>
        </div>

    </div>


    <!-- botones con drop -->
    <!-- <div [ngClass]="modalSubpantallas ? 'col-4 offset-0 col-md-2 offset-md-2' : 'col-4 col-md-2 offset-md-6' "  class="animate__animated animate__slideInRight" style="padding:0">
        <div class="row r0 ">
            
            <div class="col-12" class="caja ">
                <div class="dropArea add toRight"
                cdkDropList
                #submenuButton = "cdkDropList"
                [cdkDropListConnectedTo]="[productosList]"
                (cdkDropListDropped)="drop($event, 'submenu')">
                    <h2><i class="fa-regular fa-folder-open"></i></h2>
                    <p>Arrastra aqui <br> para agregar  <br> un submenu</p>
                </div>
            </div>
       
            <div class="col-12 " class="caja">
                <div class="dropArea edit toRight"
                cdkDropList
                #editList = "cdkDropList"
                [cdkDropListConnectedTo]="[productosList]"
                (cdkDropListDropped)="drop($event, 'edit')">
                <h2><i class="fa-solid fa-pen"></i></h2>
                <p>Arrastra aqui <br> para editar <br> la pantalla</p>
                </div>
            </div>
       
            <div class="col-12" class="caja">
                <div class="dropArea delete toRight"
                cdkDropList
                #deleteList = "cdkDropList"
                [cdkDropListConnectedTo]="[productosList]"
                (cdkDropListDropped)="drop($event, 'borrar')">
                       <h2><i class="fa-solid fa-trash-can"></i></h2>
                    <p>Arrastra aqui <br> para eliminar  <br> la pantalla</p>
                </div>
            </div>

        </div>
    </div> -->

  
    
</div>




<!-- CREAR PANTALLA -->
 

<div class="offcanvas offcanvas-start" tabindex="-1" id="nuevaPantalla" aria-labelledby="nuevaPantallaLabel">
    <div class="offcanvas-header">
      <h5 class="offcanvas-title" id="nuevaPantallaLabel">Pantallas</h5>
      <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    
    <div class="offcanvas-body">
        

        <div class="row" style="margin-bottom:20px">
            <div class="col-12">
                <form (ngSubmit)="guardarPantalla(f)" #f="ngForm" class="animated fadeInLeft">

                    <div class="campo" style="margin-top:10px">
                        <label>Nueva Pantalla</label>
                        <span *ngIf="f.submitted && f.status == 'INVALID' " class="text-danger animated fadeIn"> La pantalla debe tener más de 3 caracteres</span>
                        <input class="form-control" type="text" name="setName"  ngModel required minlength="3"  maxlength="99" placeholder="Grupo">
                    </div>

                    <button class="btn w-100 btn btn-primary mt-3" type="submit">
                        Guardar
                    </button>
            
                </form>
            </div>
        </div>

        <div *ngIf="sets.length > 0 " class="row">
            <hr>
            <div class="col-12">
                <h5>Tus Pantallas</h5>
                <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Nombre</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let s of sets; index as i" (click)="selectPantalla(s)" data-bs-dismiss="offcanvas" aria-label="Close">
                        <th scope="row">{{ i + 1}}</th>
                        <td>{{s.name}}</td>
                      </tr>
                    </tbody>
                  </table>
            </div>
        </div>
    </div> 
  </div>

<!-- Edita pantalla -->

<div class="offcanvas offcanvas-top modalTop" tabindex="-1" id="editaPantalla" aria-labelledby="editaPantalla">
    <div class="offcanvas-header">
      <h5 class="offcanvas-title" id="editaPantalla">Editar Pantalla</h5>
      <p class="hvr-icon-rotate"><i class="fas fa-times text-light fa-2x pointer hvr-icon" data-bs-dismiss="offcanvas" aria-label="Close"></i>
    </div>
    
    <div class="offcanvas-body">
        <div class="row" style="margin-bottom:20px">
           
            <div class="col-10 offset-1 col-md-6 offset-md-3">
                <form (ngSubmit)="editarPantalla(f)" #f="ngForm" class="animated fadeInLeft">

                    <div class="campo" style="margin-top:10px">
                        <label>Editar Pantalla</label>
                        <span *ngIf="f.submitted && f.status == 'INVALID' " class="text-danger animated fadeIn"> La pantalla debe tener más de 3 caracteres</span>
                        <input class="form-control" type="text" name="setName"  [(ngModel)]="selectedSet.name" required minlength="3"  maxlength="99" placeholder="Grupo">
                    </div>

                    <button class="btn w-100 btn btn-primary mt-3" type="submit">
                        Guardar
                    </button>
            
                </form>
            </div>

        </div>
    </div> 

  </div>

  <app-menu-data></app-menu-data>
