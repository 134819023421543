import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { MensajesService } from 'src/app/services/mensajes.service';
import { ProductsQuery } from 'src/app/storage/products.query';
import { ProductStore } from 'src/app/storage/products.store';

@Component({
  selector: 'app-shared-combo',
  templateUrl: './shared-combo.component.html',
  styleUrls: ['./shared-combo.component.css']
})
export class SharedComboComponent {
  @Input() productFrom: any;

  @Output()
  enviarPadre: EventEmitter<object> = new EventEmitter<object>();
  
  loading                 = true;
  productosAll:any[]      = [];
  secciones               = [];
  seccion:any             = {};
  okGrabar                = false;

  modalModificador        = false;

  selectedProduct:any     = {};

  constructor(private conexData: DataService,
              public _m: MensajesService,
              private productsQuery: ProductsQuery){      
    }

    ngOnInit(): void {
      console.log('product aca', this.productFrom);
      this.getProductos();
      
    }


info(){
      console.log('productos', this.productosAll);
      console.log('secciones', this.secciones);
}
    

  getProductos(){
  this.productosAll = this.productsQuery.getAll();
  this.getDetalleMenu();
}
   
getDetalleMenu(){
  this.conexData.getData(`/data/productosMenu/${this.productFrom.id}`).subscribe({
    next:(data:any) =>{
      if (data){
        console.log('detalleMenu', data);
        this.armarMenu(data);
      } else {
        this.cerrar();
        this.loading = false;
      }
    },
    error:(err:any) =>{ console.log('err', err)}
  })
}

armarMenu(detalleMenu){
  for (let m of detalleMenu){
    const prod    = this.productosAll.find( p => p.id == m.productId);
    m.info        = prod;
    m.selected    = false;
    m.modifs      = [];

    const existe = this.secciones.find( sec => sec.pageOrder == m.pageOrder);
    if (existe){
      existe.productos.push(m);
    } else{
      const newSec = {pageName: m.pageName, pageOrder: m.pageOrder, quantity: m.quantity, productos:[m], selected:0}
      this.secciones.push(newSec);
    }
  }

  this.info();
  this.loading = false;
}


selectProd(s, p){
  console.log('s',s)
  console.log('p',p)
  this.seccion                = s;
  this.selectedProduct        = { ...p.info, seccion: s }

  // console.log('p',p);
  if (p.selected){
    this.seccion.selected --;
    p.selected = !p.selected;
  } else {
    if (this.seccion.selected == this.seccion.quantity){
      this._m.warning('No se puede escoger ese item','Ya escogiste el total de items para esta sección')
      return;
    }


    if (p.info.modifMenu == 1){
      console.log('tiene modif');
      this.modalModificador = true;
    } else {
      this.seccion.selected ++;
      p.selected = !p.selected;
    }

  }

  this.okGrabar = this.validarGrabar();
}

recibirModifs(values){
  console.log('Recibí', values)
  if (values.modifs.length < 1){ return};

  this.seccion          = values.seccion;
  this.seccion.selected ++;

  const p    = this.seccion.productos.find( prod => prod.productId == values.id);
  console.log('este', p);

  p.selected = !p.selected;
  p.modifs   = values.modifs;

  this.modalModificador = false;
}




validarGrabar(){
  let valido = true;
  console.log('aceptar', this.secciones);
  for (let s of this.secciones){
    if (s.selected != s.quantity){
      valido = false;
    }
  }
  return valido;
}



aceptar(){
  const ok = this.validarGrabar();
  if (!ok){
    console.log('faltan items por seleccionar');
  }

  const productFather = this.productFrom;
  for (let s of this.secciones){
    for (let p of s.productos){
      if (p.selected){
        const newModif = {
          productId: p.productId,
          price: p.price,
          name: p.info.name
        }
        productFather.modifs.push(newModif);
        if(p.modifs.length > 0){
            for (let m of p.modifs){
              const newModif2 = {
                productId: m.productId,
                price: m.price,
                name: m.name
              }
              productFather.modifs.push(newModif2);
            }
        }
      }

    }
  }
  console.log('product', productFather);
  this.enviarPadre.emit(productFather);

}

    cerrar(){
      console.log('cerrar');
      const body ={
        id:0
      }
  
      this.enviarPadre.emit(body);
    }


}
