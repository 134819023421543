import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { groupModel } from 'src/app/models/data/group.model';
import { DataService } from 'src/app/services/data.service';
import { MensajesService } from 'src/app/services/mensajes.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-grupos',
  templateUrl: './grupos.component.html',
  styleUrls: ['./grupos.component.css']
})
export class GruposComponent {

  loading                = true;
  greatGroups            = [];
  groupsAll              = [];
  groups                 = [];

  gg:any                 = {};
  group: any             = new groupModel();
  user:any               = {};
  memoria                = '';
  modalGrupo             = false;
  newGroup: any          = new groupModel();

  constructor(  private router: Router,
                private conexData: DataService,
                private _user:UserService,
                public mensajes: MensajesService) { 
                this.user      = this._user.userInfo();
                }

  ngOnInit(): void {
    this.getData();
  }

  getData(){
    forkJoin([
      this.conexData.getData('/data/greatGroups'),
      this.conexData.getData('/data/groups'),
    ]).subscribe({
      next: (data:any) => {
        console.log('data', data);
        this.groupsAll = data[1];
        this.filtrarGG(data[0])
      },
      error:  (error) => {
        console.error(error);
      }
    }
      
    
    );
  }

  filtrarGG(data){
    const newgg      = {id:0, name:'Todos', type:'CV', code: 'ALL'};
    this.gg          = newgg; 
    this.greatGroups = data;
    this.greatGroups.unshift(newgg);
    console.log('greatGroups', this.greatGroups);

    this.selectGG();

  }

  selectGG(){
    console.log('greatGroup');

    if( this.gg.id == 0){
      this.group = new groupModel();
      this.groups = this.groupsAll;
    } else {
      this.groups  = this.groupsAll.filter( g => g.greatGroupId == this.gg.id);
    }

    console.log('gg', this.gg);
    console.log('groupS', this.groups);
    console.log('este grupo', this.group);
    

  }


  selectG(g:any){
    this.memoria = g.name;
    this.group   = g;
    console.log('grupo', this.group);

    this.gg      = this.greatGroups.find( gran => gran.id == g.greatGroupId);
    this.groups  = this.groupsAll.filter( g => g.greatGroupId == this.gg.id);

    console.log('gg', this.gg);
  }

  updateGrupo(){
    console.log('grupo', this.group);
    if (this.group.name.length < 4){
      this.mensajes.error('Muy corto', 'nombre del grupo tiene que tener más de 3 caracteres')
      return;
    }

    const repetidos = this.groupsAll.filter( g => g.name == this.group.name);
    console.log('repetidos', repetidos);
    if (repetidos.length > 1){
      this.mensajes.error('Repetido', 'Ya existe un grupo con ese nombre')
      this.group.name = this.memoria;
      return;
    }

    this.updateGroup(this.group);

  }


  insertGrupo(){
    if (this.newGroup.name.length < 3){
      this.mensajes.error('Muy corto', 'El nombre del grupo debe ser mas largo.');
      return;
    }
  
    const repetido = this.groupsAll.find( g => g.name == this.newGroup.name);
    if (repetido){
      this.mensajes.error('Grupo Repetido', 'Ya existe un grupo con ese nombre');
      return;
    }

    this.newGroup.companyId    = this.user.companyId;
    this.newGroup.greatGroupId = this.gg.id;
    this.newGroup.code = this.generarCodeGroup(this.newGroup.name, 1);

    console.log('newGroup', this.newGroup);

    this.guardarGroup(this.newGroup);

    
  }

  // TERMINAR ESTE CICLO

  generarCodeGroup(name, contador){
   let newCode = name.toString().substring(0, 3).toUpperCase();
   console.log('new', newCode);

   const repetido = this.evaluarRepetido(newCode);
   if (!repetido){
    newCode = name.toString().substring(0, 2).toUpperCase() + contador.toString();
    console.log('repetido', 'probaré con este', newCode);
    this.generarCodeGroup(newCode, contador ++);
  } else {
    console.log('ya este si', newCode);
    return newCode
  }

  }
  

  evaluarRepetido(code){
    const existe = this.groupsAll.find( g => g.code == code);
    if (existe){
      return false;
    } else{
      return true;
    }
  }



  guardarGroup(group){
    const paquete = [group];
    this.conexData.guardarDato(`/postData/groups`, paquete).subscribe({ 
      next:(data:any) => {
        console.log('data', data);
        group.id = data.recordset[0].id;
        console.log('respuesta', group);
        this.groupsAll.push(group);

        this.selectGG()
        this.mensajes.ok('Ok', 'Grupo creado')
        this.modalGrupo = false;

      },
      error:(error) => {
        console.error(error);
      }
      });   
    }


  updateGroup(group){
    this.conexData.guardarDato(`/postData/updateGroup`, group).subscribe({
      next:(data:any) => {
        console.log('data', data);
        this.mensajes.ok('Ok', 'Grupo Actualizado')
      },
      error:(error) => {
        console.error(error);
      } 
    
  });  
  }

  
}
