      import { Component, Output, EventEmitter } from '@angular/core';
      import { forkJoin } from 'rxjs';
import { paramsModel } from 'src/app/models/general/params.model';
      import { DataService } from 'src/app/services/data.service';
      import { UserService } from 'src/app/services/user.service';
      import { ProductStore } from 'src/app/storage/products.store';
      
      @Component({
        selector: 'app-refresh-data',
        templateUrl: './refresh-data.component.html',
        styleUrls: ['./refresh-data.component.css']
      })
      export class RefreshDataComponent {
      
        @Output()
        enviarPadre: EventEmitter<object> = new EventEmitter<object>();
        
      
        user:any;
        loading             = true;
        params: paramsModel = new paramsModel();
        storeId: 0;
        constructor(private conexData:DataService,
                    private _user:UserService,
                    private productStore: ProductStore) { 

                    this.user = this._user.userInfo();
                    this.storeId = this.user.storeId;

                    console.log('user', this.user);
  
                    
                    if (localStorage.getItem('paramsGournet')){
                      this.params = JSON.parse(localStorage.getItem('paramsGournet'));
                      this.storeId = this.params.store.id
                    } 
                    
                    this.conexData.reload();
                    
                    this.getData();
        }
      
      // ====================================================================== //
      // ====================================================================== //
      // == Este componente descarga los datos necesarios para la venta     === // 
      // == y los guarda en memoria para evitar  tener que ir a cada rato   === //
      // == a la bd a buscar la misma info                                  === //
      // ====================================================================== //
      // ====================================================================== //
      
      // Faltaria hacer una logica para los productos con stock critico
      // Y otra para determinar que lista de precios es la que está activa.
      
      
        getData(){
          forkJoin([
            this.conexData.getData(`/data/products`),
            this.conexData.getData('/data/priceLists'),
            this.conexData.getData('/data/modificadores' ),
            this.conexData.getData(`/data/caja/pantallas/${this.storeId}/1`), //el 1 corresponde a venta Salon. eventualmente podrias ser venta directa etc.
            this.conexData.getData(`/data/caja/prodPosi`),
            this.conexData.getData('/data/caja/docTypes'),
            this.conexData.getData('/data/caja/payTypes'),
            this.conexData.getData('/data/stores' ),
            this.conexData.getData('/data/cashiersAll'),
            this.conexData.getData(`/integraciones/integraciones/${this.storeId}`),
            this.conexData.getData(`/company/${this.user.companyId}`),
            
          ]).subscribe({
            next: (data: any[]) => {
              console.log('data', data)
              this.productStore.set(data[0]);
              this.getPrices(data[1]);
              this.filtrarModificadores(data[0], data[2])
              this.productStore.updatePantallas(data[3]);
              this.productStore.updateProdposis(data[4]);
              this.productStore.getDocTypes(data[5]);
              this.productStore.getPayTypes(data[6]);
              this.productStore.getStores(data[7]);
              this.productStore.getCajas(data[8]);
              this.productStore.getIntegraciones(data[9]);
              this.productStore.getCompany(data[10]);
              /////
              const body = {status:200, mensaje:'ok Actualizado'}
              this.enviarPadre.emit(body);
            },
            error: (error) => {
              console.error(error);
              const body = {status:500, mensaje:'Error actualizando'}
              this.enviarPadre.emit(body);
            }
          });
        }
      
      
      getPrices(listas){
          // console.log('listas de precios', listas);
          
          const activa = listas.find( l=> l.name = 'Principal Venta');
          console.log('activa', activa);
        // ============ //
        // ============ //
        // ============ //
        // Tengo que hacer una funcion que vea que dia y hora es para determinar la lista
        // ============ //
        // ============ //
        // ============ //
          this.params.priceListId = activa.id;
          this.guardarParams();

          this.conexData.traeDatos(`/data/prices/${activa.id}`).subscribe( (resp:any) => {
            // console.log('precios', resp)
            this.productStore.updatePrices(resp);
      
           this.loading = false;
          });
        }
      
      
        filtrarModificadores(prods, modifs){
          const modificadores = [];
      
          // console.log('modifs', modifs);

          if(modifs){
            for (let m of modifs){
              const existe = prods.find( p => p.id == m.productId);
              if (existe){
                m.name = existe.name;
                modificadores.push(m);
              } else {
                // console.log('modif sin match', m);
              }
            }
          }

         
          this.productStore.updateModificadores(modificadores);
      
          // console.log('filtré modificadores', modificadores);
        }
        


      guardarParams(){
                localStorage.setItem('paramsGournet', JSON.stringify(this.params))
              }
      
      }
      
      
            // this.conexData.getData('/data/sets/' + this.user.storeId),
            // this.conexData.getData('/data/configset/' + this.user.storeId),
            // this.conexData.getData('/data/prodPosiByProduct/' + this.product.id),
            // this.conexData.getData('/data/productPrices/' + this.product.id),
            // this.conexData.getData('/data/saleChannel'),
            // this.conexData.getData('/data/configSet/' + this.selectedStore.id),
            // this.conexData.getData('/data/sets/' + this.selectedStore.id),