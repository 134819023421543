import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';
import { paramsModel } from 'src/app/models/general/params.model';
import { detSaleModel } from 'src/app/models/venta/detSale.model';
import { headSaleModel } from 'src/app/models/venta/headSale.model';
import { paymentModel } from 'src/app/models/venta/payment.model';
import { MensajesService } from 'src/app/services/mensajes.service';
import { SalesService } from 'src/app/services/sales.service';
import { UserService } from 'src/app/services/user.service';
import { ProductStore } from 'src/app/storage/products.store';

@Component({
  selector: 'app-nota-credito',
  templateUrl: './nota-credito.component.html',
  styleUrls: ['./nota-credito.component.css']
})
export class NotaCreditoComponent {

  loading                  = true;
  loadingNC                = true;
  loadingAnulaciones       = true;

  user:any                 = {};
  params                   = new paramsModel();
  headId                   = '';

  docTypes:any[]           = [];
  noteTypes:any[]          = [];
  payTypes:any[]           = [];

  headSale:any             = {};
  detalleVenta             = [];
  notasCreditos:any[]      = [];
  payments:any[]           = [];
  stores                   = [];
  cajas                    = [];
  saleChannels             = [{id:1 , name:'SALON'},{id:2 , name:'VENTA_DIRECTA'},{id:3 , name:'DELIVERY'},{id:4 , name:'LOCAL'}]
  cifras                   = {total:0, saldo:0, anulado:0}
  
  porAnular:any[]          = [];
  
  selectedDocType:any      = {};
  modalClientes            = false;
  detTemp                  = [];

  ids:any[]                =[];
  hayDescuento             = false;
  paquete = {
    headSale: {},
    detVenta: [],
    payments: [],
    cliente: {}
  }


  constructor(
    private _user:UserService, 
    private productStore: ProductStore,
    private conexSales: SalesService,
    private route: ActivatedRoute,
    private _m: MensajesService,
    // private conexData:DataService,
    // private router: Router
    ){

      this.user = this._user.userInfo();

      if (localStorage.getItem('paramsGournet')){
        this.params = JSON.parse(localStorage.getItem('paramsGournet'))
      }

      this.headId       = this.route.snapshot.paramMap.get('headId');

      this.getDoctypes();
      this.getData();
}


getDoctypes(){
  const state          = this.productStore.getValue();
  this.noteTypes       = state.docs.filter( doc => doc.code == 'VNCE' || doc.code == 'VNCM'  );
  this.docTypes        = state.docs;
  this.payTypes        = state.payTypes;
  this.stores          = state.stores;
  this.cajas           = state.cajas

  this.selectedDocType   = this.docTypes.find( d => d.code == 'VNCE');
}


changeDocType(){
  console.log('aca', this.selectedDocType);
  }

getData(){
  forkJoin([
    this.conexSales.traeDatos(`/sales/headById/${this.headId}`),
    this.conexSales.traeDatos(`/stats/detSaleByHead/${this.headId}`),
    this.conexSales.traeDatos(`/sales/paymentByHead/${this.headId}`)
  ]).subscribe({ 
    next: (data:any) => {
      this.getSale(data[0][0]);
      this.preDetalle(data[1]) ;
      this.payments = data[2];
      this.loading = false;
    },
    error: (error) => {
      console.error(error);
    }
  });
}





getSale(v){
  const canal         = this.saleChannels.find( c => c.id == v.saleChannelId );
        v.canal       = canal.name;
        const doc     = this.docTypes.find( d => d.code == v.docType);
        v.docName     = doc.name;
        const tienda  = this.stores.find( s => s.id == v.storeId);
        v.storeName   = tienda.name;
        const caja    = this.cajas.find( ca => ca.id == v.cashierId);
        v.cajaName    = caja.name;
        this.headSale = v;
}


preDetalle(data){
  this.ids    = [];
  const items = data.sort( (a:any,b:any)=> a.numel - b.numel);

  for (let d of items){
    console.log('item',d);

      this.ids.push(d.id);
      d.anulado         = false;
      d.Qanulada        = 0;
      d.Qmemory         = 0;
      d.porAnular       = 0;
      this.cifras.total += d.total;
      const esmodif     = this.esModif(d.numel);
      if(d.bruto < 0){
        d.anulado         = true;
        this.hayDescuento = true;
      }
    if(esmodif){
      const existe      = this.detalleVenta.find( item => item.numel == Math.floor(d.numel));
      existe.modifs.push(d);
    } else {
      d.modifs = [];
      this.detalleVenta.push(d);
    }
  }


  this.getDetNC(this.ids);
}


// TRAER NOTAS DE CREDITO
// TRAER NOTAS DE CREDITO
// TRAER NOTAS DE CREDITO
// TRAER NOTAS DE CREDITO
// TRAER NOTAS DE CREDITO
// TRAER NOTAS DE CREDITO

getDetNC(ids){
  this.loadingNC     = true;
  this.notasCreditos = [];
  this.detTemp       = [];

  // con el detalle de las notas de credito puedo traerme los headId;
  this.conexSales.guardarDato(`/sales/detNC`, ids).subscribe({ 
    next: (data:any)=>{
      console.log('detalles de notas de credito', data);
      
      if (!data){
        this.loading       = false;
        this.loadingNC     = true;
        return;
      }

      if (data.length > 0){
        this.detTemp      = data;
        const headIs      = [];
        for (let d of data ){ //consulto la info de cada nota de credito asociada a esa venta
              if (!headIs.includes(d.headId)) {
                headIs.push(d.headId);
                this.getInfoNC(d.headId)
              }
        }
      } else {
        this.loading       = false;
        this.loadingNC     = true;
      }
    },
    error: (err:any)=>{console.log('error', err)}
  })

}

getInfoNC(id){
    this.loadingNC = true;
    forkJoin([
      this.conexSales.traeDatos(`/sales/headById/${id}`),
      this.conexSales.traeDatos(`/stats/detSaleByHead/${id}`),
      this.conexSales.traeDatos(`/sales/paymentByHead/${id}`)
    ]).subscribe({ 
      next: (data:any) => {
        console.log('INFO NOTA DE CREDITO', data);
        const nc    = data[0][0];
        nc.detalle  = this.ordenarDetalle(data[1]);
        nc.payments = data[2];
        this.notasCreditos.push(nc);

        console.log('notas de credito', this.notasCreditos);
        this.cifras.anulado += nc.total;
        this.loadingNC = false;
        this.info();
      },
      error: (error) => {
        console.error(error);
        this.loading = false;

      }
    });
}


ordenarDetalle(data){
    const detalleOrdenado   = []
    const items = data.sort( (a:any,b:any)=> a.numel - b.numel);

    for (let d of items){
      const esmodif = this.esModif(d.numel);
      if(esmodif){
        const existe = detalleOrdenado.find( item => item.numel == Math.floor(d.numel));
        existe.modifs.push(d);
      } else {
        d.modifs = [];
        detalleOrdenado.push(d);
      }
    }

    return detalleOrdenado;
  }





getDetalle(v){
  console.log('v',v);
}


info(){
  console.log('docTypes', this.docTypes);
  console.log('payTypes', this.payTypes);
  console.log('headSale', this.headSale);
  console.log('detalle', this.detalleVenta);
  console.log('payments', this.payments);
  console.log('cifras', this.cifras);
  console.log('notas de credito', this.notasCreditos);
}


esModif(n){
  return n % 1 !== 0;
}

selectAll(){
  for (let s of this.detalleVenta){
    
    if (s.anulado){
      continue
    };

    const cantXAnular = s.quantity - s.Qanulada;
    this.selectItem(s, cantXAnular);
  }
}





selectItem(item, cantidad){
  if(item.anulado){
    this._m.warning('Detalle ya anulado','')
    return;
  }

  item.Qanulada += cantidad
  item.porAnular += cantidad
  console.log('item', item);

  let dsctoGeneral = 0;


  if (this.hayDescuento){
    let dscto    = 0;
    let contador = 0;
    for (let d of this.detalleVenta){
      if (d.bruto < 0){
        dscto += d.bruto;
      } else {
        contador += d.quantity;
      }
    }

    dsctoGeneral = Math.round(dscto / contador) * -1;
    console.log('dsctoGeneral', dsctoGeneral, contador);
  }
  

    if(item.Qanulada > item.quantity){
      item.Qanulada  = item.Qmemory;
      item.porAnular = 0
      this.porAnular = this.porAnular.filter( a => a.idDocAnt !== item.id);
      console.log('por anular', this.porAnular);
      return;
    }

    const existe = this.porAnular.find( a => a.idDocAnt == item.id);
    if (existe){
      console.log('existe', existe);
      existe.quantity         = item.porAnular;
      existe.discount         = (item.discount / item.quantity) * existe.quantity + ( dsctoGeneral * existe.quantity )
      existe.bruto            = item.price * existe.quantity - existe.discount;
      existe.neto             = Math.round(existe.bruto / 1.19);
      existe.porIva           = existe.bruto - existe.neto;
      existe.discountPercent  = parseFloat(( existe.discount * 100 / existe.bruto ).toFixed(1));
      existe.total            = existe.bruto;

    } else {
      const newDetNc = {...item};
      console.log('newDetNc', newDetNc);
      newDetNc.id               = '';
      newDetNc.headId           = '';
      newDetNc.numel            = this.getNumel('father');
      newDetNc.idDocAnt         = item.id;
      newDetNc.esModif          = false;
      newDetNc.quantity         = item.porAnular;
      newDetNc.discount         = (item.discount / item.quantity) * newDetNc.quantity + ( dsctoGeneral * newDetNc.quantity )
      newDetNc.bruto            = item.price * newDetNc.quantity - newDetNc.discount;
      newDetNc.neto             = Math.round(newDetNc.bruto / 1.19);
      newDetNc.porIva           = newDetNc.bruto - newDetNc.neto;
      newDetNc.discountPercent  = parseFloat(( newDetNc.discount * 100 / newDetNc.bruto ).toFixed(1));
      newDetNc.total            =  newDetNc.bruto;


      this.porAnular.push(newDetNc);
      if (newDetNc.modifs.length > 0){
        for (let m of newDetNc.modifs){
          console.log('modif', m);
          const newModif = {...m};
          newModif.id             = '';
          newModif.headId         = '';
          newModif.numel          = this.getNumel('modif');
          newModif.idDocAnt       = m.id;
          newModif.esModif        = true
          newModif.quantity       = m.quantity;
          newModif.bruto          = m.price * m.quantity;
          newModif.neto           = Math.round(m.bruto / 1.19);
          newModif.IVA            = m.bruto - m.neto;
          newModif.total          = m.bruto
          this.porAnular.push(newModif);
        }
      } 
    }

    console.log('poranular', this.porAnular);
  
}




getNumel(tipo){
  let ultimo = 0;
  if (this.porAnular.length > 0){
    ultimo = (this.porAnular[this.porAnular.length - 1].numel)
  }

  if (tipo == 'father'){
    return Math.floor(ultimo + 1)
  } else {
    return ultimo + 0.01;
  }


}

getNumDoc(){
  this.loading = true;
  console.log('doctype', this.selectedDocType);

  this.conexSales.traeDatos(`/sales/lastDoc/${this.headSale.storeId}/${this.selectedDocType.code}`).subscribe({ 
    next: (data:any) => {
      console.log('numDoc', data);
      const nextDoc = data['lastDoc'] + 1;
      this.prepararNC(nextDoc);

    },
    error: (error) => {
      console.error(error);
      this.loading = false
      }
  });
}


evaluarPendientes(){
  let pendientes = false;;
  for (let n of this.detalleVenta){
    if (n.bruto < 0){continue};
    console.log('n',n);
    if (n.Qanulada < n.quantity){
      return true;
    }
    return pendientes;
  }

  
}

prepararNC(nextDoc){
  console.log('por anular', this.porAnular);
  let total     = 0;
  let discount  = 0;


 
  const pendientes = this.evaluarPendientes();
  console.log('hay pendientes?', pendientes);

  if (!pendientes && this.hayDescuento ){
    let suma = 0
    for (let n of this.notasCreditos){
      suma += n.total;
    }
    for (let p of this.porAnular){
      suma += p.total
    }

    console.log('pre total', suma);
    const diferencia = this.headSale.total - suma;
    console.log('diferencia', diferencia);
    if (diferencia > 0){
      console.log('este antes', this.porAnular[0]);
      this.porAnular[0].discount         -= diferencia;
      this.porAnular[0].bruto            =  this.porAnular[0].price * this.porAnular[0].quantity - this.porAnular[0].discount;
      this.porAnular[0].neto             = Math.round (this.porAnular[0].bruto / 1.19);
      this.porAnular[0].porIva           = this.porAnular[0].bruto - this.porAnular[0].neto;
      this.porAnular[0].discountPercent  = parseFloat(( this.porAnular[0].discount * 100 / this.porAnular[0].bruto ).toFixed(1));
      this.porAnular[0].total            = this.porAnular[0].bruto;
      console.log('este después', this.porAnular[0])
    }
  }



  for (let p of this.porAnular){
    total    += p.total;
    discount += p.discount;
  }

  // creo un headSale
  const newVenta         = {...this.headSale}
  newVenta.id            = '';
  newVenta.docType       = this.selectedDocType.code;
  newVenta.numDoc        = nextDoc;
  newVenta.realDate      = this._user.dateNow('dia');
  newVenta.endDate       = this._user.dateNow('dia');
  newVenta.openTime      = this._user.dateNow('hora');
  newVenta.closeTime     = this._user.dateNow('hora');
  newVenta.sellerId      = this.user.id;
  newVenta.neto          = Math.round(total / 1.19);
  newVenta.discount      = discount
  newVenta.iva           = total - newVenta.neto;
  newVenta.total         = total
  newVenta.abono         = total; // en venta directa se paga completa la venta
  newVenta.saldo         = 0;

  console.log('hacer NC', newVenta);

  // creo un pago
  const newPay        = new paymentModel();
  newPay.storeId      = this.headSale.storeId
  newPay.totalAmount  = newVenta.total;
  newPay.amountA      = newVenta.total // MONTO QUE VA DIRECTO A PAGAR LA VENTA
  newPay.amountP      = newVenta.total; // MONTO QUE PAGA LITERAL LA PLATA QUE ENTRA PUEDE IR INCLUIDA PROPINA Y VUELTO
  newPay.change       = 0;
  newPay.tip          = 0;
  newPay.status       = 2;
  newPay.cashierId    = this.headSale.cashierId;
  newPay.date         = this._user.dateNow('completa');
  newPay.clientId     = 0;
  newPay.shiftId      = this.headSale.shift;
  newPay.sellerId     = this.user.id;
  newPay.payType      = 'NC';
  newPay.payTypeName  = this.payTypes.find( p => p.code == 'NC').name


  this.paquete = {
    headSale: {newVenta},
    detVenta: this.porAnular,
    payments: [newPay],
    cliente: {}
  }


  console.log('paquete', this.paquete);

  this.loading = false;
}





recibirCliente(value){

  if (value.id == 0){
    this.modalClientes = false;
    return;
  }
  
  this.paquete.headSale['newVenta'].clientId   = value.id
  this.paquete.headSale['newVenta'].clientName = value.firstName + " " + value.lastName;

  console.log('paquete', this.paquete);
  this.paquete.cliente = value;
  this.modalClientes   = false;
}





guardarNC(){
  console.log('paquete', this.paquete);
  console.log('aca',this.paquete.headSale['clientId']);

  if( this.paquete.headSale['newVenta'].clientId == 0){
    this._m.warning('Falta asociar un cliente','Las notas de crédito deben tener siempre una persona o empersa asociada');
    return;
  }
  if( this.paquete.payments.length < 1 || this.paquete.headSale['newVenta'].total < 1 || this.paquete.detVenta.length < 1){
    this._m.warning('Faltan datos.','Refresca la pantalla e intentalo de nuevo. Si el problema continua ponte en contacto con Gour-net');
    return;
  }

  console.log('guardaré', this.paquete);


  this.loading = true;

  this.conexSales.guardarDato(`/postSales/newVenta`, this.paquete).subscribe({ 
    next: (data:any) => {
      console.log('data', data);
      this._m.ok('Ok', 'Guardado con éxito');
      this.getDetNC(this.ids);     
      this.limpiarData();

      this.loading = false;
    },
    error: (error) => {
      console.error(error);
      this._m.error('Ups', 'Se ha producido un error guardanda la venta' + error.error);
      this.loading = false    }
  });


}




evaluarAnulaciones(){
  if (this.notasCreditos.length < 1){
    this.loadingAnulaciones = false;
    return;
  } 

  for (let det of this.detalleVenta){
    det.Qanulada = 0;
  }


  for (let n of this.notasCreditos){
    for (let d of n.detalle){
      const existe = this.detalleVenta.find( vta => vta.id == d.idDocAnt);
      if (existe){
        existe.Qanulada += d.quantity;
        existe.Qmemory  = existe.Qanulada;
        if (existe.modifs.length > 0){
            for (let m of d.modifs){
              const existeModif = existe.modifs.find( mod => mod.id == m.idDocAnt);
              if (existeModif){
                existeModif.Qanulada = m.quantity;
              }
            }
        }

        if (existe.quantity == existe.Qanulada){
          existe.anulado = true;
        }
      }

    }
  }

  this.loadingAnulaciones = false;


}



limpiarData(){
    this.paquete = {
      headSale: {},
      detVenta: [],
      payments: [],
      cliente: {}
    }
    this.porAnular = [];
}




}
