<div class="row">
    <div class="col-12 bg-light cajaMensaje">
        
        <div class="row headerModal pad20 celeste shadow">
            <div class="col-10">
                <h2>Agregar Mensaje</h2>
            </div>
            <div class="col-2 hvr-icon-rotate">
                <i class="fas fa-times fa-2x hvr-icon pointer" (click)="cerrar()"></i>
            </div>
        </div>
     
     
        <div class="row escribir pad20">
            <div class="col-10 offset-1">
                <label>Escribe tu mensaje</label>
                <input type="text" class="form-control" maxlength="100" [(ngModel)]="msg.message">
            </div>
            <div class="col-10 offset-1">
                <button class="btn btn-primary w-100 mt-2" (click)="guardar()">Guardar</button>
            </div>
        </div>

        <hr>




        <div *ngIf="!loading && mensajes.length > 0" class="row mensajes" style="margin:0">
            <div *ngFor="let m of mensajes" class="col-12 col-md-3 hvr-shrink">
                <div class="msg shadow " (click)="selectMessage(m)" >
                    <p>{{m.message}}</p>
                </div>
            </div>
         </div>


    </div>
</div>