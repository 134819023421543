<div class="row azul  pad20 text-light">
    <div class="col-10">
        <h1>Productos</h1>
    </div>
    <div class="col-2 text-center hvr-icon-rotate">
        <i class="fas fa-times fa-2x text-light hvr-icon pointer" (click)="cerrar()"></i>
    </div>
</div>


<div class="row bg-light ">
  <div class="col-10 offset-1 ">
      
      <div class="row pad20 buscador caja text-dark shadow-sm  animate__animated animate__fadeIn">
          <div class="col-12 col-md-6  hvr-icon-rotate ">
              <p><i class="fa-solid fa-magnifying-glass hvr-icon"></i> Buscador</p>
              <input type="text" class="form-control" placeholder="Escribe el nombre del producto o su código" [(ngModel)]="searchString" >
          </div>
          <div class="col-12 col-md-6  hvr-icon-rotate">
              <p><i class="fa-solid fa-barcode hvr-icon"></i> Escanear</p>
              <input #codigoBarra type="text" class="form-control" (input)="escanear()" placeholder="Ingresa un dato">

            </div>
      </div>

        <div class="row mt-4 pad20 listado shadow-sm  animate__animated animate__fadeIn productos">
            <div class="col-12 table-responsive">
            <table class="table table-hover">
                <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Producto</th>
                    <th scope="col">Código</th>
                    <th scope="col">Código Técnico</th>
                    <th scope="col">price</th>
                    <!-- <th scope="col">Gran Grupo</th>
                    <th scope="col">Grupo</th> -->
                </tr>
                </thead>
                <tbody *ngIf="products.length > 0">
                <tr *ngFor="let p of products | filter : { 'name' : searchString, 'code' : searchString, 'codeTec' : searchString} ; index as i " class="pointer" (click)="selectProd(p)">
                    <th scope="row">{{i + 1}}</th>
                    <td >{{p.name}}</td>
                    <td >{{p.code}}</td>
                    <td >{{p.codeTec}}</td>
                    <td >{{p.price | separator :0}}</td>
                </tr>
                </tbody>
                <p *ngIf="products.length < 1">No hay productos para este filtro</p>
            </table>
            </div>
        </div>

        <div class="row pad20 cerrar salmon shadow-sm  animate__animated animate__slideInUp">
            <div class="col-12 mt-2">
                <p class="hvr-grow" (click)="cerrar()" >Cerrar</p>
              </div>
        </div>

    </div>
</div>




<div *ngIf="modalModificador"class="row fondoModal2 modificadores bg-dark  animate__animated animate__slideInRight animate__faster">
    <div class="col-12"> 
        <app-modificadores [productFrom]="selectedProduct" (enviarPadre)="recibirModifs($event)"></app-modificadores>
    </div>
</div>



<div *ngIf="modalCombo"class="row  fondoModal3 modificadores   animate__animated animate__fadeIn r0">
    <div class="col-12 col-md-6 offset-md-3"> 
        <app-shared-combo [productFrom]="selectedProduct" (enviarPadre)="recibirCombo($event)"></app-shared-combo>
       
    </div>
</div>


