<div class="row azul  pad20 text-light">
    <div class="col-11 col-md-8">
        <h1>Productos</h1>
    </div>
    <div class="col-3 d-none d-md-block text-end">
        <button class="btn btn-outline-light w-100" (click)="cerrar()"> <i class="fa-solid fa-arrow-left"></i> Volver</button>
    </div>
    <div class="col-1 text-center hvr-icon-rotate">
        <i class="fas fa-times fa-2x text-light hvr-icon pointer" (click)="cerrar()"></i>
    </div>
</div>


<div class="row bg-light ">
  
    <div class="col-4 col-md-4 izq shadow animate__animated animate__fadeIn">
        <div class="boton text-center rosado comensal" style="color:white" (click)="modifComensal() ">
            <h1><i class="fas fa-male"></i> {{comensal}}</h1>
            <p>Comensal</p>
        </div>
        <div class="boton text-center negro comensal" style="color:white" (click)="cerrar()">
            <p><i class="fa-solid fa-arrow-left"></i> Volver</p>
        </div>

        <div class="pantallas">
            
            <div *ngFor="let pantalla of sets" class="pantalla accordion">
                <div *ngIf="pantalla.secondary == 0" class="pointer" >
                    <div class="card-header" [id]="'heading' + pantalla.id" (click)="selectPantalla(pantalla)" [attr.data-bs-toggle]="'collapse'" [attr.data-bs-target]="'#cPantalla' + pantalla.id" aria-expanded="false" [attr.aria-controls]="'cPantalla' + pantalla.id">
                        <h2 class="mb-0">{{pantalla.name | titlecase}} </h2>
                    </div>

                    <div *ngIf="pantalla.subpantallas.length > 0" [id]="'cPantalla' + pantalla.id" class="collapse subpantallas" [attr.aria-labelledby]="'heading' + pantalla.id">
                        <div  *ngFor="let sub of pantalla.subpantallas" class="subpa" (click)="selectPantalla(sub)" >
                            <p>{{sub.name}}</p>
                        </div>
                    </div>
                </div>
            </div>
   
        </div>
    </div>
    <!-- FIN PANTALLAS -->
    <div class="col-8 productos">
        <div class="row">
            <div *ngFor="let p of products" class="col-6 col-md-4 text-center hvr-grow">
                <div class="btnProducto shadow animate__animated animate__flipInX position-relative pointer" (click)="selectProd(p)">
                    <span *ngIf="p.stockXsale >= 0" class="position-absolute top-0 end-0 badge bg-primary">{{p.stockXsale}}</span>
                    <p>{{p.name}}</p>
                    <h5>$ {{p.price | number}}</h5>
                </div>
            </div>
        </div>

  


    </div>
</div>



<div *ngIf="modalModificador"class="row fondoModal2 modificadores bg-dark  animate__animated animate__slideInRight">
    <div class="col-12"> 
        <app-modificadores [productFrom]="selectedProduct" (enviarPadre)="recibirModifs($event)"></app-modificadores>
    </div>
</div>




<div *ngIf="modalCombo"class="row  fondoModal3 modificadores   animate__animated animate__fadeIn r0">
    <div class="col-12 col-md-6 offset-md-3"> 
        <app-shared-combo [productFrom]="selectedProduct" (enviarPadre)="recibirCombo($event)"></app-shared-combo>
       
    </div>
</div>




