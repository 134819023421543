

<div class="offcanvas offcanvas-start bg-light" tabindex="-1" id="menuStats" aria-labelledby="menuStatsLabel">
    <div class="d-flex flex-column flex-shrink-0 p-3 " style="width: 100%;">
        <div class="row">
            <div class="col-10">
                <h1>Menu Informes y estadísticas</h1>
            </div>
            <div class="col-2 hvr-icon-rotate">
                <i class="fas fa-times fa-2x pointer hvr-icon" data-bs-dismiss="offcanvas" aria-label="Close"></i>
            </div> 
        </div>
        <hr>
        <ul class="nav nav-pills flex-column mb-auto">
          <li class="nav-item">
            <a routerLink="/stats/live" class="nav-link link-dark" data-bs-dismiss="offcanvas" aria-label="Close">
              <i class="fa-solid fa-chevron-right"></i> Detalle Diario
            </a>
          </li>
          <li>
            <a routerLink="/stats/ventas" class="nav-link link-dark"data-bs-dismiss="offcanvas" aria-label="Close">
              <i class="fa-solid fa-chevron-right"></i> Ventas Generales
            </a>
          </li>
          <li>
            <a routerLink="/stats/ranking" class="nav-link link-dark"data-bs-dismiss="offcanvas" aria-label="Close">
              <i class="fa-solid fa-chevron-right"></i> Ranking de Productos
            </a>
          </li>
          <li>
            <a routerLink="/stats/pagos" class="nav-link link-dark"data-bs-dismiss="offcanvas" aria-label="Close">
              <i class="fa-solid fa-chevron-right"></i> Pagos
            </a>
          </li>

        </ul>

        <hr>


      </div>

  </div>
