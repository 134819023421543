<!-- <app-shift></app-shift> -->

<div *ngIf="loadingPago" class="row fondoModal animate__animated animate__fadeIn" style="margin:0">
    <div class="col-12 text-center loadingPago">
        <p style="margin-bottom: 0;"><i class="fa-solid fa-sack-dollar fa-spin fa-2x"></i></p>
        <p class="animate__animated animate__pulse animate__infinite">Realizando Pago </p>
    </div>
</div>

<div class="row header azul r0">
    <div class="col-12 col-md-6">
       <h2 class="animate__animated animate__fadeIn" (click)="info()">  <i class="fa-solid fa-cash-register" ></i> Venta Directa</h2>
    </div>
    <div class="col-12 col-md-6 hvr-icon-rotate text-end">
        <p class="text-light" style="margin-bottom:0"> {{params.store.name}} | Caja: {{params.caja.name}} <i class="fa-solid fa-arrows-rotate hvr-icon text-light mt-2 pointer" (click)="refreshData = true"></i> </p> 
    </div>
</div>

<app-refresh-data *ngIf="refreshData" (enviarPadre)="recibirRefresh($event)" ></app-refresh-data>

<app-notificaciones></app-notificaciones>

<app-loading *ngIf="loading"></app-loading>

<div class="row cuentaCel d-block d-lg-none" style="margin:0">
    <div class="col-12">

        <div [ngClass]="esRedondeado ? 'redondeado' : '' " class="row pestana shadow pointer" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample" (click)="cambiarRadius()">
            <div class="col ">
                <h1>Cuenta<i [ngClass]="esRedondeado ? 'fa-solid fa-chevron-up animate__animated animate__fadeIn chico' : 'fa-solid fa-chevron-down animate__animated animate__tada  chico' "></i></h1>
                
            </div>
            <div class="col totalCel" >
                <h2 style="font-size:1.1em">Total: <span style="font-weight: 700;">{{headSale.total | number}} </span></h2>
            </div> 
        </div>


        <div *ngIf="!loading" class="row detalleCuenta shadow collapse"  id="collapseExample" data-bs-parent=".cuentaCel">
            <div class="col-12">



                <div *ngFor="let p of preOrders; index as i" 
                [ngClass]="{'modif': p.isModif || p.isMessage, 
                            'item': !p.isModif && !p.isMessage,
                            'porPagar animate__animated animate__fadeIn': p.status == 4,
                            'pagado animate__animated animate__fadeIn' : p.status == 3 }" class="row">

                    <div class="col-5" (click)="selectProducto(p)">
                        <p> <span  *ngIf="!p.isModif && !p.isMessage"  >{{p.eater }} <i class="fa-solid fa-user"></i> | </span>  {{p.productName}}</p>
                        <h5 *ngIf="!p.isModif && !p.isMessage" class="precioChico" >${{p.bruto | number}}</h5>
                    </div>
                    <div  class="col-1 hvr-icon-rotate" (click)="mensaje()">
                        <p *ngIf="!p.isModif && !p.isMessage" > <i class="fa-regular fa-comment"></i><p>
                    </div>
                    <div class="col-2 text-center hvr-icon-grow">
                        <p>
                            <i *ngIf="!p.isModif && !p.isMessage" class="text-danger fa-solid fa-minus hvr-icon pointer" (click)="sumaResta('restar', i)"></i> {{p.quantity}} 
                            <i *ngIf="!p.isModif && !p.isMessage" class="text-primary fa-solid fa-add hvr-icon pointer" (click)="sumaResta('sumar', i)"></i>
                        </p>
                    </div>
                    <div class="col-2">
                        <p>${{p.total | number}}</p>
                    </div>
                    <div  *ngIf="!p.isModif && !p.isMessage" class="col-1 text-center hvr-icon-rotate" (click)="borrarItem(i)">
                        <i class="text-danger fas fa-times hvr-icon pointer"></i>
                    </div>
                </div>  
           




    
          

                
                <div class="row botonesPago mt-2" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                    <div *ngIf="porAbonar > 0" class="col-10 offset-1 botonCuenta azul hvr-grow " data-bs-toggle="offcanvas" data-disableScrolling="false" data-bs-target="#medioPago" aria-controls="staticBackdrop"  (click)="pagoParcial = true">
                        <p>Pagar items seleccionados <span style="font-weight: 700;">${{porAbonar}}</span></p>
                    </div>
                    <div *ngIf="headSale.saldo > 0" class="col-10 offset-1 botonCuenta naranja hvr-grow" data-bs-toggle="offcanvas" data-disableScrolling="false" data-bs-target="#medioPago" aria-controls="staticBackdrop" (click)="pagoParcial = false">
                        <p class="pointer"> Pagar Total <span style="font-weight: 700;">${{headSale.saldo | number }}</span></p>
                    </div> 
                </div>
                
                <div class="row espacio"></div>

            </div>
        </div>
    </div>
</div>
<!-- FIN CUENTE CEL -->
<!-- FIN CUENTE CEL -->
<!-- FIN CUENTE CEL -->
<!-- FIN CUENTE CEL -->


<div class="row general celeste2"  style="margin:0">
    
    <div class="col-lg-4 d-none d-lg-block cuentaPc shadow animate__animated animate__fadeIn ">

        <div class="row headCuenta shadow">
            <div class="col">
                <h1>Cuenta</h1>
           
            </div>
            <div class="col totalCel" >
                <h2 style="font-size:1.1em">Total: <span style="font-weight: 700;">${{headSale.total | number}} </span></h2>
            </div>

            
        </div>

        <div *ngIf="headSale.clientId != 0" class="row cliente animate__animated animate__fadeIn">
            <div class="col-12 text-end pointer hvr-backward">
                <p (click)="modalClientes = true"><i class="fa-solid fa-user"></i> {{client.firstName}} {{client.lastName}}</p>
            </div>
        </div>


        <div class="row detalleCuenta ">
            <div class="col-12">


                <div *ngFor="let o of orders; index as i"
                    class="row order animate__animated animate__fadeIn item">
                    
                    <div class="col-5" (click)="selectProducto(p)" [ngClass]="o.status == 3 ? 'tachado' : '' ">
                        <p > <span>{{o.eater }} <i class="fa-solid fa-user"></i> | </span>  {{o.productName}}</p>
                    </div>
                    <div class="col-5" [ngClass]="o.status == 3 ? 'tachado' : '' ">
                        <p >${{o.total | number}}</p>
                    </div>
                    <div class="col-2 text-center hvr-icon-rotate" (click)="anularItem(i)">
                        <i *ngIf="o.status != 3 "class="text-danger fas fa-times hvr-icon pointer"></i>
                    </div>

                    <div *ngIf="o.modifs.length > 0 " class="col-12">
                        <div *ngFor="let m of o.modifs" [ngClass]="o.status == 3 ? 'tachado' : '' " class="row">
                            <div  class="col-5">
                                <p> {{m.productName}}</p>
                            </div>
                            <div class="col-5">
                                <p>${{m.total | number}}</p>
                            </div>
                        </div>
                    </div>
                </div>  


                <!-- <div *ngFor="let o of orders; index as i"
                [ngClass]="{'modif': o.isModif || o.isMessage, 
                            'item': !o.isModif && !o.isMessage,
                            'porPagar animate__animated animate__fadeIn': o.status == 4,
                            'pagado animate__animated animate__fadeIn' : o.status == 3 }" 
                            class="row order">
                    
                    <div class="col-5" (click)="selectProducto(p)">
                        <p> <span  *ngIf="!o.isModif && !o.isMessage"  >{{o.eater }} <i class="fa-solid fa-user"></i> | </span>  {{o.productName}}</p>
                        <h5 *ngIf="!o.isModif && !o.isMessage" class="precioChico" >${{o.bruto | number}}</h5>
                    </div>
                    <div class="col-5">
                        <p>${{o.total | number}}</p>
                    </div>
                    <div  *ngIf="!o.isModif && !o.isMessage" class="col-1 text-center hvr-icon-rotate" (click)="anularItem(i)">
                        <i class="text-danger fas fa-times hvr-icon pointer"></i>
                    </div>
                </div>   -->


                <div *ngFor="let p of preOrders; index as i" 
                [ngClass]="{'modif': p.isModif || p.isMessage, 
                            'item': !p.isModif && !p.isMessage,
                            'porPagar animate__animated animate__fadeIn': p.status == 4,
                            'pagado animate__animated animate__fadeIn' : p.status == 3 }" class="row">

                    <div class="col-5" (click)="selectProducto(p)">
                        <p> <span  *ngIf="!p.isModif && !p.isMessage"  >{{p.eater }} <i class="fa-solid fa-user"></i> | </span>  {{p.productName}}</p>
                        <h5 *ngIf="!p.isModif && !p.isMessage" class="precioChico" >${{p.price | number}}</h5>
                    </div>
                    <div  class="col-1 hvr-icon-rotate" (click)="mensaje()">
                        <p *ngIf="!p.isModif && !p.isMessage && p.productCode != 'DESCUENTO'" > <i class="fa-regular fa-comment hvr-icon msg"></i><p>
                    </div>

                    <div  class="col-1 " >
                        <img *ngIf="!p.isModif && !p.isMessage && p.productCode != 'DESCUENTO'"  src="assets/images/iconos/descuento.svg" class="iconoDesc hvr-grow-rotate" [ngClass]="p.discount > 0 ? 'animate__animated animate__heartBeat animate__infinite' : '' " (click)="descuentoProducto(p)">
                    </div>

                    <div class="col-2 text-center hvr-icon-grow">
                        <p>
                            <i *ngIf="!p.isModif && !p.isMessage && p.productCode != 'DESCUENTO' " class="text-danger fa-solid fa-minus hvr-icon pointer" (click)="sumaResta('restar', i)"></i> {{p.quantity}} 
                            <i *ngIf="!p.isModif && !p.isMessage && p.productCode != 'DESCUENTO' " class="text-primary fa-solid fa-add hvr-icon pointer" (click)="sumaResta('sumar', i)"></i>
                        </p>
                        <!-- <p *ngIf="p.productCode == 'DESCUENTO'">
                           {{p.quantity}} 
                        </p> -->
                    </div>
                    <div class="col-2" (click)="borrarDscto(p)">
                        <p>${{p.total | number}}</p>
                        <h5 *ngIf="p.discount > 0" class="descuento text-danger">-${{p.discount | separator:0}} <i class="fas fa-times"></i></h5>

                    </div>
                    <div  *ngIf="!p.isModif && !p.isMessage" class="col-1 text-center hvr-icon-rotate" (click)="borrarItem(i)">
                        <i class="text-danger fas fa-times hvr-icon pointer"></i>
                    </div>
                </div>  


        

          

                <div class="row espacio"></div>
            </div>

            
        </div>



 

        <div class="row botonesPago mt-2">
            <div *ngIf="porAbonar > 0" class="col-10 offset-1 botonCuenta azul hvr-grow " data-bs-toggle="offcanvas" data-disableScrolling="false" data-bs-target="#medioPago" aria-controls="staticBackdrop"  (click)="pagoParcial = true">
                <p>Pagar items seleccionados <span style="font-weight: 700;">${{porAbonar}}</span></p>
            </div>
            <div *ngIf="headSale.saldo > 0" class="col-10 offset-1 botonCuenta naranja hvr-grow" data-bs-toggle="offcanvas" data-disableScrolling="false" data-bs-target="#medioPago" aria-controls="staticBackdrop" (click)="pagoParcial = false">
                <p> Pagar Total <span style="font-weight: 700;">${{headSale.saldo | number }}</span></p>
            </div> 
        </div>
     
    </div>
    <!-- FIN CUENTA VISTA PC -->
    <!-- FIN CUENTA VISTA PC -->
    <!-- FIN CUENTA VISTA PC -->
    <!-- FIN CUENTA VISTA PC -->

    <div class="col-12 col-lg-7">
      <div class="row cuerpo">
        <div class="col-12">

            

            <div class="row infoTop">
                <div class="col-12 mb-2">
                    <div class=" indicadorBoleta animate__animated animate__flipInX">
                         <select [(ngModel)]="selectedDocType" class="form-control formuSin" (change)="changeDocType()">
                            <option *ngFor="let d of docTypes" [ngValue]="d">{{ d.name }}</option>
                          </select>

                    </div>
                </div>
                <div class="col mb-2">
                    <div class="indicador indiAzul animate__animated animate__flipInX">
                        <div class="row">
                            <div class="col-6 text-center">
                                <p><i class="fas fa-add fa-2x pointer" (click)="modifNpeople('sum')"></i></p>
                                <p><i class="fas fa-minus fa-2x pt-2  pointer" (click)="modifNpeople('rest')"></i></p>
                            </div>
                            <div class="col-6">
                                <h2 class="hvr-grow"><i class="fa-solid fa-person"></i> {{headSale.npeople | number }}</h2>
                                <p>Comensales</p>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="col mb-2">
                    <div class="indicador indiVerde animate__animated animate__flipInX">
                        <h2 class="hvr-grow">${{headSale.abono | number }}</h2>
                        <p>Abonado</p>
                    </div>
                </div>
                <div class="col-12 col-md d-none d-md-block" data-bs-toggle="offcanvas" data-disableScrolling="false" data-disableScrolling="false" data-bs-target="#medioPago" aria-controls="staticBackdrop" >
                    <div class="indicador indiRojo animate__animated animate__flipInX">
                        <h2 class="hvr-grow">${{headSale.saldo | number}}</h2>
                        <p>Saldo</p>
                    </div>
                </div>

                <div class="col-12 col-md d-block d-md-none" data-bs-toggle="offcanvas" data-disableScrolling="false" data-disableScrolling="false" data-bs-target="#medioPago" aria-controls="staticBackdrop">
                    <div class="indicador indiRojo animate__animated animate__flipInX">
                        <div class="row">
                            <div class="col text-start"><h2 class="hvr-grow">Pagar Saldo</h2></div>
                            <div class="col"><h2 class="hvr-grow">${{headSale.saldo | number}}</h2></div>
                        </div>
                    </div>
                </div>

            </div>

       
            <hr>

            <div class="row  animate__animated animate__fadeIn ">
                <div class="col-12 pagos">
                    <h1>Pagos</h1>
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                            <tr>
                                <th scope="col">Tipo</th>
                                <th scope="col">Abona</th>
                                <th scope="col">Vuelto</th>
                                <th scope="col">Propina</th>
                                <th scope="col">Total</th>
                                <th scope="col"></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let p of pagos; index as i" 
                                        [ngClass]="p.payType == 'WPC' && p.status == 3 ? 'bg-warning' 
                                        : p.status == 4 ? 'tachado':'' ">
                                <td (click)="verPago(p)">{{p.payTypeName}} </td>
                                <td >$ {{p.amountA | number}}</td>
                                <td >$ {{p.change | number}}</td>
                                <td> $ {{p.tip | number}}</td>
                                <td >$ {{p.amountP | number}}</td>
                                <td *ngIf="p.status == 1" class="hvr-icon-rotate"> <i class="fa-solid fa-trash-can text-danger hvr-icon pointer" (click)=borrarPago(p,i)></i></td>
                                <td *ngIf="p.status == 2" class="hvr-icon-rotate">
                                     <i class="fas fa-check text-success hvr-icon pointer" style="margin-right:10px" ></i>
                                     <i class="fa-solid fa-trash-can text-danger hvr-icon pointer" 
                                     (click)=seguroAnular(p)></i>
                                    </td>
                                <td *ngIf="p.status == 3" class="hvr-icon-rotate"> 
                                    <i *ngIf="esperandoPagoWP" class="fa-solid fa-spinner fa-spin" style="margin-right:10px"></i>
                                    <i class="fa-solid fa-trash-can text-danger hvr-icon pointer" 
                                    (click)=seguroAnular(p)></i></td>
                                <td *ngIf="p.status == 4" class="hvr-icon-rotate text-danger"> <i class="fas fa-times text-danger hvr-icon pointer" ></i></td>
                            </tr> 
                            </tbody>
                        </table>
                    </div>

                    <p *ngIf="pagos.length < 1">No hay pagos aún</p>
                </div>
            </div>
        <!-- Fin PAgos -->

            <div class="row animate__animated animate__fadeIn">
                <div class="col-10 offset-1">
                    <div class="row">
                        <div class="col-12 offset-0 col-lg-6">
                                <div class="input-group">
                                <span class="input-group-text">Vuelto</span>
                                <input type="text" class="form-control" [(ngModel)]="vuelto" disabled>
                                </div>
                        </div>
                        <div class="col-12 col-lg-6">
                                <div class="input-group">
                                <span class="input-group-text">Propina</span>
                                <input type="text" class="form-control inputRight"  [(ngModel)]="propina" disabled>
                                </div>
                        </div>


                    </div>

                </div>
            </div>



            

            <div class="row mt-4">
          
            
                <div *ngIf="headSale.saldo > 0" class="d-block d-md-none col-10 offset-1  mb-2" data-bs-toggle="offcanvas" data-disableScrolling="false" data-bs-target="#medioPago" aria-controls="staticBackdrop" (click)="pagoParcial = false">
                    <button class="btn btn-dark btn-lg naranja" > Pagar Total <span style="font-weight: 700;">${{headSale.saldo | number }}</span></button>
                </div> 

                <div *ngIf="headSale.status < 2" class="col-10 offset-1">
                    <button *ngIf="headSale.total > 0" class="btn btn-primary btn-lg w-100" (click)="guardarVenta()">Guardar Venta</button>
                </div>
                <div *ngIf="headSale.status > 1" class="col-10 offset-1">
                    <button class="btn btn-warning btn-lg w-100" (click)="nuevaVenta()">Nueva Venta</button>
                </div>
            </div>

            <div class="row">
                <div class="col-12 text-end pendiente">
                    <p class="text-primary hvr-grow pointer" (click)="modalPendientes = true">Buscar Venta pendiente de pago</p>
                </div>
            </div>

            <!-- <div class="btn btn-primary" (click)="testBoleta()">TEST</div> -->



            <div class="row d-block d-md-none link_cliente ">
                <div class="col-12">

                    <div *ngIf="client.firstName.length < 1" class="row cliente animate__animated animate__fadeIn">
                        <div class="col-12 text-end pointer hvr-backward text-center">
                            <p (click)="modalClientes = true"><i class="fa-solid fa-user"></i> Asociar Cliente</p>
                        </div>
                    </div>
                    
                    <div *ngIf="client.firstName.length > 0" class="row cliente animate__animated animate__fadeIn">
                        <div class="col-12 text-end pointer hvr-backward text-center">
                            <p (click)="modalClientes = true"><i class="fa-solid fa-user"></i> {{client.firstName}} {{client.lastName}}</p>
                        </div>
                    </div>
                    
                </div>

            </div>

         
       
          </div>
        </div>
    </div>
    <!-- Fin cuerpo celeste -->


    <div class="col-lg-1 d-none d-lg-block rosado botonera animate__animated animate__slideInRight">
        <div class="btnPc">
            <img src="assets/images/iconos/burger.svg" class="iconoPc hvr-grow-rotate" (click)="abrirProductos('botonera')">
            <p>Productos</p>
        </div>
        <div class="btnPc hvr-icon-grow-rotate" (click)="abrirProductos('buscar')">
            <i class="text-light fa-solid fa-barcode hvr-icon fa-3x"></i>
            <p>Buscar</p>
        </div>
        <div class="btnPc" (click)="cortesias()">
            <img src="assets/images/iconos/descuento.svg" class="iconoPc hvr-grow-rotate">
            <p>Cortesias</p>
        </div>
        <div class="btnPc hvr-icon-grow-rotate text-light" (click)="modifComensal()">
            <!-- <i class="fa-solid fa-person"></i> -->
            <h1 *ngIf="comensal > 0" class="text-light" ><i class="text-light fas fa-male" style="font-size: 1.5em;"></i> {{comensal}}</h1>
            <i *ngIf="comensal < 1" class="text-light fa-solid fa-people-group" style="font-size: 3em;"></i>
            <p>Comensal</p>
        </div>
        <div class="btnPc hvr-icon-grow-rotate" (click)="info()" data-bs-toggle="offcanvas" data-disableScrolling="false" data-bs-target="#infoCanvas" aria-controls="infoCanvas" >
            <i class="text-light fa-solid fa-info hvr-icon fa-3x"></i>
            <p>Info</p>
        </div>
        <div class="btnPc hvr-icon-grow-rotate" (click)="modalClientes = true" >
            <i class="text-light fa-solid fa-user hvr-icon fa-3x"></i>
            <p>Cliente</p>
        </div>
        <div class="btnPc hvr-icon-grow-rotate" routerLink="/general/home">
            <i class="text-light fa-solid fa-arrow-left hvr-icon fa-3x"></i>
            <p>Volver</p>
        </div>
    </div>
    <!-- FIN BOTONERA PC -->
</div>

<nav class="navbar fixed-bottom rosado botonera d-block d-lg-none animate__animated animate__slideInUp">
    <div class="container-fluid">
        <div class="col btnCel">
            <img src="assets/images/iconos/burger.svg" class="iconoPc hvr-grow-rotate" (click)="modalBotonera = true">
            <p>Productos</p>
        </div>
        <div class="col btnCel hvr-icon-grow-rotate" (click)="modalBuscar = true;">
            <i class="text-light fa-solid fa-barcode hvr-icon fa-2x"></i>
            <p>Buscar</p>
        </div>
        <div class="col btnCel">
            <img src="assets/images/iconos/descuento.svg" class="iconoPc hvr-grow-rotate">
            <p>Descuentos</p>
        </div>
        <div class="col btnCel hvr-icon-grow-rotate">
            <i class="text-light fa-solid fa-people-group hvr-icon fa-2x"></i>
            <p>Comensal</p>
        </div>
        <div class="col btnCel hvr-icon-grow-rotate">
            <i class="text-light fa-solid fa-arrow-left hvr-icon fa-2x"></i>
            <p>Volver</p>
        </div>
    </div>
</nav>



<!-- medios de Pago -->

  
  <div class="offcanvas offcanvas-start mediosDePago" data-bs-backdrop="static" tabindex="-1" id="medioPago" aria-labelledby="medioPagoLabel">
    <div class="row offcanvas-header ">
        <div class="col-10">
            <h5 class="offcanvas-title" id="medioPagoLabel">Medios de Pago</h5>
        </div>
      <div class="col-2 hvr-rotate pointer">
          <i class="text-light fas fa-times fa-2x hvr-icon" data-bs-dismiss="offcanvas" aria-label="Close"></i>
        </div>
    </div>
    <div class="offcanvas-body text-center ">
     
        <button class="btn btn-outline-light btn-lg w-100 mb-3" data-bs-dismiss="offcanvas" aria-label="Close" (click)="abrirPago('DEB')">
            Tarjeta Debito
        </button>
        <button class="btn btn-outline-light btn-lg w-100 mb-3" data-bs-dismiss="offcanvas" aria-label="Close" (click)="abrirPago('credito')">
            Tarjeta Crédito
        </button>
        <button class="btn btn-outline-light btn-lg w-100 mb-3" data-bs-dismiss="offcanvas" aria-label="Close"  (click)="abrirPago('EFE')">
            Efectivo
        </button>
        <button class="btn btn-outline-light  w-100 mb-3" data-bs-dismiss="offcanvas" aria-label="Close" (click)="abrirPago('TRA')">
            Transferencia
            </button>

        <button *ngIf="!hasWebPay" class="btn btn-outline-dark  w-100 mb-3 botonHover" >
            <p> Inactivo.
                <img src="assets/images/webpayBlanco.svg" class="logo">
                </p></button>
        <button *ngIf="!loadingWebPay && hasWebPay" class="btn btn-outline-light  w-100 mb-3 botonHover" data-bs-dismiss="offcanvas" aria-label="Close" (click)="abrirPago('WPC')" ><img src="assets/images/webpayBlanco.svg" class="logo">
      </button>




        <button class="btn btn-outline-dark  w-100 mb-3" data-bs-dismiss="offcanvas" aria-label="Close">
            Convenio
        </button>
    
        <button class="btn btn-outline-dark  w-100 mb-3" data-bs-dismiss="offcanvas" aria-label="Close">
            Gift Card
        </button>
        <button class="btn btn-outline-light  w-100 mb-3" data-bs-dismiss="offcanvas" aria-label="Close" (click)="holdPay()" >
            pendiente de Pago
        </button>
        <button class="btn btn-danger  w-100 mb-3" data-bs-dismiss="offcanvas" aria-label="Close">
            Volver
        </button>

        
    </div>
  </div>



<!-- MODAL PAGOS -->
<div *ngIf="modalPago" class="row fondoModal animate__animated animate__fadeIn animate__faster" style="margin-right: 0;">
    <div class="col-12 col-md-8 offset-md-4 col-lg-6 offset-lg-6 animate__animated animate__slideInRight">
        <app-digito-pay [datoDesdeCaja]="datoCaja" (enviarPadre)="recibirTotal($event)" ></app-digito-pay>
    </div>
</div>  

<div  *ngIf="modalBotonera" class="row fondoModal animate__animated animate__fadeIn" style="margin-right: 0;">
    <div class="col-12 col-lg-11 offset-lg-1 animate__animated animate__slideInRight">
        <app-botonera [datoComensales]="headSale.npeople" (enviarPadre)="recibirProducto($event)" ></app-botonera>
    </div>
</div>

<div  *ngIf="modalBuscar" class="row fondoModal animate__animated animate__fadeIn animate__faster" style="margin-right: 0;">
    <div class="col-12 col-md-10 offset-md-2 col-lg-8 offset-lg-4 animate__animated animate__slideInRight">
        <app-buscador-productos (enviarPadre)="recibirProducto($event)" ></app-buscador-productos>
    </div>
</div>



<div *ngIf="modalCortesias" class="row fondoModal animate__animated animate__fadeIn animate__faster" style="margin-right: 0;">
    <div class="col-12 col-md-8 offset-md-4 col-lg-6 offset-lg-6 animate__animated animate__slideInRight bg-dark">
        
        <div class="row text-light pad20">
            <div class="col-10">
                <h1>Cortesías</h1>
            </div>
            <div class="col-2 text-center hvr-icon-rotate pointer">
                <i class="fas fa-times fa-2x hvr-icon " (click)="modalCortesias = false"></i>
            </div>
        </div>

        <div class="row">
            <form #form="ngForm" (ngSubmit)="evaluarCortesia(form)" class="col-10 offset-1 col-md-6 offset-md-3">
                <div class="form-group text-light">
                  <label for="dcto">Descuento:</label>
                  <input type="number" id="dcto" name="dcto" ngModel class="form-control" required placeholder="0" min="0">
                </div>

                <button type="submit" class="btn btn-outline-primary mt-2 w-100">Guardar</button>
              </form>
        </div>

        <div class="row">
            <div  class="col-10 offset-1 col-md-6 offset-md-3">
                
                <div class="row align-items-center">
                    <div class="col-4 col-md-3 text-center">

                        <div class="btnDscto" (click)="dscto(10)">
                            <p>10%</p>
                        </div>
                    </div>
                    <div class="col-4 col-md-3 text-center">
                        <div class="btnDscto" (click)="dscto(20)">
                            <p>20%</p>
                        </div>
                    </div>
                    <div class="col-4 col-md-3 text-center">
                        <div class="btnDscto" (click)="dscto(30)">
                            <p>30%</p>
                        </div>
                    </div>
                    <div class="col-4 col-md-3 text-center">
                        <div class="btnDscto" (click)="dscto(40)">
                            <p>40%</p>
                        </div>
                    </div>
               
                    <div class="col-4 col-md-3 text-center">

                        <div class="btnDscto" (click)="dscto(50)">
                            <p>50%</p>
                        </div>
                    </div>
                    <div class="col-4 col-md-3 text-center">
                        <div class="btnDscto" (click)="dscto(60)">
                            <p>60%</p>
                        </div>
                    </div>
                    <div class="col-4 col-md-3 text-center">
                        <div class="btnDscto" (click)="dscto(70)">
                            <p>70%</p>
                        </div>
                    </div>
                    <div class="col-4 col-md-3 text-center">
                        <div class="btnDscto" (click)="dscto(80)">
                            <p>80%</p>
                        </div>
                    </div>
                </div>
            </div>
         
        </div>


        <div class="row">
            <div class="col-10 offset-1 col-md-6 offset-md-3">
                <div class="btn btn-outline-danger  mt-2 w-100" (click)="modalCortesias = false">Cancelar</div>
            </div>
        </div>
    </div>

</div>  





<div class="offcanvas offcanvas-start" tabindex="-1" id="infoCanvas" aria-labelledby="infoCanvasLabel">
  <div class="offcanvas-header">
    <h5 class="offcanvas-title" id="infoCanvasLabel">Info Caja</h5>
    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body small row info">
    <div class="col-12">
        <h2><span>Local:</span> {{params.store.name}}</h2>
        <h2><span>Caja:</span> {{params.caja.name}}</h2>
        <p><span>Usuario:</span> {{user.firstName}} {{user.lastName}}</p>  
        <p><span>Lista de precio:</span> {{params.priceListId}}</p>
        <p><span>Turno:</span> {{params.turno.name}}</p>
        <p><span>Abierto:</span> {{params.turno.started | date:'EEEE d':'':'es' | titlecase }} de {{params.turno.started | date:'MMMM':'':'es'  }} |  {{params.turno.started | date: 'HH:mm'}}</p>  
        <hr>
        <p *ngIf="headSale.clientId !=0 "> <span>Cliente: </span>{{client.firstName}} {{client.lastName}}</p>
    </div>
  </div>
</div>




<!-- WEBPAY -->
<div *ngIf="modalWP" class="row fondoModal animate__animated animate__fadeIn animate__faster wp" style="margin-right: 0;">
    <div class="col-12 col-md-8 offset-md-4 col-lg-6 offset-lg-6 animate__animated animate__slideInRight bg-dark">
        
        <div class="row text-light pad20">
            <div class="col-10">
                <h1>Pago <img src="assets/images/webpayBlanco.svg" width="150px"></h1>
            </div>

            <div class="col-2 text-center hvr-icon-rotate pointer">
                <i class="fas fa-times fa-2x hvr-icon " (click)="cerrarWP()"></i>
            </div>
            <hr>
        </div>

        <div class="row">
            <div  class="col-10 offset-1 col-md-6 offset-md-3 text-center">
                    <p>Codigo QR de pago:</p>
                    <p><img [src]="qrCode" alt="QR Code" class="qr"></p>
                    <p><a [href]="urlPago"><i class="fa-solid fa-link"></i> Link de Pago</a></p>
                    <p *ngIf="selectedPayment.status == 2 " (click)="verComprobante()"><i class="fa-solid fa-receipt"></i> Ver combrobante</p>
                    <p *ngIf="selectedPayment.status == 1 "><i class="fas fa-spinner fa-spin"></i> Evaluando Pago</p>
            </div>
        </div>

        


        <div class="row">
            <div class="col-10 offset-1 col-md-6 offset-md-3">
                <div class="btn btn-outline-danger  mt-2 w-100" (click)="modalWP = false">Cancelar</div>
            </div>
        </div>
    </div>

</div>  



<div *ngIf="modalClientes" class="row fondoModal animate__animated animate__fadeIn animate__faster" style="margin-right: 0;">
    <div class="col-12">
        <app-shared-clientes (enviarPadre)="recibirCliente($event)"> </app-shared-clientes>
    </div>
</div> 

<div *ngIf="modalPendientes" class="row fondoModal animate__animated animate__fadeIn animate__faster" style="margin-right: 0;">
    <div class="col-10">
       <app-pendiente (enviarPadre)="recibirPendiente($event)" ></app-pendiente>
    </div>
</div> 



<div *ngIf="modalAnular" class="row fondoModal animate__animated animate__fadeIn animate__faster" style="margin-right: 0;">
    <div class="col-12">

        <div class="row bg-dark modalAnular animate__animated animate__slideInDown">
            <div class="col-12 col-md-6 offset-md-3 text-center">
                <h1>Anular</h1>
                <hr>
                
                <div class="row">
                    <div class="col-12">

                        <div class="row">
                            <div class="col-2">
                                <h2> x {{porAnular.quantity}}</h2>
                            </div>
                            <div class="col-6 text-start">
                                <h2>{{porAnular.productName}}</h2>
                            </div>
                            <div class="col-4">
                                <h2>${{porAnular.total}}</h2>
                            </div>

                        </div>
                        <div *ngFor="let m of porAnular.modifs" class="row">
                            <div class="col-2">
                                <p>x {{m.quantity}}</p>
                            </div>
                            <div class="col-6 text-start">
                                <p>{{m.productName}}</p>
                            </div>
                            <div class="col-4">
                                <p> $ {{m.total}}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <hr>

                <div class="row">
                    <form (submit)="anularItem2(motivoAnulacionInput.value)">
                        <div class="col-12">
                            <label>Motivo anulación</label>
                            <textarea name="mensaje" rows="2" cols="50" class="form-control" #motivoAnulacionInput></textarea>
                            <div *ngIf="motivoAnulacionInput.invalid && (motivoAnulacionInput.dirty || motivoAnulacionInput.touched)">
                                <small class="text-danger">Debe proporcionar un motivo de anulación.</small>
                            </div>
                        </div>
                        <button type="submit" class="btn btn-outline-danger w-100 mt-3">Anular</button>
                    </form>
                </div>


                
                <hr>



                <div class="row">
                    <div class="col-12">
                        <button class="btn btn-outline-light w-100" (click)="modalAnular = false;">Volver</button>
                    </div>
                </div>
            </div>
        </div>



    </div>
</div> 



