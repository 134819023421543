import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { groupModel } from 'src/app/models/data/group.model';
import { memoryModel } from 'src/app/models/general/memory.model';
import { DataService } from 'src/app/services/data.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-productos',
  templateUrl: './productos.component.html',
  styleUrls: ['./productos.component.css']
})
export class ProductosComponent implements OnInit {

  loading            = true;
  searchString       = '';
  productosAll       = [];
  productos          = [];
  user:any           = {};

  greatGroups        = [];
  groupsAll          = [];
  groups             = [];
  gg:any             = {};
  grupo:any          = {};
  memory:memoryModel = JSON.parse(localStorage.getItem('memoryGournet'));
  firstload           = true;

  constructor(private conexData: DataService,
              private _user:UserService,
              private router: Router) { 

              this.user = this._user.userInfo();

              forkJoin([
                this.conexData.getData(`/data/products`),
                this.conexData.getData('/data/greatGroups'),
                this.conexData.getData('/data/groups'),
              ]).subscribe({
                next:(data:any)  => {
                 console.log('data', data);
                  this.filtrarData(data);
                },
               error: (error) => {
                  console.error(error);
                }
              });
  }

  ngOnInit(): void {
    // this.getproducts();
  }



  selectProd(p){
    this.router.navigateByUrl('/data/producto/' + p.id );
  }

  filtrarData(data){
    console.log('filñtrar data', data);
    if (!data[0]){
      return;
    }
   this.getProducts(data[0]) 
   const newgg      = {id:0, name:'Todos', type:'V', code: 'ALL'};
   this.gg          = newgg; 
   this.greatGroups = data[1].filter( g => g.type == 'V');
   this.greatGroups.unshift(newgg);

   this.groupsAll   = data[2];

    console.log('memory gran grupo', this.memory.data.greatGroupId);

    if( this.memory.data.greatGroupId > 0 && this.firstload){
      this.gg = this.greatGroups.find( greatg => greatg.id == this.memory.data.greatGroupId );
      console.log('el gran grupo es por memoria', this.gg);
    }
    
   this.selectGG();
  }


  getProducts(resp){
    this.loading = true;
        if (resp){
          this.productosAll = resp;
          this.productos    = resp.filter( p => p.role != 'C' );
          this.loading  = false;
        } else{
          this.productosAll = [];
          this.loading  = false;
        }
  }

  

  selectGG(){
    this.memory.data.greatGroupId = this.gg.id;
    this.groups                   = this.groupsAll.filter( g => g.greatGroupId == this.gg.id);

  
    const newgrupo         = new groupModel();
    newgrupo.name          = 'Todos';
    this.grupo             = newgrupo;
    this.groups.unshift(newgrupo);


    if( this.memory.data.groupId > 0 && this.firstload){
      this.grupo = this.groupsAll.find( gr => gr.id == this.memory.data.groupId );
    }


    if( this.gg.id == 0){
      this.grupo = this.groups[0];
    } else {
      this.productos = this.productosAll.filter( p => p.greatGroupId == this.gg.id);
    }

    
    this.selectG();

  }

  selectG(){

    this.memory.data.groupId = this.grupo.id;

    if (this.grupo.id == 0){
      if (this.gg.id == 0){
        this.productos = this.productosAll;
      } else {
        this.productos = this.productosAll.filter( p => p.greatGroupId == this.gg.id);
      }
    } else {
      this.productos = this.productosAll.filter( p => p.groupId == this.grupo.id);
    }

    if(this.firstload){
      this.selectOrdernar('abc');
    }

    localStorage.setItem('memoryGournet', JSON.stringify(this.memory));
    this.firstload = false;


  
    
  }


  selectOrdernar(value) {
    let compararObjetivos;
    switch (value) {
      case 'abc':
        compararObjetivos = (a, b) => a.name.localeCompare(b.name);
        break;
      case 'grangrupo':
        compararObjetivos = (a, b) => a.greatGroupId - b.greatGroupId;
        break;
      case 'grupos':
        compararObjetivos = (a, b) => a.groupId - b.groupId;
        break;
      case 'id':
        compararObjetivos = (a, b) => a.id - b.id;
        break;
      default:
        return;
    }
    this.ordenarProductos(compararObjetivos);
  }


  ordenarProductos(comparar) {
    this.productos.sort(comparar);
    this.productos = [...this.productos];
    console.log('ordenados', this.productos);
  }


  // orderAlfabeticamente(){
  //   function compararObjetivos(a, b) {
  //     if (a.name < b.name) {return -1;}
  //     if (a.name > b.name) {return 1;}
  //     return 0;
  //   }
  //   this.productos = this.productos.slice();
  //   this.productos.sort(compararObjetivos);
    
  //   console.log('ordenados', this.productos);
  // }

  // ordergg(){
  //   function compararObjetivos(a, b) {
  //     if (a.greatGroupId < b.greatGroupId) { return -1; }
  //     if (a.greatGroupId > b.greatGroupId) { return 1; }
  //     return 0;
  //   }
  //   this.productos.sort(compararObjetivos);
  //   this.productos = this.productos.slice();
    
  //   console.log('ordenados', this.productos);
  // }

  // orderg(){
  //   function compararObjetivos(a, b) {
  //     if (a.groupId < b.groupId) { return -1; }
  //     if (a.groupId > b.groupId) { return 1; }
  //     return 0;
  //   }
  //   this.productos.sort(compararObjetivos);
  //   this.productos = this.productos.slice();
    
  //   console.log('ordenados', this.productos);
  // }
  // orderId(){
  //   function compararObjetivos(a, b) {
  //     if (a.id < b.id) { return -1; }
  //     if (a.id > b.id) { return 1; }
  //     return 0;
  //   }
  //   this.productos.sort(compararObjetivos);
  //   this.productos = this.productos.slice();
    
  //   console.log('ordenados', this.productos);
  // }


}
