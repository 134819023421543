<app-loading *ngIf="loading"></app-loading>

<div class="row header azul r0">
    <div class="col-11">
       <h2 class="animate__animated animate__fadeIn" > <i class="fa-solid fa-print "></i> Destinos  <span> de impresión</span></h2>
    </div>
    <div class="col-1 text-right hvr-icon-bounce">
        <h2><i class="fa-solid fa-circle-question hvr-icon pointer" (click)="_m.info('Destinos de Impresión', 'Cada producto debe tener asociado un lugar donde se debe preparar. Por ejemplo si se comanda un pisco Sour, debiesemos tener creado una destino de impresión que se llame BAR. ')"></i></h2>
    </div>
</div>
 <div class="row r0">
    <div class="col-12 col-md-5 col-lg-4 usuarios shadow pad20 tablaUsuarios">
        
        <div class="row animate__animated animate__fadeIn">
            <div class="col-6">
                <div class="hvr-forward">
                    <p class="botonMenu animate__animated animate__pulse" style="margin:10px 0 0 0" data-bs-toggle="offcanvas" data-disableScrolling="false" href="#menuData" role="button" aria-controls="menuData"> < Menu Data </p>
                </div>
             </div>
             <div class="col-6 text-end">
                <button class="btn btn-danger rosado text-light" (click)="newDest()">Crear nuevo</button>
            </div>

        </div>

        <div class="row pad20 ">
            <table class="table table-hover  ">
                <tbody>

                <tr *ngFor="let d of destinations ; index as i " class="pointer" (click)="selectDest(d)" class="animate__animated animate__flipInX pointer">
                    <th scope="row" >{{i + 1}}</th>
                    <td>{{d.name | titlecase }}</td>
                </tr>
                

                </tbody>
            </table>

            <p style="font-size:0.8em" class="pointer hvr-grow" routerLink="/data/config/printers"><i class="fa-solid fa-gear"></i> Configurar impresoras</p>

        </div>
    </div>
   
   
   
 <div class="col-12 col-md-7 col-lg-8 derecha pad20 animate__animated animate__fadeIn">
        <div class="row">
            <div class="col-8">
                <h1 *ngIf="printDest.id > 0"><span>Editar</span> <br> {{printDest.name}}</h1>
                <h1 *ngIf="printDest.id < 1"><span>Crear</span> <br> Destino de impresión</h1>
            </div>
            <div class="col-4 text-end">
                <h3 (click)="desactivar()">Activo <i [ngClass]="printDest.status == 1 ?  'text-success fa-solid fa-toggle-on animate__animated animate__fadeIn' : 'text-danger fa-solid fa-toggle-off animate__animated animate__fadeIn' "></i></h3>
            </div>
        </div>
      
        <div class="row">
            <div class="col-12 col-md-6">

                <div class="campo">
                    <label>Nombre</label>
                    <input type="text" class="form-control" placeholder="Aqui el nombre..." aria-label="Username" [(ngModel)]="printDest.name" aria-describedby="basic-addon1">
                </div>

                <div class="campo text-end ">
                    <button style="font-size:0.8em" class="btn btn-primary w-100" (click)="guardar()">Guardar</button>
                    <p (click)="borrar()" class="pointer text-danger hvr-grow mt-2" style="font-size:0.7em">Borrar</p>
                </div>
                <!-- <div class="campo">
                    <label>Sector</label>
                    <input type="text" class="form-control" placeholder="Sanchez..." aria-label="lastname" [(ngModel)]="selectedUser.lastName" aria-describedby="basic-addon1">
                </div> -->


              </div>
              
              <!-- <div class="col-12 col-md-6">
               
                <div class="campo">
                    <label>Tienda</label>
                    <select [(ngModel)]="selectedUser.storeId"   class="form-select">
                        <option *ngFor="let store of stores" [ngValue]="store.id">
                            {{ store.name }}
                        </option>
                    </select>
                </div>


                <div class="campo">
                    <label>Nivel de usuario</label>
                    <select [(ngModel)]="selectedUser.level"   class="form-select">
                        <option *ngFor="let l of niveles" [ngValue]="l.id">
                            {{ l.name }}
                        </option>
                    </select>
                </div>

                <div class="campo">
                    <label>Password</label>
                    <input type="password" class="form-control" placeholder="xxxx"  maxlength="4" [(ngModel)]="selectedUser.password" aria-label="pass" aria-describedby="basic-addon1" >
                </div>
           
               
                
            </div> -->
        </div>

    </div> 
</div> 

<app-menu-data></app-menu-data>
