export class priceListModel {
    id: number;
    companyId: number;
    name: string;
    netoBruto: string;
    currency: string;
    type: string;
    status: number;

    constructor() {
        this.id             = 0;
        this.companyId      = 0;
        this.name           = '';
        this.netoBruto      = '';
        this.currency       = 'CLP';
        this.type           = '';
        this.status         = 1;
    }
}
