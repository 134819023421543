export class prodposiModel {
    id: number;
    setId: number;
    productId: '';
    position: number;
    companyId:number;
    status: number;

    constructor() {
        this.id             = 0;
        this.setId          = 0;
        this.productId      = '';
        this.position       = 1;
        this.companyId      = 0;
        this.status         = 1;
    }
}
