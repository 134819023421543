import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ProductStore } from '../storage/products.store';
import { FechasService } from './fechas.service';


@Injectable({
  providedIn: 'root'
})
export class BoletasService {

    public url   = 'http://localhost:3000/ingefactura';
    token        = 'eyJhbGciOiJIUzUxMiJ9.eyJpc3MiOiJJbmdlZmFjdHVyYS1hcGkiLCJzdWIiOiI4ODY6MzIyOjk2OTkyNTcwLTIifQ.jX_F5Oq8_CYS-vIZphq1rdbqFqEVUrFErSM3LGZFkoa6U5et9SMwanriZ37y7zKaL74OsIxoOW916FPYeUvhgA'

  //   boletaTest  = {
  //     "Encabezado": {
  //         "IdDoc": {
  //             "TipoDTE": 39,
  //             "NumPed": "1B17A3F0-4858-43CA-9985-381A5736F50B",
  //             "FchEmis": "2024-03-15",
  //             "IndServicio": "3"
  //         },
  //         "Emisor": {
  //             "RUTEmisor": "99.999.999-9",
  //             "RznSoc": "Tirua S.A",
  //             "GiroEmis": "Restaurant",
  //             "DirOrigen": "Virginia subercaseux 123",
  //             "CmnaOrigen": "LO BARNECHEA"
  //         },
  //         "Receptor": {
  //             "RUTRecep": "66666666-6",
  //             "RznSocRecep": "DESCONOCIDO"
  //         },
  //         "Totales": {
  //             "MntNeto": 8151,
  //             "MntExe": 0,
  //             "IVA": 1549,
  //             "TasaIVA": 19,
  //             "MntTotal": 9700
  //         }
  //     },
  //     "Detalle": [
  //         {
  //             "NroLinDet": 1,
  //             "NmbItem": "Burger Texana",
  //             "QtyItem": 1,
  //             "PrcItem": 7800,
  //             "MontoItem": 7800
  //         },
  //         {
  //             "NroLinDet": 2,
  //             "NmbItem": "--> Agregado Palta",
  //             "QtyItem": 1,
  //             "PrcItem": 100,
  //             "MontoItem": 100
  //         },
  //         {
  //             "NroLinDet": 3,
  //             "NmbItem": "Empanada de Pino",
  //             "QtyItem": 1,
  //             "PrcItem": 1800,
  //             "MontoItem": 1800
  //         }
  //     ]
  // }

  constructor(private http: HttpClient,
             private productStore: ProductStore,
             private _f:FechasService) { 
              
             }


  getData(ruta: string): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.token}`);
    return this.http.get<any>(`${this.url}${ruta}`, { headers });
  }



  // datos:HttpHeaders   = new HttpHeaders({
  //   'Authorization': 'Bearer ' + this.token,
  //   'Content-Type': 'application/json' // Si es necesario, también configura otros encabezados aquí
  // });


  saveData(ruta:string, body): Observable<any> {
    console.log('SAVE DATA', ruta)
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.token}`);
    return this.http.post<any>(`${this.url}${ruta}`, body, { headers });
  }



  boletaIngefectura(tipoDTE, headId, paquete){

    const state            = this.productStore.getValue();
    const company          = state.company;
    console.log('hacer boleta', company);
    console.log('items', paquete);


    const boleta = {
      Encabezado: {
          IdDoc: {
              TipoDTE: tipoDTE, //'39'
              NumPed: headId,
              FchEmis: this._f.obtenerFechaHoy2(),
              IndServicio: '3'
          },
          Emisor: {
              RUTEmisor: company.rut.replace(/[.]/g, ''),
              RznSoc: company.razonSocial,
              GiroEmis: company.giro,
              DirOrigen: company.direccion,
              CmnaOrigen: company.comuna
          },
          Receptor: {
              RUTRecep: '66666666-6',
              RznSocRecep: 'DESCONOCIDO'
           },
          Totales: {
              MntNeto: paquete.headSale.newVenta.neto,
              MntExe: 0,
              IVA: paquete.headSale.newVenta.iva,
              TasaIVA:19,
              MntTotal: paquete.headSale.newVenta.total
          }
      },
      Detalle: []
  }


  let num   = 0;
  let neto  = 0;
  let total = 0;
  
  for (let p of paquete.detVenta){

    // if (){}

    num   ++;
    const newItem = {
                          NroLinDet: num,
                          NmbItem: p.productName,
                          QtyItem: p.quantity,
                          PrcItem: p.price,
                          MontoItem: p.total
                      }

    boleta.Detalle.push(newItem);
  }

  const boletaValida = this.validarBoleta(boleta)
  
  if(boletaValida){
    return boleta
  }


  return 'error';
  }




  validarBoleta(boleta){
    console.log('validar boleta', boleta);
    let neto  = 0;
    let total = 0;

    for (let b of boleta.Detalle){
      neto  += b.MontoItem / 1.19;
      total += b.MontoItem;
    }

    if (Math.round(neto) != boleta.Encabezado.Totales.MntNeto){
      console.log('neto no coincide', neto, boleta.Encabezado.Totales.MntNeto );
      return false
    } 
  
    if (total != boleta.Encabezado.Totales.MntTotal){
      console.log('neto no coincide', neto, boleta.Encabezado.Totales );
      return false
    } 
    
    if ( (boleta.Encabezado.Totales.MntTotal - boleta.Encabezado.Totales.MntNeto) != boleta.Encabezado.Totales.IVA){
      console.log('el iva no coincide', boleta.Encabezado.Totales );
      return false
    } 
 

    return true;
  }
}
