export class cajaModel {
    id: number;
    storeId: number;
    name: string;
    status: number;
    constructor() {
        this.id             = 0;
        this.storeId        = 0;
        this.name           = '';
        this.status         = 1;
    }
}


