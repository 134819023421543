<app-declaracion *ngIf="modalCierre" [datoDesdeListado]="selectedShift" (enviarPadre)="recibirCierre($event)" ></app-declaracion>


  <div *ngIf="abrirVista" class="fondoModal">
    <!-- TURNO ABIERTO  -->
    <div *ngIf="!loading && shift.id > 0" class="row cuerpo r0">
        <div class="col-12 col-md-6 col-lg-4 azul animate__animated animate__slideInLeft bordes">
            
            <div class="row">
                <div class="col-12 text-end hvr-icon-rotate">
                    <i *ngIf="!loading2" class="fas fa-times fa-2x hvr-icon text-light pointer" (click)="close()"></i>
                    <i *ngIf="loading2" class="fas fa-times fa-2x fa-spin text-danger"></i>
                </div>
            </div>

            <div class="row text-light mt-4">
                <div class="col-12 titulo">
                    <h1>Turno  <br> Abierto</h1>
                    <p>{{params.caja.name}}</p>
                </div>
            </div>

            <hr>

            <div class="row">
                <div class="col-12 info">
                    <h5>{{shift.name}}</h5>
                    <p><span>Abrió el </span> {{shift.started | date: 'dd-MM-yyyy'}} <span>a las </span> {{shift.started | date: 'HH:mm'}}</p>
                    <p><span>Hace </span> {{elapsed.horas}} horas y {{elapsed.minutos}} minutos </p>
                    <p><span>Caja Inicial: </span> $ {{shift.initial_amount | separator }}</p>
                    <button *ngIf="!shift.declaracion" class="btn btn-outline-light w-100 mt-2" (click)="hacerCierre(shift)" >Hacer declaración de caja</button>
                    <p *ngIf="shift.declaracion" class="text-warning mt-2"><i class="fa-solid fa-check"></i> Declaración de Caja rendida</p>
                </div>
            </div>

            <div class="row mt-4">
                <div class="col-12 text-center ">
                    <button *ngIf="!loading2" class="btn btn-danger btn-lg w-100" (click)="closeShift()">Cerrar Turno</button>
                    <p *ngIf="loading2" class="text-light animate__animated animate__fadeIn">Cerrando Turno <i class="fas fa-spinner fa-spin"></i></p>
                </div>
            </div>

        </div>
    </div>




    <!-- TURNO CERRADO  -->
    <div *ngIf="!loading && shift.id == 0" class="row cuerpo r0">
        <div class="col-12 col-md-6 col-lg-4 rojo animate__animated animate__slideInLeft bordes">
            
            <div class="row">
                <div class="col-12 text-end hvr-icon-rotate">
                    <i class="fas fa-times fa-2x hvr-icon text-light pointer" (click)="close()"></i>
                </div>
            </div>

            <div class="row text-light mt-4">
                <div class="col-12 titulo">
                    <h1>Turno  <br> Cerrado</h1>
                    <p>{{params.caja.name}}</p>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <label>Selecciona el turno</label>
                    <select [(ngModel)]="turno" (change)="changeShift(turno)"  class="form-select">
                        <option *ngFor="let t of turnos" [ngValue]="t">
                          {{ t.name }} | de {{t.start2}} a {{t.finish2}}
                        </option>
                      </select>


                      <label class="mt-4">Caja inicial</label>
                      <input type="number" class="form-control" [(ngModel)]="shift.initial_amount" min="0" (change)="validar0()"> 
                </div>
            </div>

            <div class="row mt-4">
                <div class="col-12">
                    <button class="btn btn-outline-light btn-lg w-100" (click)="newShift()">Abrir Turno</button>
                </div>
            </div>

        </div>
    </div>


  
  </div>




  <div class="row header azul r0 ">
    <div class="col-12">
       <h2 class="animate__animated animate__fadeIn">   <i class="fa-regular fa-clock icono"></i> Turnos y Cierres </h2>
    </div>
</div>

  <div class="row filtros shadow pad20 text-dark animate__animated animate__fadeIn r0">
    <div class="col-12 ">
        
        <div class="row">
            <div class="col-6 col-md-3 col-lg-2">
                <label>Local</label>
                <select [(ngModel)]="store" (change)="onChangeStore()"  class="form-select">
                    <option *ngFor="let store of stores" [ngValue]="store">
                      {{ store.name }}
                    </option>
                  </select>
            </div>
         
            <div class="col-6 col-md-3 col-lg-2">
                <label>Caja</label>
                <select  [(ngModel)]="caja"  class="form-select">
                    <option *ngFor="let c of cajas" [ngValue]="c">
                      {{ c.name }}
                    </option>
                  </select>
            </div>

            <div class="col-6 col-md-3 col-lg-2">
                <label>Fecha Inicial</label>
                <input type="date" [(ngModel)]="firstDay" class="form-control">
            </div>
            <div class="col-6 col-md-3 col-lg-2">
                <label>Fecha Final</label>
                <input type="date" [(ngModel)]="lastDay" class="form-control">
            </div>


            <div class="col-12 col-md-3">
              <label style="color:rgba(0,0,0,0)">.</label>
                <button class="btn btn-primary w-100 mt" (click)="getHistory()">Buscar </button>
            </div>




        </div>
    </div>
  </div>


  <div *ngIf="!loadingData" class="row text-center r0">
    <div class="col-10 offset-1 col-md-8 offset-md-2 ">
        
        <div *ngIf="shift.id > 0" class="row bg-primary text-light pad20 infoTurno animate__animated animate__flipInX" style="border-radius: 10px;">
            <div class="col-12 col-md-8 text-start">
                <h2>Turno Actual <br> {{shift.name}}</h2>
                <p><span>Abrió el </span> {{shift.started | date: 'dd-MM-yyyy'}} <span>a las </span> {{shift.started | date: 'HH:mm'}}</p>
                <p><span>Hace </span> {{elapsed.horas}} horas y {{elapsed.minutos}} minutos </p>
                <p><span>Caja Inicial: </span> $ {{shift.initial_amount | separator }}</p>
            </div>

            <div class="col-12 col-md-4">
                <button *ngIf="!shift.declaracion" class="btn btn-dark mr-2" (click)="hacerCierre(shift)"> Declarar Caja</button>
                <button class="btn btn-warning" (click)="verCierre(shift)"> Ver Cierre </button>
                <button class="btn btn-danger" (click)="closeShift()">Cerrar Turno</button>            
            </div>
        </div>
    
        <div *ngIf="selectedShift.id != shift.id && selectedShift.id > 0" class="row pad20 infoTurno animate__animated animate__flipInX">
            <div class="col-12 col-md-6 text-start">
                <h2>Turno  Seleccionado {{selectedShift.name}}</h2>
                <p><span>Abrió el </span> {{selectedShift.started | date: 'dd-MM-yyyy'}} <span>a las </span> {{selectedShift.started | date: 'HH:mm'}}</p>
                <p><span>Cerró el </span> {{selectedShift.ended | date: 'dd-MM-yyyy'}} <span>a las </span> {{selectedShift.ended | date: 'HH:mm'}}</p>
                <p><span>Caja Inicial: </span> $ {{selectedShift.initial_amount | separator }}</p>
            </div>
            <div class="col-12 col-md-6 text-end">
                <button *ngIf="!selectedShift.declaracion" class="btn btn-dark animate__animated animate__flipInX mr-2" (click)="hacerCierre(selectedShift)" > Declarar Caja</button>
                <button class="btn btn-warning animate__animated animate__flipInX" (click)="verCierre(selectedShift)" > Ver Cierre </button>
            </div>
        </div>
        
        <div class="row mt-4 pad20 listado shadow-sm  animate__animated animate__slideInUp">
            <div class="col-12 table-responsive">
                <table class="table table-hover">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Turno</th>
                        <th scope="col">Iniciado</th>
                        <th scope="col">Cerrado</th>
                        <th scope="col">Monto inicial</th>
                        <!-- <th scope="col">Gran Grupo</th>
                        <th scope="col">Grupo</th> -->
                      </tr>
                    </thead>
                    <tbody *ngIf="history.length > 0">
                      <tr *ngFor="let h of history ; index as i " class="pointer" (click)="selectTurno(h)">
                        <th scope="row">{{i + 1}}</th>
                        <td >{{h.name}}</td>
                        <td >{{h.started | date: 'dd-MM-yyyy'}} | {{h.started | date: 'HH:mm'}}</td>
                        <td >{{h.ended | date: 'dd-MM-yyyy'}} | {{h.ended | date: 'HH:mm'}}</td>
                        <td > $ {{h.initial_amount | separator }} </td>
                        <!-- <td >{{p.}}</td> -->
                      </tr>
         
                    </tbody>
                    <p *ngIf="history.length < 1">No hay productos para este filtro</p>
                  </table>
            </div>
        </div>

    </div>
</div>





