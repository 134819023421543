 <div *ngIf="!loading" class="row headModif text-light">
    <div class="col-10">
        <h1>Modificadores</h1>
        <p *ngIf="productFrom.maxModifi > 0">{{modifCounts}} / {{productFrom.maxModifi}}</p>
        <div class="row ">
            <div class="col-12 hvr-icon-rotate">
                <p *ngFor="let s of productFrom.modifs" (click)="remover(s)" class="pointer" >{{s.name}} <i class="fas fa-times text-danger hvr-icon" ></i></p>
            </div>
        </div>
    </div>
    <div class = "col-2 text-end hvr-icon-rotate">
        <i class="fas fa-times fa-2x text-light hvr-icon pointer" (click)="selectModif('cerrar')" ></i>
    </div> 
</div>

<div *ngIf="!loading" class="container">
    <div class="row ">
      <div  *ngFor="let m of modificadores" class="col-6 col-md-4">
        <div class="producto" (click)="selectModif(m)">
                <h5>{{m.name}}</h5>
                <p class="text-end">$ {{m.price}}</p>
            </div>
      </div>
    </div>
    
    <div *ngIf="productFrom.maxModifi == 0" class="row">
        <div class="col-12">
            <button class="btn btn-outline-success w-100 mt-3" (click)="selectModif('siguiente')">Siguiente</button>
        </div>
    </div>
  </div>