<div class="row azul r0 pad20 text-light">
    <div class="col-10">
        <h1>Productos</h1>
    </div>
    <div class="col-2 text-end">
        <button type="button" class="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
</div>

<div *ngIf="!loading">

<!-- <div class="row pad20 buscador caja shadow-sm  animate__animated animate__fadeIn">
    <div class="col-12 col-md-4  hvr-icon-rotate">
        <p><i class="fa-solid fa-magnifying-glass hvr-icon"></i> Buscador</p>
        <input type="text" class="form-control" placeholder="Escribe el nombre del producto o su código">
    </div>
  
    <div class="col-12 col-md-3 ">
        <p> Filtrar por Gran Grupo</p>
        <select class="form-select" >
            <option value="VAL">Venta de Alimentos</option>
            <option value="BEB">Venta de Bebestibles</option>
          </select>
    </div>
    <div class="col-12 col-md-3 ">
        <p> Filtrar por Grupos</p>
        <select class="form-select" >
            <option value="VAL">Aperitivos</option>
            <option value="BEB">Fondos</option>
            <option value="BEB">Postres</option>
          </select>
    </div>

</div> -->

<div *ngIf="productosAll.length > 0" class="row r0">
  <div class="col-10 offset-1 ">
      
      <div class="row pad20 buscador caja shadow-sm  animate__animated animate__fadeIn">
          <div class="col-12 col-md-4  hvr-icon-rotate">
              <p><i class="fa-solid fa-magnifying-glass hvr-icon"></i> Buscador</p>
              <input type="text" class="form-control" placeholder="Escribe el nombre del producto o su código" [(ngModel)]="searchString" >
          </div>
        
          <div class="col-12 col-md-3 ">
              <p> Filtrar por Gran Grupo</p>
              <select class="form-select" [(ngModel)]="gg" (change)="selectGG()">
                <option *ngFor="let g of greatGroups" [ngValue]="g">{{g.name}}</option>
              </select>
          </div>


          <div class="col-12 col-md-3 ">
              <p> Filtrar por Grupos</p>
              <select class="form-select" [(ngModel)]="grupo" (change)="selectG()">
                <option *ngFor="let group of groups" [ngValue]="group">{{group.name}}</option>
              </select>

                <p *ngIf="groups.length < 1" style="font-size:0.7em"> No hay grupos aún para este gran grupo</p>
          </div>

          <div class="col-12 col-md-2 ">
              <p>Ordenar lista</p>
              <select class="form-select"  (change)="selectOrdernar($any($event.target).value)">
                <option value="abc"> Alfabeticamente</option>
                <option value="grangrupo"> Gran Grupos</option>
                <option value="grupos"> Grupos</option>
                <option value="id">por Id</option>
              </select>

                <p *ngIf="groups.length < 1" style="font-size:0.7em"> No hay grupos aún para este gran grupo</p>
          </div>
        
          <!-- <div class="col-12 col-md-2 ">
              <p> Exportar a Excel</p>
             <button class="btn btn-outline-success w-100">Descargar</button>
          </div> -->
      </div>

<div class="row mt-4 pad20 listado shadow-sm  animate__animated animate__fadeIn">
    <div class="col-12 table-responsive">
      <table class="table table-hover">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Producto</th>
            <th scope="col">Código</th>
            <th scope="col">Código Técnico</th>
            <!-- <th scope="col">Gran Grupo</th>
            <th scope="col">Grupo</th> -->
          </tr>
        </thead>
        <tbody *ngIf="productos.length > 0">
          <tr *ngFor="let p of productos | filter : { 'name' : searchString, 'code' : searchString, 'codeTec' : searchString} ; index as i " class="pointer" (click)="selectProd(p)" data-bs-dismiss="offcanvas">
            <th scope="row">{{i + 1}}</th>
            <td >{{p.name}}</td>
            <td >{{p.code}}</td>
            <td >{{p.codeTec}}</td>
          </tr>
        </tbody>
        <p *ngIf="productos.length < 1">No hay productos para este filtro</p>
      </table>
    </div>
</div>


</div>


<div class="row cargando" *ngIf="loading">
  <div class="col-12 text-center">
    <p>Cargando <i class="fa-solid fa-hotdog fa-spin text-primary"></i></p>
  </div>
</div>


