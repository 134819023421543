export class messageModel {
    id: any;
    companyId: number;
    message: string;
    constructor() {
        this.id             = '';
        this.companyId      = 0;
        this.message        = '';
    }
}


