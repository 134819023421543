export class configsetModel {
    id: number;
    storeId: number;
    saleChannelId: number;
    setId: number;
    position: number;
    secondary: number;
    fatherSetId: number;
    status: number;
    name: string; // no se guarda en bd, es solo para las vistas

    constructor() {
        this.id             = 0;
        this.storeId        = 0;
        this.saleChannelId  = 0;
        this.setId          = 0;
        this.position       = 0;
        this.secondary      = 0;
        this.fatherSetId    = 0;
        this.status         = 1;
        this.name           = '';
    }
}


