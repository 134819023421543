<div class="row headerModal">
    <div class="col-10 bg-light pendientes ">

            <div class="row headerModal">
                <div class="col-10">
                    <h1>Pendientes de Pago
                    </h1>         
                    <p style="margin-bottom:0">{{params.store.name}}</p>     
                </div>
                <div class="col-2 text-end hvr-icon-rotate pointer">
                    <p style="margin-bottom:0" (click)="cerrar()"><i class="fas fa-times fa-2x hvr-icon"></i></p>
                </div>
            </div>
            <hr>

                
            <div class="row headMenu animate__animated animate__fadeIn r0">
                <div class="col-12 col-md-3">
                    <div class="form-group">
                        <label>Fecha Inicial</label>
                        <input type="date" class="form-control" placeholder="Inicio Periodo" [(ngModel)]="firstDay" name="fechaIni" required>
                    </div>
                </div>
                <div class="col-12 col-md-3">
                    <div class="form-group">
                        <label>Fecha Final</label>
                        <input type="date" class="form-control" placeholder="Fin Periodo" [(ngModel)]="lastDay" name="fechaFin" required>
                    </div>
                </div>
                <div class="col-12 col-md-2 mt-4">
                <button class="btn btn-primary" (click)="filtrar()">Buscar</button>
                </div>
                <div class="col-12 col-md-4">
                    <label>Buscador</label>
                    <input type="text" class="form-control" placeholder="Buscador" [(ngModel)]="searchString" name="fechaIni" required>

                </div>
            </div> 

            <hr>

            <div class="row datos">
                <div class="col-12">
                    <table class="table table-hover ">
                        <thead>
                          <tr>
                            <th scope="col">Fecha Turno</th>
                            <th scope="col">Hora</th>
                            <th scope="col">Total</th>
                            <th scope="col">Cliente</th>
                          </tr>
                        </thead>
                        <tbody *ngIf="ventas.length > 0" >
                          <tr *ngFor="let v of ventas | filter : { 'total' : searchString, 'shiftDate' : searchString, 'firstName': searchString, 'lastName':searchString} ; index as i " 
                              class="pointer" (click)="abrirEnCaja(v)">
                            <td >{{v.shiftDate | date: 'dd-MM-yyyy'}}</td>
                            <td >{{v.closeTime}}</td>
                            <td>$ {{v.total | separator:0}}</td>
                            <td *ngIf="v.firstName.length > 0">{{v.firstName}} {{v.lastName}} </td>
                            <td *ngIf="v.firstName.length < 1"> Sin asignar cliente</td> 
                        </tr>
                        </tbody>
                      </table>
                </div>
            </div>

    </div>
</div>








