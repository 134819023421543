export class orderModel {
    id: string;
    storeId: number;
    ncomanda: number;
    tableId: string;
    tableCode: string;
    productId: string;
    productCode: string;
    productName: string;
    discount: number;
    discountPercent: number;
    neto: number;
    price: number;
    bruto: number;
    IVA: number;
    quantity: number;
    total: number;
    status: number; //0 Borrado, 1Seleccionado, 2Comandado, 3:pagado, 9:anulado
    isMessage: number;
    isModif: number;
    sellerId: number;
    numel: number;
    printed: number;
    await: number;  // retenida
    eater: number;
    date: string;
    realDate: string;
    hasModifs: boolean;
    created_at: string;
    ready:string;
    finished: string;

    constructor() {
        this.id                = '';
        this.storeId           = 0;
        this.ncomanda          = 0;
        this.tableId           = '';
        this.tableCode         = '';
        this.productId         = '';
        this.productCode       = '';
        this.productName       = '';
        this.discount          = 0;
        this.discountPercent   = 0;
        this.price             = 0;
        this.neto              = 0;
        this.bruto             = 0;
        this.IVA               = 0;
        this.quantity          = 0;
        this.total             = 0;
        this.status            = 1; //0 Borrado, 1 Seleccionado, 2 Comandado, 3:pagado, 4:seleccionadoParaPago, 9:anulado
        this.isMessage         = 0;
        this.isModif           = 0;
        this.sellerId          = 0; // garzon
        this.numel             = 0;
        this.printed           = 0;
        this.await             = 0;  // retenida
        this.eater             = 0; // comensal
        this.date              = '';
        this.realDate          = '';
        this.hasModifs         = false;
        this.created_at        = '';
        this.ready             = '';
        this.finished          = '';
        }

}