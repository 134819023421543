export class priceModel {
    id: number;
    priceListId: number;
    productId: '';
    price: number;
    created_at: string;

    constructor() {
        this.id             = 0;
        this.priceListId    = 0;
        this.productId      = '';
        this.price          = 0;
        this.created_at     = '';
    }
}
