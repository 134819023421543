import { cajaModel } from "../data/caja.model";
import { shiftModel } from "../venta/shiftHistory.model";

export class paramsModel {
    store: any;
    caja: any;
    turno: shiftModel;
    priceListId: number;
    constructor() {
        this.store          = {id:0, name:''};
        this.caja           = new cajaModel();
        this.turno          = new shiftModel();
        this.priceListId    = 0;
        }

}