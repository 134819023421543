import { Component } from '@angular/core';

@Component({
  selector: 'app-listasprecio',
  templateUrl: './listasprecio.component.html',
  styleUrls: ['./listasprecio.component.css']
})
export class ListasprecioComponent {

}
