export class productModel {
    id: any;
    companyId: number;
    greatGroupId: number;
    groupId: number;
    subGroupId: number;
    code:string;
    codeTec:string; // codigo alternatiov
    name:string;
    type:number; // 1 mueve inventario // 2 mueve inventario a través de una receta.
    unit:string;
    image: string;
    stockXsale: number;
    modifMenu: number; // 0 producto normal // 1 tiene modificador // 2 es menu
    maxModifi: number; // 0 infinitos modificadores // 1 un modifocador obligaotiro // 2 modificadores obligatorios etc
    status: number;
    created_at: string;
    info:string;
    role:string;
f
    constructor() {
            this.id             = 'new';
            this.companyId      = 0;
            this.greatGroupId   = 1;
            this.groupId        = 0;
            this.subGroupId     = 0;
            this.code           = '';
            this.codeTec        = '';
            this.name           = '';
            this.type           = 2;
            this.unit           = 'UN';
            this.image          = '';
            this.stockXsale     = -1;
            this.modifMenu      = 0;
            this.maxModifi      = 1;
            this.status         = 1;
            this.created_at     = '';
            this.info           = '';
            this.role           = 'V';
    }
}
