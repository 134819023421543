<app-loading *ngIf="loading"></app-loading>


<div class="row header azul r0">
    <div class="col-11">
       <h2 class="animate__animated animate__fadeIn"><i class="fa-regular fa-clock"></i> Turnos</h2>
    </div>
    <div class="col-1 text-right hvr-icon-bounce">
        <h2><i class="fa-solid fa-circle-question hvr-icon pointer" (click)="mensajes.info('Turnos', 'Cada Jornada puede tener 1 o más turnos. Es un periodo de tiempo donde quedan agrupadas todas las ventas dentro de un periodo definido. Por ejemplo un local puede tener un turno de almuerzo de 10:00 a 16:00 y otro de cena de 16:01 a 23:00')"></i></h2>
    </div>
</div>

<div class="row headMenu animate__animated animate__fadeIn r0">
    <div class="col-6 pad20 hvr-forward">
        <p class=" botonMenu animate__animated animate__pulse" data-bs-toggle="offcanvas" data-disableScrolling="false" href="#menuData" role="button" aria-controls="menuData"> < Menu Data </p>
    </div>
    <div class="col-5 text-end " style="padding:30px 20px 0 0 "> 
        <!-- <button *ngIf="grupos.length > 0" class="btn btn-outline-primary animate__animated animate__heartBeat animate__infinite animate__slower" routerLink="/data/producto/new">Crear Producto</button> -->
    </div>
</div> 

<div *ngIf="stores.length > 1" class="row r0">
    <div class="col-12 col-md-8 offset-md-2 col-lg-8 offset-lg-2 opciones shadow">
        <label>Selecciona el local</label>
        <select [(ngModel)]="selectedStore" (change)="onStoreChange()"  class="form-select">
            <option *ngFor="let store of stores" [ngValue]="store">
              {{ store.name }}
            </option>
          </select>
    </div>
</div>

<div *ngIf="modalNuevo" class="row r0">
    <div class="col-10 offset-1  col-lg-8 offset-lg-2 listado shadow animate__animated animate__flipInX">
        <div class="row">
            <div class="col-11">
                <h5>Nuevo Turno</h5>
            </div>
            <div class="col-1 text-right hvr-icon-rotate cruz">
                <i class="fas fa-times hvr-icon" (click)="modalNuevo = false"></i>
            </div>
            <div class="col-12 col-md-4">
                <label>Nombre</label>
                <input type="text" class="form-control"  placeholder="Escribe aqui" [(ngModel)]="newTurno.name" >
            </div>
            <div class="col-12 col-md-3">
                <label>Hora Inicio</label>
                <input type="time" class="form-control"  placeholder="Escribe aqui" [(ngModel)]="newTurno.start" >
            </div>
            <div class="col-12 col-md-3">
                <label>Hora Finish</label>
                <input type="time" class="form-control"  placeholder="Escribe aqui" [(ngModel)]="newTurno.finish" >
            </div>
            <div class="col-12 col-md-2">
                <label>Activa</label>
                <p (click)="modifStatus('new')"><i [ngClass]="newTurno.status > 0 ? 'fa-solid fa-toggle-on icono text-success fa-2x':'fa-solid fa-toggle-off fa-2x icono text-danger' " class="mt-1"></i></p>
            </div>
        </div>
        <div class="row">
            <div class="col-10">
                <button class="btn btn-success w-100" (click)="guardar('insert')" >Guardar</button>
            </div>
            <div class="col-2">
                <button class="btn btn-danger"  (click)="modalNuevo = false;" >Cancelar</button>
            </div>
        </div>
    </div>
</div>        

<div *ngIf="modalEdit" class="row r0">
    <div class="col-10 offset-1 col-md-10 offset-md-1 col-lg-8 offset-lg-2 listado shadow animate__animated animate__flipInX">
        <div class="row">
            <div class="col-11">
                <h5>Editar Turno</h5>
            </div>
            <div class="col-1 text-right hvr-icon-rotate cruz">
                <i class="fas fa-times hvr-icon" (click)="modalEdit = false"></i>
            </div>
            <div class="col-12 col-md-4">
                <label>Nombre</label>
                <input type="text" class="form-control"  placeholder="Escribe aqui" [(ngModel)]="selectedTurno.name" >
            </div>
            <div class="col-12 col-md-3">
                <label>Hora Inicio</label>
                <input type="time" class="form-control"  placeholder="Escribe aqui" [(ngModel)]="selectedTurno.start2" >
            </div>
            <div class="col-12 col-md-3">
                <label>Hora Finish</label>
                <input type="time" class="form-control"  placeholder="Escribe aqui" [(ngModel)]="selectedTurno.finish2" >
            </div>
            <div class="col-12 col-md-2">
                <label>Activa</label>
                <p (click)="modifStatus('old')"><i [ngClass]="selectedTurno.status > 0 ? 'fa-solid fa-toggle-on icono text-success fa-2x':'fa-solid fa-toggle-off fa-2x icono text-danger' " class="mt-1"></i></p>
            </div>
        </div>
        <div class="row">
            <div class="col-10">
                <button class="btn btn-success w-100" (click)="guardar('update')">Guardar</button>
            </div>
            <div class="col-2">
                <button class="btn btn-danger" (click)="modalEdit = false;">Cancelar</button>
            </div>
        </div>
    </div>
</div>        


<div class="row r0">
    <div class="col-10 offset-1 col-lg-8 offset-lg-2 listado shadow">
        <table class="table table-hover" *ngIf="!loading2">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Nombre</th>
                <th scope="col">Inicio</th>
                <th scope="col">Fin</th>
                <th scope="col">status</th>
              </tr>
            </thead>
            <tbody *ngIf="turnos.length > 0">
              <tr *ngFor="let t of turnos ; index as i " class="pointer" (click)="selectTurno(t)">
                <th scope="row">{{t.position}}</th>
                <td >{{t.name}}</td>
                <td >{{t.start | date:'HH:mm' :'UTC'}}</td>
                <td >{{t.finish | date:'HH:mm' :'UTC'}}</td>
                <td [ngClass]="t.status > 0 ? 'text-success':'text-danger' " ><i class="fa-solid fa-circle"></i></td>
              </tr> 
            </tbody>
            <p *ngIf="turnos.length < 1">No hay turnos para este filtro</p>
          </table>
          <button *ngIf="!loading2" class="btn btn-outline-primary w-100" (click)="nuevo()">Nuevo turno</button>

          <div class="row" *ngIf="loading2">
            <div class="col-12 text-center">
                <p> Cargando <i class="fas fa-spinner fa-spin"></i></p>
            </div>
          </div>
    </div>
</div>




<app-menu-data></app-menu-data>
