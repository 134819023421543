import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { paramsModel } from 'src/app/models/general/params.model';
import { shiftModel } from 'src/app/models/venta/shiftHistory.model';
import { DataService } from 'src/app/services/data.service';
import { MensajesService } from 'src/app/services/mensajes.service';
import { ShiftService } from 'src/app/services/shift.service';
import { TransactionsService } from 'src/app/services/transactions.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-info-footer',
  templateUrl: './info-footer.component.html',
  styleUrls: ['./info-footer.component.css']
})
export class InfoFooterComponent implements OnInit {

  user:any               = {};
  stores:any[]           = [];
  cajas:any[]            = [];
  turnos:any[]           = [];
  loading                = true;
  params                 = new paramsModel();
  currentTime: Date;
  turno:any               = new shiftModel();


  constructor(private _user: UserService,
              public _m: MensajesService,
              public mensajes: MensajesService,
              private shift: ShiftService,
              private conexData: DataService,
              private conexTrans: TransactionsService,
              private router: Router ) { }

  ngOnInit(): void {
    this.user   = this._user.userInfo();
    
    if (localStorage.getItem('paramsGournet')){
      this.params = JSON.parse(localStorage.getItem('paramsGournet'));
      this.openShift()
    }else {
      this.getStores()
    }
    this.info();

  }


    // BUSCO TURNOS ABIERTOS
    openShift(){
      this.conexTrans.getData(`/trans/openShift/${this.params.store.id}/${this.params.caja.id}`).subscribe({
        next: (data: any) => {
          if (data == null){
            console.log('No hay turnos Abierto');
            this.turno = new shiftModel();
          } else {
            console.log('Hay TURNO', data)
            this.turno        = data;
            // const elapsed = this.calculateElapsedTime(data.started);
            // if (elapsed.horas > 24){
            //   this._m.warning('Turno Abierto hace más de 24 horas', `${this.elapsed.horas} horas y ${this.elapsed.minutos} minutos`)
            // }
  
          }
          this.params.turno = this.turno;
          this.loading      = false;
          this.guardarParams();
        },
        error: (error) => {
          console.error(error);
        }
      });
    }



    guardarParams(){
      localStorage.setItem('paramsGournet', JSON.stringify(this.params));
    }


  info(){
    console.log('params', this.params);
  }
   

  // ===================================== //
  // ===================================== //
  // ===================================== //
  // ===================================== //
  // ===================================== //


  getStores(){
    this.conexData.getData('/data/stores').subscribe( resp =>{
      console.log('resp stores', resp);
      this.stores            = resp;
      if (this.stores.length < 1){
        this.mensajes.error('error', 'Comunicate con Gour-net por favor');
        this.loading = false;
        return;
      } 
        if (this.params.store.id > 0){
          const existe = this.stores.find( s => s.id == this.params.store.id);
          if (existe){
            this.params.store = existe;
          } else {
            this.params.store = this.stores[0];
          }
        } else {
          this.params.store = this.stores.find( s => s.id == this.user.storeId);
        }
        this.getCajas();
        console.log('stores', this.stores);
    })
  }


  
  getCajas(){
    this.cajas = [];

    this.conexData.getData(`/data/cashiers/${this.params.store.id}`).subscribe( (resp:any) => {
      console.log('resp', resp);
      if (resp == null){
        console.log('es null, no han creado cajas aún');
        this.router.navigateByUrl('/data/config/cajas');
      } else {
        this.cajas = resp.filter( c => c.status > 0);
        
        if (this.params.caja.id > 0){ 
          const existe = this.cajas.find( c => c.id == this.params.caja.id);
          if (existe){
            this.params.caja  = existe;
          } else {
            this.params.caja = this.cajas[0];
          }
        } else {          
          this.params.caja = this.cajas[0];
        }

        
        // this.getTurnos();
        this.guardarParams();
        this.getTurnos();
        this.info();
      }
      });

  }


  getTurnos(){
    this.conexData.getData(`/data/shifts/${this.params.store.id}`).subscribe( (resp:any) => {
      if (resp == null){
       return 'error';
      } else {
        this.turnos = this.shift.modificarTurnos(resp)      }
        console.log('turnos', resp);
        this.openShift();
    })
  }


 



 

}
