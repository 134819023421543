import { EntityStore, EntityState, StoreConfig } from '@datorama/akita';
import { productModel } from '../models/data/product.model';
import { priceModel } from '../models/data/price.model';
import { modifModel } from '../models/data/modificador.model';


export interface ProductState extends EntityState<productModel> {
  prices: priceModel[];
  modificadores: modifModel[];
  pantallas: any[];
  prodPosis: any[];
  docTypes: any[];
}

@StoreConfig({ name: 'products' })
export class ProductStore extends EntityStore<ProductState, productModel> {
  constructor() {
    super({ prices: [], modificadores: [] });
  }

  updatePrices(prices: priceModel[]): void {
    this.update(state => ({
      ...state,
      prices
    }));
  }
 
  updateModificadores(modificadores: modifModel[]): void {
    this.update(state => ({
      ...state,
      modificadores
    }));
  }

  updatePantallas(pantallas: any[]): void {
    this.update(state => ({
      ...state,
      pantallas
    }));
  }

  updateProdposis(prodPosis: any[]): void {
    this.update(state => ({
      ...state,
      prodPosis
    }));
  }

  getDocTypes(docs: any[]): void {
    this.update(state => ({
      ...state,
      docs
    }));
  }
 
  getPayTypes(payTypes: any[]): void {
    this.update(state => ({
      ...state,
      payTypes
    }));
  }
 
  getStores(stores: any[]): void {
    this.update(state => ({
      ...state,
      stores
    }));
  }

  getCajas(cajas: any[]): void {
    this.update(state => ({
      ...state,
      cajas
    }));
  }
 
  getIntegraciones(integraciones: any[]): void {
    console.log('integraciones', integraciones)
    if (!integraciones || integraciones.length < 1){
      return;
    }
    
    this.update(state => ({
      ...state,
      integraciones
    }));
  }

  getCompany(company: any[]): void {
    this.update(state => ({
      ...state,
      company
    }));
  }
 

 
}