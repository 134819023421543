<div class="row azul r0 pad20 text-light">
    <div class="col-10">
        <h1 (click)="info()">Productos con Modificador</h1>
    </div>
    <div class="col-2 text-end">
        <button type="button" class="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
</div>

<div *ngIf="!loading">

<div *ngIf="ultimo.id != 0" class="row ultimo r0">
 <div class="col-10 animate__animated animate__fadeIn">
    <h5>Clonar</h5>
    <h1>{{ultimo.name}}</h1>
 </div>
 <div class="col-2 hvr-icon-rotate pointer">
    <i class="fas fa-copy fa-2x hvr-icon" (click)="exportarClones()" data-bs-dismiss="offcanvas" aria-label="Close"></i>
 </div>

 <div class="col-12">
    <div *ngFor="let c of clones" class="row">
        <div class="col-10">
            <p>{{c.name}}</p>
        </div>
        <div class="col-2">
            <p>{{c.price}}</p>
        </div>
    </div>
 </div>

 <div class="col-12 text-center mt-2" >
    <button class="btn btn-outline-light" (click)="exportarClones()" data-bs-dismiss="offcanvas" aria-label="Close" >Clonar <i class="fas fa-copy"></i></button>
 </div>
</div>

<div *ngIf="productos.length > 0" class="row r0">
  <div class="col-10 offset-1 ">
      
      <div class="row pad20 buscador caja shadow-sm  animate__animated animate__fadeIn">
          <div class="col-12  hvr-icon-rotate">
              <p><i class="fa-solid fa-magnifying-glass hvr-icon"></i> Buscador</p>
              <input type="text" class="form-control" placeholder="Escribe el nombre del producto o su código" [(ngModel)]="searchString" >
          </div>
      </div>




<div class="row mt-4 pad20 listado shadow-sm  animate__animated animate__fadeIn">
    <div class="col-12 table-responsive">
      <table class="table table-hover">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Producto</th>
          </tr>
        </thead>
        <tbody *ngIf="productos.length > 0" class="listado">
          <tr *ngFor="let p of productos | filter : { 'name' : searchString} ; index as i " class="pointer" (click)="selectProd(p)" >
            <th scope="row">{{i + 1}}</th>
            <td >{{p.name}}</td>
          </tr>
        </tbody>
        <p *ngIf="productos.length < 1">No hay productos con modificador</p>
      </table>
    </div>
</div>


</div>


<div class="row cargando" *ngIf="loading">
  <div class="col-12 text-center">
    <p>Cargando <i class="fa-solid fa-hotdog fa-spin text-primary"></i></p>
  </div>
</div>


