
<div class="row header azul r0">
    <div class="col-12">
       <h2 class="animate__animated animate__fadeIn">            
         <i class="fa-solid fa-chart-simple icono"></i>
        Detalle del día</h2>
    </div>
</div>

<div class="row headMenu animate__animated animate__fadeIn r0">
    <div class="col-6 pad20 hvr-forward">
        <p class=" botonMenu animate__animated animate__pulse" data-bs-toggle="offcanvas" data-disableScrolling="false" href="#menuStats" role="button" aria-controls="menuStats"> < Menu estadisticas </p>
    </div>
    <div class="col-5 text-end " style="padding:30px 20px 0 0 "> 
        <!-- <button *ngIf="productos.length > 0" class="btn btn-outline-primary animate__animated animate__heartBeat animate__infinite animate__slower" routerLink="/data/producto/new">Crear Producto</button> -->
    </div>
</div> 



<app-menu-stats></app-menu-stats>