<app-loading *ngIf="loading"></app-loading>


<div class="row header azul r0">
    <div class="col-12">
       <h2 class="animate__animated animate__fadeIn"> <i class="fa-solid fa-user"></i> Usuarios</h2>
    </div>
</div>


<div class="row r0">
    <div class="col-12 col-md-5 col-lg-4 usuarios shadow pad20 tablaUsuarios">
        
        <div class="row animate__animated animate__fadeIn">
            <div class="col-6">
                <div class="hvr-forward">
                    <p class="botonMenu animate__animated animate__pulse" data-bs-toggle="offcanvas" data-disableScrolling="false" href="#menuConfig" role="button" aria-controls="menuConfig"> < Menu Config </p>
                </div>
             </div>
             <div class="col-6 text-end">
                <button class="btn btn-danger rosado text-light" (click)="newUser()">Crear Usuario</button>
            </div>

          

            <div class="col-12">
                <hr>
                <p style="margin-bottom:0"><i class="fa-solid fa-magnifying-glass hvr-icon"></i> Buscador</p>
                <input type="text" class="form-control" placeholder="Escribe el nombre del producto o su código">
            </div>
        </div>

        <div class="row pad20 ">
            <table class="table table-hover  ">
                <!-- <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Nombre</th>
                    <th scope="col">Last</th>
                    <th scope="col">Handle</th>
                </tr>
                </thead> -->
                <tbody>

                <tr *ngFor="let u of users | filter : { 'firstName' : searchString, 'lastName' : searchString} ; index as i " class="pointer" (click)="selectUser(u)" class="animate__animated animate__flipInX pointer">
                    <th scope="row" >{{i + 1}}</th>
                    <td>{{u.firstName}} {{u.lastName}}</td>
                    <td></td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
   
   
   
    <div class="col-12 col-md-7 col-lg-8 derecha pad20 animate__animated animate__fadeIn">
        <div class="row">
            <div class="col-8">
                <h1 *ngIf="selectedUser.id > 0"><span>Editar</span> <br> {{selectedUser.firstName}} {{selectedUser.lastName}}</h1>
                <h1 *ngIf="selectedUser.id < 1"><span>Crear</span> <br> Usuario</h1>
            </div>
            <div class="col-4 text-end">
                <h3 (click)="desactivar()">Activo <i [ngClass]="selectedUser.status == 1 ?  'text-success fa-solid fa-toggle-on animate__animated animate__fadeIn' : 'text-danger fa-solid fa-toggle-off animate__animated animate__fadeIn' "></i></h3>
            </div>
        </div>
      
        <div class="row">
            <div class="col-12 col-md-6">

                <div class="campo">
                    <label>Nombre</label>
                    <input type="text" class="form-control" placeholder="Alexis..." aria-label="Username" [(ngModel)]="selectedUser.firstName" aria-describedby="basic-addon1">
                </div>
                <div class="campo">
                    <label>Apellido</label>
                    <input type="text" class="form-control" placeholder="Sanchez..." aria-label="lastname" [(ngModel)]="selectedUser.lastName" aria-describedby="basic-addon1">
                </div>

                <div class="campo">
                    <label>Celular</label>
                    <input type="text" class="form-control" placeholder="+569" aria-label="phone" [(ngModel)]="selectedUser.phone" aria-describedby="basic-addon1">
                </div>

                <div class="campo">
                    <label>E-mail</label>
                    <input type="email" class="form-control" placeholder="@" aria-label="email" [(ngModel)]="selectedUser.email" aria-describedby="basic-addon1">
                </div>

              </div>
              
              <div class="col-12 col-md-6">
               
                <div class="campo">
                    <label>Tienda</label>
                    <select [(ngModel)]="selectedUser.storeId"   class="form-select">
                        <option *ngFor="let store of stores" [ngValue]="store.id">
                            {{ store.name }}
                        </option>
                    </select>
                </div>


                <div class="campo">
                    <label>Nivel de usuario</label>
                    <select [(ngModel)]="selectedUser.level"   class="form-select">
                        <option *ngFor="let l of niveles" [ngValue]="l.id">
                            {{ l.name }}
                        </option>
                    </select>
                </div>

                <div class="campo">
                    <label>Password</label>
                    <input type="password" class="form-control" placeholder="xxxx"  maxlength="12" [(ngModel)]="selectedUser.password" aria-label="pass" aria-describedby="basic-addon1" >
                </div>
           
                <div class="campo text-end ">
                    <button style="font-size:0.8em" class="btn btn-primary w-100 mt-4" (click)="guardarUser()">Guardar</button>
                    <p (click)="borrar()" class="pointer text-danger hvr-grow mt-2" style="font-size:0.7em">Borrar usuario</p>
                </div>
            </div>
        </div>

    </div>
</div>

<app-menu-config>