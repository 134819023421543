<div class="row fondo">
    <div class="col-12">

        <div class="row headerPago ">
            <div class="col-10">
                <h1 *ngIf="datoDesdeCaja.tipoPago == 'DEB'">Tarjeta de Debito</h1>
                <h1 *ngIf="datoDesdeCaja.tipoPago == 'credito'">Tarjeta de crédito</h1>
                <h1 *ngIf="datoDesdeCaja.tipoPago == 'EFE'">Efectivo</h1>
                <h1 *ngIf="datoDesdeCaja.tipoPago == 'TRA'">Transferencia</h1>
                <h1 *ngIf="datoDesdeCaja.tipoPago == 'WPC' || datoDesdeCaja.tipoPago == 'WPD'"><img src="assets/images/webpayBlanco.svg" width="200px"></h1>
                <p class="text-start">Abonado: ${{cifras.abonado}}</p>
                <p class="text-start">Saldo: ${{cifras.saldo}}</p>
            </div>
            <div class="col-2 hvr-rotate pointer text-center">
                <i class="text-light fas fa-times fa-2x hvr-icon" (click)="cerrar()"></i>
              </div>
        </div>

        <div class="row cuerpoModal">
            
            <div class="col-12 col-md-6 offset-md-3 botonera" >
            
                <div class="row mb-3">
                    <div *ngIf="datoDesdeCaja.tipoPago != 'EFE' " class="col-10 offset-1" style="padding:0">
                        <label class="text-light" >Monto a pagar</label>
                        <input type="number" class="form-control  formuTotal text-center" min='0' id="basic-url" aria-describedby="basic-addon3 basic-addon4"  [(ngModel)]="cifras.amountA" (change)="changePago()">
                    </div>
             
                    <div  *ngIf="cifras.tipoPago == 'EFE' " class="col-10 offset-1 efe" style="padding:0">
                        <h2>Monto a pagar $ {{montoSugerido | number}}</h2>
                        <label class="text-light" >Efectivo Entregado</label>
                        <input type="number" class="form-control  formuTotal text-center" min='0' id="basic-url" aria-describedby="basic-addon3 basic-addon4"  [(ngModel)]="cash" (change)="changePago()">
                    </div>
                </div>



                <div class="row numerico">

                    <div class="col-12 contenedor">
                        <div class="btnNum hvr-grow" (click)="btnClick('1')"><p>1</p></div>
                        <div class="btnNum btnCentro hvr-grow" (click)="btnClick('2')"><p>2</p></div>
                        <div class="btnNum hvr-grow" (click)="btnClick('3')"><p>3</p></div>
                    </div>
                    <div class="col-12 contenedor">
                        <div class="btnNum hvr-grow" (click)="btnClick('4')"><p>4</p></div>
                        <div class="btnNum btnCentro hvr-grow" (click)="btnClick('5')"><p>5</p></div>
                        <div class="btnNum hvr-grow" (click)="btnClick('6')"><p>6</p></div>
                    </div>
                    <div class="col-12 contenedor">
                        <div class="btnNum hvr-grow" (click)="btnClick('7')"><p>7</p></div>
                        <div class="btnNum btnCentro hvr-grow" (click)="btnClick('8')"><p>8</p></div>
                        <div class="btnNum hvr-grow" (click)="btnClick('9')"><p>9</p></div>
                    </div>
                    <div class="col-12 contenedor">
                        <div class="btnNum hvr-grow" (click)="btnClick('0')"><p>0</p></div>
                        <div class="btnNum btnCentro hvr-grow" (click)="btnClick('00')"><p>00</p></div>
                        <div class="btnNum hvr-grow" (click)="btnClick('delete')"><p>X</p></div>
                    </div>

                  
                </div>

                <!-- <div class="row mt-2 ">
                        <div class="input-group">
                            <span class="input-group-text" id="basic-addon3">Paga</span>
                            <input type="number" class="form-control" min='0' id="basic-url" aria-describedby="basic-addon3 basic-addon4"  [(ngModel)]="cifras.amountA" (change)="changePago()">
                        </div>
                </div> -->

                <div class="row mt-2 " *ngIf="this.cifras.tipoPago != 'WPC' && this.cifras.tipoPago != 'WPD'">
                    <div class="col-10">
                        <div class="input-group">
                            <span class="input-group-text pointer" id="basic-addon3" (click)="calcPropina()">Propina</span>
                            <input type="number" class="form-control" min='0' id="basic-url" aria-describedby="basic-addon3 basic-addon4"  [(ngModel)]="cifras.propina" (change)="changeTip()">
                        </div>
                    </div>
         
                    <div class="col-2">
                        <button class="btn btn-danger" (click)="borrarPropina()"><i class="fas fa-times"></i></button>
                    </div>
                </div>

                <div class="row mt-2 " *ngIf="datoDesdeCaja.tipoPago == 'credito' ">
                        <div class="col-12">
                            <span class="text-light">Tarjeta de crédito</span>
                            <select [(ngModel)]="selectedCard.code" class="form-control ">
                                <option *ngFor="let c of creditCards" [ngValue]="c.code">{{ c.name }}</option>
                              </select>
                        </div>
                </div>
      


                <div class="row mt-2" *ngIf="cifras.tipoPago == 'EFE' ">
                    <div class="input-group" >
                        <span class="input-group-text" id="basic-addon2"> Vuelto </span>
                        <input type="number" class="form-control" min='0' id="basic-url" aria-describedby="basic-addon3 basic-addon4"  [(ngModel)]="cifras.vuelto"  disabled >
                     </div>
                </div>

                <hr style="color:white">

                <div class="row mt-2 ">
                    <label class="text-light">Abona a la cuenta</label>
                    <div class="input-group" >
                        <span class="input-group-text" id="basic-addon3">$</span>
                        <input type="number" class="form-control" min='0' id="basic-url" aria-describedby="basic-addon3 basic-addon4"  [(ngModel)]="cifras.amountA" disabled>
                    </div>
                </div>


            <div *ngIf="cifras.propina > 0" class="animate__animated animate__flipInX">
                <div *ngIf="cifras.tipoPago != 'EFE' && this.cifras.tipoPago != 'WPC' && this.cifras.tipoPago != 'WPD' " class="row mt-2 ">
                    <label class="text-light">Total + Propina</label>
                    <div class="input-group" >
                        <span class="input-group-text" id="basic-addon3">$</span>
                        <input type="number" class="form-control" min='0' id="basic-url" aria-describedby="basic-addon3 basic-addon4"  [(ngModel)]="cifras.amountP" disabled>
                    </div>
                </div>
            </div>


               
                <div class="row mt-4">
                    <div class="col-12">
                        <button class="btn btn-primary w-100 hvr-grow" (click)="pagar()">Pagar</button>
                    </div>
                </div>

              

            </div>
            </div>


    </div>
</div>

