<p>En desarrollo</p>

<!-- 
<div class="row header azul r0">
    <div class="col-12">
       <h2 class="animate__animated animate__fadeIn"> <i class="fa-solid fa-dollar-sign"></i> Listas de Precio</h2>
    </div>
</div>


<div class="row r0">
    <div class="col-12 col-md-4 col-lg-3 usuarios shadow pad20 ">
        
 

        <div class="row animate__animated animate__fadeIn">
            <div class="col-12 ">
                <div class="hvr-forward">
                    <p class=" botonMenu animate__animated animate__pulse" data-bs-toggle="offcanvas" data-disableScrolling="false" href="#menuData" role="button" aria-controls="menuData"> < Menu Data </p>
                </div>

                <h1>Listas de precios</h1>
                <p style="margin-bottom:0"><i class="fa-solid fa-magnifying-glass hvr-icon"></i> Buscador</p>
                <input type="text" class="form-control" placeholder="Escribe el nombre del producto o su código">
             </div>
        </div>

        <div class="row tablaUsuarios pad20 ">
            <table class="table table-hover  ">

                <tbody>
                    <tr class="animate__animated animate__flipInX">
                        <th scope="row">1</th>
                        <td>01P</td>
                        <td>Lista Principal</td>
                    </tr>
                    <tr class="animate__animated animate__flipInX">
                        <th scope="row">1</th>
                        <td>02P</td>
                        <td>Happy Hour</td>
                    </tr>
                    <tr class="animate__animated animate__flipInX">
                        <th scope="row">1</th>
                        <td>01P</td>
                        <td>Lista Principal</td>
                    </tr>
                    <tr class="animate__animated animate__flipInX">
                        <th scope="row">1</th>
                        <td>02P</td>
                        <td>Happy Hour</td>
                    </tr>
                    <tr class="animate__animated animate__flipInX">
                        <th scope="row">1</th>
                        <td>01P</td>
                        <td>Lista Principal</td>
                    </tr>
                    <tr class="animate__animated animate__flipInX">
                        <th scope="row">1</th>
                        <td>02P</td>
                        <td>Happy Hour</td>
                    </tr>
                    <tr class="animate__animated animate__flipInX">
                        <th scope="row">1</th>
                        <td>01P</td>
                        <td>Lista Principal</td>
                    </tr>
                    <tr class="animate__animated animate__flipInX">
                        <th scope="row">1</th>
                        <td>02P</td>
                        <td>Happy Hour</td>
                    </tr>
                    <tr class="animate__animated animate__flipInX">
                        <th scope="row">1</th>
                        <td>01P</td>
                        <td>Lista Principal</td>
                    </tr>
                    <tr class="animate__animated animate__flipInX">
                        <th scope="row">1</th>
                        <td>02P</td>
                        <td>Happy Hour</td>
                    </tr>
                    <tr class="animate__animated animate__flipInX">
                        <th scope="row">1</th>
                        <td>01P</td>
                        <td>Lista Principal</td>
                    </tr>
                    <tr class="animate__animated animate__flipInX">
                        <th scope="row">1</th>
                        <td>02P</td>
                        <td>Happy Hour</td>
                    </tr>
                    <tr class="animate__animated animate__flipInX">
                        <th scope="row">1</th>
                        <td>01P</td>
                        <td>Lista Principal</td>
                    </tr>
                    <tr class="animate__animated animate__flipInX">
                        <th scope="row">1</th>
                        <td>02P</td>
                        <td>Happy Hour</td>
                    </tr>
                    <tr class="animate__animated animate__flipInX">
                        <th scope="row">1</th>
                        <td>01P</td>
                        <td>Lista Principal</td>
                    </tr>
                    <tr class="animate__animated animate__flipInX">
                        <th scope="row">1</th>
                        <td>02P</td>
                        <td>Happy Hour</td>
                    </tr>
                    <tr class="animate__animated animate__flipInX">
                        <th scope="row">1</th>
                        <td>01P</td>
                        <td>Lista Principal</td>
                    </tr>
                    <tr class="animate__animated animate__flipInX">
                        <th scope="row">1</th>
                        <td>02P</td>
                        <td>Happy Hour</td>
                    </tr>
                    <tr class="animate__animated animate__flipInX">
                        <th scope="row">1</th>
                        <td>01P</td>
                        <td>Lista Principal</td>
                    </tr>
                    <tr class="animate__animated animate__flipInX">
                        <th scope="row">1</th>
                        <td>02P</td>
                        <td>Happy Hour</td>
                    </tr>
                    <tr class="animate__animated animate__flipInX">
                        <th scope="row">1</th>
                        <td>01P</td>
                        <td>Lista Principal</td>
                    </tr>
                    <tr class="animate__animated animate__flipInX">
                        <th scope="row">1</th>
                        <td>02P</td>
                        <td>Happy Hour</td>
                    </tr>
                    <tr class="animate__animated animate__flipInX">
                        <th scope="row">1</th>
                        <td>01P</td>
                        <td>Lista Principal</td>
                    </tr>
                    <tr class="animate__animated animate__flipInX">
                        <th scope="row">1</th>
                        <td>02P</td>
                        <td>Happy Hour</td>
                    </tr>
                    <tr class="animate__animated animate__flipInX">
                        <th scope="row">1</th>
                        <td>01P</td>
                        <td>Lista Principal</td>
                    </tr>
                    <tr class="animate__animated animate__flipInX">
                        <th scope="row">1</th>
                        <td>02P</td>
                        <td>Happy Hour</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
   
   
   
    <div class="col-12 col-md deracha pad20 animate__animated animate__fadeIn">
        <div class="row">
            <div class="col-12">
                <h1>Crear nueva Lista</h1>
            </div>
        </div>
      
        <div class="row">
            <div class="col-12 col-md-4">

                <div class="campo">
                    <label>Nombre</label>
                    <input type="text" class="form-control" placeholder="Happy Hour..." aria-label="nombre" aria-describedby="basic-addon1">
                </div>
                <div class="campo">
                    <label>Neto o Bruto</label>
                    <select class="form-select" >
                      <option value="1">Neto</option>
                      <option value="2">Bruto</option>
                    </select>
                </div>
                <div class="campo">
                    <label>Precio o costo</label>
                    <select class="form-select" >
                      <option value="1">Precio</option>
                      <option value="2">Costo</option>
                    </select>
                </div>


                  <div class="row">
                    <div class="col-12 col-md-6">
                        <button class="btn btn-outline-danger w-100">Borrar</button>
                    </div>
                    <div class="col-12 col-md-6">
                        <button class="btn btn-outline-success w-100">Guardar</button>
                    </div>
                  </div>



            </div>
        </div>

    </div>
</div>



<app-menu-data></app-menu-data> -->