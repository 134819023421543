import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { paramsModel } from 'src/app/models/general/params.model';
import { SalesService } from 'src/app/services/sales.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-pendiente',
  standalone: false,
  templateUrl: './pendiente.component.html',
  styleUrl: './pendiente.component.css'
})
export class PendienteComponent {

  @Output()
  enviarPadre: EventEmitter<object> = new EventEmitter<object>();


  loading                  = true;
  searchString             = '';


  store:any                = {};
  ids:any[]                = [];
  cajasAll:any[]           = [];
  cajas:any[]              = [];

  date                     = new Date();
  firstDay                 = new Date(this.date.getFullYear(), this.date.getMonth(), 1).toString();
  lastDay                  = new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0).toString();

  ventas                   = [];
  params                   = new paramsModel();

  constructor(
    private _user:UserService, 
    private conexSales: SalesService,
    private router: Router
    ){
      if (localStorage.getItem('paramsGournet')){
        this.params = JSON.parse(localStorage.getItem('paramsGournet'))
      } else {
        // CERRAR MODAL
      }
  
      this.firstDay = this._user.formatoFechaSql(this.firstDay);
      this.lastDay  = this._user.formatoFechaSql(this.lastDay);
      this.filtrar();
}




  filtrar(){
    this.ventas         = [];
    const last_con_hora = this.lastDay + ' 23:59:59.999';

    console.log('firstDay', this.firstDay);
    console.log('lastDat', last_con_hora);

    this.conexSales.traeDatos(`/stats/headPendientes/${this.params.store.id}/${this.firstDay}/${last_con_hora}`).subscribe({
      next: (data: any) => {
        if (!data){
          this.ventas = [];
        } else {

          for (let v of data){
            if (v.docType != 'VNCE' && v.docType != 'VNCM'){
              this.ventas.push(v);
            } 
          }          
        }

  
      },
      error: (error) => {
        console.error(error);
      }
    });
  }


  cerrar(){
    const cerrar = {id:0}
    this.enviarPadre.emit(cerrar);
  }


  abrirEnCaja(v){
    let tipo = 'directa';
    
    if (v.saleChannelId == 1){
      tipo = 'salon';
    }

    this.router.navigateByUrl('/ruta-ficticia').then(() => {
      this.router.navigate([`/cajas/caja/${tipo}/${v.id}`]);
    });


  }



}

