import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { MensajesService } from 'src/app/services/mensajes.service';
import { TransactionsService } from 'src/app/services/transactions.service';
import { UserService } from 'src/app/services/user.service';
import { forkJoin } from 'rxjs';
import { tableModel } from 'src/app/models/data/table.model';
import { shiftModel } from 'src/app/models/venta/shiftHistory.model';
import { FechasService } from 'src/app/services/fechas.service';

@Component({
  selector: 'app-tables',
  templateUrl: './tables.component.html',
  styleUrls: ['./tables.component.css']
})
export class TablesComponent {

  loading                 = true;
  loading2                = false;
  user:any                = {};
  turno:shiftModel        = new shiftModel();
  selectedStore:any       = {};

  sectores:any            = [];
  selectedSector:any      = { id: 0,  name: '', storeId: '', status: 1}

  mesasAll:any[]          = [];
  mesas:any[]             = [];
  modalNpersonas          = false;
  table:tableModel        = new tableModel();



  constructor(  
    private router: Router,
    private _user:UserService,
    public _m: MensajesService,
    private _f: FechasService,
    private conexData: DataService,
    private conexTrans: TransactionsService) { 
    
    this.user    = this._user.userInfo();
    console.log('user', this.user);
}


ngOnInit(): void {
  this.getData();
  this.turno = JSON.parse(localStorage.getItem('paramsGournet')).turno
  console.log('turno',this.turno)
}

getData(){
  this.loading = true;
  forkJoin([
    this.conexData.getData(`/data/salon/sectores/${this.user.storeId}`),
    this.conexTrans.getData(`/mesas/mesas/${this.user.storeId}`)
  ]).subscribe({ 
    next: (data:any) => {
      console.log('data', data);
      
      if(!data[0]){
        this._m.warning('No hay sectores creados','Seras redireccionado a la pantalla de sectores.');
        this.router.navigateByUrl('data/config/sectores');
        return;
      }

      this.sectores         = data[0];
      this.sectores.push( { id: 0,  name: 'Mis Mesas', storeId: '0', status: 1})
      this.selectedSector   = this.sectores[0];
      
      if(!data[1]){
        console.log('no hay mesas');
        this.loading = false;
        this._m.error('No hay mesas creadas', 'Crea mesas en Data > Configuración Local > Mesas')
      } else {
        this.mesasAll =  data[1];
        this.filtrarSector();
      }
      
      this.loading = false;
      

    },
    error: (error) => {
      console.error(error);
    }
  });
}

filtrarSector(){
  console.log('sector', this.selectedSector);
  console.log('mesas ALL', this.mesasAll);
  this.mesas    = this.mesasAll.filter( m => m.sectorId == this.selectedSector.id);
  this.loading  = false;
}

selectSector(s){


  console.log('s',s);
  this.selectedSector = s;

  if (this.selectedSector.id > 0){
    this.mesas = this.mesasAll.filter( m => m.sectorId == this.selectedSector.id);
  } else {
    this.mesas = this.mesasAll.filter( m => m.userId ==  this.user.id );
  }

}

selectMesa(m){
  this.table             = m;
  console.log('this.table', this.table);

  //0: borrado 1:libre, 2: ocupada, 3: comandada, 5:precuenta
  switch (m.status) {
    case 1:
        this.pedirNpeople(m);
        break;
    case 2:
        this._m.warning('Mesa ocupada', '');
        this.abrirMesa();
        break;
    case 3:
      this.abrirMesa();
    default:
        // Código a ejecutar cuando ninguno de los casos anteriores coincide
  }
}


pedirNpeople(m:any){
  console.log('levantar npersonas', m);
  this.table.npeople  = 0;
  this.modalNpersonas = true;
}



abrirMesa(){
  console.log('abrir mesa');
  this.router.navigateByUrl(`/salon/mesa/${this.table.id}`);

}

clickPersona(num:number){
  console.log('click', num);
  
  // CASO CERRAR
  if (num == 999){
    this.modalNpersonas = false;
    this.table.npeople = 1;
    return;
  }

  let n1:any = this.table.npeople.toString();


  // CASO BORRAR
  if (num == -1){
    console.log('n1', n1, 'largo', n1.length);
    if (n1 != '0' && n1.length > 1){
      n1 = n1.slice(0,-1);
      this.table.npeople = Number(n1);
      return;
    }

    if (n1 != '0' && n1.length == 1){
      n1 = 0
      this.table.npeople = Number(n1);
      return;
    }
    return
  }

  //Caso ok
  if (num == 100){
    if(n1 == 0 ){ n1 = 1; }
    this.modalNpersonas = false;
    console.log('mesa', this.table);
    this.updateMesa();
    return;
  }

  if (this.table.npeople == 0){
    n1 = '';
  } 
  const n2 = Number(n1 + num.toString());
  if (n2 > 98){
    this._m.warning('¿Cabe tanta gente en la mesa?', 'Escogiste un numero muy grande')
    this.table.npeople = Number(n1);
    return;
  }
  this.table.npeople = n2;
}


updateMesa(){
  this.loading2 = true;


  console.log('debo cambiar estado de m', this.table);
  if (this.table.status == 1){
    this.table.status         = 2;
    this.table.openedAt       = this._f.obtenerDatetime();
    this.table.date           = this.turno.started;
    this.table.realDate       = this._f.obtenerFechaHoySQL();
    this.table.isOpen         = 1;
    this.table.openFrom       = '';
    this.table.userId         = this.user.id;
  }

  console.log('table despues', this.table);
  this.conexTrans.guardarDato(`/mesas/updateTable`, this.table).subscribe({
    next:(resp:any) =>{
      console.log('update mesa', resp);
      this.router.navigateByUrl(`/salon/mesa/${this.table.id}`);
    },
    error:(err:any) =>{
      console.log('error', err);
      this._m.error('error', err);
    },
    complete: () => { this.loading2 = false;}
  })



//   {
//     "id": "C1589BD1-7643-4D9A-BFB1-10809161C4C6",
//     "storeId": 1,
//     "code": "M01",
//     "sectorId": 1,
//     "openedAt": "1900-01-01T00:00:00.000Z",
//     "date": "1900-01-01T00:00:00.000Z",
//     "realDate": "1900-01-01T00:00:00.000Z",
//     "userId": 0,
//     "isOpen": 0,
//     "openFrom": "",
//     "comment": "",
//     "npeople": 9,
//     "status": 1,
//     "hold": 0
// }
}

}
