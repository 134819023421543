<app-loading *ngIf="loading"></app-loading>

<div class="row header azul r0">
    <div class="col-11">
       <h2 class="animate__animated animate__fadeIn" > <i class="fa-solid fa-print "></i> Impresoras</h2>
    </div>
    <div class="col-1 text-right hvr-icon-bounce">
        <h2><i class="fa-solid fa-circle-question hvr-icon pointer" (click)="_m.info('Destinos de Impresión', 'Cada producto debe tener asociado un lugar donde se debe preparar. Por ejemplo si se comanda un pisco Sour, debiesemos tener creado una destino de impresión que se llame BAR. ')"></i></h2>
    </div>
</div>
 <div *ngIf="!loading" class="row r0">
    <div class="col-12 col-md-5 col-lg-4 usuarios shadow pad20 tablaUsuarios">
        
        <div class="row animate__animated animate__fadeIn">
            <div class="col-6">
                <div class="hvr-forward">
                    <p class="botonMenu animate__animated animate__pulse" style="margin:10px 0 0 0" data-bs-toggle="offcanvas" data-disableScrolling="false" href="#menuData" role="button" aria-controls="menuData"> < Menu Data </p>
                </div>
             </div>
             <div class="col-6 text-end">
                <button class="btn btn-danger rosado text-light" (click)="newPrint()">Crear nuevo</button>
            </div>

        </div>

        <div *ngIf="stores.length > 1" class="row">
            <div class="col-12">
                <div class="campo">
                    <label>Tienda</label>
                    <!-- <select [(ngModel)]="store" class="form-select" [ngModelOptions]="{standalone: true}" (change)="changeLocal($any($event.target).value)">
                        <option *ngFor="let store of stores" [value]="store.id">
                            {{ store.name }}
                        </option>
                    </select> -->

                    <select [(ngModel)]="selectedStore" (change)="onStoreChange($event.target.value)" class="form-select">
                        <option *ngFor="let store of stores" [value]="store.id">
                            {{ store.name }}
                        </option>
                      </select>
  
                </div>
            </div>
        </div>




        <div class="row pad20 ">
            <table *ngIf="printers.length > 0" class="table table-hover  ">
                <tbody>
                <tr *ngFor="let p of printers ; index as i " class="pointer" (click)="selectPrinter(p)" class="animate__animated animate__flipInX pointer">
                    <th scope="row" >{{i + 1}}</th>
                    <td>{{p.name | titlecase }}</td>
                </tr>
                </tbody>
            </table>

            <p *ngIf="printers.length < 1"> No hay impresora creadas para este local </p>

        </div>
    </div>
   
   
   
 <div class="col-12 col-md-7 col-lg-8 derecha pad20 animate__animated animate__fadeIn">
        <div class="row">
            <div class="col-8">
                <h1 *ngIf="printer.id > 0"><span>Editar</span> <br> {{printer.name}}</h1>
                <h1 *ngIf="printer.id < 1"><span>Crear</span> <br> Impresora</h1>
            </div>
            <div class="col-4 text-end">
                <!-- <h3 (click)="desactivar()">Activo <i [ngClass]="printer.status == 1 ?  'text-success fa-solid fa-toggle-on animate__animated animate__fadeIn' : 'text-danger fa-solid fa-toggle-off animate__animated animate__fadeIn' "></i></h3> -->
            </div>
        </div>
      
        <div class="row">
            <div class="col-12 col-md-6">

                <div class="campo">
                    <label>Nombre para reconocerla</label>
                    <input type="text" class="form-control" placeholder="ej: epson cuadrada." aria-label="name" [(ngModel)]="printer.name" aria-describedby="basic-addon1">
                </div>

                <div class="campo">
                    <label>Destino de impresión</label>
                    <select [(ngModel)]="printer.destinationId"   class="form-select" >
                        <option *ngFor="let d of destinations" [ngValue]="d.id">
                            {{ d.name }}
                        </option>
                    </select>                
                </div>

                <div class="campo">
                    <label>Ip</label>
                    <input type="text" class="form-control" placeholder="ej: 192.XXX.XX.XX" aria-label="ip" [(ngModel)]="printer.ip" aria-describedby="basic-addon1">
                </div>

                <div class="campo">
                    <label>Puerto</label>
                    <input type="text" class="form-control" placeholder="ej: 1433" aria-label="port" [(ngModel)]="printer.port" aria-describedby="basic-addon1">
                </div>
         
                <div class="campo">
                    <label>Sistema o marca</label>
                    <input type="text" class="form-control" placeholder="ej: Bixolon" aria-label="port" [(ngModel)]="printer.printerOS" aria-describedby="basic-addon1">
                </div>

           

                <div class="campo text-end ">
                    <button style="font-size:0.8em" class="btn btn-primary w-100" (click)="guardar()">Guardar</button>
                    <!-- <p (click)="borrar()" class="pointer text-danger hvr-grow mt-2" style="font-size:0.7em">Borrar</p> -->
                </div>
   


              </div>
            
        </div>

    </div> 
</div> 

<app-menu-data></app-menu-data>
