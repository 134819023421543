import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { cajaModel } from 'src/app/models/data/caja.model';
import { DataService } from 'src/app/services/data.service';
import { MensajesService } from 'src/app/services/mensajes.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-cajas',
  templateUrl: './cajas.component.html',
  styleUrls: ['./cajas.component.css']
})
export class CajasComponent {

  loading                = true;
  user:any               = {};
  stores:any             = [];
  selectedStore:any      = {};
  storeId                = 0;
  cajas:any[]            = [];
  caja:any               = new cajaModel();
  newCaja:any            = new cajaModel(); 
  memoria:string         = ''
  modalNuevo             = false;
  modalEdit              = false;
  constructor(  private router: Router,
                private conexData: DataService,
                private _user:UserService,
                public mensajes: MensajesService) { 
  this.user    = this._user.userInfo();
  this.storeId = this.user.storeId;

  console.log('user', this.user);
    }

  ngOnInit(): void {
    this.getStores();
  }

  getStores(){
    this.conexData.getData('/data/stores').subscribe( resp =>{
      console.log('resp stores', resp);
      this.stores            = resp;
      if (this.stores.length < 1){
        this.mensajes.error('error', 'Comunicate con Gour-net por favor');
        this.loading = false;
        return;
      } 
        this.selectedStore = this.stores.find( s => s.id == this.user.storeId);
        this.getCajas();
        console.log('stores', this.stores);
    })
  }


  
  getCajas(){
    this.cajas = [];

    this.conexData.getData(`/data/cashiers/${this.selectedStore.id}`).subscribe( (resp:any) => {
      console.log('resp', resp);

      if (resp == null){
        console.log('es null')
        const caja   = new cajaModel();
        caja.name    = 'Caja 1';
        caja.storeId = this.selectedStore.id;
        console.log('caja', caja);
        this.guardarCaja(caja);
      } else {
        this.cajas = resp;
        this.loading = false;
      }
    })
  }


  guardarCaja(caja){
    let tarea = 'update';
    if (caja.id < 1){
      tarea        = 'insert';
      caja.storeId = this.selectedStore.id;
    }

    console.log('tarea', tarea, caja);

    const repetido = this.cajas.find( c => c.name == caja.name);
    if (repetido){
      this.mensajes.error('Repetido', 'ojo ya hay una caja que se llama ' + caja.name)
      caja.name = this.memoria;
      return
    }
    this.conexData.guardarDato(`/postData/cashier/${tarea}`, caja).subscribe( (data:any) => {
      if (tarea == 'insert'){
        caja.id      = data.recordset[0].id; 
        this.cajas.push(caja);
        this.newCaja =  new cajaModel();
        this.modalNuevo = false;
      }
      this.loading = false;
    })
  }


  selectCaja(c:any){
    this.modalEdit   = true;
    this.modalNuevo  = false;

    this.memoria   = c.name;
    this.caja      = c;
    console.log('caja', this.caja);
  }


  onStoreChange() {
    // this.loading = true;
    console.log('store', this.selectedStore);
    this.getCajas();
 
  }


  nuevo(){
    this.newCaja    = new cajaModel();
    this.modalNuevo = true;
    this.modalEdit  = false;
  }
  

}
