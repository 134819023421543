

<div class="offcanvas offcanvas-start bg-light" tabindex="-1" id="menuConfig" aria-labelledby="menuConfigLabel">
    <div class="d-flex flex-column flex-shrink-0 p-3 " style="width: 100%;">
        <div class="row">
            <div class="col-10">
                <h1>Menu Configuraciones</h1>
            </div>
            <div class="col-2 hvr-icon-rotate">
                <i class="fas fa-times fa-2x pointer hvr-icon" data-bs-dismiss="offcanvas" aria-label="Close"></i>
            </div> 
        </div>
        <hr>
        <ul class="nav nav-pills flex-column mb-auto">
          <li class="nav-item">
            <a routerLink="/config/config" class="nav-link link-dark" >
              <i class="fa-solid fa-chevron-right"></i> Configuraciones
            </a>
          </li>
          <li>
            <a routerLink="/config/usuarios" class="nav-link link-dark">
              <i class="fa-solid fa-chevron-right"></i> Usuarios
            </a>
          </li>
      
        </ul>

        <hr>


      </div>

  </div>