import { Routes } from "@angular/router";
import { HomeComponent } from "./home/home.component";
import { LoginComponent } from "./login/login.component";
import { AuthGuard } from '../guards/auth.guard';
import { ShiftComponent } from "./shift/shift.component";
import { NodesComponent } from "./nodes/nodes.component";

export const routesGenerals: Routes = [
    {path: 'login', component: LoginComponent},
    {path: 'nodes', component: NodesComponent},
    {path: 'home', component: HomeComponent, canActivate: [AuthGuard]},
    {path: 'shift/:origen', component: ShiftComponent, canActivate: [AuthGuard]},
    {path: '**', pathMatch: 'full', redirectTo: 'login'}
  ];
  
  