import { Component, Output, EventEmitter, Input } from '@angular/core';
import { paramsModel } from 'src/app/models/general/params.model';
import { declaracionModel } from 'src/app/models/venta/declaracion.model';
import { shiftModel } from 'src/app/models/venta/shiftHistory.model';
import { MensajesService } from 'src/app/services/mensajes.service';
import { SalesService } from 'src/app/services/sales.service';
import { UserService } from 'src/app/services/user.service';
import { ProductStore } from 'src/app/storage/products.store';

@Component({
  selector: 'app-declaracion',
  templateUrl: './declaracion.component.html',
  styleUrls: ['./declaracion.component.css']
})
export class DeclaracionComponent {

  @Input() datoDesdeListado: any;

  @Output()
  enviarPadre: EventEmitter<object> = new EventEmitter<object>();

  loading                = true;
  user:any               = {};
  params                 = new paramsModel();
  
  selectedShift: any     = new shiftModel();
  totalDeclarado         = 0;
  payList:any            = []
  payTypes               = [];

  stores: any[]    = [];
  cajasAll: any[]  = [];
  cajas: any[]     = [];
  store: any;
  caja: any;

  paqueteUpdate  = [];

  constructor(
    public _user:UserService, 
    private conexSales:SalesService,
    private _m: MensajesService,
    private productStore: ProductStore
    // private conexData:DataService,
    // private shiftServices: ShiftService,
    // private conexTrans: TransactionsService,
    // private router: Router,
    // private route: ActivatedRoute,
    
    ){
      this.user   = this._user.userInfo();
      this.params = this._user.getParams();
      this.getMemory();
    };


    ngOnInit(): void {
      this._m.sonido('menu_flick.mp3');
    
      Object.assign(this.selectedShift, this.datoDesdeListado);
      console.log('selectd Shift', this.selectedShift);
      this.selectedShift.cajaName = this.cajasAll.find( c => c.id == this.selectedShift.cashierId).name

      if (this.selectedShift.declaracion){
        this.getDeclaracionUsuario();
      } else {
        this.loading = false;
      }
    } 
    
    getDeclaracionUsuario(){
      console.log('declaracion usuario');
      this.conexSales.traeDatos(`/sales/declaration/${this.selectedShift.id}`).subscribe({
        next: (data: any) => {
          console.log('esto declaró', data);
          console.log('payList', this.payList);

          for (let p of data){
            const existe:any = Object.values(this.payList).find((a:any) => a.id === p.payType);
            console.log('existe', existe);
            if (existe){
              existe.amount = p.total;
              existe.update = true;
              existe.id_declracion = p.id
            
            }
          }

          console.log('payList', this.payList);
          this.calcTotal();
          this.loading = false;
        },
        error: (error) => {
          console.error(error);
          this._m.error('error', error.error);
          this.loading = false;
        }
      });



    }


    getMemory(){
      const state                 = this.productStore.getValue();
      this.stores                 = state.stores;
      this.store                  = this.stores.find( s => s.id == this.params.store.id);
      this.cajasAll               = state.cajas;
      this.cajas                  = this.cajasAll.filter( c => c.storeId == this.store.id);
      this.caja                   = this.cajas.find( ca => ca.id == this.params.caja.id);
      this.payTypes               = state.payTypes;

      this.limpiarPayList();
    
      console.log('payTypes', this.payTypes)
  } 



calcTotal(){

  this.totalDeclarado = 0;
  this.paqueteUpdate  = [];
  const paquete       = [];

  for (const key in this.payList) {
    if (this.payList.hasOwnProperty(key)) {
        const item = this.payList[key];
        if(item.amount.isNaN || item.amount == ''){
          item.amount = 0;
        }

        if (item.amount > 0 || item.update) {
            this.totalDeclarado += item.amount;
            const newDec         = new declaracionModel();
            newDec.storeId       = this.params.store.id;
            newDec.cashierId     = this.caja.id;
            newDec.payType       = item.id;
            newDec.total         = item.amount;
            newDec.shiftId       = this.selectedShift.id;
            newDec.userId        = this.user.id;
            newDec.date          = this._user.dateNow('dia');
            newDec.time          = this._user.dateNow('hora');

            if (item.update){
              newDec.id = item.id_declracion;
              this.paqueteUpdate.push(newDec); // para editar los que ya existen.
            } else {
              paquete.push(newDec);
            }

        }  else {
          item.amount = 0;
        }
    }
}

  return paquete;
}


// this.enviarPadre.emit(p);


validarDeclaracion(){
  const paquete = this.calcTotal();

  if ( this.totalDeclarado < 1){
    this._m.warning('Declaración incompleta', 'El total de la declaración debe ser mayor que 0');
    return;
  }

  this.loading = true;
  
  console.log('validar Declaracion', this.payList);
  console.log('paquete', paquete);

  if (paquete.length < 1 && this.paqueteUpdate.length < 1){
    this._m.warning('Declaración Vacia', 'Debes de clarar algo.');
    return;
  }

 if (paquete.length > 0){
  this.conexSales.guardarDato('/postSales/declaracion', paquete).subscribe({
    next: (data: any) => {
      console.log('tiene declaración hecha', data);
      this._m.ok('Listo', 'Declaración Guardada');

      if (this.paqueteUpdate.length < 1){
        this.enviarPadre.emit(this.selectedShift);
        this.loading = false;
      } else {
        this.actualizarDeclaracion();
      }

    },
    error: (error) => {
      console.error(error);
      this._m.error('error', error.error);
      this.loading = false;
    }
  });
 } else {
  this.actualizarDeclaracion();
 }



}


actualizarDeclaracion(){
  console.log('actualziar declaracion', this.paqueteUpdate);
  for (let p of this.paqueteUpdate){
      let tarea = 'update';
      if (p.total == 0){
        tarea = 'delete'
      }
      console.log('p',p)
      this.conexSales.guardarDato(`/postSales/updateDeclaracion/${tarea}`, p).subscribe({
        next: (data: any) => {
          console.log('update ok', data)
    
        },
        error: (error) => {
          console.error(error);
          this._m.error('error', error.error);
          this.loading = false;
        }
      });
  }

  this.enviarPadre.emit(this.selectedShift);
  this.loading = false;
}


limpiarPayList(){

  this.payList = {};

 for(let p of this.payTypes){
   const newObj = Object.assign({}, p, { amount: 0 });
   this.payList[newObj.code] = newObj;
   }
}



cerrar(p){
  console.log('cerrar',p);
  this.enviarPadre.emit(p);
}

}










