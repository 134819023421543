import { Injectable } from '@angular/core';
import Swal from 'sweetalert2'

@Injectable({
  providedIn: 'root'
})
export class MensajesService {

  constructor() { }


  // this._gs.sonido('Klick.mp3');
  sonido(sonido) {
    const audio = new Audio();
    audio.src = `assets/sounds/${sonido}`;
    audio.play();
  }

  alertAgrega(mensaje:any) {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })
    
    Toast.fire({
      icon: 'success',
      title: mensaje
    })
  }

  
  ok(titulo, mensaje){
    Swal.fire(
      titulo,
      mensaje,
      'success'
    )
  }
  
  error(titulo, mensaje){
    Swal.fire(
      titulo,
      mensaje,
      'error'
    )
  }

  info(titulo, mensaje){
    Swal.fire(
      titulo,
      mensaje,
      'info'
    )
  }
 
  warning(titulo, mensaje){
    Swal.fire(
      titulo,
      mensaje,
      'warning'
    )
  }

  toast(texto:string){
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
      }
    });
    Toast.fire({
      icon: "success",
      title: texto
    });
  }
  

  toastNeutro(texto:string){
    const Toast = Swal.mixin({
      toast: true,
      position: "center",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
      }
    });
    Toast.fire({
      icon: "info",
      title: texto
    });
  }
  

}
