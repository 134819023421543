

<div class="offcanvas offcanvas-start bg-light" tabindex="-1" id="menuData" aria-labelledby="menuDataLabel">
    <div class="d-flex flex-column flex-shrink-0 p-3 " style="width: 100%;">
        <div class="row">
            <div class="col-10">
                <h1>Menu Data</h1>
            </div>
            <div class="col-2 hvr-icon-rotate">
                <i class="fas fa-times fa-2x pointer hvr-icon" data-bs-dismiss="offcanvas" aria-label="Close"></i>
            </div> 
        </div>
        <hr>
        <ul class="nav nav-pills flex-column mb-auto">
          <li class="nav-item">
            <a routerLink="/data/productos" class="nav-link link-dark" data-bs-dismiss="offcanvas" aria-label="Close">
              <i class="fa-solid fa-chevron-right"></i> Productos
            </a>
          </li>
          <!-- <li>
            <a routerLink="/data/producto/nuevo" class="nav-link link-dark"data-bs-dismiss="offcanvas" aria-label="Close">
              <i class="fa-solid fa-chevron-right"></i> Insumos
            </a>
          </li> -->
          <li>
            <a routerLink="/data/pantallas" class="nav-link link-dark"data-bs-dismiss="offcanvas" aria-label="Close">
              <i class="fa-solid fa-chevron-right"></i> Pantallas
            </a>
          </li>
          <li>
            <a routerLink='/data/grupos' class="nav-link link-dark" data-bs-dismiss="offcanvas" aria-label="Close">
              <i class="fa-solid fa-chevron-right"></i>  Grupos
            </a>
          </li>
          <!-- <li>
            <a routerLink='/data/listasprecio' class="nav-link link-dark"data-bs-dismiss="offcanvas" aria-label="Close">
              <i class="fa-solid fa-chevron-right"></i> Listas de precio
            </a>
          </li> -->
          <li>
            <a routerLink='/data/printDestinations' class="nav-link link-dark"data-bs-dismiss="offcanvas" aria-label="Close">
              <i class="fa-solid fa-chevron-right"></i> Destinos de impresión
            </a>
          </li>
          <li>
            <a routerLink='/data/clientes' class="nav-link link-dark"data-bs-dismiss="offcanvas" aria-label="Close">
              <i class="fa-solid fa-chevron-right"></i> Clientes
            </a>
          </li>
          <li>
            <a routerLink='/data/mensajes' class="nav-link link-dark"data-bs-dismiss="offcanvas" aria-label="Close">
              <i class="fa-solid fa-chevron-right"></i> Mensajes
            </a>
          </li>
          
          <li>
            <a data-bs-toggle="collapse" href="#configLocal" role="button" aria-expanded="false" aria-controls="configLocal">
              Configuración Local
            </a>          
            <div class="collapse" id="configLocal">
              <div class="card card-body">
                <ul class="nav nav-pills flex-column mb-auto">
                  <li class="nav-item">
                    <a routerLink="/data/config/cajas" class="nav-link link-dark" data-bs-dismiss="offcanvas" aria-label="Close">
                      <i class="fa-solid fa-chevron-right"></i> Cajas
                    </a>
                  </li>
                  <li class="nav-item">
                    <a routerLink="/data/config/turnos" class="nav-link link-dark" data-bs-dismiss="offcanvas" aria-label="Close">
                      <i class="fa-solid fa-chevron-right"></i> Turnos
                    </a>
                  </li>
                  <li class="nav-item">
                    <a routerLink="/data/config/printers" class="nav-link link-dark" data-bs-dismiss="offcanvas" aria-label="Close">
                      <i class="fa-solid fa-chevron-right"></i> Impresoras
                    </a>
                  </li>
                  <li class="nav-item">
                    <a routerLink="/data/config/sectores" class="nav-link link-dark" data-bs-dismiss="offcanvas" aria-label="Close">
                      <i class="fa-solid fa-chevron-right"></i> Sectores
                    </a>
                  </li>
                  <li class="nav-item">
                    <a routerLink="/data/config/mesas" class="nav-link link-dark" data-bs-dismiss="offcanvas" aria-label="Close">
                      <i class="fa-solid fa-chevron-right"></i> Mesas
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </ul>

        <hr>


      </div>

  </div>