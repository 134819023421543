
<div class="row header azul r0">
    <div class="col-12">
       <h2 class="animate__animated animate__fadeIn">            
         <i class="fa-solid fa-chart-simple icono"></i>
        Ventas</h2>
    </div>
</div>

<div class="row headMenu animate__animated animate__fadeIn r0 pad20 shadow r0  ">
    <div class="col-12 hvr-forward">
        <p class=" botonMenu animate__animated animate__pulse" data-bs-toggle="offcanvas" data-disableScrolling="false" href="#menuStats" role="button" aria-controls="menuStats"> < Menu estadísticas </p>
    </div>

    <div class="col-12 col-md-4 " > 
        <label>Escoge en local</label>
        <select [(ngModel)]="store" (change)="onChangeStore()"  class="form-select">
            <option>Todos</option>
            <option *ngFor="let store of stores" [ngValue]="store">
              {{ store.name }}
            </option>
          </select>
    </div>

    <div class="col-12 col-md-3">
        <div class="form-group">
            <label>Fecha Inicial</label>
            <input type="date" class="form-control" placeholder="Inicio Periodo" [(ngModel)]="firstDay" name="fechaIni" required>
        </div>
    </div>
    <div class="col-12 col-md-3">
        <div class="form-group">
            <label>Fecha Final</label>
            <input type="date" class="form-control" placeholder="Fin Periodo" [(ngModel)]="lastDay" name="fechaFin" required>
        </div>
    </div>
    <div class="col-12 col-md-2 mt-4">
      <button class="btn btn-primary" (click)="filtrar()">Buscar</button>
    </div>
</div> 


<div *ngIf="loadingHistorial == false && ventas.length < 1" class="row r0">
    <div  class="col-12 text-center pad20">
        <p>No hay ventas para los filtros que escogiste</p>
    </div>
</div>


<div *ngIf="loadingHistorial == false && ventas.length > 0" >
    

            <div class="row cifras pad20  r0 rosado text-light shadow">
                <div class="col-6">
                    <h1>Ventas Totales <br> $ {{cifras.pagadas.total - cifras.nc.total | separator:0}}</h1>
                </div>
                <div class="col-6 text-end">
                    <p style="margin-bottom:0">Ventas $ {{cifras.pagadas.total | separator:0}}  | {{cifras.pagadas.cantidad}}</p>
                    <p style="margin-bottom:0">Notas de Crédito $ {{cifras.nc.total | separator:0}}  | {{cifras.nc.cantidad}}</p>
                    <p style="margin-bottom:0">Ventas impagas $ {{cifras.impagas.total | separator:0}} | {{cifras.impagas.cantidad | number}}</p>
                </div>
            </div>
  
    
    <div class="row r0">
        <div class="col-10  offset-1 col-md-10 offset-md-1 col-lg-8 offset-lg-2 tabla table-responsive  animate__animated animate__fadeIn scroll">

            <div class="row  buscador caja mb-3">
                <div class="col-1 offset-0 offset-md-7 hvr-icon-rotate text-end mt-2">
                    <i class="fa-solid fa-magnifying-glass hvr-icon"></i>        
                </div>
                <div class="col-10 col-md-4">
                     <input type="text" class="form-control" placeholder="Buscador" [(ngModel)]="searchString" >
            </div>


            <table class="table table-hover ">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th *ngIf="stores.length > 0">Local</th>
                    <th scope="col">Fecha Turno</th>
                    <th scope="col">Hora</th>
                    <th scope="col">Canal</th>
                    <th scope="col">Total</th>
                    <th scope="col">Estado Pago</th>
                  </tr>
                </thead>
                <tbody *ngIf="ventas.length > 0" >
                  <tr *ngFor="let v of ventas | filter : { 'total' : searchString, 'shiftDate' : searchString} ; index as i " 
                  [ngClass]="v.docType == 'VNCE' || v.docType == 'VNCM' ? 'bg-secondary text-light' : ''  "
                  class="pointer" (click)="selectSale(v)" data-bs-toggle="offcanvas" data-disableScrolling="false" data-bs-target="#offDetalle" aria-controls="offDetalle">
                    
                    <th scope="row">{{i + 1}} 
                        <span *ngIf="v.clientId != '0' " (mouseover)="buscarCliente(v.clientId)">
                            <i class="fa-solid fa-user-large"></i>
                        </span>
                     
                </th>
                   
                    <td *ngIf="stores.length > 0" >{{v.storeName}} </td>
                    <td >{{v.shiftDate | date: 'dd-MM-yyyy'}}</td>
                    <td >{{v.closeTime}}</td>
                    <td *ngIf="v.docType == 'VNCE' || v.docType == 'VNCM'" >Nota de Crédito</td>
                    <td *ngIf="v.docType != 'VNCE' && v.docType != 'VNCM' && v.canal == 'VENTA_DIRECTA' ">Venta Directa</td>
                    <td *ngIf="v.docType != 'VNCE' && v.docType != 'VNCM' && v.canal != 'VENTA_DIRECTA' ">{{v.canal}}</td>
                    <td>$ {{v.total | number}}</td>
                    <td class="text-center"><i [ngClass]="v.status == 2 ? 'fas fa-check text-success' : 'fas fa-times text-danger'"></i></td>
                  </tr>

     
                </tbody>
              </table>
        </div>
    </div>
        
</div>





<div class="offcanvas offcanvas-start row fondoOff " tabindex="-1" id="offDetalle" aria-labelledby="offDetalleLabel">
   <div class="col-12">

        <div [ngClass]="venta.status == 2 ? 'verde' : 'rojo' " class="row p-3 shadow text-light ">
            <div class="col-9">
                <h5 *ngIf="venta.docType == 'VNCE' || venta.docType == 'VNCM'" class="offcanvas-title" id="offDetalleLabel">Detalle Nota de Crédito</h5>
                <h5 *ngIf="venta.docType != 'VNCE' && venta.docType != 'VNCM'" class="offcanvas-title" id="offDetalleLabel">Detalle Venta</h5>
            </div>
            <!-- <div class="col-6 text-center">
                <button *ngIf="venta.status != 2" class="btn btn-outline-light" (click)="abrirEnCaja(venta)">Abrir en Caja</button>
            </div> -->
            <div class="col-3 text-end hvr-icon-rotate">
                <i class="fas fa-times fa-2x pointer hvr-icon" data-bs-dismiss="offcanvas" aria-label="Close" ></i>
            </div>
        </div>

        
        <div  class="scroll">

            <div  class="row encVenta ">
                <div class="col-12 col-md-8">
                    <p><span>Tienda: </span>{{venta.storeName}}</p>
                    <p><span>Caja: </span>{{venta.cajaName}}</p>
                    <p><span>Fecha Turno: </span>{{venta.shiftDate | date: 'dd-MM-yyyy'}}</p>
                    <p><span>Fecha Real: </span>{{venta.realDate | date: 'dd-MM-yyyy'}}</p>
                    <p><span>Hora: </span>{{venta.openTime}}</p>
                    <p><span>Canal: </span>{{venta.canal}}</p>
                    <p><span>Documento: </span>{{venta.docName | titlecase}}</p>
                    <p><span>Cantidad de personas: </span>{{venta.npeople}}</p>
                    <p><span >Origen: </span>{{venta.saleOrigin}}</p>
                    <p *ngIf="venta.clientId != 0"><span>Cliente:</span> {{client.firstName}} {{client.lastName}}</p>
                    
            
                
                    <p *ngIf="venta.saleChannel == 1"><span >Mesa</span>{{venta.mesaCode}}</p>
                </div>
                <div class="col-12 col-md-4 text-end">
                    <h1>$ {{venta.total | number}}</h1>
                    <p><span class="text-danger text-end">Saldo: </span>{{venta.saldo}}</p>
                    <p><span class="text-success text-end">Abono: </span>{{venta.abono}}</p>
                    <button *ngIf="venta.status != 2" class="btn btn-danger" (click)="abrirEnCaja(venta)">Abrir en Caja</button>
                </div>
            </div>


            <div class="row detVenta ">
                <div class="col-10 offset-1 table-responsive comanda papel shadow">
                    <h5 style="font-weight: 700;padding-left:10px"><i class="fa-solid fa-receipt"></i> Detalle</h5>
                    <hr>
                    <table class="table table-hover" style="border-bottom: rgba(0,0,0,0);">
                        <tbody *ngIf="!loadingDetalle">
                        <tr *ngFor="let d of detalleVenta ; index as i " class="pointer">
                            <th scope="row">{{i + 1}}</th>
                            <td >{{d.productName }}</td>
                            <td >{{d.quantity | number }} x $ {{d.price | number}}</td>
                            <td >$ {{d.total | number}} <span  *ngIf="d.discount > 0"  style="font-size:0.7em" class="text-danger"><br> $ -{{d.discount}}</span>        </td>
                        </tr>

                        </tbody>
                    </table>

                    <p *ngIf="loadingDetalle"> Cargando Detalle de la venta <i class="fas fa-spinner fa-spin"></i></p>

                </div>
            </div>


            <div class="row">
                <div  class="col-5 offset-1 ">
                    <p class ="text-success pointer hvr-grow" data-bs-toggle="collapse" data-bs-target="#pagosExample" aria-expanded="false" aria-controls="pagosExample"> <i class="fa-solid fa-hand-holding-dollar"></i> Ver pagos</p>
                </div>
                
                <div *ngIf="venta.docType != 'VNCE' && venta.docType != 'VNCM'" class="col-5 text-end">
                    <p class ="text-danger pointer hvr-grow" data-bs-dismiss="offcanvas" aria-label="Close"  (click)="hacerNC(venta)" > <i class="fas fa-times"></i> Anular con Nota de crédito</p>
                </div>

                <div class="col-10 offset-1 collapse" id="pagosExample">
                    <div class="card card-body table-responsive">

                        <table class="table table-hover" style="border-bottom: rgba(0,0,0,0);">

                            <thead>
                                <tr>
                                    <th scope="col">Medio de pago</th>
                                    <th scope="col">Pagado</th>
                                    <th scope="col">Abonado</th>
                                    <th scope="col">Propina</th>
                                    <th scope="col">Vuelto</th>
                                    <th scope="col">Atendió</th>
                                </tr>
                              </thead>


                            <tbody *ngIf="payments.length > 0">
                                <tr *ngFor="let p of payments ; index as i " class="pointer"
                                [ngClass]="p.status == 4 ? 'tachado' : '' ">
                                    <td >{{p.tipoPago.name }}</td>
                                    <td >$ {{p.amountP  | number}}</td>
                                    <td >$ {{p.amountA  | number}}</td>
                                    <td >$ {{p.tip  | number}}</td>
                                    <td >$ {{p.change  | number}}</td>
                                    <td >{{p.seller.firstName  | titlecase}} {{p.seller.lastName  | titlecase}}</td>
                                    <td></td>
                                </tr>
                            </tbody>
                            <p *ngIf="payments.length < 1" class="text-center">Sin pagos </p>
                        </table>

                    </div>
                  </div>

            </div>




        </div>
      </div>
    </div>
</div>

  <app-loading *ngIf="loadingHistorial"></app-loading>


<app-menu-stats></app-menu-stats>