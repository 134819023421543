export class clientModel {
    id: any;
    companyId: number;
    firstName: string;
    lastName: string;
    rut: string;
    phone: string;
    email: string;
    type: number;
    status: number;
    constructor() {
        this.id             = '';
        this.companyId      = 0;
        this.firstName      = '';
        this.lastName       = '';
        this.rut            = '';
        this.phone          = '';
        this.email          = '';
        this.type           = 1; // 1 cliente // 2 empresa
        this.status         = 1; // 0 borrado // 1 ok // 2:bloqueado
    }
}


